import {
  Box,
  Tabs,
  Tab,
  TextField,
  Stack,
  MenuItem,
  Button,
  styled,
  Badge,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { tableCellClasses } from "@mui/material";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import AdminApprovebtn from "../../Components/AdminApprovebtn/AdminApprovebtn";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../Sidebar/Sidebar";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7E0D0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.secondary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.black,
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));
const PurchaseReports = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  // GET PURCHASE BILLS FROM DATABASE

  const [purbills, setPurBills] = useState([]);

  async function getPurBills() {
    try {
      const response = await fetch("/purbilldtl", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setPurBills(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getPurBills();
  }, []);
  return (
    <>
   <Sidebar
        Title={"Purchase Approve"}
        expandedPanel={"panel2"}
        children={
      <Box p={1}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          <TableContainer component={Paper} sx={{ overflowY: "auto" }}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">Inward No</StyledTableCell>
                  <StyledTableCell align="left">Bill No</StyledTableCell>
                  <StyledTableCell align="left">Inward Date</StyledTableCell>
                  <StyledTableCell align="left">Branch</StyledTableCell>
                  <StyledTableCell align="left">Party</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {purbills.map((user) => (
                  <StyledTableRow
                    key={user.inwardid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="left">
                      {user.inwardno}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.billno}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.inwarddate}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.branchname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.subledgername}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AdminApprovebtn user={user} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>}/>
      {redirectToLoginPage()}
    </>
  );
};

export default PurchaseReports;
