import React, { useEffect, useRef } from "react";
import {
  Box,
  Stack,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Task from "@mui/icons-material/Task";

const PicklistView = () => {
  const iframeStyle = {
    width: "100%",
    height: "680px",
    "@media print": {
      ".hidden-print": {
        display: "none !important",
      },
    },
    /* Hide download button in Chrome */
    /* Note: This may not work in all versions of Chrome */
    "::-webkit-media-controls-overlay-play-button, ::-webkit-media-controls-enclosure":
      {
        display: "none !important",
      },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    p: 3,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const userToken = LocalstorageHelper.GetUserToken();

  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [pdfStream, setPdfStream] = useState(null);
  //view
  const viewReport = async (e) => {
    e.preventDefault();
    try {
      const report = await fetch(
        `/reports/view/getpicklist/${searchInput.corderheaderid}/${sorttype}`,
        {
          headers: {
            sptoken: userToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };

  const [searchInput, setSearchInput] = useState(null);
  const [param, setParam] = useState([]);
  const [subledgerCode, setSubledgerCode] = useState("");
  const [subledgerName, setSubledgerName] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSearchInput(null);
    setSubledgerCode("");
    setSubledgerName("");
  };
  async function getParam() {
    try {
      const response = await fetch("/reports/customerorderno", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);
  const [sorttype, setSortType] = useState("rackname");
  const sortoptions = [
    {
      value: "rackname",
      label: "Rack",
    },
    {
      value: "itemcode",
      label: "Item Code",
    },
  ];

  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const data = await fetch(
        `/pickList/download/excel/${searchInput.corderheaderid}/${sorttype}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `PickList_${searchInput.orderno}.xlsx`;

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="success"
        size="small"
        sx={{
          boxShadow: "1px 1px 3px  #404142",
          width: 120,
          marginBottom: "50px",
        }}
      >
        Pick List View
      </Button>
      <Box>
        <Dialog
          PaperProps={{
            style: {
              width: "80%", // Or any other percentage or pixel value
              maxWidth: "none", // Ensures the dialog can grow beyond the default max width
            },
          }}
          open={open}
        >
          <DialogTitle>Select PickList:</DialogTitle>
          <DialogContent sx={{ width: "100%", height: "auto" }}>
            <Stack direction={"row"} spacing={2}>
              <Autocomplete
                id="orderno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                  setSubledgerCode(newValue.subledgercode);
                  setSubledgerName(newValue.subledgername);
                }}
                options={param}
                getOptionLabel={(option) => option.orderno}
                sx={{
                  width: "200px ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Order No" />
                )}
                size="small"
              />
              <TextField
                required
                select
                id="sort by"
                label="Sort By"
                value={sorttype}
                onChange={(e) => setSortType(e.target.value)}
                variant="outlined"
                size="small"
                sx={{
                  width: "100px",
                }}
              >
                {sortoptions.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                size="small"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                Show Report
              </Button> */}
              <Button
                type="submit"
                onClick={viewReport}
                variant="contained"
                component="label"
                size="small"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                View Report
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Stack>
            <Stack mt={1} direction={"row"} spacing={2}>
              <TextField
                size="small"
                variant="outlined"
                value={subledgerCode}
                placeholder="Party Code"
              />
              <TextField
                sx={{ width: 500 }}
                size="small"
                variant="outlined"
                value={subledgerName}
                placeholder="Party Name"
              />
            </Stack>
            {/* {pdfStream && <ReportNavigation />} */}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  style={iframeStyle}
                  //src={pdfStream}
                  src={`${pdfStream}#toolbar=0`}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              tabIndex={-1}
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default PicklistView;
