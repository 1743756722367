import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack, MenuItem, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const PurchaseReturn = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    LocalstorageHelper.RemoveUserToken();
  }, 3600000);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //  GET BILL Search Parameters //
  const [partyopts, setpartyOpts] = useState([]);
  const [partydetails, setPartyDetails] = useState(null);
  async function getpartyOpts() {
    try {
      const response = await fetch("/purreturn/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //DOCUMENT NOS FOR EDIT MODE
  const [docopts, setDocOpts] = useState([]);
  const [docdetails, setDocDetails] = useState(null);
  async function getDocOpts() {
    try {
      const response = await fetch("/purreturn/editmode/documents", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setDocOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getDocOpts();
  }, []);

  const [billnos, setBillNos] = useState([]);
  const [billnoval, setBillNoVal] = useState(null);
  //HOLD STATE OF PARTY SEARCH INPUT

  const [billsearch, setBillSearch] = useState(null);
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  //   CLICKING ON PARTY SEARCH EXECUTES THE FOLLOWING    //
  const handleFetchBills = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/purreturn/fetchbills/${partydetails.partyid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setBillNos(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  //////////**************************** STATES OF TEXT FIELDS ************************************\\\\\\
  const date = new Date();
  const [billdate, setbilldate] = useState(null);
  const [documentdate, setdocumentdate] = useState(date);
  const [inwarddate, setinwarddate] = useState(null);
  const [docno, setDocNo] = useState("");

  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "srlno",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No",
      width: 60,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 150,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 250,
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 80,
      type: "number",
    },

    {
      field: "recvqty",
      headerClassName: "super-app-theme--header",
      headerName: "Received Qty",
      width: 100,
      type: "number",
    },
    {
      field: "returnqty",
      headerClassName: "super-app-theme--header",
      headerName: "Retn-Qty",
      width: 100,
      type: "number",
      editable: true,
      valueSetter: (params) => {
        if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) > parseFloat(params.row.recvqty)) {
          toast.error("Can't be Greater Than Sale Qty!", {
            toastId: "error2",
          });
        } else {
          const returnqty = params.value;
          const grossvalue = parseFloat(returnqty * params.row.rate).toFixed(2);
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(partydetails.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(partydetails.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(partydetails.stateid) === 1
              ? 0
              : parseFloat(gstamt).toFixed(2);
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = rows.findIndex(
            (obj) => obj.srlno === params.row.srlno
          );
          rows[upd_obj].returnqty = returnqty;
          rows[upd_obj].value = grossvalue;
          rows[upd_obj].discountamount = discountvalue;
          rows[upd_obj].taxableamount = taxablevalue;
          rows[upd_obj].cgstamount = cgstvalue;
          rows[upd_obj].sgstamount = sgstvalue;
          rows[upd_obj].igstamount = igstvalue;
          rows[upd_obj].netamount = netvalue;
          return {
            ...params.row,
            returnqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    {
      field: "rate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 100,
      type: "number",
    },
    {
      field: "discountamount",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Amt",
      width: 100,
      type: "number",
    },
    {
      field: "taxableamount",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 100,
      type: "number",
    },
    {
      field: "cgstamount",
      headerClassName: "super-app-theme--header",
      headerName: "CGST",
      width: 100,
      type: "number",
    },
    {
      field: "sgstamount",
      headerClassName: "super-app-theme--header",
      headerName: "SGST",
      width: 100,
      type: "number",
    },
    {
      field: "igstamount",
      headerClassName: "super-app-theme--header",
      headerName: "IGST",
      width: 100,
      type: "number",
    },
    {
      field: "netamount",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
  ];
  const emodecolumns = [
    {
      field: "srlno",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No",
      width: 60,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 150,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 250,
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 80,
      type: "number",
    },

    {
      field: "billqty",
      headerClassName: "super-app-theme--header",
      headerName: "Received Qty",
      width: 100,
      type: "number",
    },
    {
      field: "returnqty",
      headerClassName: "super-app-theme--header",
      headerName: "Retn-Qty",
      width: 100,
      type: "number",
      editable: true,
      valueSetter: (params) => {
        if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) > parseFloat(params.row.billqty)) {
          toast.error("Can't be Greater Than Sale Qty!", {
            toastId: "error2",
          });
        } else {
          const retnqty = params.value;
          const grossvalue = parseFloat(retnqty * params.row.rate).toFixed(2);
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(emodedetails.headerdata.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(emodedetails.headerdata.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(emodedetails.headerdata.stateid) === 1
              ? 0
              : parseFloat(gstamt).toFixed(2);
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = emodedetails.findIndex(
            (obj) => obj.srlno === params.row.srlno
          );
          emodedetails[upd_obj].returnqty = retnqty;
          emodedetails[upd_obj].value = grossvalue;
          emodedetails[upd_obj].discountamount = discountvalue;
          emodedetails[upd_obj].taxableamount = taxablevalue;
          emodedetails[upd_obj].cgstamount = cgstvalue;
          emodedetails[upd_obj].sgstamount = sgstvalue;
          emodedetails[upd_obj].igstamount = igstvalue;
          emodedetails[upd_obj].netamount = netvalue;
          return {
            ...params.row,
            retnqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    // {
    //   field: "mrp",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "MRP",
    //   width: 100,
    //   type: "number",
    // },
    {
      field: "rate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 100,
      type: "number",
    },
    {
      field: "discountamount",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Amt",
      width: 100,
      type: "number",
    },
    {
      field: "taxableamount",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 100,
      type: "number",
    },
    {
      field: "cgstamount",
      headerClassName: "super-app-theme--header",
      headerName: "CGST",
      width: 100,
      type: "number",
    },
    {
      field: "sgstamount",
      headerClassName: "super-app-theme--header",
      headerName: "SGST",
      width: 100,
      type: "number",
    },
    {
      field: "igstamount",
      headerClassName: "super-app-theme--header",
      headerName: "IGST",
      width: 100,
      type: "number",
    },
    {
      field: "netamount",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
  ];
  const [rows, setrows] = useState([]);

  // STORE GRID DETAILS

  const [griddata, setgriddata] = useState([]);
  const griddataarray = Object.values(griddata);
  //console.log(griddataarray);
  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [tottaxable, setTottaxable] = useState(0);
  const [totcgst, setTotcgst] = useState(0);
  const [totsgst, setTotsgst] = useState(0);
  const [totigst, setTotigst] = useState(0);
  const [totgross, setTotgross] = useState(0);
  const [totnet, setTotnet] = useState(0);
  const [totdiscount, setTotdiscount] = useState(0);

  function CustomFooterTotalComponent(props) {
    return (
      <Box sx={{ padding: "10px", height: 110 }}>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  TAXABLE
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  GROSS
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  DISCOUNT
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  NET
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.tottaxable}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.totcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totigst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totgross}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totdiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.totnet}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent.propTypes = {
    tottaxable: PropTypes.number,
    totcgst: PropTypes.number,
    totsgst: PropTypes.number,
    totigst: PropTypes.number,
    totgross: PropTypes.number,
    totnet: PropTypes.number,
    totdiscount: PropTypes.number,
  };
  function CustomFooterTotalComponent2(props) {
    return (
      <Box sx={{ padding: "10px", height: 110 }}>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  TAXABLE
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  GROSS
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  DISCOUNT
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  NET
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaltaxable}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.newtotalcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaligst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalgross}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaldiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.newtotalnet}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent2.propTypes = {
    newtotaltaxable: PropTypes.number,
    newtotalcgst: PropTypes.number,
    newtotalsgst: PropTypes.number,
    newtotaligst: PropTypes.number,
    newtotalgross: PropTypes.number,
    newtotaldiscount: PropTypes.number,
    newtotalnet: PropTypes.number,
  };

  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotaltaxable, setnewtotaltaxable] = useState(0);
  const [newtotalcgst, setnewtotalcgst] = useState(0);
  const [newtotaldiscount, setnewtotaldiscount] = useState(0);
  const [newtotalgross, setnewtotalgross] = useState(0);
  const [newtotalnet, setnewtotalnet] = useState(0);
  const [newtotalsgst, setnewtotalsgst] = useState(0);
  const [newtotaligst, setnewtotaligst] = useState(0);
  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  const editedDetails = Object.values(emodedetails);
  // SUBMIT PURCHASE RETURN DETAILS
  const handleSaleReturnSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        documentdate,
        billdate,
        griddataarray,
        tottaxable,
        totgross,
        totcgst,
        totsgst,
        totigst,
        totdiscount,
        totnet,
        billamt,
        roundoff,
        billnoval,
      };
      const response = await fetch("/purreturn/new", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.documentid !== null && parseRes.message === "success") {
        toast.success(
          `Document No ${parseRes.documentno} Generated Successfully!`,
          {
            autoClose: 3000,
          }
        );
      } else {
        toast.error(parseRes.message);
        toast.error("Something Went Wrong!");
        console.log(parseRes.error);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [partydisabled, setpartydisabled] = useState(false);
  const [outwarddisabled, setoutwarddisabled] = useState(false);
  // SOURCES FLAG  DROPDOWN VALUES AND HANDLER
  const [mode, setMode] = useState(1);

  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];

  //Fetch Bill Details
  const handlebilldetails = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/purreturn/billdetails/${billnoval.inwardid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setrows(jsonData);
      setbilldate(jsonData[0].billdate);
      setinwarddate(jsonData[0].inwarddate);
    } catch (err) {
      console.log(err.message);
    }
  };
  // FETCH DATA FOR EDITMODE
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/purreturn/editmode/data/${docdetails.documentid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);
      setEmodeDetails(jsonData.detail);
      setdocumentdate(jsonData.headerdata.documentdate);
      setbilldate(jsonData.headerdata.billdate);
      setDocNo(jsonData.headerdata.documentno);
      setinwarddate(jsonData.headerdata.inwarddate);
    } catch (err) {
      console.log(err.message);
    }
  };
  var billamt = Math.round(parseFloat(totnet));
  var roundoff = parseFloat(billamt - parseFloat(totnet).toFixed(2)).toFixed(2);
  var emodebillamt = Math.round(parseFloat(newtotalnet));
  var emoderoundoff = parseFloat(
    emodebillamt - parseFloat(newtotalnet).toFixed(2)
  ).toFixed(2);

  /// SUBMIT EDITMODE DETAILS

  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        emodedetails,
        newtotaltaxable,
        newtotalcgst,
        newtotalsgst,
        newtotaligst,
        newtotalgross,
        newtotaldiscount,
        newtotalnet,
        emodebillamt,
        emoderoundoff,
        documentdate,
      };
      const response = await fetch("/salereturn/emodesubmit", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      toast.success(parseRes);
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"PURCHASE RETURN"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"35%"} mb={"40px"} direction="row">
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={docdetails}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setDocDetails(newValue);
                }}
                options={docopts}
                getOptionLabel={(option) => option.documentdetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Document" />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={6} direction="row" mt={4} ml={4}>
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (parseInt(e.target.value) === 1) {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartydisabled(false);
                    setoutwarddisabled(false);
                  } else if (parseInt(e.target.value) === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartydisabled(true);
                    setoutwarddisabled(true);
                  }
                }}
                helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack spacing={2} columnGap={2} direction="row" mt={4} ml={4}>
              <TextField
                disabled
                id="documentNo"
                label="Document No"
                value={docno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Document Date"
                  inputFormat="DD/MM/YYYY"
                  value={documentdate}
                  onChange={(newValue) => {
                    setdocumentdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Inward Date"
                  inputFormat="DD/MM/YYYY"
                  value={inwarddate}
                  onChange={(newValue) => {
                    setinwarddate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <TextField
                id="againstBill"
                required
                select
                label="Against Bill"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                helperText="Please select  YES or NO ."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Category.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}

              <Autocomplete
                id="party"
                value={partydetails}
                disabled={partydisabled}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setPartyDetails(newValue);
                  //console.log(newValue);
                }}
                options={partyopts}
                getOptionLabel={(option) => option.partydetails}
                sx={{
                  width: "20% ",
                }}
                renderInput={(ordernos) => (
                  <TextField required {...ordernos} placeholder="Party" />
                )}
                size="small"
              />
              <Button onClick={handleFetchBills}>FETCH</Button>
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={4}
              ml={4}
              mb={4}
            >
              <Autocomplete
                id="inwardNo"
                value={billnoval}
                disabled={outwarddisabled}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setBillNoVal(newValue);
                }}
                options={billnos}
                getOptionLabel={(option) => option.inwardno}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billnos) => (
                  <TextField required {...billnos} placeholder="Inward No" />
                )}
                size="small"
              />
              <Button onClick={handlebilldetails}>FETCH</Button>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Bill Date"
                  disabled
                  inputFormat="DD/MM/YYYY"
                  value={billdate}
                  onChange={(newValue) => {
                    setbilldate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                id="roundoff"
                label="Round Off"
                value={parseInt(mode) === 2 ? emoderoundoff : roundoff}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="billamt"
                label="Bill Amount"
                value={parseInt(mode) === 2 ? emodebillamt : billamt}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                <DataGrid
                  sx={{ border: "1px solid #000" }}
                  rowHeight={25}
                  headerHeight={40}
                  showColumnRightBorder={true}
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.srlno}
                  onStateChange={(params) => {
                    setgriddata(params.rows.idRowsLookup);
                    const taxabletotal = griddataarray
                      .map((item) => parseFloat(item.taxableamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTottaxable(taxabletotal);
                    const cgsttotal = griddataarray
                      .map((item) => parseFloat(item.cgstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotcgst(cgsttotal);
                    const sgsttotal = griddataarray
                      .map((item) => parseFloat(item.sgstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotsgst(sgsttotal);
                    const igsttotal = griddataarray
                      .map((item) => parseFloat(item.igstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotigst(igsttotal);
                    const grosstotal = griddataarray
                      .map((item) => parseFloat(item.value))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotgross(grosstotal);
                    const discounttotal = griddataarray
                      .map((item) => parseFloat(item.discountamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotdiscount(discounttotal);
                    const nettotal = griddataarray
                      .map((item) => parseFloat(item.netamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotnet(nettotal);
                  }}
                  components={{
                    Footer: CustomFooterTotalComponent,
                  }}
                  componentsProps={{
                    footer: {
                      tottaxable,
                      totcgst,
                      totsgst,
                      totigst,
                      totgross,
                      totdiscount,
                      totnet,
                    },
                  }}
                />

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    type="submit"
                    onClick={handleSaleReturnSubmit}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    SAVE DOCUMENT
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                <DataGrid
                  sx={{ border: "1px solid #000" }}
                  rowHeight={25}
                  headerHeight={40}
                  showColumnRightBorder={true}
                  rows={emodedetails}
                  columns={emodecolumns}
                  getRowId={(row) => row.srlno}
                  onStateChange={(params) => {
                    const taxabletotal = emodedetails
                      .map((item) => parseFloat(item.taxableamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaltaxable(taxabletotal);
                    const cgsttotal = emodedetails
                      .map((item) => parseFloat(item.cgstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalcgst(cgsttotal);
                    const sgsttotal = emodedetails
                      .map((item) => parseFloat(item.sgstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalsgst(sgsttotal);
                    const igsttotal = emodedetails
                      .map((item) => parseFloat(item.igstamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaligst(igsttotal);
                    const grosstotal = emodedetails
                      .map((item) => parseFloat(item.value))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalgross(grosstotal);
                    const discounttotal = emodedetails
                      .map((item) => parseFloat(item.discountamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaldiscount(discounttotal);
                    const nettotal = emodedetails
                      .map((item) => parseFloat(item.netamount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalnet(nettotal);
                  }}
                  components={{
                    Footer: CustomFooterTotalComponent2,
                  }}
                  componentsProps={{
                    footer: {
                      newtotaltaxable,
                      newtotalcgst,
                      newtotalsgst,
                      newtotaligst,
                      newtotalgross,
                      newtotaldiscount,
                      newtotalnet,
                    },
                  }}
                />

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    type="submit"
                    onClick={handleEditmodeSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    UPDATE DOCUMENT
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default PurchaseReturn;
