import React, { useEffect } from "react";
import {
  Box,
  Stack,
  Autocomplete,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Task from "@mui/icons-material/Task";

const Deliverynote = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    p: 3,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const userToken = LocalstorageHelper.GetUserToken();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const date = new Date();
  const [deliverynotedate, setdeliverynotedate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const handleReport = async (e) => {
    e.preventDefault();

    if (window.confirm("Do You Want To Save the Delivery Note?")) {
      try {
        const headers = {
          "Content-Type": "application/json",
          sptoken: userToken,
        };
        const requestBody = {
          deliverynotedate,
          subledgerid: searchInput.subledgerid,
        };
        const report = await fetch(
          `/deliverynote/report/${searchInput.billheaderid}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestBody),
          }
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const [searchInput, setSearchInput] = useState(null);
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/deliverynote/bills", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
        size="small"
        sx={{ width: 150 }}
      >
        DELIVERY NOTE
      </Button>
      <Modal
        open={open}
        aria-labelledby="modl-box"
        aria-describedby="search partitem"
      >
        <Box sx={style}>
          <Stack spacing={5} columnGap={2} direction="row" mt={2} ml={40}>
            <Autocomplete
              id="billno"
              value={searchInput}
              filterOptions={filterOptions}
              onChange={(event, newValue) => {
                setSearchInput(newValue);
              }}
              options={param}
              getOptionLabel={(option) => option.billdetails}
              sx={{
                width: "30% ",
              }}
              renderInput={(billopts) => (
                <TextField required {...billopts} placeholder="Invoice No" />
              )}
              size="small"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                inputFormat="DD/MM/YYYY"
                value={deliverynotedate}
                onChange={(newValue) => {
                  setdeliverynotedate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>

            <Button
              //type="submit"
              onClick={handleReport}
              variant="contained"
              component="label"
              size="large"
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              endIcon={<Task />}
            >
              SAVE
            </Button>
          </Stack>
          {pdfStream && <ReportNavigation />}
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStream && (
              <iframe
                src={pdfStream}
                width="100%"
                height="680px"
                title="report"
              ></iframe>
            )}
          </Box>

          <Box sx={{ mx: "45%", mb: "10px", mt: "20px" }}>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Deliverynote;
