import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

toast.configure();
const RackwiseStockList = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const options = [
    {
      name: "All",
      value: 2,
    },
    {
      name: "In Stock",
      value: 1,
    },
  ];

  const [optvalue, setOptValue] = useState(1);
  const [pdfStream, setPdfStream] = useState(null);
  const [rackname, setrackname] = useState(null);
  const [rackinput, setrackinput] = useState([]);
  const [loading, setLoading] = useState(false);
  async function fetchRackname() {
    try {
      const res = await fetch("/reports/spare/rackwisestock/racknos", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      setrackinput(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    fetchRackname();
  }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (rackname === null) {
      setLoading(false)
      return toast.error("Please Enter Rackname");
    }
    try {
      // const fromDateStr = fromdate.toISOString();
      // const toDateStr = todate.toISOString();
      const res = await fetch(
        `/reports/spare/rackwisestock/${optvalue}/${rackname.rackid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const data = await res.blob();
      const pdfUrl = URL.createObjectURL(data)
      setPdfStream(pdfUrl);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      // const fromDateStr = fromdate.toISOString();
      // const toDateStr = todate.toISOString();
      const res = await fetch(
        `/reports/spare/rackwisestock/download/${optvalue}/${option}/${rackname.rackid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `RackWIseStock_${rackname.rackname}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `RackWIseStock_${rackname.rackname}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const body = { rackid: rackname?.rackid, opt: optvalue };
      const data = await fetch("/rackwisestock/download/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "rackwisestock.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"RACK WISE STOCK LIST"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                disablePortal
                value={rackname}
                options={rackinput}
                sx={{ width: 250 }}
                size="small"
                filterOptions={filterOptions}
                getOptionLabel={(opt) => opt.rackname}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    return;
                  }
                  setrackname(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Rack Name" />
                )}
              />
              <TextField
                id="options"
                name="options"
                required
                select
                label="Options"
                value={optvalue}
                onChange={(e) => setOptValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                disabled={loading===true}
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
               {loading ? "Fetching Data..." : " Show Report"}
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Box>
            {pdfStream && (
              <ReportNavigation
                onDownload={handleDownload}
                reportName={"rackwisestock"}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default RackwiseStockList;
