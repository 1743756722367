import React from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, Autocomplete, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { darken, lighten, styled } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
toast.configure();
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Rejected": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));
//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const SaleOrderStatus = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const date = new Date();
  const [fromdate, setfromdate] = useState(date);
  const [todate, settodate] = useState(date);
  const [rows, setrows] = useState([]);
  const [sumordervalue, setsumordervalue] = useState(0);
  const [sumavailablevalue, setsumavailablevalue] = useState(0);
  const [sumopenvalue, setsumopenvalue] = useState(0);
  const [sumpicklistopenvalue, setsumpicklistopenvalue] = useState(0);
  const [sumclosedvalue, setsumclosedvalue] = useState(0);
  const [autoFetch, setAutoFetch] = useState(false);
  const handleStatusFetch = async (e) => {
    e.preventDefault();
    try {
      const body = { fromdate, todate };
      const response = await fetch("/customerorder/getstatus", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const jsonData = await response.json();
      setrows(jsonData.orderstatus);
      setsumordervalue(jsonData.sumofvalues.sumordervalue);
      setsumavailablevalue(jsonData.sumofvalues.sumavailablevalue);
      setsumopenvalue(jsonData.totalopen.sumopenvalue);
      setsumpicklistopenvalue(jsonData.totalpicklistopen.sumpicklistopenvalue);
      setsumclosedvalue(jsonData.totalclosed.sumclosedvalue);
      setAutoFetch(true);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleAutoFetch = async () => {
    try {
      const body = { fromdate, todate };
      const response = await fetch("/customerorder/getstatus", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const jsonData = await response.json();
      setrows(jsonData.orderstatus);
      setsumordervalue(jsonData.sumofvalues.sumordervalue);
      setsumavailablevalue(jsonData.sumofvalues.sumavailablevalue);
      setsumopenvalue(jsonData.totalopen.sumopenvalue);
      setsumpicklistopenvalue(jsonData.totalpicklistopen.sumpicklistopenvalue);
      setsumclosedvalue(jsonData.totalclosed.sumclosedvalue);
      // setAutoFetch(true);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  // useEffect(() => {
  //   let intervalId; // Declare intervalId variable to clear interval when component unmounts
  //   if (autoFetch) {
  //     intervalId = setInterval(() => {
  //       handleAutoFetch();
  //     }, 3000);
  //   }

  //   return () => {
  //     clearInterval(intervalId); // Clear interval when component unmounts
  //   };
  // }, [autoFetch, fromdate, todate]);
  const columns = [
    {
      field: "orderno",
      headerName: "Order Number",
      width: 150,
    },
    {
      field: "billno",
      headerName: "Bill No",
      width: 150,
      valueGetter: (params) => {
        return params.row.cancelflag === true ? "N/A" : params.row.billno;
      },
    },
    {
      field: "orderdate",
      headerName: "Order Date",
      width: 100,
    },
    {
      field: "subledgercode",
      headerName: "Cust. Code",
      width: 90,
    },
    {
      field: "subledgername",
      headerName: "Customer Name",
      width: 300,
    },
    {
      field: "totalordervalue",
      headerName: "Total Order Value",
      width: 120,
    },
    {
      field: "netamt",
      headerName: "Available Order Value",
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      valueGetter: (params) => {
        if (params.row.salebillid !== null) {
          return "Closed";
        } else if (params.row.useridpicklist !== null) {
          return "Picklist Open";
        } else if (
          params.row.salebillid === null &&
          params.row.useridpicklist === null &&
          params.row.cancelflag === true
        ) {
          return "Cancelled";
        } else {
          return "Open";
        }
      },
    },
    {
      field: "subledgercatgname",
      headerName: "Customer Type",
      width: 140,
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
    },
  ];
  return (
    <div>
      <Sidebar
        Title={"Sale Order Status"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              columnGap={4}
              direction="row"
              sx={{
                width: 750,
                margin: "auto",
                p: 3,
              }}
              autoComplete="off"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "30%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "30%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                size="small"
                onClick={handleStatusFetch}
              >
                View
              </Button>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
                columnGap: 5,
                p: 2,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="subtitle1"
                sx={{ pr: 2 }}
              >
                Total Opening Value: {sumopenvalue}
              </Typography>
              <Typography fontWeight={"bold"} variant="subtitle1">
                Total Picklist Opening Value: {sumpicklistopenvalue}
              </Typography>
              <Typography fontWeight={"bold"} variant="subtitle1">
                Total Closing Value: {sumclosedvalue}
              </Typography>
            </Box>
            <Box
              sx={{
                height: 550,
                width: "100%",
                boxShadow: "2px 6px 15px #5a5a5a",
              }}
            >
              <StyledDataGrid
                sx={{ border: "1px solid #000" }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.corderheaderid}
                getRowClassName={(params) => {
                  if (params.row.cancelflag === true) {
                    return "super-app-theme--Rejected";
                  } else {
                    return "";
                  }
                }}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
                columnGap: 5,
                m: 2,
                p: 2,
              }}
            >
              <Typography
                fontWeight={"bold"}
                variant="subtitle1"
                sx={{ pr: 2 }}
              >
                Sum of order value: {sumordervalue}
              </Typography>
              <Typography fontWeight={"bold"} variant="subtitle1">
                Sum of available value: {sumavailablevalue}
              </Typography>
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SaleOrderStatus;
