import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Fab,
  Tooltip,
  Typography,
  Switch,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingAnimation from "../../Components/LoadingAnimation";

toast.configure();
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const ShortExcess = () => {
  const [loading, setLoading] = useState(true);

  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    LocalstorageHelper.RemoveUserToken();
  }, 3600000);
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const currentDate = new Date();
  const [date, setdate] = useState(currentDate);
  const [inwarddate, setinwarddate] = useState(null);
  const [shortexcessinput, setshortexcessinput] = useState(null);
  console.log(shortexcessinput);
  const [shortexcessopts, setshortexcessopts] = useState([]);
  const [partyinput, setpartyinput] = useState(null);
  console.log(partyinput);
  const [partyopts, setpartyopts] = useState([]);
  const [invnoinput, setinvnoinput] = useState(null);
  const [invnoopts, setinvnoopts] = useState([]);
  const [partnoinput, setpartnoinput] = useState(null);
  // console.log(partnoinput)
  const [partnoopts, setpartnoopts] = useState([]);
  const [billqty, setbillqty] = useState(0);
  const [recvqty, setrecvqty] = useState(0);
  const [shortqty, setshortqty] = useState(0);
  const [excessqty, setexcessqty] = useState(0);
  const [disableshort, setdisableshort] = useState(false);
  const [disableaddnew, setdisableaddnew] = useState(true);
  const [disablesave, setdisablesave] = useState(false);
  const [disableupdate, setdisableupdate] = useState(false);
  const [pdfStream, setPdfStream] = useState(null);
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
  const mode = [
    {
      name: "New",
      value: 1,
    },
    {
      name: "Edit",
      value: 2,
    },
  ];
  const [modeValue, setmodeValue] = useState(1);
  ///party data
  async function getpartyOpts() {
    try {
      const response = await fetch("/shortexc/parties", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyopts(jsonData.parties);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);
  ///part no
  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartnoopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  ///inwardid
  async function getinwardid() {
    try {
      const data = await fetch(
        `/shortexc/inwardnos/${partyinput.subledgerid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const res = await data.json();
      if (res.type === "success") {
        setinvnoopts(res.bills);
        setinwarddate(res.bills[0].inwarddate);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getinwardid();
  }, [partyinput]);
  //check items
  async function getItemChecked() {
    try {
      const data = await fetch(
        `/shortexc/checkitem/${partnoinput.itemid}/${invnoinput.inwardid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const res = await data.json();
      // console.log(res)
      if (res.status === "found") {
        toast.info("Found!!");
        setbillqty(res.qtyData.billqty);
        setrecvqty(res.qtyData.recvqty);
        setdisableshort(false);
      } else {
        toast.info("Not Found");
        setdisableshort(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getItemChecked();
  }, [partnoinput]);
  //docno
  async function getdocno() {
    try {
      const data = await fetch("/shortexcess/docnos", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const res = await data.json();
      setshortexcessopts(res);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getdocno();
  }, []);
  //editmode data
  async function getdocnodata() {
    try {
      const data = await fetch(
        `/shortexcess/editdata/${shortexcessinput.stexcheaderid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const res = await data.json();
      setdate(res.headerdata.stexcdate);
      setpartyinput(
        partyopts.find((opt) => opt.subledgerid === res.headerdata.partyid)
      );
      setinvnoinput({
        inwardid: res.headerdata.inwardid,
        inwardno: res.headerdata.inwardno,
      });
      setrows(res.detaildata);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getdocnodata();
  }, [shortexcessinput]);

  //save
  const handlesave = async (e) => {
    e.preventDefault();
    setdisablesave(true);
    try {
      const body = {
        itemdata: rows,
        billid: invnoinput.inwardid,
        partyid: partyinput.subledgerid,
        stexcdate: date,
      };
      const data = await fetch("/shortexc/save", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const res = await data.json();
      if (res.type === "success") {
        toast.success(res.message, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        toast.error(res.message);
        setdisablesave(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //update
  const handleupdate = async (e) => {
    e.preventDefault();
    setdisableupdate(true);
    try {
      const body = {
        itemdata: rows,
        billid: invnoinput.inwardid,
        partyid: partyinput.subledgerid,
        stexcdate: date,
      };
      const data = await fetch(
        `/shortexcess/update/${shortexcessinput.stexcheaderid}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const res = await data.json();
      if (res.type === "success") {
        setTimeout(() => {
          window.location.reload();
          toast.info(res.message);
        }, 2500);
      } else {
        toast.error(res.message);
        setdisableupdate(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "srl",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No.",
      type: "number",
      width: 60,
    },
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Part No",
      width: 350,
    },
    {
      field: "billqty",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Qty",
      type: "number",
      width: 80,
    },
    {
      field: "recvqty",
      headerClassName: "super-app-theme--header",
      headerName: "Received Qty",
      type: "number",
      width: 100,
    },
    {
      field: "short",
      headerClassName: "super-app-theme--header",
      headerName: "Short",
      type: "number",
      width: 80,
    },
    {
      field: "excess",
      headerClassName: "super-app-theme--header",
      headerName: "Excess",
      type: "number",
      width: 80,
    },
  ];
  const [rows, setrows] = useState([]);
  //addrow to datagrid
  const handleaddrow = (e) => {
    e.preventDefault();
    if (shortqty === 0 && excessqty === 0) {
      toast.error("Choose Only Short Or Excess!!");
    } else if (shortqty < 0 || excessqty < 0) {
      toast.error("Do Not Put Negative Value!!");
    } else if (date === null || date === "") {
      toast.error("Chosse Date!!");
    } else {
      setrows((oldrows) => [
        ...oldrows,
        {
          srl: oldrows.length + 1,
          partno: partnoinput.partdetails,
          itemid: partnoinput.itemid,
          billqty: billqty,
          recvqty: recvqty,
          short: 0 - shortqty,
          excess: excessqty,
        },
      ]);
    }
    setbillqty(0);
    setrecvqty(0);
    setshortqty(0);
    setexcessqty(0);
    setpartnoinput(null);
  };
  const handleDeleteClick = (id) => () => {
    setrows(rows.filter((row) => row.srl !== id));
  };
  //print bill
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `/shortexcess/print/${shortexcessinput.stexcheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <div>
        <Sidebar
          Title={"SHORT EXCESS"}
          expandedPanel={"panel2"}
          children={
            <Box
              component="form"
              sx={{
                width: "100%",
              }}
              autoComplete="off"
            >
              <Stack spacing={2} columnGap={1} direction="row" mt={1} ml={1}>
                <TextField
                  id="mode"
                  name="mode"
                  required
                  select
                  label="Mode"
                  value={modeValue}
                  onChange={(e) => setmodeValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                >
                  {mode.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Box display={parseInt(modeValue) === 1 ? "none" : "flex"}>
                  <Autocomplete
                    id="Short Excess No"
                    value={shortexcessinput}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setshortexcessinput(newValue);
                    }}
                    options={shortexcessopts}
                    getOptionLabel={(option) => option.docno}
                    sx={{
                      width: 300,
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        placeholder="Short Excess No."
                      />
                    )}
                    size="small"
                  />
                  <Button
                    disabled={shortexcessinput === null}
                    onClick={handleReport}
                    variant="contained"
                    component="label"
                    size="small"
                    sx={{ ml: 5, boxShadow: "2px 2px 6px  #404142" }}
                    //endIcon={<Task />}
                  >
                    Print
                  </Button>
                </Box>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={1} ml={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    value={date || null}
                    onChange={(newValue) => {
                      setdate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: 200,
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>

                <Autocomplete
                  id="Party"
                  value={partyinput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setpartyinput(newValue);
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.partydetails}
                  sx={{
                    width: 300,
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} placeholder="Party" />
                  )}
                  size="small"
                />
                <Autocomplete
                  id="Order Search"
                  value={invnoinput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setinvnoinput(newValue);
                  }}
                  options={invnoopts}
                  getOptionLabel={(option) => option.inwardno}
                  sx={{
                    width: 250,
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} placeholder="Inward No." />
                  )}
                  size="small"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Inward Date"
                    inputFormat="DD/MM/YYYY"
                    value={inwarddate || null}
                    onChange={(newValue) => {
                      setinwarddate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: 200,
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={3} ml={1}>
                <Autocomplete
                  id="Part No."
                  value={partnoinput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setpartnoinput(newValue);
                  }}
                  options={partnoopts}
                  getOptionLabel={(option) => option.partdetails}
                  sx={{
                    width: 400,
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} placeholder="Part No." />
                  )}
                  size="small"
                />
                <TextField
                  disabled
                  id="altitem"
                  label="Bill Qty"
                  type="number"
                  value={billqty}
                  // onChange={(e) => setbillqty(e.target.value)}
                  sx={{
                    width: "100px",
                  }}
                  size="small"
                  inputProps={{ tabIndex: "-1" }}
                />
                <TextField
                  disabled
                  id="altitem"
                  label="Received Qty"
                  type="number"
                  value={recvqty}
                  // onChange={(e) => setrecvqty(e.target.value)}
                  sx={{
                    width: "100px",
                  }}
                  size="small"
                  inputProps={{ tabIndex: "-1" }}
                />
                <TextField
                  disabled={disableshort}
                  id="altitem"
                  label="Short"
                  type="number"
                  value={shortqty}
                  onChange={(e) => setshortqty(e.target.value)}
                  sx={{
                    width: "100px",
                  }}
                  size="small"
                  inputProps={{ tabIndex: "-1" }}
                />
                <TextField
                  id="altitem"
                  label="Excess"
                  type="number"
                  value={excessqty}
                  onChange={(e) => setexcessqty(e.target.value)}
                  sx={{
                    width: "100px",
                  }}
                  size="small"
                  inputProps={{ tabIndex: "-1" }}
                />
                <Button
                  disabled={partnoinput === null ? true : false}
                  onClick={handleaddrow}
                  size="small"
                  variant="contained"
                >
                  Add Row
                </Button>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={1} ml={1}>
                <Box
                  sx={{
                    height: 550,
                    width: "100%",
                    position: "relative",
                    mt: "15px",
                    boxShadow: "2px 6px 15px #5a5a5a",
                    // border: "0.5px solid black",
                    // borderRadius: "5px",
                    "& .super-app-theme--header": {
                      //   backgroundColor: "#3bb9ff",
                      color: "#000",
                    },
                  }}
                >
                  <DataGrid
                    sx={{ border: "1px solid #000" }}
                    rowHeight={25}
                    headerHeight={40}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "serial", sort: "desc" }],
                      },
                    }}
                    showColumnRightBorder={true}
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    getRowId={(row) => row.srl}
                  />
                </Box>
              </Stack>
              <Stack spacing={2} columnGap={1} direction="row" mt={3} ml={1}>
                {parseInt(modeValue) === 1 ? (
                  <>
                    <Button
                      disabled={disablesave}
                      onClick={handlesave}
                      variant="contained"
                      size="small"
                      color="success"
                    >
                      SAVE
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={disableupdate}
                      onClick={handleupdate}
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      UPDATE
                    </Button>
                  </>
                )}
              </Stack>
              <Box sx={{ backgroundColor: "white", mt: 5 }}>
                {pdfStream && (
                  <iframe
                    ref={myElementRef}
                    src={pdfStream}
                    width="100%"
                    height="680px"
                  ></iframe>
                )}
              </Box>
            </Box>
          }
        />
      </div>
    </>
  );
};

export default ShortExcess;
