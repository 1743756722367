import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
toast.configure();

const TvsMIS = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  const date = new Date();
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);

  //report
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (fromdate === null) {
        setLoading(false)
        toast.error("Please Select A valid Date!");
      } else {
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "GET",
        };
        const fromDateStr = fromdate.toISOString();
        const toDateStr = todate.toISOString();
        const response = await fetch(
          `/reports/spare/tvsMIS/download/${fromDateStr}/${toDateStr}`,
          options
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
          setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };
  // const handleReport1 = async (e) => {
  //   e.preventDefault();
  //   if (fromdate === null) {
  //     return toast.error("Please Enter Date");
  //   }
  //   try {
  //     const fromDateStr = fromdate.toISOString();
  //     const toDateStr = todate.toISOString();
  //     const res = await fetch(
  //       `/reports/spare/tvsMIS/download/${fromDateStr}/${toDateStr}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           sptoken: userToken,
  //         },
  //       }
  //     );
  //     const data = await res.blob();
  //     setPdfStream(data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      //partyarray = parray.map((opt) => opt.subledgerid);
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const data = await fetch("/reports/TVSMIS/dataonly/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "TVSMIS.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"TVS MIS"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                disabled={loading===true}
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
              <Button
                type="submit"
                onClick={printexcel}
                variant="contained"
                component="label"
                size="large"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                //endIcon={<Task />}
              >
                Download Excel
              </Button>
            </Box>
            {/* {pdfStream && (
          <ReportNavigation
            onDownload={handleDownload}
            reportName={"GOVT GST"}
          />
        )} */}
            <Box sx={{ backgroundColor: "white", mt: 2 }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default TvsMIS;
