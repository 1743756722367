import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, MenuItem, Autocomplete, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Transporter = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const [transportercode, setTransporterCode] = useState("");
  const [transportername, setTransporterName] = useState("");
  const [transporterphone, setTransporterPhone] = useState("");
  const [availableday, setAvailableDay] = useState("");
  const [startingdate, setStartingDate] = useState(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [gstno,setGstNo] = useState("");
  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      setInsertDisabled(true);
      try {
        const body = {
          transportercode,
          transportername,
          transporterphone,
          availableday,
          startingdate,
          address1,
          address2,
          address3,
          gstno
        };
        const response = await fetch("/insertTransporter", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.transporterid) {
          toast.success("Transporter Created Successfully", {
            autoClose: 2000,
          });

          //RESET ALL STATE VALUES
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      setUpdatedisabled(true);
      try {
        const body = {
          transportercode,
          transportername,
          transporterphone,
          availableday,
          startingdate,
          address1,
          address2,
          address3,
          gstno
        };
        const response = await fetch(
          `/updateTransporter/${searchInput.transporterid}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json", sptoken: userToken },
            body: JSON.stringify(body),
          }
        );
        toast.success(await response.json(), { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `/deleteTransporter/${searchInput.transporterid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/transportersearch", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/transporterfetch/${searchInput.transporterid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData !== "") {
        setUpdatedisabled(false);
        setInsertDisabled(true);
        setTransporterCode(jsonData.transportercode);
        setTransporterName(jsonData.transportername);
        setTransporterPhone(jsonData.mobileno);
        setAvailableDay(jsonData.availableday);
        setStartingDate(jsonData.doj);
        setAddress1(jsonData.address1);
        setAddress2(jsonData.address2);
        setAddress3(jsonData.address3);
        setGstNo(jsonData.gstno);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  //GENERATE AUTO CODE

  const [Fetch, setFetch] = useState(false);

  const handleTransporterName = (e) => {
    if (!insertDisabled) {
      setTransporterName(e.target.value);
      setFetch(true);
      if (maxCode !== null) {
        const maxCodeNoStr = maxCode.slice(1); // sliced first String e.g- From 'D002' => '002'
        const maxCodeNo = parseInt(maxCodeNoStr); // '002' => 2
        const newMaxCode = (maxCodeNo + 1).toString(); // 2 => '3'
        const padString = newMaxCode.padStart(3, "0"); // '003'
        const finalValue = transportername
          .charAt(0)
          .toUpperCase()
          .concat(padString);
        setTransporterCode(finalValue);
      } else {
        setMaxCode(transportername.charAt(0).toUpperCase().concat("000"));
      }
    }
    if (insertDisabled) {
      setFetch(false);
      setTransporterName(e.target.value);
    }
  };
  const [maxCode, setMaxCode] = useState(""); // max Code e.g - 'D002'
  async function getMaxTransporterCode() {
    try {
      const response = await fetch(
        `/maxtransportercode/${transportername.charAt(0).toUpperCase()}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      setFetch(false);
      const jsonData = await response.json();
      setMaxCode(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    if (Fetch === true) {
      getMaxTransporterCode();
    }
  }, [Fetch]);

  return (
    <div>
      <Sidebar
        Title={"TRANSPORTER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.transporterdetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                required
                disabled
                id="transporterCode"
                label="Transporter Code"
                value={transportercode}
                type="text"
                sx={{
                  width: "25%",
                }}
              />
              <TextField
                required
                id="transporterName"
                label="Transporter Name"
                value={transportername}
                onChange={handleTransporterName}
                type="text"
                sx={{
                  width: "25%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                id="transporterPhone"
                label="Phone No"
                value={transporterphone}
                onChange={(e) => setTransporterPhone(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
              />

              <TextField
                required
                id="availableDay"
                label="Available Day"
                value={availableday}
                onChange={(e) => setAvailableDay(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Starting Date"
                  inputFormat="DD/MM/YYYY"
                  value={startingdate}
                  onChange={(newValue) => {
                    setStartingDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "25%",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                required
                id="Address1"
                label="Address 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
              />
              <TextField
                id="Address2"
                label="Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
              />

              <TextField
                id="Address3"
                label="Address3"
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                id="gstno"
                label="GST NO"
                value={gstno}
                onChange={(e) => setGstNo(e.target.value)}
                sx={{
                  width: "25%",
                }}
              />
            </Stack>
            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setTransporterCode("");
                  setTransporterName("");
                  setTransporterPhone("");
                  setAvailableDay("");
                  setAddress1("");
                  setAddress2("");
                  setAddress3("");
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setStartingDate(null);
                  setSearchInput(null);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Transporter;
