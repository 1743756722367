import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Typography,
  Tooltip,
  Fab,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Debitnote = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const [mode, setMode] = useState(1);
  const datenow = new Date();
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];
  const [billmode, setbillMode] = useState(false);
  const billmodes = [
    {
      value: true,
      label: "With Bill",
    },
    {
      value: false,
      label: "With Out Bill",
    },
  ];

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [billsearch, setBillSearch] = useState(null);
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  //----------- Modal Handler-------\\

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const [disableSave, setDisableSave] = useState(false);
  const handlesave = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = {
        debitnoteno: debitnoteno,
        dndate: date,
        accountid: acname.accountid,
        partyid: partyInput.subledgerid,
        billflag: billmode,
        billid: billno?.billheaderid,
        taxable: taxablevalue,
        cgstrate: cgstpers,
        cgstamt: cgstamt,
        sgstrate: sgstpers,
        sgstamt: sgstamt,
        igstrate: igstpers,
        igstamt: igstamt,
        othercharges: othrchrgs,
        netamt: netamount,
        remarks,
        hsn
      };
      const res = await fetch(`/debitnote/insert`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      if (data.type === "success") {
        toast.success(data.message, {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  ///set data edit mode
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `/debitnote/specificdoc/${debitnoteno.debitnoteid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const data = await res.json();
      setdate(data[0].debitnotedate);
      setacname({
        accountid: data[0].accountid,
        accountname: data[0].accountname,
      });
      setpartyInput({
        subledgerid: data[0].partyid,
        partydetails: data[0].partydetails,
      });
      setbillno({
        billheaderid: data[0].salebillid,
        billno: data[0].billno,
        billdate: data[0].billdate,
      });
      setbilldate(data[0].billdate);
      setbillMode(data[0].againstbill);
      settaxablevalue(data[0].taxableamt);
      setcgstpers(data[0].cgstrate);
      setcgstamt(data[0].cgstamt);
      setsgstamt(data[0].sgstamt);
      setsgstpers(data[0].sgstrate);
      setigstamt(data[0].igstamt);
      setigstpers(data[0].igstrate);
      setothrchrgs(data[0].othercharges);
      setnetamount(data[0].netamt);
      setRemarks(data[0].remarks);
      sethsn(data[0].accountcode);
    } catch (error) {
      console.log(error.message);
    }
  };
  ///update data
  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        debitnoteno: debitnoteno,
        dndate: date,
        accountid: acname.accountid,
        partyid: partyInput.subledgerid,
        billflag: billmode,
        billid: billno?.billheaderid,
        taxable: taxablevalue,
        cgstrate: cgstpers,
        cgstamt: cgstamt,
        sgstrate: sgstpers,
        sgstamt: sgstamt,
        igstrate: igstpers,
        igstamt: igstamt,
        othercharges: othrchrgs,
        netamt: netamount,
        remarks,
        hsn
      };
      const res = await fetch(`/debitnote/update/${debitnoteno.debitnoteid}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      if (data.type === "success") {
        toast.success("Debitnote Updated!", { autoClose: 15000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //sates
  const [debitnoteno, setdebitnoteno] = useState(null);
  const [debitnotenoopts, setdebitnoteopts] = useState([]);
  const [date, setdate] = useState(datenow);
  const [acname, setacname] = useState(null);
  const [acnameopts, setacnameopts] = useState(null);
  const [partyInput, setpartyInput] = useState(null);
  const [partyopts, setpartyOpts] = useState([]);
  const [billno, setbillno] = useState(null);
  // console.log(billno);
  const [billnoopts, setbillnoopts] = useState([]);
  const [billdate, setbilldate] = useState(null);
  const [taxablevalue, settaxablevalue] = useState(0);
  const [cgstpers, setcgstpers] = useState(0);
  const [igstpers, setigstpers] = useState(0);
  const [sgstpers, setsgstpers] = useState(0);
  const [cgstamt, setcgstamt] = useState(0);
  const [igstamt, setigstamt] = useState(0);
  const [sgstamt, setsgstamt] = useState(0);
  const [netamount, setnetamount] = useState(0);
  const [othrchrgs, setothrchrgs] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [hsn, sethsn] = useState("");

  /////acc names
  async function getAccnames() {
    try {
      const res = await fetch("/debitnote/getaccounts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setacnameopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getAccnames();
  }, []);
  ///bills
  async function getpartybills() {
    try {
      const res = await fetch(`/debitnote/bills/${partyInput.subledgerid}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();

      setbillnoopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (billmode) {
      getpartybills();
    }
  }, [partyInput]);

  //HANDLE PARTIES WHO HAVE ORDERED
  const [partyfetch, setpartyfetch] = useState(false);
  async function getdebitnotes() {
    try {
      const res = await fetch("/debitnote/alldocs", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      console.log(data);
      setdebitnoteopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (mode === 2) {
      getdebitnotes();
    }
  }, [mode]);
  //  GET BILL Search Parameters //
  async function getpartyOpts() {
    try {
      const response = await fetch("/dnote/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);
  //calculation=
  const handlecalculate = () => {
    if (cgstamt > 0 && igstamt > 0) {
      toast.error("Cgst and Igst both not applicable!");
      setigstamt(0);
      setigstpers(0);
      setnetamount(0);
      setcgstamt(0);
      setsgstamt(0);
      setcgstpers(0);
      setsgstpers(0);
      settaxablevalue(0);
      setothrchrgs(0);
      return;
    }
    if (cgstpers > 0 && sgstpers > 0 && cgstpers !== sgstpers) {
      toast.error("Cgst and Sgst must be same!");
      setigstamt(0);
      setigstpers(0);
      setnetamount(0);
      setcgstamt(0);
      setsgstamt(0);
      setcgstpers(0);
      setsgstpers(0);
      settaxablevalue(0);
      setothrchrgs(0);
      return;
    }
    setnetamount(
      parseFloat(cgstamt) +
        parseFloat(sgstamt) +
        parseFloat(igstamt) +
        parseFloat(othrchrgs) +
        parseFloat(taxablevalue)
    );
    setcgstamt(((taxablevalue * cgstpers) / 100).toFixed(2));
    setsgstamt(((taxablevalue * sgstpers) / 100).toFixed(2));
    setigstamt(((taxablevalue * igstpers) / 100).toFixed(2));
  };
  useEffect(() => {
    handlecalculate();
  }, [
    taxablevalue,
    cgstpers,
    sgstpers,
    igstpers,
    othrchrgs,
    cgstamt,
    sgstamt,
    igstamt,
  ]);

  return (
    <div>
      <Sidebar
        Title={"Debit Note"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"15px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={debitnoteno}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setdebitnoteno(newValue);
                }}
                options={debitnotenoopts}
                getOptionLabel={(option) => option.docdetails}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    sx={{
                      width: "500px",
                    }}
                    {...params}
                    placeholder="Debit No"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Link to="/debitnoteprint">
                <Button>Print Debit Note</Button>
              </Link>
            </Stack>
            <hr />
            <Stack
              spacing={1}
              columnGap={2}
              mb={2}
              direction="row"
              mt={2}
              ml={4}
            >
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartyfetch(true);
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartyfetch(false);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: 100,
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="billmode"
                required
                select
                label="Bill Mode"
                value={billmode}
                onChange={(e) => {
                  setbillMode(e.target.value);
                  setacname(null);
                  setpartyInput(null);
                  setbilldate(null);
                  setbillno(null);
                }}
                variant="outlined"
                sx={{
                  width: 150,
                }}
                size="small"
              >
                {billmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* <Autocomplete
                disabled={searchdisabled}
                id="debitnoteno"
                value={debitnoteno}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setdebitnoteno(newValue);
                }}
                options={debitnotenoopts}
                getOptionLabel={(option) => option.docdetails}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 200,
                    }}
                    required
                    {...params}
                    placeholder="Debit Note No"
                  />
                )}
                size="small"
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  disabled
                  value={date || null}
                  onChange={(newValue) => {
                    setdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Autocomplete
                id="partysearch"
                sx={{ width: "40%" }}
                value={partyInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setpartyInput(newValue);
                }}
                options={partyopts}
                getOptionLabel={(option) => option.partydetails}
                renderInput={(partyopts) => (
                  <TextField required {...partyopts} placeholder="Party Name" />
                )}
                size="small"
              />
              {/* 
              <TextField
                id="Docno"
                label="Docno"
                value={docno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={docdate || null}
                  onChange={(newValue) => {
                    setdocdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider> */}
            </Stack>
            <hr />
            <Stack
              spacing={3}
              columnGap={1}
              direction="row"
              mb={2}
              mt={1}
              ml={4}
            >
              <Autocomplete
                id="accname"
                value={acname}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setacname(newValue)
                  sethsn(newValue.accountcode);
                }}
                options={acnameopts}
                getOptionLabel={(option) => option.accountname}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 210,
                    }}
                    required
                    {...params}
                    placeholder="Account Name"
                  />
                )}
                size="small"
              />
              <TextField
                id="hsn"
                label="hsn"
                value={hsn}
                onChange={(e) => sethsn(e.target.value)}
                sx={{
                  width: 200,
                }}
                size="small"
              />
              <TextField
                id="remarks"
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                sx={{
                  width: "40%",
                }}
                size="small"
              />

              <Autocomplete
                disabled={billmode === false}
                id="partysearch"
                value={billno}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setbillno(newValue);
                  setbilldate(newValue.billdate);
                }}
                options={billnoopts}
                getOptionLabel={(option) => option.billno}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 200,
                    }}
                    required
                    {...params}
                    placeholder="Bill No"
                  />
                )}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={billmode === false}
                  label="Bill Date"
                  inputFormat="DD/MM/YYYY"
                  value={billdate || null}
                  onChange={(newValue) => {
                    setbilldate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "25%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <hr />
            <Stack
              spacing={3}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mb={2}
            >
              <TextField
                id="tax"
                label="Taxable Value"
                value={taxablevalue}
                onChange={(e) => settaxablevalue(e.target.value)}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="cgstper"
                label="CGST %"
                value={cgstpers}
                onChange={(e) => {
                  setcgstpers(e.target.value);
                  setcgstamt((taxablevalue * e.target.value) / 100);
                  setsgstpers(e.target.value);
                  setsgstamt((taxablevalue * e.target.value) / 100);
                }}
                type="number"
                sx={{
                  width: "10%",
                }}
                size="small"
              />
              <TextField
                id="sgstper"
                label="SGST %"
                value={sgstpers}
                onChange={(e) => {
                  setsgstpers(e.target.value);
                  setsgstamt((taxablevalue * e.target.value) / 100);
                }}
                type="number"
                sx={{
                  width: "10%",
                }}
                size="small"
              />
              <TextField
                id="igstper"
                label="IGST %"
                value={igstpers}
                onChange={(e) => {
                  setigstpers(e.target.value);
                  setigstamt((taxablevalue * e.target.value) / 100);
                }}
                type="number"
                sx={{
                  width: "10%",
                }}
                size="small"
              />
              <TextField
                id="otherchrges"
                label="Other Charges"
                value={othrchrgs}
                onChange={(e) => setothrchrgs(e.target.value)}
                type="number"
                sx={{
                  width: "15%",
                }}
                size="small"
              />
              {/* <Tooltip placement="top" title="Calculate">
                <Fab
                  size="medium"
                  color="success"
                  style={{ fontSize: 1 }}
                  onClick={handlecalculate}
                >
                  <CalculateIcon />
                </Fab>
              </Tooltip> */}
            </Stack>
            <hr />
            <Stack
              spacing={3}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mb={2}
            >
              <TextField
                disabled
                id="cgstamt"
                label="CGST Amt"
                value={cgstamt}
                // onChange={(e) => setcgstamt(e.target.value)}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                disabled
                id="sgstamt"
                label="SGST Amt"
                value={sgstamt}
                // onChange={(e) => setsgstamt(e.target.value)}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                disabled
                id="igstamt"
                label="IGST Amt"
                value={igstamt}
                // onChange={(e) => setigstamt(e.target.value)}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                disabled
                id="netamt"
                label="NET Amt"
                value={netamount}
                onChange={(e) => setnetamount(e.target.value)}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack
              spacing={2}
              columnGap={4}
              direction="row"
              mt={4}
              pb={6}
              mx={"35%"}
            >
              <Button
                disabled={mode === 2 || disableSave}
                type="submit"
                onClick={handlesave}
                variant="contained"
                color="primary"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                SAVE
              </Button>
              <Button
                // onClick={handledel}
                variant="contained"
                color="error"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                DELETE
              </Button>
              <Button
                disabled={mode === 1}
                type="submit"
                onClick={handleEditmodeSubmit}
                variant="contained"
                color="success"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Debitnote;
