import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, MenuItem, Autocomplete, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Model = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES

  const [modelcode, setModelCode] = useState("");
  const [modelcategory, setModelCategory] = useState("");
  const [modelName, setModelName] = useState("");

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  // GET DROPDOWN OPTIONS

  const [catg, setCatg] = useState([]);
  async function getCatg() {
    try {
      const response = await fetch("/modcatg", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setCatg(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getCatg();
  }, []);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      setInsertDisabled(true);
      try {
        const body = {
          modelName,
          modelcategory,
          modelcode,
        };
        const response = await fetch("/insertmodel", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes > 0) {
          toast.success("Model Created Successfully", {
            autoClose: 2000,
          });

          //RESET ALL STATE VALUES
          setSearchInput(null);
          setModelCategory("");
          setModelName("");
          setModelCode("");
          setInsertDisabled(false);
          setUpdatedisabled(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      setUpdatedisabled(true);
      try {
        const body = { modelName, modelcategory, modelcode };
        const response = await fetch(`/updatemodel/${searchInput.modelid}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        toast.success(await response.json(), { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(`/deletemodel/${searchInput.modelid}`, {
        method: "DELETE",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/allmodels", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/allmodels/${searchInput.modelid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      if (jsonData !== "") {
        setUpdatedisabled(false);
        setInsertDisabled(true);
        setModelName(jsonData[0].modelname);
        setModelCategory(jsonData[0].modelcatgid);
        setModelCode(jsonData[0].modelcode);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"MODEL"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.modeldetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                required
                id="modelCode"
                label="Model Code"
                value={modelcode}
                onChange={(e) => setModelCode(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                id="modelCategory"
                required
                select
                label="Model Category"
                value={modelcategory}
                onChange={(e) => {
                  setModelCategory(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "100%",
                }}
              >
                {catg.map((option) => (
                  <MenuItem key={option.categoryid} value={option.categoryid}>
                    {option.categoryname}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                required
                id="modelNwame"
                label="Model Name"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>

            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                  setModelCategory("");
                  setModelName("");
                  setModelCode("");
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Model;
