import React, { useRef } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Fab,
  Tooltip,
  Typography,
  Switch,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TaskIcon from "@mui/icons-material/Task";
import CancelIcon from "@mui/icons-material/Cancel";
import Sidebar from "../Sidebar/Sidebar";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const ReceiveStockTransfer = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [searchInput, setSearchInput] = useState(null);
  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    matchFrom: "start",
  });
  //docno
  const [parties, setparties] = useState([]);
  const [checked, setChecked] = useState(false);
  async function getparties() {
    try {
      const response = await fetch("/stktransfer/documents/toapprove", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      //console.log(jsondata);
      setparties(jsondata);
    } catch (err) {
      console.log(err.message);
    }
  }
  async function getrecvdocs() {
    try {
      const response = await fetch("/stktransfer/recv/documents/toapprove", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      //console.log(jsondata);
      setparties(jsondata);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    if (checked) {
      getrecvdocs();
    } else {
      getparties();
    }
  }, [checked]);
  //rack data
  const [rackinput, setrackinput] = useState({});

  const [pdfStream, setPdfStream] = useState(null);
  console.log(rackinput);
  const [rackopts, setrackopts] = useState([]);
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  async function getrackdata() {
    try {
      const response = await fetch("/stktransfer/rack", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      setrackopts(jsondata);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    getrackdata();
  }, []);
  //search
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/stktransfer/data/${searchInput.docid}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      setdataarray(jsondata);
      jsondata.forEach((item) => {
        const itemId = item.itemid;
        setrackinput((prevRackInput) => ({
          ...prevRackInput,
          [itemId]: {
            rackid: item.rackid,
            rackname: `${item.rackname}`,
          },
        }));
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  //datagrid
  const handleDeleteClick = (id) => () => {
    setdataarray(dataarray.filter((row) => row.itemid !== id));
  };
  const [dataarray, setdataarray] = useState([]);
  console.log(dataarray);
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "🗑️",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Item Detail",
      type: "number",
      width: 250,
    },
    {
      field: "rack",
      headerName: "Rack",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Autocomplete
          id={params.id}
          value={rackinput[params.id] || null}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            const findIndex = dataarray.findIndex(
              (opt) => opt.itemid === params.row.itemid
            );

            // Update the rackid in the dataarray
            dataarray[findIndex].rackid = newValue ? newValue.rackid : null;
            dataarray[findIndex].rackname = newValue ? newValue.rackname : null;
            // Update the rackinput state for the specific itemid
            setrackinput((prevRackInput) => ({
              ...prevRackInput,
              [params.row.itemid]: newValue,
            }));
          }}
          options={rackopts}
          getOptionLabel={(option) => option.rackname}
          renderInput={(params) => (
            <TextField
              sx={{
                width: 200,
              }}
              {...params}
              size="small"
            />
          )}
        />
      ),
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "qty",
      type: "number",
      width: 80,
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "value",
      type: "number",
      width: 100,
    },
    {
      field: "cgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "cgstamt",
      type: "number",
      width: 100,
    },
    {
      field: "sgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "sgstamt",
      type: "number",
      width: 100,
    },
    {
      field: "igstamt",
      headerClassName: "super-app-theme--header",
      headerName: "igstamt",
      type: "number",
      width: 100,
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "netamt",
      type: "number",
      width: 100,
    },
  ];
  //approve stk
  const handleapprove = async () => {
    try {
      const body = {
        detaildata: dataarray,
      };
      const response = await fetch(
        `/stktransfer/approve/${searchInput.docid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );

      const parseRes = await response.json();

      toast.success(parseRes);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (err) {
      console.log(err.message);
    }
  };
  //recv stk placing list report
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const report = await fetch(
        `/reports/recvstk/placinglist/${searchInput.docid}`
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"Receive Stock Transfer"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"15px"}
              direction="row"
              justifyContent={"center"}
            >
              <FormControlLabel
                label="Toggle Placing List"
                control={<Checkbox checked={checked} onChange={handleToggle} />}
              />
              <Autocomplete
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={parties}
                getOptionLabel={(option) => option.docno}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "500px",
                    }}
                    {...params}
                    size="small"
                    placeholder="Search Doc No."
                  />
                )}
              />
              {checked && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReport}
                  sx={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                  }}
                >
                  Show Placing List
                </Button>
              )}
              {!checked && (
                <ColorButton
                  type="search"
                  variant="contained"
                  key="one"
                  onClick={handleSearch}
                  sx={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                  }}
                >
                  <SearchRoundedIcon />
                  SEARCH
                </ColorButton>
              )}
            </Stack>
            {checked && (
              <Stack
                columnGap={3}
                direction="row"
                mt={1}
                justifyContent={"center"}
              >
                <Box sx={{ backgroundColor: "white", width: "80%" }}>
                  {pdfStream && (
                    <iframe
                      src={pdfStream}
                      width="100%"
                      height="680px"
                      title="report"
                    ></iframe>
                  )}
                </Box>
              </Stack>
            )}
            {!checked && (
              <Stack columnGap={3} direction="row" mt={1}>
                <Box
                  sx={{
                    height: 550,
                    width: "100%",
                    position: "relative",
                    mt: "15px",
                    boxShadow: "2px 6px 15px #5a5a5a",
                    // border: "0.5px solid black",
                    // borderRadius: "5px",
                    "& .super-app-theme--header": {
                      backgroundColor: "#3bb9ff",
                      color: "#000",
                    },
                  }}
                >
                  <DataGrid
                    sx={{ border: "1px solid #000" }}
                    rowHeight={25}
                    headerHeight={40}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "serial", sort: "desc" }],
                      },
                    }}
                    showColumnRightBorder={true}
                    rows={dataarray}
                    columns={columns}
                    pageSize={100}
                    getRowId={(row) => row.itemid}
                  />
                </Box>
              </Stack>
            )}
            {!checked && (
              <Stack
                spacing={2}
                columnGap={6}
                direction="row"
                mt={6}
                pb={6}
                mx={"42%"}
              >
                <Button variant="contained" onClick={handleapprove}>
                  Approve
                </Button>
              </Stack>
            )}
          </Box>
        }
      />
    </>
  );
};

export default ReceiveStockTransfer;
