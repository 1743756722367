import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import {
  Grid,
  Paper,
  Typography,
  useTheme,
  Stack,
  Card,
  CardContent,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { PureComponent, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
  LineChart,
  Line,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const dataline = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const dataarea = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const AdminDashHome = () => {
  const userToken = LocalstorageHelper.GetAdminToken();
  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  //GET ALL THE USERS FROM DATABASE
  const [barchartdata, setBarChartData] = useState([]);
  const [piechartdata, setpieChartData] = useState([]);
  const [orderincluded, setOrderIncluded] = useState(true);
  const orderincludedType = [
    {
      name: "With Order",
      value: true,
    },
    {
      name: "Without Order",
      value: false,
    },
  ];
  //sale and order
  async function getBarChartData() {
    try {
      const data = await fetch(`/dashboard/bar/${orderincluded}`, {
        method: "GET",
        headers: {
          token: userToken,
        },
      });
      const response = await data.json();
      setBarChartData(response);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getBarChartData();
  }, []);
  //areawise sale
  async function getpieChartData() {
    try {
      const data = await fetch("/dashboard/pie", {
        method: "GET",
        headers: {
          token: userToken,
        },
      });
      const response = await data.json();
      console.log(response);
      setpieChartData(response);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getpieChartData();
  }, []);

  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  async function getUsers() {
    try {
      const response = await fetch("/user", {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      const filteredUsers = jsonData.filter(
        (user) => user.deactivation_date === null
      );
      setUsers(filteredUsers);
      setUserCount(filteredUsers.length);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <AdminSidebar />
      <Typography fontFamily={"inherit"} ml={5} align="left" variant="h4">
        Dashboard:
      </Typography>
      <Stack spacing={2} mb={4} mt={2} direction="row">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mx: "auto", // Center align horizontally
            columnGap: 2,
          }}
        >
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "lightcyan",
            }}
          >
            <CardContent>
              <Typography color={"darkcyan"}>Total Users</Typography>
              <Typography color={"darkcyan"} fontWeight={"bold"} align="center">
                {userCount}
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography>Total Parties</Typography>
              <Typography align="center">-</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography>Total Bills</Typography>
              <Typography align="center">-</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex", 
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography>Total Orders</Typography>
              <Typography align="center">-</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography>Total Items</Typography>
              <Typography align="center">-</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography>Total Pickers/Makers</Typography>
              <Typography align="center">-</Typography>
            </CardContent>
          </Card>
        </Box>
      </Stack>
      <Stack spacing={2} mb={4} mt={2} direction="row">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mx: "auto", // Center align horizontally
            columnGap: 2,
          }}
        >
          <Card
            sx={{
              maxWidth: 1500,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mx: "auto", // Center align horizontally
                  columnGap: 2,
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  Sales Analytics
                </Typography>
                <TextField
                  id="receipt"
                  name="receipt"
                  required
                  select
                  label="Data Option"
                  value={orderincluded}
                  onChange={(e) => setOrderIncluded(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                >
                  {orderincludedType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <BarChart
                width={1200}
                height={300}
                data={barchartdata}
                margin={{
                  top: 50,
                  right: 30,
                  left: 70,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month_name" />
                <YAxis type="number" domain={[0, 100000000]} />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="total_sales"
                  name="Total Sale"
                  fill="#4CAF50"
                  activeBar={<Rectangle fill="#388E3C" stroke="#1B5E20" />}
                  barSize={25}
                />
                {orderincluded && (
                  <Bar
                    dataKey="total_order"
                    name="Total Order"
                    fill="#2196F3"
                    activeBar={<Rectangle fill="#1565C0" stroke="#0D47A1" />}
                    barSize={25}
                  />
                )}
              </BarChart>
            </CardContent>
          </Card>
          {/* <Card
            sx={{
              maxWidth: 500,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                LineChart
              </Typography>
              <LineChart
                width={450}
                height={300}
                data={dataline}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </CardContent>
          </Card> */}

          {/* <Card
            sx={{
              maxWidth: 1000,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                PieChart
              </Typography>
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="saletotal"
                  startAngle={0}
                  endAngle={360}
                  data={piechartdata}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label="destname"
                />
              </PieChart>
            </CardContent>
          </Card> */}
        </Box>
      </Stack>
      <Stack spacing={2} mb={4} mt={5} direction="row">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mx: "auto",
            columnGap: 2,
          }}
        >
          <Card
            sx={{
              maxWidth: 1510,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                AreaChart
              </Typography>
              <AreaChart
                width={1200}
                height={300}
                data={piechartdata}
                margin={{
                  top: 50,
                  right: 10,
                  left: 70,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="destname" />
                <YAxis type="number" domain={[0, 'dataMax + 100000']} />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="saletotal"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                />
              </AreaChart>
            </CardContent>
          </Card>
        </Box>
      </Stack>
      {redirectToLoginPage()}
    </>
  );
};

export default AdminDashHome;
