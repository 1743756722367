import React, { useEffect } from "react";
import {
  Box,
  Stack,
  Autocomplete,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Task from "@mui/icons-material/Task";
import { toast } from "react-toastify";

const EInvoice = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 700,
    p: 3,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const userToken = LocalstorageHelper.GetUserToken();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [pdfStream, setPdfStream] = useState(null);
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        "Content-Type": "application/json",
        sptoken: userToken,
      };
      const report = await fetch(`/einvoice/irn/${searchInput.billheaderid}`, {
        headers,
      });
      if (report.ok) {
        // Success case: Generate PDF or handle as needed
        const pdfBlob = await report.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfStream(pdfUrl);
      } else {
        // Error case: Handle the error information from the server
        const errorData = await report.json();
        toast.error(errorData.errorMessage);
      }
      // if (response.status === "0") {
      //   toast.error(pdfUrl.errorMessage);
      // }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [searchInput, setSearchInput] = useState(null);
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/einvoice/bills", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
        size="small"
        sx={{ width: 100 }}
      >
        E-Invoice
      </Button>
      <Modal
        open={open}
        aria-labelledby="modl-box"
        aria-describedby="search partitem"
      >
        <Box sx={style}>
          <Stack spacing={2} columnGap={2} direction="row" mt={2}>
            <Autocomplete
              id="billno"
              value={searchInput}
              filterOptions={filterOptions}
              onChange={(event, newValue) => {
                setSearchInput(newValue);
              }}
              options={param}
              getOptionLabel={(option) => option.billdetails}
              sx={{
                width: 350,
              }}
              renderInput={(billopts) => (
                <TextField
                  required
                  {...billopts}
                  placeholder="Invoice No"
                />
              )}
              size="small"
            />
            <Button
              //type="submit"
              onClick={handleReport}
              variant="contained"
              component="label"
              size="small"
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              endIcon={<Task />}
            >
              Show Report
            </Button>
          </Stack>
          {pdfStream && <ReportNavigation />}
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStream && (
              <iframe
                src={pdfStream}
                width="100%"
                height="480px"
                title="report"
              ></iframe>
            )}
          </Box>

          <Box sx={{ mx: "45%", mb: "10px", mt: "20px" }}>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EInvoice;
