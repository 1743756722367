import React from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Narrations = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES

  const [narrationid, setnarrationid] = useState("");
  const [narration, setnarration] = useState("");

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      setInsertDisabled(true);
      try {
        const body = {
          narration,
        };
        const response = await fetch("/spare/narration/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();
        toast.success("Narration Created Successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
        //RESET ALL STATE VALUES
        setnarrationid("");
        setnarration("");
        setSearchInput(null);
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      setUpdatedisabled(true);
      try {
        const body = {
          narration,
        };
        const response = await fetch(
          `/spare/narration/update/${searchInput.narrationid}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json", sptoken: userToken },
            body: JSON.stringify(body),
          }
        );
        toast.success(await response.json(), { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `/spare/narration/delete/${searchInput.narrationid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/spare/narration", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
    // eslint-disable-next-line
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/narration/${searchInput.narrationid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData !== "") {
        setUpdatedisabled(false);
        setInsertDisabled(true);
        setnarrationid(jsonData.narrationid);
        setnarration(jsonData.narration);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"NARRATIONS"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.narration}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                disabled
                required
                id="narrationCode"
                label="Narration Code"
                value={narrationid}
                onChange={(e) => setnarrationid(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                required
                id="narrationName"
                label="Narration Name"
                value={narration}
                onChange={(e) => setnarration(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>

            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                  setnarrationid("");
                  setnarration("");
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Narrations;
