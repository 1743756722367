import React from "react";
import { Box, Stack, Autocomplete, Modal, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { toast } from "react-toastify";
toast.configure();
const PrintBill = ({
  state,
  partyInput,
  mkpkInput,
  pickerInput,
  salesmanInput,
  salebillarray,
  totalcgst,
  totalsgst,
  totalvalue,
  totaldisamt,
  totaltaxamt,
  totaligst,
  totalgstamt,
  delvcharge,
  roundOff,
  billAmt,
  invoicedate,
  jobcardInput,
  jobDate,
  regno,
  disabledprintbtn,
  setdisabledprintbtn,
  setDisableSubmit,
}) => {
  const style = {
    position: "absolute",
    top: "47%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    p: 3,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
  };
  const userToken = LocalstorageHelper.GetUserToken();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [pdfStream, setPdfStream] = useState(null);
  const handleBill = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (mkpkInput === null || pickerInput === null) {
      setDisableSubmit(false);
      return toast.error("Please enter Maker Picker!!");
    }
    if (parseInt(state.salecategory) === 1) {
      try {
        const body = {
          state,
          partyInput,
          mkpkInput,
          pickerInput,
          salesmanInput,
          salebillarray,
          totalcgst,
          totalsgst,
          totalvalue,
          totaldisamt,
          totaltaxamt,
          totaligst,
          totalgstamt,
          delvcharge,
          roundOff,
          billAmt,
          invoicedate,
        };
        const response = await fetch("/spare/salebill/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });

        const parseRes = await response.json();
        if(parseRes.type==="error"){
          return toast.error(parseRes.message);
        }
        setOpen(true);
        const resp = await fetch(
          `/reports/getpdf/${parseInt(parseRes.billheaderid)}`,
          {
            method: "GET",
            headers: { sptoken: userToken },
          }
        );

        const pdfBlob = await resp.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfStream(pdfUrl);
      } catch (err) {
        console.log(err.message);
      }
    }
    if (parseInt(state.salecategory) === 2) {
      try {
        const body = {
          state,
          partyInput,
          mkpkInput,
          pickerInput,
          salesmanInput,
          salebillarray,
          totalcgst,
          totalsgst,
          totalvalue,
          totaldisamt,
          totaltaxamt,
          totaligst,
          totalgstamt,
          delvcharge,
          roundOff,
          billAmt,
          invoicedate,
          jobcardInput,
          jobDate,
          regno,
        };
        const response = await fetch("/spare/salebill/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });

        const parseRes = await response.json();
        if(parseRes.type==="error"){
          return toast.error(parseRes.message);
        }
        setOpen(true);
        // console.log(parseRes);
        const resp = await fetch(
          `/reports/getpdf/${parseInt(parseRes.billheaderid)}`,
          {
            method: "GET",
            headers: { sptoken: userToken },
          }
        );

        const pdfBlob = await resp.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfStream(pdfUrl);
      } catch (err) {
        console.log(err.message);
      }
    }
  };
  return (
    <div>
      <Button
        // type="submit"
        onClick={handleBill}
        disabled={billAmt > 0 ? !disabledprintbtn : disabledprintbtn}
        variant="contained"
        color="secondary"
        size="small"
        sx={{ boxShadow: "2px 2px 6px  #404142" }}
      >
        SAVE & PRINT BILL
      </Button>

      <Modal
        open={open}
        aria-labelledby="modl-box"
        aria-describedby="search partitem"
      >
        <Box sx={style}>
          {pdfStream && <ReportNavigation />}
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStream && (
              <iframe
                src={pdfStream}
                width="100%"
                height="680px"
                title="report"
              ></iframe>
            )}
          </Box>

          <Box sx={{ mx: "38%", mt: "2px" }}>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PrintBill;
