
export default class LocalstorageHelper {
  static GetUserToken(){
    return localStorage.getItem("sptoken");
  }
  static SetUserToken(token){
     localStorage.setItem("sptoken", token);
  }
  static RemoveUserToken(){
    return localStorage.removeItem("sptoken");
  }

  static GetAdminToken(){
    return localStorage.getItem("token");
  }
  static SetAdminToken(token){
     localStorage.setItem("token", token);
  }
  static RemoveAdminToken(){
    return localStorage.removeItem("token");
  }

}
