import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Redirect } from "react-router-dom";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Task from "@mui/icons-material/Task";
const TopSlow = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);

  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3

  // Use the useState hook to manage the state
  const date = new Date();
  const [fromdate, setfromdate] = useState(currentDate);
  const [todate, settodate] = useState(date);
  const [loading, setLoading] = useState(false);
  const categories = [
    {
      name: "Top",
      value: "top",
    },
    {
      name: "Slow",
      value: "slow",
    },
  ];

  const sortType = [
    {
      name: "Quantity",
      value: "qty",
    },
    {
      name: "Value",
      value: "value",
    },
  ];

  const reportType = [
    {
      name: "Value",
      value: false,
    },
    {
      name: "MRP",
      value: true,
    },
  ];

  const opttype = [
    {
      name: "Purchase",
      value: "PUR",
    },
    {
      name: "Customer Order",
      value: "CUS",
    },
    {
      name: "Sale",
      value: "SALE",
    },
  ];

  const [pdfStream, setPdfStream] = useState(null);
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (todate === null) {
        toast.error("Please Select A valid Date!");
      } else {
        const body = {
          fromdate,
          todate,
          format: 1,
          mrptypeflag: reportTypeValue,
          noofparts: totParts,
          topslow: catgvalue,
          poption: optvalue,
          sortedon: sortTypeValue,
        };
        console.log(body);
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch("/reports/topslowitems", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
          setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const body = {
        fromdate,
        todate,
        format: option,
        mrptypeflag: reportTypeValue,
        noofparts: totParts,
        topslow: catgvalue,
        poption: optvalue,
        sortedon: sortTypeValue,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const res = await fetch("/reports/topslowitems", options);
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `TopSlowMovingItems_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `TopSlowMovingItems_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);

  const [totParts, setTotParts] = useState(100);
  const [catgvalue, setCatgValue] = useState("top");
  const [optvalue, setOptValue] = useState("SALE");
  const [sortTypeValue, setSortTypeValue] = useState("qty");
  const [reportTypeValue, setReportTypeValue] = useState(false);

  //excel
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select From Date!!");
    }
    try {
      const body = {
        fromdate,
        todate,
        mrptypeflag: reportTypeValue,
        noofparts: totParts,
        topslow: catgvalue,
        poption: optvalue,
        sortedon: sortTypeValue,
      };
      const data = await fetch("/reports/topslowitems/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      if (reportTypeValue === false) {
        downloadLink.download = "TopSlowMovingItemsListValue.xlsx";
      } else {
        downloadLink.download = "TopSlowMovingItemsListMRP.xlsx";
      }

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div>
        <Sidebar
          Title={"TOP SLOW MOVING REPORT"}
          expandedPanel={"panel3"}
          children={
            <Box
              component="form"
              sx={{
                width: "100%",
              }}
              //onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Box
                sx={{
                  marginTop: "4rem",
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: "20px",
                  justifyContent: "center",
                }}
              >
                <TextField
                  id="TotParts"
                  type="number"
                  label="Number of Parts"
                  name="TotParts"
                  value={totParts}
                  onChange={(e) => setTotParts(e.target.value)}
                  sx={{
                    width: "120px",
                  }}
                  size="small"
                />
                <TextField
                  id="categories"
                  name="Categories"
                  required
                  select
                  label="Categories"
                  value={catgvalue}
                  onChange={(e) => setCatgValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "120px",
                  }}
                  size="small"
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="Options"
                  name="Options"
                  required
                  select
                  label="Options"
                  value={optvalue}
                  onChange={(e) => setOptValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "120px",
                  }}
                  size="small"
                >
                  {opttype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="sorttype"
                  name="sorttype"
                  required
                  select
                  label="Sort Type"
                  value={sortTypeValue}
                  onChange={(e) => setSortTypeValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "120px",
                  }}
                  size="small"
                >
                  {sortType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="reporttype"
                  name="reporttype"
                  required
                  select
                  label="Report Type"
                  value={reportTypeValue}
                  onChange={(e) => setReportTypeValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "120px",
                  }}
                  size="small"
                >
                  {reportType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  marginTop: "2rem",
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: "20px",
                  justifyContent: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromdate}
                    onChange={(newValue) => {
                      setfromdate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: "20%",
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={todate}
                    onChange={(newValue) => {
                      settodate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: "20%",
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  type="submit"
                  disabled={loading===true}
                  onClick={handleReport}
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  endIcon={<Task />}
                >
                  {loading ? "Fetching Data..." : " Show Report"}
                </Button>
                <Button
                  onClick={printexcel}
                  size="small"
                  variant="contained"
                  color="success"
                  //endIcon={<PrintIcon />}
                >
                  Excel
                </Button>
              </Box>
              <Box ref={myElementRef}></Box>
              {pdfStream && (
                <ReportNavigation
                  reportName={"TOP SLOW MOVING ITEM"}
                  onDownload={handleDownload}
                />
              )}
              <Box sx={{ backgroundColor: "white" }}>
                {pdfStream && (
                  <iframe
                    src={pdfStream}
                    width="100%"
                    height="480px"
                    title="report"
                  ></iframe>
                )}
              </Box>
            </Box>
          }
        />
        {/* <FooterElement /> */}
        {redirectToLoginPage()}
      </div>
    </>
  );
};

export default TopSlow;
