import React, { useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Box, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const AdminInstructions = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [altdata, setAltData] = useState([
    {
      oldItemCode: "1100329",
      oldItemDescription: "PLUG - OIL",
      newItemCode: "28101141",
      newItemDescription: "PLUG OIL",
    },
    {
      oldItemCode: "1110316",
      oldItemDescription: "SCREW",
      newItemCode: "39147201",
      newItemDescription: "SCREW WSP M3 M3 X 12L",
    },
    {
      oldItemCode: "01140122",
      oldItemDescription: "FUEL PIPE",
      newItemCode: "06141010",
      newItemDescription: "PIPE FUEL",
    },
    {
      oldItemCode: "01160509",
      oldItemDescription: "BOLT HEX HEAD",
      newItemCode: "KAAA0518",
      newItemDescription: "BOLT",
    },
    {
      oldItemCode: "01160602",
      oldItemDescription: "BONNET ASSEMBLY - ENGINE",
      newItemCode: "52018004",
      newItemDescription: "BONNET ENGINE OLD SCOOTER",
    },
  ]);
  const [backorderdata, setbackorderdata] = useState([
    {
      partno: "01100342",
      BAL_ORD_NO: 102097795,
      CUSTOMER_ORDER_REF: "DPO154262300191",
      ORD_QTY: 10,
      backorderqty: 10,
      backordervalue: 1122.73,
      DESCRIPTION: "BEARING  BALL",
    },
    {
      partno: "01100342",
      BAL_ORD_NO: 102612459,
      CUSTOMER_ORDER_REF: "DPO154262300198",
      ORD_QTY: 10,
      backorderqty: 10,
      backordervalue: 1122.73,
      DESCRIPTION: "BEARING  BALL",
    },
    {
      partno: "15101146",
      BAL_ORD_NO: 102062471,
      CUSTOMER_ORDER_REF: "DPO154262300166",
      ORD_QTY: 1,
      backorderqty: 1,
      backordervalue: 342.17,
      DESCRIPTION: "GEAR CORONA",
    },
    {
      partno: "15151066",
      BAL_ORD_NO: 102042122,
      CUSTOMER_ORDER_REF: "DPO154262300151",
      ORD_QTY: 10,
      backorderqty: 10,
      backordervalue: 832.58,
      DESCRIPTION: "BEARING  BALL",
    },
    {
      partno: "15151066",
      BAL_ORD_NO: 102081962,
      CUSTOMER_ORDER_REF: "DPO154262300185",
      ORD_QTY: 10,
      backorderqty: 10,
      backordervalue: 832.58,
      DESCRIPTION: "BEARING  BALL",
    },
  ]);
  const [customerOrderdata, setcustomerOrderdata] = useState([
    {
      srNo: 1,
      itemCode: "01100342",
      itemDescription: "BEARING BALL",
      itemSegment: "COM",
      mrpPerUnit: 147,
      hsnCode: 84821020,
      gstPercentage: 18,
      vehDlrSetQty: 1,
      distributorSetQty: 10,
    },
    {
      srNo: 2,
      itemCode: "01100355",
      itemDescription: "SEAL OIL MAGN SIDE",
      itemSegment: "2W",
      mrpPerUnit: 26,
      hsnCode: 40169330,
      gstPercentage: 18,
      vehDlrSetQty: 10,
      distributorSetQty: 10,
    },
    {
      srNo: 3,
      itemCode: "01100610",
      itemDescription: "KEY WOODRUFF MAGNETO",
      itemSegment: "COM",
      mrpPerUnit: 6.2,
      hsnCode: 87149990,
      gstPercentage: 28,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
    {
      srNo: 4,
      itemCode: "01100611",
      itemDescription: "KEY WOODRUFF CLUTCH",
      itemSegment: "COM",
      mrpPerUnit: 7,
      hsnCode: 87149990,
      gstPercentage: 28,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
    {
      srNo: 5,
      itemCode: "01100612",
      itemDescription: "WASHER FAN DISC",
      itemSegment: "COM",
      mrpPerUnit: 7,
      hsnCode: 87149990,
      gstPercentage: 28,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
  ]);
  const [mrpupdatedata, setmrpupdatedata] = useState([
    {
      srNo: 1,
      itemCode: "01100342",
      itemDescription: "BEARING BALL",
      itemSegment: "COM",
      mrpPerUnit: 147,
      hsnCode: 84821020,
      gstPercentage: 18,
      vehDlrSetQty: 1,
      distributorSetQty: 10,
    },
    {
      srNo: 2,
      itemCode: "01100355",
      itemDescription: "SEAL OIL MAGN SIDE",
      itemSegment: "2W",
      mrpPerUnit: 26,
      hsnCode: 40169330,
      gstPercentage: 18,
      vehDlrSetQty: 10,
      distributorSetQty: 10,
    },
    {
      srNo: 3,
      itemCode: "01100610",
      itemDescription: "KEY WOODRUFF MAGNETO",
      itemSegment: "COM",
      mrpPerUnit: 6.2,
      hsnCode: 87149990,
      gstPercentage: 28,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
    {
      srNo: 4,
      itemCode: "01100611",
      itemDescription: "KEY WOODRUFF CLUTCH",
      itemSegment: "COM",
      mrpPerUnit: 7,
      hsnCode: 87149990,
      gstPercentage: 28,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
    {
      srNo: 5,
      itemCode: "01100612",
      itemDescription: "WASHER FAN DISC",
      itemSegment: "COM",
      mrpPerUnit: 3,
      hsnCode: 73182990,
      gstPercentage: 18,
      vehDlrSetQty: 50,
      distributorSetQty: 50,
    },
  ]);
  const [transitdata, setTransitData] = useState([
    {
      dealerCode: 30133,
      invNo: "3003152790",
      invDate: 1092023,
      partNo: "K6161190",
      qty: 4,
      perQValue: 219.94,
      perValueIGST: 61.58,
      totalValue: 879.76,
      gst: 246.32,
      perValueSGST: 0,
      perValueCGST: 0,
      other: 0,
    },
    {
      dealerCode: 30133,
      invNo: "3003152790",
      invDate: 1092023,
      partNo: "N9160410",
      qty: 10,
      perQValue: 84.84,
      perValueIGST: 15.27,
      totalValue: 848.4,
      gst: 152.7,
      perValueSGST: 0,
      perValueCGST: 0,
      other: 0,
    },
    {
      dealerCode: 30133,
      invNo: "3003152790",
      invDate: 1092023,
      partNo: "N3170030",
      qty: 15,
      perQValue: 127.47,
      perValueIGST: 35.69,
      totalValue: 1912.05,
      gst: 535.35,
      perValueSGST: 0,
      perValueCGST: 0,
      other: 0,
    },
    {
      dealerCode: 30133,
      invNo: "3003152790",
      invDate: 1092023,
      partNo: "N8011700",
      qty: 4,
      perQValue: 100.39,
      perValueIGST: 28.11,
      totalValue: 401.56,
      gst: 112.44,
      perValueSGST: 0,
      perValueCGST: 0,
      other: 0,
    },
  ]);

  return (
    <>
      <AdminSidebar />
      <Stack spacing={0} mb={4} mt={2} mx={"20%"} direction="column">
        <Typography variant="h6" fontWeight={"bold"} mb={1}>
          Instructions for uploading excel:
        </Typography>
        <Typography variant="h6" color={"coral"} fontStyle={"italic"}>
          1. There should be a single sheet only.
        </Typography>
        <Typography variant="h6" color={"coral"} fontStyle={"italic"}>
          2. There should not be any extra line at the end or start.
        </Typography>
        <Typography variant="h6" color={"coral"} fontStyle={"italic"}>
          3. There must be a single heading line.
        </Typography>
        <Typography variant="h6" color={"coral"} mb={3} fontStyle={"italic"}>
          4. Final excel formats are given below with proper headings and sample
          data.
        </Typography>
        <Box>
          <Accordion sx={{ width: 1020 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={"bold"}>Alternate Item</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Follow this format for alternate item's excel
              </Typography>
              <Box sx={{ width: 800, margin: "auto", marginTop: "20px" }}>
                <Table
                  stickyHeader
                  sx={{
                    minWidth: 350,
                    // boxShadow: "2px 6px 15px #5a5a5a",
                    marginTop: "20px",
                  }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#3bb9ff",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        SRL.NO.
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        OLD ITEM_CD
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        ITEM_DESCRIPTION
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        {"NEW (ALTERNATE) ITEM_CD"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        ITEM_DESCRIPTION_1
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {altdata.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.oldItemCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.oldItemDescription}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.newItemCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.newItemDescription}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: 1020 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={"bold"}>Back Order</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Follow this format for backorder's excel</Typography>
            </AccordionDetails>
            <Box sx={{ width: 800, margin: "auto", marginTop: "20px", mb: 1 }}>
              <Table
                stickyHeader
                sx={{
                  minWidth: 350,
                  marginTop: "20px",
                }}
                size="small"
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "#3bb9ff",
                    border: "0.5px solid black",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      partno
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      BAL_ORD_NO.
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      CUSTOMER_ORDER_REF
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      ORD_QTY
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      backorderqty
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      backordervalue
                    </TableCell>
                    <TableCell
                      sx={{ color: "#000", border: "0.5px solid black" }}
                      align="center"
                    >
                      DESCRIPTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                  {backorderdata.map((item) => (
                    <TableRow key={item.a}>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.partno}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.BAL_ORD_NO}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.CUSTOMER_ORDER_REF}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.ORD_QTY}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.backorderqty}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.backordervalue}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        {item.DESCRIPTION}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Accordion>
          <Accordion sx={{ width: 1020 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={"bold"}>Customer Order</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Follow this format for customer order's excel
              </Typography>
              <Box sx={{ width: 900, margin: "auto", marginTop: "20px" }}>
                <Table
                  stickyHeader
                  sx={{
                    minWidth: 350,
                    // boxShadow: "2px 6px 15px #5a5a5a",
                    marginTop: "20px",
                  }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#3bb9ff",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Sr. No.
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Description
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Segment
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        MRP - per unit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        HSN Code
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        GST %
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        VEH_DLR Set Qty
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Distributor Set Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {customerOrderdata?.map((item) => (
                      <TableRow key={item.srNo}>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.srNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemDescription}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemSegment}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.mrpPerUnit}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.hsnCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.gstPercentage}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.vehDlrSetQty}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.distributorSetQty}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: 1020 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={"bold"}>MRP Update</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Follow this format for mrp update excel</Typography>
              <Box sx={{ width: 900, margin: "auto", marginTop: "20px" }}>
                <Table
                  stickyHeader
                  sx={{
                    minWidth: 350,
                    // boxShadow: "2px 6px 15px #5a5a5a",
                    marginTop: "20px",
                  }}
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#3bb9ff",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Sr. No.
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Description
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Item Segment
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        MRP - per unit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        HSN Code
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        GST %
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        VEH_DLR Set Qty
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Distributor Set Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {mrpupdatedata.map((item) => (
                      <TableRow key={item.srNo}>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.srNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemDescription}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.itemSegment}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.mrpPerUnit}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.hsnCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.gstPercentage}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.vehDlrSetQty}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.distributorSetQty}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: 1020 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={"bold"}>Transit</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Follow this format for transit's excel</Typography>
              <Box sx={{ width: 980, margin: "auto", marginTop: "20px" }}>
                <Table
                  stickyHeader
                  sx={{
                    minWidth: 350,
                    // boxShadow: "2px 6px 15px #5a5a5a",
                    marginTop: "20px",
                  }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#3bb9ff",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Dealer Code
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Invoice No
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Invoice Date
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Part No
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Per Q Value
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Per Value IGST
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Total Value
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        GST
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Per Value SGST
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Per Value CGST
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Other
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {transitdata.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.dealerCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.invNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.invDate}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.partNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.qty}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.perQValue}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.perValueIGST}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.totalValue}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.gst}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.perValueSGST}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.perValueCGST}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {item.other}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
      {redirectToLoginPage()}
    </>
  );
};

export default AdminInstructions;
