import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, MenuItem, Autocomplete, Input, } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const MainLedger = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // DROPDOWN AND TEXT FIELD STATES FOR ENABLING OR DISABLING THEIR STATE

  const [subledgerdisabled, setSubledgerDisabled] = useState(false);
  const [bookdisabled, setBookDisabled] = useState(false);
  const [cashbankdisabled, setCashbankDisabled] = useState(false);
  const [bankbrdisabled, setBankbrDisabled] = useState(false);
  const [ifscdisabled, setIfscdisabled] = useState(false);
  const [bankacdisabled, setBankacDisabled] = useState(false);
  //subgroup dropdown handle

  const [allsubgrp, setAllSubgrp] = useState([]);
  const [subgrpid, setSubgrpid] = useState("");

  async function getAllsubgrpNames() {
    try {
      const response = await fetch("/ledger/getsub", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setAllSubgrp(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAllsubgrpNames();
  }, []);

  // TEXT FIELD VALUE STATES
  const [mainledgercode, setMainLedgerCode] = useState("");
  const [mainledgernamevalue, setMainLedgerNameValue] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankbranchname, setBankBranchName] = useState("");
  const [bankacno, setBankAcno] = useState("");

  // SUBLEDGER FLAG  DROPDOWN VALUES

  const [subldgrflag, setSubldgrflag] = useState(false);

  const handlesubldgrflag = (e) => {
    if (e.target.value === true) {
      setBankacDisabled(true);
      setBankbrDisabled(true);
      setBookDisabled(true);
      setCashbankDisabled(true);
      setIfscdisabled(true);
      setBookFlag("");
      setCashbank("");
      setBankBranchName("");
      setIfsc("");
      setBankAcno("");
    } else {
      setBankacDisabled(false);
      setBankbrDisabled(false);
      setBookDisabled(false);
      setCashbankDisabled(false);
      setIfscdisabled(false);
    }
    setSubldgrflag(e.target.value);
  };

  const subldgrFlags = [
    {
      value: false,
      label: "False",
    },
    {
      value: true,
      label: "True",
    },
  ];

  //BOOK FLAG DROPDOWN VALUES

  const [bookflag, setBookFlag] = useState(true);

  const bookFlags = [
    {
      value: false,
      label: "False",
    },
    {
      value: true,
      label: "True",
    },
  ];

  const handleBookFlag = (e) => {
    if (e.target.value === false) {
      setBankacDisabled(true);
      setBankbrDisabled(true);
      setCashbankDisabled(true);
      setIfscdisabled(true);
      setCashbank("");
      setBankBranchName("");
      setIfsc("");
      setBankAcno("");
    } else {
      setBankacDisabled(false);
      setBankbrDisabled(false);
      setCashbankDisabled(false);
      setIfscdisabled(false);
    }
    setBookFlag(e.target.value);
  };
  // CASH BANK DROPDOWN VALUES

  const [cashbank, setCashbank] = useState("B");

  const handlecashbank = (e) => {
    if (e.target.value === "B") {
      setBankacDisabled(false);
      setBankbrDisabled(false);
      setIfscdisabled(false);
    } else {
      setBankacDisabled(true);
      setBankbrDisabled(true);
      setIfscdisabled(true);
      setBankBranchName("");
      setIfsc("");
      setBankAcno("");
    }
    setCashbank(e.target.value);
  };

  const cashbankvlus = [
    {
      value: "B",
      label: "B",
    },
    {
      value: "C",
      label: "C",
    },
  ];

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      try {
        const body = {
          mainledgercode,
          mainledgernamevalue,
          subgrpid,
          subldgrflag,
          bookflag,
          cashbank,
          bankbranchname,
          ifsc,
          bankacno,
        };
        if (subldgrflag === "") {
          setSubldgrflag(null);
        }
        if (bookflag === "") {
          setBookFlag(null);
        }
        if (cashbank === "") {
          setCashbank(null);
        }
        const response = await fetch("/insertMainldgr", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.mainledgerid) {
          toast.success("Main Ledger Created Successfully", {
            autoClose: 2000,
          });
          //RESET ALL STATE VALUES
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      try {
        const body = {
          mainledgercode,
          mainledgernamevalue,
          subgrpid,
          subldgrflag,
          bookflag,
          cashbank,
          bankbranchname,
          ifsc,
          bankacno,
        };
        const response = await fetch(`/updateMainldgr/${searchInput.mainledgerid}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        toast.success(await response.json(), { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `/deleteMainldgr/${searchInput.mainledgerid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };
  
  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/mainledgersearch", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/mainledgerfetch/${searchInput.mainledgerid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData !== "") {
        setUpdatedisabled(false);
        setInsertDisabled(true);
        setMainLedgerCode(jsonData.mainledgercode);
        setMainLedgerNameValue(jsonData.mainledgername);
        setIfsc(jsonData.ifsccode || "");
        setBankBranchName(jsonData.bankbranch || "");
        setBankAcno(jsonData.bankacno || "");
        setSubgrpid(jsonData.subgrpid);
        setSubldgrflag(jsonData.subledgerflag);
        setBookFlag(jsonData.bookflag || "");
        setCashbank(jsonData.cashbank || "");
        setBookDisabled(jsonData.subledgerflag === true ? true : false);
        setCashbankDisabled(
          jsonData.subledgerflag === true || jsonData.bookflag === false
            ? true
            : false
        );
        setBankbrDisabled(
          jsonData.subledgerflag === true ||
            jsonData.bookflag === false ||
            jsonData.cashbank === "C"
            ? true
            : false
        );
        setIfscdisabled(
          jsonData.subledgerflag === true ||
            jsonData.bookflag === false ||
            jsonData.cashbank === "C"
            ? true
            : false
        );
        setBankacDisabled(
          jsonData.subledgerflag === true ||
            jsonData.bookflag === false ||
            jsonData.cashbank === "C"
            ? true
            : false
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"MAIN LEDGER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.mainledgerdetails}
                sx={{ width: "30% ", boxShadow: "2px 2px 6px  #404142", borderTopLeftRadius:"10px", borderBottomLeftRadius:"10px",   }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." 
                  />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr/>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                required
                id="mainledgerCode"
                label="Main Ledger Code"
                value={mainledgercode}
                onChange={(e) => setMainLedgerCode(e.target.value)}
                type="text"
                sx={{
                  width: "25%",
                }}
                size="small"
              />

              <TextField
                required
                id="mainledgerName"
                label="Main Ledger Name"
                type="text"
                value={mainledgernamevalue}
                onChange={(e) => setMainLedgerNameValue(e.target.value)}
                sx={{
                  width: "25%",
                }}
                size="small"
              />

              <TextField
                id="subgroupName"
                required
                select
                label="Sub Group Name"
                value={subgrpid}
                onChange={(e) => {
                  setSubgrpid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "25%",
                }}
                size="small"
              >
                {allsubgrp.map((option) => (
                  <MenuItem key={option.subgrpid} value={option.subgrpid}>
                    {option.subgrpname}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                id="subledgerFlag"
                required
                select
                label="Sub Ledger Flag"
                disabled={subledgerdisabled}
                value={subldgrflag}
                onChange={handlesubldgrflag}
                helperText="Please select 'True' or 'False'."
                variant="outlined"
                sx={{
                  width: "25%",
                }}
                size="small"
              >
                {subldgrFlags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="bookFlag"
                select
                label="Book Flag"
                value={bookflag}
                disabled={bookdisabled}
                onChange={handleBookFlag}
                helperText="Please select 'True' or 'False'."
                variant="outlined"
                sx={{
                  width: "25%",
                }}
                size="small"
              >
                {bookFlags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="cashBank"
                select
                disabled={cashbankdisabled}
                label="Cash Bank"
                value={cashbank}
                onChange={handlecashbank}
                helperText="Please select 'C' or 'B'."
                variant="outlined"
                sx={{
                  width: "25%",
                }}
                size="small"
              >
                {cashbankvlus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack spacing={10} columnGap={4} direction="row" mt={5} ml={5}>
              <TextField
                disabled={bankbrdisabled}
                id="outlined-required"
                label="Bank Branch Name"
                type="text"
                value={bankbranchname}
                onChange={(e) => setBankBranchName(e.target.value)}
                sx={{
                  width: "25%",
                }}
                size="small"
              />

              <TextField
                disabled={ifscdisabled}
                id="outlined-required"
                label="IFSC Code"
                type="text"
                value={ifsc}
                onChange={(e) => setIfsc(e.target.value)}
                sx={{
                  width: "25%",
                }}
                size="small"
              />
              <TextField
                disabled={bankacdisabled}
                id="outlined-required"
                label="Bank Account No"
                type="text"
                value={bankacno}
                onChange={(e) => setBankAcno(e.target.value)}
                sx={{
                  width: "25%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setMainLedgerCode("");
                  setMainLedgerNameValue("");
                  setSubgrpid("");
                  setSubldgrflag(false);
                  setBookFlag(true);
                  setCashbank("B");
                  setBankBranchName("");
                  setIfsc("");
                  setBankAcno("");
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setBankacDisabled(false);
                  setBankbrDisabled(false);
                  setBookDisabled(false);
                  setCashbankDisabled(false);
                  setIfscdisabled(false);
                  setSearchInput(null);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px:3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
               onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default MainLedger;
