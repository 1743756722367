import React from "react";
import Sidebar from "../Sidebar/Sidebar";

import { Box, Stack, Skeleton, Autocomplete, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Check from '@mui/icons-material/Check';
import { useState } from "react";
import { useEffect } from "react";

import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { green, purple } from "@mui/material/colors";

import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

///MODAL STYLE

const style = {
  position: "absolute",
  top: "50%",
  left: "58%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #C0C0C0",
  borderRadius: "10px",
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  "& button": { m: 1 },
};

//SUBMIT ROLE BUTTON STYLE
const submitbtn = {

  hover: {
    "&:hover": {
     backgroundColor: "#008000",
     color: "white"
    }
}
}
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const BranchSubledger = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  //MODAL STATES

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setFetch(true);
  };
  const handleClose = () => setOpen(false);
  const [isdisabled, SetIsDisabled] = useState(true);
  const [Fetch, setFetch] = useState(false);
  //NEW SUBLEDGER MODAL HANDLES
  const [newpartydetails, setNewPartyDetails] = useState([]);

  async function getDetails() {
    try {
      const response = await fetch("/newbranchsubledgerdetails", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setFetch(false);
      setNewPartyDetails(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (Fetch === true) {
      getDetails();
    }
  }, [Fetch]);

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/subledgersearch", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [partydetails, setPartyDetails] = useState([]);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/branchsubledgerdetails/${searchInput.subledgerid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData.length > 0) {
        setPartyDetails(jsonData);
        SetIsDisabled(false);
      } else {
        setPartyDetails([]);
        SetIsDisabled(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  // STORE THE VALUE OF ASSIGNED ROLES
  const [roleList, setroleList] = useState([]);

  const handleSwitchChange = (e, opt) => {
    console.log(`${e.target.value} is ${e.target.checked}`);
    if (e.target.checked) {
      setroleList([
        ...roleList,
        {
          companyid: opt.companyid,
          branchid: opt.branchid,
        },
      ]);
    }
    // if(submenulist.includes(e.target.value)){
    //   setSubMenuList(submenulist.filter((o)=> o !== e.target.value && e.target.checked),[...submenulist,e.target.value,false])
    // }
    else {
      setroleList(roleList.filter((o) => o.branchid !== opt.branchid));
    }

    //     submenulist.push(e.target.value, e.target.checked);
    //     if(submenulist.includes(e.target.value)){
    // let index = submenulist.indexOf(e.target.value);

    //     }
  };
  console.log(roleList);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { roleList };
      const response = await fetch(
        `/updatebranchsubledger/${searchInput.subledgerid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", token: userToken },
          body: JSON.stringify(body),
        }
      );
      toast.success(await response.json(), { autoClose: 1000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"BRANCH SUBLEDGER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.subledgerdetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={10} columnGap={4} direction="row" mt={2}>
              <Button
                variant="outlined"
                startIcon={<AddCircleOutline />}
                size="large"
                sx={{
                  pl: "20px",
                  pr: "20px",
                  pt: "10px",
                  pb: "10px",
                  boxShadow: " rgba(0, 0, 0, 0.35) 0px -35px 36px -28px inset",
                  fontSize: "20px",
                  margin: "auto",
                }}
                onClick={handleOpen}
                disabled={isdisabled}
              >
                Assign
              </Button>
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5}>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ pt: 2, textAlign: "center" }}
                  >
                    ASSIGN COMPANY ROLES
                  </Typography>
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxWidth: "90%",
                      background:
                        "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                      maxHeight: "45vh",
                      overflowY: "auto",
                      padding: "18px",
                      mt: "30px",
                      ml: "5%",
                    }}
                  >
                    <Table
                      sx={{
                        maxWidth: "100%",
                        boxShadow: "2px 6px 10px #404142",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#aad3f0",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            Company
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            Branch
                          </TableCell>
                          {/* <TableCell
                        sx={{ color: "#000", border: "0.5px solid black",paddingY:"16px" }}
                        
                      >
                        Subledger
                      </TableCell> */}
                          <TableCell
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                            align="center"
                          >
                            Set Activate or Deactivate
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        {newpartydetails.map((opt) => (
                          <TableRow key={opt.branchid}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                                paddingY: "16px",
                              }}
                            >
                              {opt.companyname}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                                paddingY: "16px",
                              }}
                            >
                              {opt.branchname}
                            </TableCell>
                            {/* <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black",paddingY:"16px" }}
                        >
                          
                         {searchInput.subledgerdetails}
                        </TableCell> */}
                            <TableCell
                              align="center"
                              sx={{
                                color: "#000",
                                border: "0.5px solid black",
                                paddingY: "16px",
                              }}
                            >
                              <FormControlLabel
                                key={opt.branchid}
                                value={roleList}
                                control={
                                  <Switch
                                    defaultChecked={false}
                                    onChange={(e) => handleSwitchChange(e, opt)}
                                    color="success"
                                  />
                                }
                                
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack   direction="row" mt={5} mb={3} >
                  <Button   type="submit" variant="outlined" size="large" color="success"   onClick={handleSubmit} endIcon={<Check />} sx={{m:"auto!important", pl:"340px", pr:"340px", color: "#008000",
                '&:hover': {
                  background: "#008000",
                  color: "white",
                },   
                }}  >
                  SUBMIT ROLES
                  </Button>
                  </Stack>
                </Box>
              </Modal>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "50vh",
                  overflowY: "auto",
                  padding: "18px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                      >
                        COMPANY
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                      >
                        BRANCH
                      </TableCell>
                      {/* <TableCell
                        sx={{ color: "#000", border: "0.5px solid black",paddingY:"16px" }}
                        
                      >
                        Subledger
                      </TableCell> */}
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                        align="center"
                      >
                        ACTIVE STATE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {partydetails.map((opt) => (
                      <TableRow key={opt.branchid}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          {opt.companyname}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          {opt.branchname}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black",paddingY:"16px" }}
                        >
                          
                         {searchInput.subledgerdetails}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={opt.activeflag}
                                color="primary"
                              />
                            }
                            label="Active"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default BranchSubledger;
