import React from 'react';
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

toast.configure();
const WarrantyRegister = () => {


     //GET THE TOKEN FROM HELPER CLASS

     const userToken = LocalstorageHelper.GetUserToken();

     const redirectToLoginPage = () => {
       if (!userToken) {
         return <Redirect exact to="/sparepartsLogin" />;
       }
     };
   
     //TIMEOUT WHEN JWT EXPIRES
     setTimeout(() => {
       window.location.reload(false);
     }, 1800000);
   
     // TEXT FIELD VALUE STATES
     const date = new Date();
     const [fromdate, setfromdate] = useState(date);
     const [todate, settodate] = useState(date);
     const [pdfStream, setPdfStream] = useState(null);
     const handleReport = async (e) => {
       e.preventDefault();
       try {
         if (fromdate === null) {
           toast.error("Please Select A valid Date!");
         } else {
           const body = {fromdate,todate}
           const options = {
             headers: {
               "Content-Type": "application/json",
               sptoken: userToken,
             },
             method: "POST",
             body:JSON.stringify(body)
           };
           const response = await fetch(
             "/reports/saleregistersummary",
             options
           )
             .then((response) => response.blob())
             .then((pdfBlob) => {
               const pdfUrl = URL.createObjectURL(pdfBlob);
               setPdfStream(pdfUrl);
             })
             .catch((error) => console.error(error));
         }
       } catch (err) {
         console.log(err.message);
       }
     };


  return (
    <>
          <Sidebar
        Title={"WARRANTY REGISTER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={10}
              columnGap={2}
              direction="row"
              mt={5}
              mx={20}
              mb={4}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                endIcon={<Task />}
              >
                Show Report
              </Button>
            </Stack>
            {pdfStream && <ReportNavigation />}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  )
}

export default WarrantyRegister