
import React, { useRef } from "react";
import './login.modal.css';
import CloseIcon from '@mui/icons-material/Close';
import {  Box } from "@mui/material";

const LoginModal = ({show, onClose, children, set }) =>{
    const modalRef = useRef();

    const closeModal = e =>{
        if(modalRef.current === e.target){
            set (false);
        }
    };

    if (!show){
        return null;
    }
    return( 
        
    <div className="modalWrapper" ref={modalRef} onClick={closeModal}>
        <Box className="modal">
            
                <CloseIcon onClick={onClose} className="btnClose"/>
            {children}
            </Box>
    </div>
    
    )
};
export default LoginModal;