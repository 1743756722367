import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Fab,
  Tooltip,
  Typography,
  Switch,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const SubledgerOp = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const [param, setParam] = useState([]);
  const [searchInput, setSearchInput] = useState(null);
  const [checked, setChecked] = useState(false);
  const [dramt, setdramt] = useState(0);
  const [cramt, setcramt] = useState(0);
  const [updateDisabled, setUpdatedisabled] = useState(false);
  const [partyInput, setPartyInput] = useState(null);
  const [partyopts, setpartyOpts] = useState([]);
  const [mainledgerInput, setmainledgerInput] = useState(null);
  const [mainledgeropts, setmainledgerOpts] = useState([]);
  const [pdfStream, setPdfStream] = useState(null);
  const reporttypeopts = [
    {
      value: 0,
      label: "ALL",
    },
    {
      value: 1,
      label: "Selective",
    },
  ];
  const [reporttype, setreporttype] = useState(0);
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  //party
  async function getParam() {
    try {
      const response = await fetch("/spare/subldgrop/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //subledgerop data
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/subldgrop/data/${searchInput.subledgerid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setdramt(jsonData.openingdebit);
      setcramt(jsonData.openingcredit);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  //update
  const handleupdate = async (e) => {
    e.preventDefault();
    setUpdatedisabled(true);
    try {
      const body = { dramt, cramt };
      const response = await fetch(
        `/spare/subldgrop/up/${searchInput.subledgerid}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const jsonData = await response.json();
      if (jsonData.type === "success") {
        toast.success(jsonData.message);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  //mainledger
  async function getmainledger() {
    try {
      const response = await fetch("/spare/subldgrop/mainledger", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setmainledgerOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getmainledger();
  }, []);
  //party for report
  async function getpart4report() {
    try {
      const response = await fetch(
        `/spare/subldgrop/party/${mainledgerInput.mainledgerid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getpart4report();
  }, [mainledgerInput]);

  //report call

  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const body = {
        mainledgerid: mainledgerInput.mainledgerid,
        subledgerid: parseInt(reporttype) === 0 ? 0 : partyInput?.subledgerid,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };

      const response = await fetch("/spare/subldgrop/report", options)
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"SUBLEDGER OPENING"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              mb={"40px"}
              justifyContent={"center"}
              direction="row"
            >
              <FormControlLabel
                label="Toggle Report"
                control={<Checkbox checked={checked} onChange={handleToggle} />}
              />
              {!checked && (
                <Autocomplete
                  id="controlled-demo"
                  value={searchInput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setSearchInput(newValue);
                  }}
                  options={param}
                  getOptionLabel={(option) => option.subledgername}
                  sx={{
                    width: "40% ",
                    boxShadow: "2px 2px 6px  #404142",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search Items Here..." />
                  )}
                />
              )}
              {!checked && (
                <ColorButton
                  type="search"
                  variant="contained"
                  key="one"
                  onClick={handleSearch}
                  sx={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                  }}
                >
                  <SearchRoundedIcon />
                  SEARCH
                </ColorButton>
              )}
            </Stack>
            <Stack
              spacing={2}
              mb={"40px"}
              justifyContent={"center"}
              direction="row"
            >
              {checked && (
                <Autocomplete
                  id="mainledgersearch"
                  value={mainledgerInput}
                  filterOptions={filterOptions}
                  //isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setmainledgerInput();
                    } else {
                      setmainledgerInput(newValue);
                    }
                  }}
                  options={mainledgeropts}
                  getOptionLabel={(option) => option.mainledgername}
                  sx={{
                    width: 250,
                  }}
                  renderInput={(billopts) => (
                    <TextField
                      required
                      {...billopts}
                      placeholder="Mainledger"
                    />
                  )}
                  size="small"
                />
              )}
              {checked && (
                <TextField
                  id="receipt"
                  name="receipt"
                  required
                  select
                  label="Receipt Type"
                  value={reporttype}
                  onChange={(e) => {
                    setreporttype(e.target.value);
                    setPartyInput(null);
                  }}
                  variant="outlined"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                >
                  {reporttypeopts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {checked && (
                <Autocomplete
                  disabled={reporttype === 0}
                  id="partysearch"
                  value={partyInput}
                  filterOptions={filterOptions}
                  //isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setPartyInput();
                    } else {
                      setPartyInput(newValue);
                    }
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.subledgername}
                  sx={{
                    width:400,
                  }}
                  renderInput={(billopts) => (
                    <TextField
                      required
                      {...billopts}
                      placeholder="Party Details"
                    />
                  )}
                  size="small"
                />
              )}
              {checked && (
                <Button
                  //disabled={updateDisabled}
                  onClick={handleReport}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                >
                  SHOW REPORT
                </Button>
              )}
              {!checked && (
                <TextField
                  required
                  id="subledgerName"
                  label="Opening Debit"
                  value={dramt}
                  onChange={(e) => setdramt(e.target.value)}
                  type="text"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                />
              )}
              {!checked && (
                <TextField
                  required
                  id="subledgerName"
                  label="Opening Credit"
                  value={cramt}
                  onChange={(e) => setcramt(e.target.value)}
                  type="text"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                />
              )}
            </Stack>
            {checked && (
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
             )}
            <Stack
              spacing={2}
              columnGap={6}
              direction="row"
              mt={12}
              justifyContent={"center"}
            >
              {!checked && (
                <Button
                  disabled={updateDisabled}
                  onClick={handleupdate}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                >
                  UPDATE
                </Button>
              )}
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SubledgerOp;
