import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Stack,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createFilterOptions } from "@mui/material/Autocomplete";
import PrintIcon from "@mui/icons-material/Print";
const StockSummary = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [loading, setLoading] = useState(false);
  const date = new Date();
  // const [fromdate, setfromdate] = useState(date);
  const formatOpts = [
    {
      value: 1,
      type: "PDF",
    },
    {
      value: 2,
      type: "Excel",
    },
  ];
  const [format, setFormat] = useState(1);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  // const [catg, setcatg] = useState(null);
  // const [catgopts, setcatgopts] = useState([]);
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      if (todate === null) {
        toast.error("Please Select A valid Date!");
      } else {
        setLoading(true);
        const body = { todate, format };
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch("/reports/stksummary", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const toDateStr = todate.toISOString();
      const body = { todate: toDateStr };
      const data = await fetch("/reports/stksummary/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      downloadLink.download = "STOCK_SUMMARY_REPORT";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"STOCK SUMMARY"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            {loading ? (
              <>
                <Typography textAlign={"center"} variant="h6">
                  Loading...
                </Typography>
              </>
            ) : (
              <>
                <Stack
                  spacing={6}
                  columnGap={2}
                  direction="row"
                  mt={5}
                  mx={20}
                  mb={4}
                >
                  {/* <Autocomplete
                id="category"
                value={catg}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setcatg(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.techid) === parseInt(value.techid)
                }
                options={[]}
                // getOptionLabel={(option) => option.techname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(catgopts) => (
                  <TextField
                    required
                    {...catgopts}
                    placeholder="Select Category"
                  />
                )}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider> */}

                  <TextField
                    required
                    helperText="Select The Required Format"
                    select
                    id="format"
                    label="Format"
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "20%",
                    }}
                  >
                    {formatOpts.map((grp) => (
                      <MenuItem key={grp.value} value={grp.value}>
                        {grp.type}
                      </MenuItem>
                    ))}
                  </TextField>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      value={todate}
                      onChange={(newValue) => {
                        settodate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <Button
                    type="submit"
                    onClick={handleReport}
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    endIcon={<Task />}
                  >
                    Show Report
                  </Button>
                  <Button
                    onClick={printexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    endIcon={<PrintIcon />}
                  >
                    Excel
                  </Button>
                </Stack>
              </>
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default StockSummary;
