import React, { useState, useEffect } from "react";
import { Category } from "@mui/icons-material";
import { Button, Checkbox, FormGroup, Modal, Stack } from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import Tooltip,{ tooltipClasses } from '@mui/material/Tooltip';
toast.configure();

const RoleButton = ({ user }) => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };

  //Box Style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #C0C0C0",
    borderRadius: "2%",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  //Modal States and Functions
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Child Modal States and Functions
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  //Get Default Role Status

  const [access, setAccess] = useState([]);

  async function getAccess() {
    try {
      const response = await fetch(`/getrole/${user.user_id}`);
      const jsonData = await response.json();
      setAccess(jsonData);
      //  console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAccess();
    // eslint-disable-next-line
  }, []);
  //Update Roles

  const [accessflag1, setAccessflag1] = useState(false);
  const [accessflag2, setAccessflag2] = useState(false);
  const [accessflag3, setAccessflag3] = useState(false);
  const [accessflag4, setAccessflag4] = useState(false);

  const updateRoles = async (e) => {
    e.preventDefault();
    try {
      const body = { accessflag1, accessflag2, accessflag3, accessflag4 };
      const response = await fetch(`/userrole/${user.user_id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", token: userToken },
        body: JSON.stringify(body),
      });
      toast.success("Roles Updated!!", { autoClose: 1000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
      // console.log(response);
    } catch (err) {
      console.error(err.message);
    }
  };

  //ToolTip
  const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div>
         <MyTooltip  title="Role"   placement="top">
      <Button
        variant="contained"
        onClick={handleOpen}
        size="small"
        color="success"
      >
        <Category />
      </Button>
      </MyTooltip>
      <Modal
        open={open}
        aria-labelledby="Show Given User Roles"
        aria-describedby="Here the user roles given by admin is shown"
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: "30ch",
              margin: "auto",
              pt: 4,
              pb: 4,
            }}
            spacing={5}
            noValidate
            autoComplete="off"
          >
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={{ m: "auto" }}
              >
                Authorized User Role
              </FormLabel>

              <FormGroup sx={{ margin: "auto" }}>
                {access.map((access) => (
                  <FormControlLabel
                    key={access.domain_id}
                    control={<Checkbox checked={access.accessflag} />}
                    label={access.domain_name}
                  />
                ))}
              </FormGroup>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={handleShow}
              >
                Change
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </FormControl>
          </Stack>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={show}
        onClose={handleHide}
        aria-labelledby="Update User Role"
        aria-describedby="Here Admin Updates an existing User Role"
      >
        <Box sx={{ ...style, width: 270, p: 4, textAlign: "center" }}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Update User Role
            </FormLabel>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accessflag1}
                    onChange={(e) => setAccessflag1(e.target.checked)}
                  />
                }
                label="SHOWROOM"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accessflag2}
                    onChange={(e) => setAccessflag2(e.target.checked)}
                  />
                }
                label="SPAREPARTS"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accessflag3}
                    onChange={(e) => setAccessflag3(e.target.checked)}
                  />
                }
                label="SERVICE"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accessflag4}
                    onChange={(e) => setAccessflag4(e.target.checked)}
                  />
                }
                label="ACCOUNTS"
              />
            </FormGroup>
          </FormControl>
          <Button
            variant="outlined"
            color="success"
            startIcon={<Done />}
            size="small"
            onClick={(e) => updateRoles(e)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<Close />}
            size="small"
            onClick={handleHide}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RoleButton;
