import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import UploadFile from "@mui/icons-material/UploadFile";
import Task from "@mui/icons-material/Task";
import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
toast.configure();
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Transit = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const [missingTransitData, setMissingTransitData] = useState([]);
  const [missingTransitinvno, setMissingTransitinvno] = useState("");
  const [existinginvoices,setExistingInvoices] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", file);
      const response = await fetch("/uploadtransit", {
        method: "POST",
        headers: { sptoken: userToken },
        body: data,
      });
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 2000 });
      setImportDisabled(false);
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleImport = async (e) => {
    e.preventDefault();
    try {
      setImportDisabled(true);
      const response = await fetch(`/importtransit/${fileName}`, {
        method: "POST",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      // console.log(parseRes);
      if (parseRes.type && parseRes.type === "missing") {
        setMissingTransitData(parseRes.data);
        setMissingTransitinvno(parseRes.invoiceno);
        return;
      }
      if (parseRes.type && parseRes.type === "overwrite") {
        const confirm = window.confirm(parseRes.message);
        if (confirm) {
          const res = await fetch(`/importtransit/overwrite/${fileName}`, {
            method: "POST",
            headers: { sptoken: userToken },
          });
          const data = await res.json();
          toast.success(data, { autoClose: 1000 });
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
          return;
        } else {
          window.location.reload();
        }
      }
      if (parseRes.type && parseRes.type === "block") {
        toast.error(parseRes.message);
        setExistingInvoices(parseRes.data);
        return 
      }
      toast.success(parseRes, { autoClose: 1000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (err) {
      console.log(err.message);
      setImportDisabled(false);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"TRANSIT"}
        expandedPanel={"panel4"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            // onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Typography variant="h5" align="center" mt={"24px"} mb={4}>
              SELECT THE FILE TO IMPORT
            </Typography>
            <hr />

            <Stack
              spacing={2}
              ml={"34%"}
              mt={"100px"}
              direction="row"
              align="center"
            >
              <IconButton
                aria-label="upload picture"
                component="label"
                sx={{ color: "#e69b00" }}
              >
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFileName(e.target.files[0].name);
                    setFile(e.target.files[0]);
                    setDisabled(false);
                  }}
                />
                <FolderOpenTwoTone sx={{ width: "45px", height: "45px" }} />
              </IconButton>
              <TextField
                disabled
                id="filename"
                label="File Name"
                variant="standard"
                value={fileName}
                type="text"
                sx={{
                  width: "36%",
                }}
              />
            </Stack>
            <Stack spacing={20} ml={"29%"} mt={"80px"} direction="row">
              <Button
                disabled={disabled}
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                component="label"
                color="success"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                endIcon={<UploadFile />}
              >
                UPLOAD
              </Button>
              <Button
                type="submit"
                disabled={importDisabled}
                onClick={handleImport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                endIcon={<Task />}
              >
                IMPORT
              </Button>
            </Stack>
            {missingTransitData.length > 0 && (
              <Box mt={4}>
                <Typography align="center" variant="h6">
                  Missing Items For Invoice: {missingTransitinvno}
                </Typography>
                <Table
                  sx={{
                    maxWidth: 500,
                    margin: "auto",
                    mt: 2,
                    boxShadow: "2px 6px 15px #5a5a5a",
                  }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#3bb9ff",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Srl No.
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Part No.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {missingTransitData?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          {row.partno}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
            {existinginvoices.length > 0 && (
                            <Box mt={4}>
                            <Typography align="center" variant="h6">
                              Existing Invoices
                            </Typography>
                            <Table
                              sx={{
                                maxWidth: 500,
                                margin: "auto",
                                mt: 2,
                                boxShadow: "2px 6px 15px #5a5a5a",
                              }}
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#3bb9ff",
                                  border: "0.5px solid black",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{ color: "#000", border: "0.5px solid black" }}
                                    align="center"
                                  >
                                    Srl No.
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: "#000", border: "0.5px solid black" }}
                                    align="center"
                                  >
                                    Invoice No.
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {existinginvoices?.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{ color: "#000", border: "0.5px solid black" }}
                                      align="center"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: "#000", border: "0.5px solid black" }}
                                      align="center"
                                    >
                                      {row.invno}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Transit;
