import { Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const LoadingAnimation = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100vh", // Set to 100% of the viewport height for vertical centering
        }}
      >
        <CircularProgress />
      </Box>
    </>
  );
};

export default LoadingAnimation;
