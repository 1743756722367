import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";

import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
toast.configure();

const WayBill = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);
  const [pdfStream, setPdfStream] = useState(null);
  const [billno, setbillno] = useState(null);
  const [billopts, setbillopts] = useState([]);
  const [transname, settransname] = useState(null);
  //console.log(transname);
  const [transopts, settransopts] = useState([]);
  const [distance, setdistance] = useState();
  const [waybilltype, setWayBillType] = useState(1); // 1 - NORMAL , 2 - Against IRN
  const [frompin, setFromPin] = useState(null);
  const [topin, setToPin] = useState(null);
  const [gstno, setGstNo] = useState("");
  const [regno, setRegNo] = useState("");
  const [irn, setIrn] = useState("");
  const [generatedewaybill, setgeneratedewaybill] = useState([]);
  const [btndisabled, setBtnDisabled] = useState(false);
  const date = new Date();
  const [fromdate, setfromdate] = useState(date);
  const [todate, settodate] = useState(date);
  const columns = [
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No.",
      width: 60,
    },
    {
      field: "billno",
      headerClassName: "super-app-theme--header",
      headerName: "Bill No",
      width: 160,
    },
    {
      field: "billdate",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Date",
      width: 120,
    },
    {
      field: "partydetails",
      headerClassName: "super-app-theme--header",
      headerName: "Party",
      width: 240,
    },
    {
      field: "ewbno",
      headerClassName: "super-app-theme--header",
      headerName: "eWayBill No",
      width: 240,
    },
    {
      field: "ewbdate",
      headerClassName: "super-app-theme--header",
      headerName: "eWayBill Date",
      width: 240,
    },
  ];
  //excel
  const handleExcel = async () => {
    try {
      setBtnDisabled(true);
      const body = { fromdate, todate };
      const data = await fetch("/ewaybill/register/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const blob = await data.blob();
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "eWayBillRegister.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();
      setBtnDisabled(false);
      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
      setBtnDisabled(false);
    }
  };

  // console.log(waybilltype);
  //bill nos
  async function getbilldata() {
    try {
      const res = await fetch(`/ewaybill/billdetails/${waybilltype}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setbillopts(data);
      // console.log(data)
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getbilldata();
  }, [waybilltype]);
  //transporter
  async function gettransdata() {
    try {
      const res = await fetch("/ewaybill/transdetails", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      settransopts(data);
      // console.log(data)
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    gettransdata();
  }, []);

  useEffect(() => {
    if (billno !== null) {
      console.log(billno);
      settransname({
        transporterid: billno.transporterid,
        transportername: billno.transportername,
        gstno: billno.gstno,
      });
      setToPin(parseInt(billno.topincode));
      setFromPin(parseInt(billno.frompincode));
      setGstNo(billno.gstno);
      setIrn(parseInt(waybilltype) === 2 ? billno.irn : "");
    }
  }, [billno]);

  useEffect(() => {
    if (transname !== null) {
      console.log(transname);
      setGstNo(transname.gstno);
    }
  }, [transname]);
  const getDistanceBetweenPincode = async () => {
    try {
      const body = { topin, frompin };
      const response = await fetch("/ewaybill/getdistance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        body: JSON.stringify(body), // No need to send any body from frontend
      });

      const responseData = await response.json(); // Get the response text
      // console.log(responseData);
      if (responseData.IsSuccess === "true") {
        setdistance(responseData.Distance);
      } else {
        return toast.error("Distance Not Found!", {
          toastId: 1,
        });
      }
      // const data = JSON.parse(responseData.slice(1, -1)) // Parse the response text into JSON
      // console.log(data);
      // if (data.IsSuccess === "true") {
      //   setdistance(data.Distance);
      // } else {
      //   return toast.error("Failed to Fetch Distance!");
      // }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (frompin !== null && topin !== null) {
      // console.log("DISTANCE HIT!");
      getDistanceBetweenPincode();
    }
  }, [frompin, topin]);
  const handleReport = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    try {
      if (parseInt(waybilltype) === 1) {
        const body = {
          transgst: gstno,
          transname: transname ? transname.transportername : "",
          vehicleno: regno,
          distance,
          id: billno.billheaderid,
        };
        const response = await fetch(`/ewaybill/generate`, {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        console.log(data);
        if (data.IsSuccess === "false") {
          toast.error(data.ErrorCode + ":" + data.ErrorMessage, {
            autoClose: false,
          });
          setDisableSubmit(false);
        } else {
          // console.log("Success");
          toast.success(
            `Ewaybill No ${data.EWayBill} Generated Against Document Number ${data.DocNo}!`,
            {
              autoClose: false,
              onClose: () => window.location.reload(false),
            }
          );
        }
      } else {
        const body = {
          transgst: gstno,
          transname: transname ? transname.transportername : "",
          vehicleno: regno,
          distance,
          id: billno.billheaderid,
          irn
        };
        const response = await fetch(`/ewaybill/irn/generate`, {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        console.log(data);
        if (data.IsSuccess === "False") {
          toast.error(data.ErrorCode + ":" + data.ErrorMessage, {
            autoClose: false,
          });
          setDisableSubmit(false);
        } else {
          // console.log("Success");
          toast.success(
            `Ewaybill No ${data.EwbNo} Generated Against Irn ${data.Irn}`,
            {
              autoClose: false,
              onClose: () => window.location.reload(false),
            }
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  //generated billdetails
  async function getewaybilldetails() {
    try {
      const res = await fetch("/ewaybill/billdetails", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setgeneratedewaybill(data);
      //console.log(data)
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getewaybilldetails();
  }, []);

  return (
    <>
      <Sidebar
        Title={"e-Way Bill"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Link to="/salebill">
              <Button>←Go Back to Sale Bill</Button>
            </Link>
            {/* <a
              style={{ textdecoration: "none", marginLeft: 30 }}
              href="https://einvoice1.gst.gov.in/Others/GetPinCodeDistance"
              target="_blank"
            >
              Click here to visit the Pin Code Distance Calculator
            </a> */}

            <Stack spacing={2} mt={5} ml={10} mb={2} direction="row">
              <FormControl size="small">
                <InputLabel id="waybilltype">Waybill Type</InputLabel>
                <Select
                  labelId="waybilltype"
                  id="waybilltype"
                  value={waybilltype}
                  label="Waybill Type"
                  onChange={(e) => setWayBillType(e.target.value)}
                >
                  <MenuItem value={1}>General</MenuItem>
                  <MenuItem value={2}>Against IRN</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                id="bill"
                value={billno}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setbillno(newValue);
                }}
                options={billopts}
                getOptionLabel={(option) => option.billno}
                sx={{
                  width: "50%",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Bill Nos" />
                )}
                size="small"
              />
              <Autocomplete
                id="trans"
                value={transname}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  settransname(newValue);
                }}
                options={transopts}
                getOptionLabel={(option) => option.transportername}
                sx={{
                  width: 250,
                }}
                renderInput={(billopts) => (
                  <TextField
                    required
                    {...billopts}
                    placeholder="Transporter Name"
                  />
                )}
                size="small"
              />
            </Stack>
            <Stack spacing={2} mt={5} ml={10} mb={2} direction="row">
              <TextField
                id="gstno"
                label="GST No"
                value={gstno}
                onChange={(e) => setGstNo(e.target.value)}
                variant="outlined"
                sx={{
                  width: 200,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              <TextField
                id="regno"
                label="REGNO"
                value={regno}
                onChange={(e) => setRegNo(e.target.value)}
                variant="outlined"
                sx={{
                  width: 200,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              {parseInt(waybilltype) === 2 && (
                <TextField
                  id="irn"
                  label="IRN"
                  value={irn}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              )}
            </Stack>
            <Stack spacing={2} mt={5} ml={10} mb={2} direction="row">
              <TextField
                id="frompin"
                required
                label="From Pin"
                value={frompin}
                type="number"
                onChange={(e) => setFromPin(e.target.value)}
                variant="outlined"
                sx={{
                  width: 120,
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="topin"
                required
                label="To Pin"
                value={topin}
                type="number"
                onChange={(e) => setToPin(e.target.value)}
                variant="outlined"
                sx={{
                  width: 120,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              <TextField
                id="type"
                required
                label="Distance"
                value={distance}
                type="number"
                onChange={(e) => setdistance(e.target.value)}
                variant="outlined"
                sx={{
                  width: 120,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              <Button
                disabled={disableSubmit}
                onClick={handleReport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                // endIcon={<Task />}
              >
                Generate e-Waybill
              </Button>
            </Stack>
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="600px"
                  title="report"
                ></iframe>
              )}
            </Box>
            <Stack
              spacing={2}
              mt={4}
              ml={10}
              mb={2}
              direction="row"
              justifyContent={"center"}
            >
              {" "}
              <Typography textAlign={"center"} variant="h5" fontWeight={"bold"}>
                Generated eWay Bills
              </Typography>
            </Stack>
            <Stack
              spacing={2}
              mt={1}
              ml={10}
              mb={2}
              direction="row"
              justifyContent={"center"}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        //backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        //backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                size="small"
                onClick={handleExcel}
                color="success"
                disabled={btndisabled}
              >
                Export Excel
              </Button>
            </Stack>
            <Stack spacing={2} mt={2} ml={10} mb={2} direction="row">
              <DataGrid
                sx={{ height: 300 }}
                rowHeight={40}
                headerHeight={40}
                columns={columns}
                rows={generatedewaybill}
                getRowId={(row) => row.billno}
              />
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default WayBill;
