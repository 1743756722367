import { Box, Skeleton, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CatgEdit from "./GridEditButtons/CatgEdit";
import { useEffect } from "react";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import CatgDel from "./GridEditButtons/CatgDel";
import UomEdit from "./GridEditButtons/UomEdit";
import UomDel from "./GridEditButtons/UomDel";
import GroupEdit from "./GridEditButtons/GroupEdit";
import GroupDelete from "./GridEditButtons/GroupDelete";
import RackEdit from "./GridEditButtons/RackEdit";
import RackDelete from "./GridEditButtons/RackDelete";
import AddCircle from "@mui/icons-material/AddCircle";

toast.configure();
//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/////////////////////////////// ARROW COMPONENT STARTS HERE /////////////////////////////////
const Other = () => {
  const userToken = LocalstorageHelper.GetUserToken();
  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);

  //CATEGORY HANDLE

  const [catgCode, setCatgCode] = useState("");
  const [catgName, setCatgName] = useState("");
  const [Fetch, setFetch] = useState(true);
  const [loading, setloading] = useState(false);
  const handleCatg = async (e) => {
    e.preventDefault();
    try {
      const body = { catgCode, catgName };
      const response = await fetch("/other/insertCatg", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setloading(true);
      if (parseRes === 1) {
        toast.success("Item Created Successfully", { autoClose: 1000 });
        setCatgCode("");
        setCatgName("");
        setloading(false);
        //   setTimeout(() => {
        //     window.location.reload(false)
        // }, 1000);
        setFetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [category, setCategory] = useState([]);
  async function getCategory() {
    setloading(true);
    try {
      const response = await fetch("/getcategory", {
        method: "GET",
        headers: { sptoken: userToken },
      });

      const jsonData = await response.json();
      setCategory(jsonData);
      setloading(false);
      setFetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (Fetch == true) {
      getCategory();
    }
  }, [Fetch]);

  //UOM HANDLE
  const [uomName, setUomName] = useState("");
  const [uomloading, setUomloading] = useState(false);
  const [uomfetch, setUomfetch] = useState(true);
  const handleUom = async (e) => {
    e.preventDefault();
    try {
      const body = { uomName };
      const response = await fetch("/other/insertUom", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setUomloading(true);
      if (parseRes === 1) {
        toast.success("UOM Created Successfully", { autoClose: 1000 });
        setUomName("");
        //   setTimeout(() => {
        //     window.location.reload(false)
        // }, 1000);
        setUomloading(false);
        setUomfetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [uom, setUom] = useState([]);
  async function getUom() {
    setUomloading(true);
    try {
      const response = await fetch("/getuom", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setUom(jsonData);
      setUomloading(false);
      setUomfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (uomfetch === true) {
      getUom();
    }
  }, [uomfetch]);
  //GROUP HANDLE
  const [groupName, setGroupName] = useState("");
  const [itemloading, setItemloading] = useState(false);
  const [groupfetch, setGroupfetch] = useState(true);
  const [rackdisable,setRackDisable] = useState(false);
  const handleGroup = async (e) => {
    e.preventDefault();
    try {
      const body = { groupName };
      const response = await fetch("/other/insertGroup", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setItemloading(true);
      if (parseRes === 1) {
        toast.success("Item Group Created Successfully", { autoClose: 1000 });
        setGroupName("");
        //  setTimeout(() => {
        //      window.location.reload(false)
        //  }, 1000);
        setItemloading(false);
        setGroupfetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [group, setGroup] = useState([]);
  async function getGroup() {
    setItemloading(true);
    try {
      const response = await fetch("/getitemgroup", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setGroup(jsonData);
      // console.log(jsonData);
      setItemloading(false);
      setGroupfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (groupfetch == true) {
      getGroup();
    }
  }, [groupfetch]);

  //RACK HANDLE
  const [rackName, setRackName] = useState("");
  const [rackloading, setRackloading] = useState(false);
  const [rackfetch, setRackfetch] = useState(true);
  const handleRack = async (e) => {
    e.preventDefault();
    setRackDisable(true);
    try {
      const body = { rackName };
      const response = await fetch("/other/insertRack", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setRackloading(true);
      if (parseRes === 1) {
        toast.success("Rack Created Successfully", { autoClose: 1000 });
        setGroupName("");
        //  setTimeout(() => {
        //      window.location.reload(false)
        //  }, 1000);
        setRackloading(false);
        setRackfetch(true);
        setRackName("");
        setRackDisable(false);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
        setRackDisable(false);
      }
    } catch (err) {
      console.log(err.message);
      setRackDisable(false);
    }
  };
  const [rack, setRack] = useState([]);
  async function getRack() {
    setRackloading(true);
    try {
      const response = await fetch("/getrack", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setRack(jsonData);
      setRackloading(false);
      setRackfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (rackfetch == true) {
      getRack();
    }
  }, [rackfetch]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Sidebar
        Title={"OTHER MASTER"}
        expandedPanel={"panel1"}
        children={
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "divider",
                background:
                  "linear-gradient(90deg, rgba(181,184,184,1) 42%, rgba(157,205,219,1) 90%)",
              }}
            >
              <Tabs
                value={value}
                variant="fullWidth"
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab label="Category" {...a11yProps(0)} />
                <Tab label="UOM" {...a11yProps(1)} />
                <Tab label="Item Group" {...a11yProps(2)} />
                <Tab label="Rack Details" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box component={"form"} onSubmit={handleCatg}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW CATEGORY
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"30%"}
                >
                  <TextField
                    required
                    value={catgCode}
                    onChange={(e) => {
                      setCatgCode(e.target.value);
                    }}
                    id="catgCode"
                    label="Category Code"
                    variant="standard"
                  />
                  <TextField
                    required
                    value={catgName}
                    onChange={(e) => {
                      setCatgName(e.target.value);
                    }}
                    id="catgName"
                    label="Category Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                    <AddCircle fontSize="medium" />
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {loading === false ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "5px 5px 10px #404142",
                    backgroundColor: "#868c91",
                    maxHeight: "55vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                  }}
                >
                  <Table
                    sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Category Code
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Category Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {category.map((catg) => (
                        <TableRow key={catg.categoryid}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {catg.categorycode}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {catg.categoryname}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <CatgEdit
                              allCategory={category}
                              category={catg}
                              setloading={setloading}
                              setFetch={setFetch}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <CatgDel
                              category={catg}
                              setloading={setloading}
                              setFetch={setFetch}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ mt: "2%" }}
                  width={"100%"}
                  height={"55vh"}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box component={"form"} onSubmit={handleUom}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW UNIT OF MEASURE
                </Typography>

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"35%"}
                >
                  <TextField
                    required
                    value={uomName}
                    onChange={(e) => {
                      setUomName(e.target.value);
                    }}
                    id="uomName"
                    label="UOM Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                    <AddCircle fontSize="medium" />
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {uomloading === false ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "5px 5px 10px #404142",
                    backgroundColor: "#868c91",
                    maxHeight: "55vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                  }}
                >
                  <Table
                    sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Unit of Measure
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {uom.map((opt) => (
                        <TableRow key={opt.uomid}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.uomname}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <UomEdit allUom={uom} uom={opt} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <UomDel uom={opt} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ mt: "2%" }}
                  width={"100%"}
                  height={"55vh"}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box component="form" onSubmit={handleGroup}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW ITEM GROUP
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"35%"}
                >
                  <TextField
                    required
                    value={groupName}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    id="groupName"
                    label="Group Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                    <AddCircle fontSize="medium" />
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {itemloading === false ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "5px 5px 10px #404142",
                    backgroundColor: "#868c91",
                    maxHeight: "55vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                  }}
                >
                  <Table
                    sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                            backgroundColor: "#aad3f0",
                          }}
                        >
                          Item Group
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                            backgroundColor: "#aad3f0",
                          }}
                          align="center"
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                            backgroundColor: "#aad3f0",
                          }}
                          align="center"
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {group.map((grp) => (
                        <TableRow key={grp.groupid}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {grp.groupname}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <GroupEdit allGroup={group} group={grp} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <GroupDelete group={grp} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ mt: "2%" }}
                  width={"100%"}
                  height={"55vh"}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box component={"form"} onSubmit={handleRack}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW RACK DETAILS
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"35%"}
                >
                  <TextField
                    required
                    value={rackName}
                    onChange={(e) => {
                      setRackName(e.target.value);
                    }}
                    id="rackName"
                    label="Rack Name"
                    variant="standard"
                  />
                  <ColorButton type="submit" disabled={rackdisable}>
                    <AddCircle fontSize="medium" />
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {/* {rackloading === false ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "5px 5px 10px #404142",
                    backgroundColor: "#868c91",
                    maxHeight: "55vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                  }}
                >
                  <Table
                    sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Rack Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {rack.map((options) => (
                        <TableRow key={options.rackid}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {options.rackname}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <RackEdit allRack={rack} rack={options} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            <RackDelete rack={options} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ mt: "2%" }}
                  width={"100%"}
                  height={"55vh"}
                />
              )} */}
            </TabPanel>
          </Box>
        }
      />
      {redirectToLoginPage()}
    </div>
  );
};

export default Other;
