import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
toast.configure();
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AdminSidebar = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [refreshState, setRefreshState] = useState(false);
  const pagRefresh = () => {
    if (refreshState === true) {
      window.location.reload();
    }
  };
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const adminarray = [
    {
      menuName: "DashBord",
      path: "/admin",
      icon: <DashboardIcon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Create User",
      path: "/usercreate",
      icon: <PersonAddAlt1Icon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Created User",
      path: "/createduser",
      icon: <PeopleIcon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Unlock Provisional MR",
      path: "/unprovmr",
      icon: <AssignmentIcon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Instructions",
      path: "/admininst",
      icon: <MenuBookIcon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Sale Bill Print",
      path: "/salebilldoc",
      icon: <MenuBookIcon sx={{ color: "#ffff" }} />,
    },
    {
      menuName: "Item Opening Stock Correction",
      path: "/itemopcor",
      icon: <MenuBookIcon sx={{ color: "#ffff" }} />,
    },
  ];
  const history = useHistory();
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    toast.info("Logged Out!", { autoClose: 1000 });
    setTimeout(() => {
      window.location.reload();
    }, 1500);
    history.push("/");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Admin Dashboard
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"contained"}
                color={"warning"}
                onClick={logout}
                btnText={"Logout"}
              >
                LogOut
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#1976d2",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Divider />
          <List>
            {adminarray.map((text, index) => (
              <ListItem key={text.menuName} disablePadding>
                <NavLink
                  exact
                  to={text.path}
                  className="link-normal"
                  activeClassName="link-active"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {text.icon}
                      {/* {index === adminarray.length - 4 ? ( 
                        <DashboardIcon sx={{ color: "#ffff" }} />
                      ) : index === adminarray.length - 3  ? (
                        <PersonAddAlt1Icon sx={{ color: "#ffff" }} />
                      ) : (
                        <ConstructionIcon sx={{ color: "#ffff" }} />
                      )} */}
                    </ListItemIcon>
                    <ListItemText primary={text.menuName} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
      </Box>
      {pagRefresh()}
      {redirectToLoginPage()}
    </>
  );
};

export default AdminSidebar;
