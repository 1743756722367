import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";

import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
toast.configure();

const CrediteNoteInvoice = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const [loading, setLoading] = useState(false);
  const [serviceflag, setserviceflag] = useState();
  const [typeflag, settypeflag] = useState(1);
  const [creditnoteno, setcreditnoteno] = useState(null);
  const [creditnoteopts, setcreditnotepts] = useState([]);
  const service = [
    {
      value: "NO",
      label: "Is Service",
    },
    {
      value: "YES",
      label: "Non Service",
    },
  ];
  const type = [
    {
      value: 1,
      label: "Normal Print",
    },
    {
      value: 2,
      label: "eInvoice",
    },
  ];
  const [selectedbills, setselectedbills] = useState([]);
  const columns = [
    {
      field: "partyname",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "Party's Name",
      width: 250,
      editable: false,
    },
    {
      field: "gstno",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "GST NO",
      width: 120,
      editable: false,
    },
    {
      field: "documentdate",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "Date",
      width: 80,
      editable: false,
      type: "date",
    },
    {
      field: "documentno",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "DocNo.",
      width: 98,
      editable: false,
    },
    {
      field: "ackdate",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "Ack date",
      width: 80,
      editable: false,
      type: "date",
    },
    {
      field: "ackno",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "AckNo.",
      width: 120,
      editable: false,
    },

    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      headerName: "Generate e-Invoice",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Button
            onClick={(e) => handleEinvoice(e, id)}
            variant="contained"
            size="small"
            disabled={loading}
          >
            {/* {selectedbills.ackno === null ? "Generate" : "Download"} */}
            Generate
          </Button>,
        ];
      },
    },
  ];

  async function getbills() {
    try {
      //   const response = await fetch("/einvoice/bills/details", {
      //     method: "GET",
      //     headers: { sptoken: userToken },
      //   });
      //   const jsonData = await response.json();
      //   console.log(jsonData);
      //   // setallparts(jsonData);
      //   setselectedbills(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getbills();
  }, []);

  const [pdfStream, setPdfStream] = useState(null);
  //get creditnos
  async function getcreditnotenos() {
    try {
      const res = await fetch("/reports/creditnote/documentnos", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setcreditnotepts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getcreditnotenos();
  }, []);
  const handleEinvoice = async (e, id) => {
    e.preventDefault();
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        sptoken: userToken,
      };
      const report = await fetch(`/einvoice/crn/${id}`, {
        headers,
      });
      if (report.status === 400) {
        // Error case: Handle the error information from the server
        const errorData = await report.json();
        toast.error(errorData.errorMessage);
        setLoading(false);
      } else {
        // Success case: Generate PDF or handle as needed
        const pdfBlob = await report.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfStream(pdfUrl);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/reports/spare/creditenote/${creditnoteno.salereturnheaderid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfStream(pdfUrl);
    } catch (err) {
      console.error(err);
    }
  };
  //eninvoice
  async function geteInvoicedata() {
    try {
      const res = await fetch(`/einvoice/creditnote/data/${serviceflag}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setselectedbills(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    geteInvoicedata();
  }, [serviceflag]);

  const [checked, setChecked] = useState(false);
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const date = new Date();
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(null);
  const [cndetails, setcndetails] = useState([]);
  const einvoicecolumns = [
    {
      field: "gstno",
      headerClassName: "super-app-theme--header",
      headerName: "Gst No",
      width: 130,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "partyname",
      headerClassName: "super-app-theme--header",
      headerName: "Party's Name",
      width: 300,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "billno",
      headerClassName: "super-app-theme--header",
      headerName: "Bill No.",
      width: 150,
      editable: false,
    },
    {
      field: "billdate",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Date",
      width: 100,
      editable: false,
      type: "date",
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "billamt",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Amt",
      width: 100,
      editable: false,
      type: "date",
      // cellClassName: "custom-cell",
      // headerClassName: "custom-header",
    },
    {
      field: "ackno",
      headerClassName: "super-app-theme--header",
      headerName: "Ack No.",
      width: 120,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "ackdate",
      headerClassName: "super-app-theme--header",
      headerName: "Ack Date",
      width: 100,
      editable: false,
      type: "date",
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "irn",
      headerClassName: "super-app-theme--header",
      headerName: "IRN",
      width: 400,
      editable: false,
      cellClassName: "custom-cell4Irn",
      headerClassName: "custom-header",
    },
  ];
  //formatted excel non service
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      //partyarray = parray.map((opt) => opt.subledgerid);
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const data = await fetch("/salereturn/einvoice/download/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "CreditNote_eInvoice_NonService.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  //formatted excel Is service
  const prinIsServicetexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      //partyarray = parray.map((opt) => opt.subledgerid);
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const data = await fetch(
        "/salereturn/einvoice/isservice/download/excel",
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "CreditNote_eInvoice_IsService.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
    //formatted excel Mixed
    const printMixedtexcel = async (e) => {
      e.preventDefault();
      if (fromdate === null) {
        return toast.error("Please Select The From Date!!");
      }
      try {
        const fromDateStr = fromdate.toISOString();
        const toDateStr = todate.toISOString();
        //partyarray = parray.map((opt) => opt.subledgerid);
        const body = { fromdate: fromDateStr, todate: toDateStr };
        const data = await fetch(
          "",
          {
            method: "POST",
            headers: {
              sptoken: userToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );
  
        const blob = await data.blob();
  
        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "CreditNote_eInvoice_Mixed.xlsx";
  
        // Append the link to the body
        document.body.appendChild(downloadLink);
  
        // Trigger the click event to start the download
        downloadLink.click();
  
        // Remove the link from the body
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.log(error.message);
      }
    };
  //for view
  const viewdata = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const res = await fetch("/salereturn/einvoice/data/view", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      setcndetails(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      // const fromDateStr = fromdate.toISOString();
      // const toDateStr = todate.toISOString();
      const body = { fromdate, todate };
      const res = await fetch(`/salereturn/einvoice/data/download`, {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const blob = await res.blob(); // Convert response to a Blob
      const filename = `CREDITNOTE_INVOICE_REGISTER.pdf`; // Set the desired file name for the download
      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to initiate download
      link.click();

      // Clean up resources
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  return (
    <>
      <Sidebar
        Title={"Credit Note Print"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Link to="/salereturn">
              <Button>←Go Back to Credit Note</Button>
            </Link>
            <Stack spacing={2} mt={1} justifyContent={"center"} direction="row">
              {" "}
              <FormControlLabel
                label="Toggle Report"
                control={<Checkbox checked={checked} onChange={handleToggle} />}
              />
            </Stack>
            {!checked && (
              <>
                <Stack spacing={2} mt={5} ml={10} mb={2} direction="row">
                  <TextField
                    id="type"
                    required
                    select
                    label="Type"
                    value={typeflag}
                    onChange={(e) => settypeflag(e.target.value)}
                    variant="outlined"
                    sx={{
                      width: 250,
                    }}
                    size="small"
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {typeflag === 2 ? (
                    <>
                      <TextField
                        id="isService"
                        required
                        select
                        label="ServiceFlag"
                        value={serviceflag}
                        onChange={(e) => setserviceflag(e.target.value)}
                        variant="outlined"
                        sx={{
                          width: 250,
                        }}
                        size="small"
                      >
                        {service.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>
                {parseInt(typeflag) === 2 ? (
                  <>
                    <Stack
                      spacing={2}
                      mt={2}
                      mb={2}
                      direction="row"
                      justifyContent={"flex-start"}
                    >
                      <Box
                        sx={{
                          height: 600,
                          width: "100%",
                          marginLeft: 10,
                          display: "flex",
                        }}
                      >
                        <DataGrid
                          sx={{
                            width: "100%",
                            "& .super-app-theme--header": {
                              color: "#000",
                              fontSize: "14px", // Adjust font size here
                            },
                            "& .super-app-theme--cell": {
                              fontSize: 10, // Adjust font size for cell
                            },
                          }}
                          rows={selectedbills}
                          columns={columns}
                          loading={loading}
                          rowHeight={30}
                          headerHeight={30}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                            sorting: {
                              sortModel: [
                                { field: "documentno", sort: "desc" },
                              ],
                            },
                          }}
                          pageSizeOptions={[5]}
                          getRowId={(item) => item.salereturnheaderid}
                          // checkboxSelection
                          // onSelectionModelChange={(item) => setpartno(item)}
                        />
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack spacing={2} mt={5} ml={10} mb={2} direction="row">
                      <Autocomplete
                        id="debitnos"
                        value={creditnoteno}
                        // filterOptions={filterOptions}
                        onChange={(event, newValue) => {
                          setcreditnoteno(newValue);
                        }}
                        options={creditnoteopts}
                        getOptionLabel={(option) => option.documentdetails}
                        sx={{
                          width: "40% ",
                        }}
                        renderInput={(billopts) => (
                          <TextField
                            required
                            {...billopts}
                            placeholder="Credit Nos"
                          />
                        )}
                        size="small"
                      />
                      <Button
                        type="submit"
                        // disabled={importDisabled}
                        onClick={handleReport}
                        variant="contained"
                        component="label"
                        size="large"
                        sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                        // endIcon={<Task />}
                      >
                        Show Report
                      </Button>
                    </Stack>
                  </>
                )}
                <Box sx={{ backgroundColor: "white" }}>
                  {pdfStream && (
                    <iframe
                      src={pdfStream}
                      width="100%"
                      height="600px"
                      title="report"
                    ></iframe>
                  )}
                </Box>{" "}
              </>
            )}
            {checked && (
              <>
                <Stack
                  spacing={2}
                  mt={1}
                  justifyContent={"center"}
                  direction="row"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD/MM/YYYY"
                      value={fromdate}
                      onChange={(newValue) => {
                        setfromdate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      value={todate}
                      onChange={(newValue) => {
                        settodate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    onClick={printexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    //endIcon={<PrintIcon />}
                  >
                    Non_Service Excel
                  </Button>
                  <Button
                    onClick={prinIsServicetexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    //endIcon={<PrintIcon />}
                  >
                    Is_Service Excel
                  </Button>
                  <Button
                    onClick={printMixedtexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    //endIcon={<PrintIcon />}
                  >
                    Mixed Excel
                  </Button>
                  <Button
                    onClick={viewdata}
                    size="small"
                    variant="contained"
                    color="primary"
                    //endIcon={<PrintIcon />}
                  >
                    View
                  </Button>
                  <Button
                    onClick={handleDownload}
                    size="small"
                    variant="contained"
                    color="primary"
                    //endIcon={<PrintIcon />}
                  >
                    Download Pdf
                  </Button>
                </Stack>
                <Stack
                  spacing={2}
                  mt={3}
                  justifyContent={"center"}
                  direction="row"
                >
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      marginLeft: 10,
                      display: "flex",
                    }}
                  >
                    <DataGrid
                      sx={{
                        width: "100%",
                        "& .super-app-theme--header": {
                          // backgroundColor: "#3bb9ff",
                          color: "#000",
                        },
                        // "& .custom-header": {
                        //   fontSize: 10, // Decrease font size for the custom header
                        // },
                        "& .custom-cell": {
                          fontSize: 11, // Decrease font size for the custom cell
                        },
                        "& .custom-cell4Irn": {
                          fontSize: 9, // Decrease font size for the custom cell
                        },
                      }}
                      rows={cndetails}
                      columns={einvoicecolumns}
                      //loading={loading}
                      rowHeight={40}
                      headerHeight={40}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      getRowId={(item) => item.salereturnheaderid}
                      // checkboxSelection
                      // onSelectionModelChange={(item) => setpartno(item)}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default CrediteNoteInvoice;
