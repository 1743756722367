import { Edit } from "@mui/icons-material";
import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../../LocalStorageHelper/LocalstorageHelper";
toast.configure();



const CatgEdit = ({ category, allCategory, setloading,setFetch }) => {
  const userToken = LocalstorageHelper.GetUserToken();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "& button": { m: 1 },
  };
  const [categoryCode, setCategoryCode] = useState(category.categorycode);
  const [categoryName, setCategoryName] = useState(category.categoryname);
  const [helpText, setHelpText] = useState(""); // change helper text
  const [disabled, setDisabled] = useState(false); //UPDATE BUTTON STATE
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCategoryCode(category.categorycode);
    setCategoryName(category.categoryname);
  };
  //edit Data Function

  const updateDetails = async (e) => {
    e.preventDefault();
    try {
      const body = { categoryCode, categoryName };
      const response = await fetch(
        `/other/updateCatg/${category.categoryid}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      setloading(true);
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 1000 });
      setOpen(false);
      setloading(false);
      setFetch(true);
    //   setTimeout(() => {
    //     window.location.reload(false)
    // }, 1000);
    } catch (err) {
      console.error(err.message);
    }
  };

///CHECK IF THE CATEGORY EXISTS ALREADY 
const handleCheck = (e)=>{

  let value = e.target.value;
  setCategoryCode(e.target.value);
  if(allCategory.map(a=>a.categorycode).includes(value)){
  setHelpText("Already Exists!");
  setDisabled(true);
  }
  else{
    setHelpText("");
  setDisabled(false);
  }
  }
  
  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        open={open}
        aria-labelledby="Edit Category"
        aria-describedby="edit Categorycode and Categoryname and Update"
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: "30ch",
            }}
            spacing={5}
            noValidate
            autoComplete="off"
          >
            <TextField
            required
              id="categoryCode"
              label="Category Code"
              variant="standard"
              type="text"
              helperText={helpText}
              value={categoryCode}
              onChange={handleCheck}
            />
            <TextField
            required
              id="categoryName"
              label="Category Name"
              variant="standard"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />

            <Button
            disabled={disabled}
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              onClick={(e) => updateDetails(e)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default CatgEdit;
