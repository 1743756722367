import {
  Box,
  Tabs,
  Tab,
  TextField,
  Stack,
  MenuItem,
  Button,
  styled,
  Badge,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { tableCellClasses } from "@mui/material";
import { Redirect } from "react-router-dom";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import BillSearch from "@mui/icons-material/Search";
import HowToReg from "@mui/icons-material/HowToReg";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";
import { keyframes } from "@emotion/react";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
toast.configure();
const uCategory = [
  {
    value: 1,
    label: "Admin",
  },
  {
    value: 2,
    label: "User",
  },
];
const CreateUser = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };

  const [companyopts, setcompanyopts] = useState([]);
  async function getCompanies() {
    try {
      const response = await fetch("/companies", {
        method: "GET",
        headers: { token: userToken },
      });

      const jsonData = await response.json();
      console.log(jsonData);
      setcompanyopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  const [branchopts, setBranchOpts] = useState([]);
  const [branchdisabled, setBranchDisabled] = useState(true);
  const handleBranchFetch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/branches/${companyid}`, {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setBranchOpts(jsonData);
      setBranchDisabled(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const [branchid, setBranch] = useState(1);
  const [companyid, setCompany] = useState(1);
  const [category, setCategory] = useState(1);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [discreateuserbtn, setdiscreateuserbtn] = useState(false);
  const isUnlocked = true;
  const onCreateUser = async (e) => {
    e.preventDefault();
    setdiscreateuserbtn(true);
    try {
      const body = { username, password, category, companyid, branchid };
      const response = await fetch("/user", {
        method: "POST",
        headers: { "Content-Type": "application/json", token: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();

      if (parseRes.user_name) {
        toast.success("User added Succesfully", { autoClose: 2000 });
        setUsername("");
        setPassword("");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } else {
        console.log(parseRes.errors[0].msg);
        toast.error(parseRes.errors[0].msg, { autoClose: 2000 });
      }
      setdiscreateuserbtn(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <>
      <AdminSidebar />
      <Box
        sx={{
          ml: "39%",
          mb: 2,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          rowGap: 3,
          justifySelf: "center",
        }}
      >
        <Typography variant="h4">Create User</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            rowGap: 3,
            width: 400,
          }}
        >
          <TextField
            sx={{ width: 300 }}
            id="standard-basic"
            label="UserName"
            variant="standard"
            type="text"
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          />
          <TextField
            sx={{ width: 300 }}
            id="standard-role"
            label="Password"
            variant="standard"
            type="password"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
          />

          <TextField
            sx={{ width: 300 }}
            id="standard-select-category"
            select
            label=" Select User Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            variant="standard"
          >
            {uCategory.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ display: "inline-flex", marginRight: 1, flexGrow: 1 }}>
              <TextField
                sx={{ width: 300 }}
                id="standard-select-company"
                select
                label=" Select User Company"
                value={companyid}
                onChange={(e) => setCompany(e.target.value)}
                variant="standard"
              >
                {companyopts?.map((option) => (
                  <MenuItem key={option.companyid} value={option.companyid}>
                    {option.companyname}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: "inline-flex" }}>
              <Button
                aria-label="search"
                type="search"
                sx={{
                  height: "100%",
                }}
                onClick={handleBranchFetch}
              >
                <HowToReg sx={{ color: "green" }} />
              </Button>
            </Box>
          </Box>

          <TextField
            sx={{ width: 300 }}
            id="standard-select-branch"
            select
            label=" Select User Branch"
            disabled={branchdisabled}
            value={branchid}
            onChange={(e) => setBranch(e.target.value)}
            variant="standard"
          >
            {branchopts.map((option) => (
              <MenuItem key={option.branchid} value={option.branchid}>
                {option.branchdetails}
              </MenuItem>
            ))}
          </TextField>

          <Button
            disabled={discreateuserbtn}
            variant="contained"
            color="success"
            startIcon={<AddBoxIcon />}
            sx={{ width: 300 }}
            onClick={onCreateUser}
          >
            Create
          </Button>
        </Box>
      </Box>
      {redirectToLoginPage()}
    </>
  );
};

export default CreateUser;
