import React from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect, Link } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const TestReport2 = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);

  // TEXT FIELD VALUE STATES
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if(searchInput===null){
        setLoading(false)
        toast.error("Please Select A Bill!!")
      }
      const response = await fetch(
        `/reports/getpdf/${searchInput.billheaderid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfStream(pdfUrl);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err);
    }
  };
  const handleDownload = async (e) => {
    try {
      const res = await fetch(`/reports/getpdf/${searchInput.billheaderid}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const blob = await res.blob(); // Convert response to a Blob
      const filename = `${searchInput.billno}.pdf`;
      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to initiate download
      link.click();

      // Clean up resources
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleExcelDownload = async () => {
    try {
      const res = await fetch(
        `/salebill/excel/download/${searchInput.billheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );

      // Check if response is successful
      if (!res.ok) {
        throw new Error("Failed to download Excel file");
      }

      // Convert response to blob
      const blob = await res.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${searchInput.billno}.xlsx`;

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error.message);
    }
  };
  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  const saletypeopts = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Spare",
    },
    {
      value: 3,
      label: "Labour",
    },
  ];
  const [billType, setBillType] = useState(1);
  async function getParam() {
    try {
      const response = await fetch(`/reports/billdetails/${billType}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
    // eslint-disable-next-line
  }, [billType]);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  return (
    <div>
      <Sidebar
        Title={"SALE BILL"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Link to="/salebill">
              <Button>←Go Back to SaleBill</Button>
            </Link>
            <Link to="/directsale">
              <Button>←Go Back to Direct Sale</Button>
            </Link>
            <Stack
              spacing={10}
              columnGap={2}
              direction="row"
              mt={2}
              mx={20}
              mb={2}
            >
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Bill Type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={1}
                  name="radio-buttons-group"
                  row
                >
                  {saletypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio />}
                      label={opt.label}
                      onChange={(e) => setBillType(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              spacing={10}
              columnGap={2}
              direction="row"
              mt={5}
              mx={20}
              mb={4}
            >
              <Autocomplete
                id="party"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.billno}
                sx={{
                  width: 500,
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Bill No" />
                )}
                size="small"
              />
              <Button
                type="submit"
                // disabled={importDisabled}
                onClick={handleReport}
                disabled={loading===true}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
            </Stack>
            {pdfStream && (
              <>
                <Button
                  onClick={handleDownload}
                  variant="outlined"
                  sx={{ mb: 1 }}
                >
                  Download PDF
                </Button>
                <Button
                  onClick={handleExcelDownload}
                  sx={{ ml: 2, mb: 1 }}
                  variant="outlined"
                >
                  Download Excel
                </Button>
              </>
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="600px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default TestReport2;
