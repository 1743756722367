import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";

import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const ProvisionalMoneyReceipt = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [salesman, setsalesman] = useState(null);
  const [salesmanInput, setsalesmanInput] = useState([]);
  const [salesmanrows, setsalesmanrows] = useState([]);
  const [cashTotal, setCashTotal] = useState(0);
  const [chqTotal, setChqTotal] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);

  // useEffect(() => {
  //   for (let i = 0; i < selectionModel.length; i++) {
  //     const filterData = salesmanrows.filter(
  //       (opt) => opt.pmrid === selectionModel[i]
  //     );
  //     finalPrMr.push(filterData[0]);
  //     console.log(finalPrMr);
  //   }
  // }, [selectionModel]);

  // console.log("Cash:",cashTotal,"Check:",chqTotal);
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--Rejected": {
      backgroundColor: getBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
        },
      },
    },
    "& .super-app-theme--warning": {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.light,
            theme.palette.mode
          ),
        },
      },
    },
  }));
  const columns = [
    {
      field: "serial_number",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No",
      width: 60,
    },
    {
      field: "subledgername",
      headerClassName: "super-app-theme--header",
      headerName: "Party Name",
      width: 250,
    },
    {
      field: "recv_date",
      headerClassName: "super-app-theme--header",
      headerName: "Receive Date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString("en-GB"),
      width: 150,
    },
    {
      field: "recv_time",
      headerClassName: "super-app-theme--header",
      headerName: "Receive Time",
      width: 150,
    },

    {
      field: "cash_amt",
      headerClassName: "super-app-theme--header",
      headerName: "Cash Amt",
      width: 100,
    },
    {
      field: "chq_date",
      headerClassName: "super-app-theme--header",
      headerName: "Cheque Date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString("en-GB"),
      width: 100,
    },
    {
      field: "chq_no",
      headerClassName: "super-app-theme--header",
      headerName: "cheque No. ",
      width: 150,
    },
    {
      field: "chq_amt",
      headerClassName: "super-app-theme--header",
      headerName: "Cheque Amt",
      width: 100,
    },

    {
      field: "remarks",
      headerClassName: "super-app-theme--header",
      headerName: "Remarks ",
      width: 400,
    },
  ];
  async function getsalesman() {
    try {
      const res = await fetch("/promr/salesman", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      setsalesmanInput(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getsalesman();
  }, []);

  async function getpmr() {
    try {
      const res = await fetch(`/promr/list/${salesman.salesmanid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      setsalesmanrows(data);
      let cashAmtTotal = 0;
      let checkAmtTotal = 0;

      data.forEach((item) => {
        if (item.cash_amt) {
          cashAmtTotal += parseFloat(item.cash_amt);
          setCashTotal(cashAmtTotal);
        }

        if (item.chq_amt) {
          checkAmtTotal += parseFloat(item.chq_amt);
          setChqTotal(checkAmtTotal);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getpmr();
  }, [salesman]);

  const handleMRgen = async (e) => {
    e.preventDefault();
    try {
      const body = { dataArray:selectionModel };
      const res = await fetch("/promr/convertmr", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const data = res.json;
      console.log(data);
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"PROVISIONAL MONEY RECEIPT"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={salesman}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setsalesman(newValue);
                }}
                options={salesmanInput}
                getOptionLabel={(option) => option.salesmandetails}
                sx={{
                  width: "30% ",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Salesman" />
                )}
              />
            </Stack>
            <Stack spacing={2} ml={10} mb={"40px"} direction="row">
              <Box
                sx={{
                  height: 350,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    // backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                <StyledDataGrid
                  sx={{ border: "1px solid #000" }}
                  rowHeight={25}
                  headerHeight={40}
                  checkboxSelection={true}
                  showColumnRightBorder={true}
                  rows={salesmanrows}
                  columns={columns}
                  getRowId={(row) => row.pmrid}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  getRowClassName={(params) => {
                    const chequeDate = new Date(params.row.chq_date); // Assuming params.row.nextduedate is a date string
                    const currentDate = new Date();

                    const timeDifferenceInMilliseconds =
                      chequeDate - currentDate;
                    const timeDifferenceInDays =
                      timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
                    if (
                      timeDifferenceInDays >= 0 &&
                      timeDifferenceInDays <= 7
                    ) {
                      // Apply the CSS class when the time difference is less than 3 days
                      return "super-app-theme--warning";
                    } else if (timeDifferenceInDays < 0) {
                      return "super-app-theme--Rejected";
                    } else {
                      // Return an empty string for no additional class when the condition is not met
                      return "";
                    }
                  }}
                />
                <Box
                  sx={{
                    width: "350px",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    padding: "16px", // Adjust the padding as needed
                    border: "1px solid #ccc", // Add border with a color of your choice
                    backgroundColor: "#f0f0f0", // Add background color of your choice
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant="subtitle1"
                    sx={{ pr: 2 }}
                  >
                    Total Cash: {cashTotal}
                  </Typography>
                  <Typography fontWeight={"bold"} variant="subtitle1">
                    Total Cheque: {chqTotal}
                  </Typography>
                </Box>
              </Box>
            </Stack>
            <Stack
              spacing={2}
              columnGap={6}
              direction="row"
              mt={12}
              pb={6}
              mx={"42%"}
            >
              <Button
                onClick={handleMRgen}
                variant="contained"
                color="primary"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                CONVERT TO MONEY RECEIPT
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default ProvisionalMoneyReceipt;
