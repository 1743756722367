import React from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const ReasonMaster = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  //   setTimeout(() => {
  //     window.location.reload(false);
  //   }, 1800000);

  // TEXT FIELD VALUE STATES

  const [reason, setreason] = useState("");
  const [gstrate, setgstrate] = useState(0);
  const [hsncode, sethsncode] = useState("");
  const [againstbillflag, setagainstbillflag] = useState("NO");
  const againstbillflagtype = [
    {
      name: "TRUE",
      value: "YES",
    },
    {
      name: "FALSE",
      value: "NO",
    },
  ];
  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);
  const [delDisabled, setdelDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      setInsertDisabled(true);
      try {
        const body = {
          reason,
          gstrate,
          hsncode,
          againstbillflag,
        };
        const response = await fetch("/salereturn/reasons/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();
        if (parseRes.type === "success") {
          toast.success(parseRes.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        //RESET ALL STATE VALUES
        setreason("");
        setgstrate("");
        setSearchInput(null);
        setagainstbillflag("");
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      setUpdatedisabled(true);
      try {
        const body = {
          reason,
          gstrate,
          hsncode,
          againstbillflag,
        };
        const response = await fetch(
          `/salereturn/reasons/update/${searchInput.reasonid}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json", sptoken: userToken },
            body: JSON.stringify(body),
          }
        );
        const parseRes = await response.json();
        if (parseRes.type === "success") {
          toast.success(parseRes.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async (e) => {
    e.preventDefault();
    setdelDisabled(true);
    try {
      const response = await fetch(
        `/salereturn/reasons/delete/${searchInput.reasonid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.info(parseRes.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  console.log(param);
  async function getParam() {
    try {
      const res = await fetch("/salereturn/reasons/master", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setParam(data);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getParam();
    // eslint-disable-next-line
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  console.log(searchInput);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `/salereturn/reasons/data/${searchInput.reasonid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const data = await res.json();
      //console.log(data);
      setreason(data.name);
      setgstrate(data.gstrate);
      sethsncode(data.hsn);
      setUpdatedisabled(false);
      setInsertDisabled(true);
      setagainstbillflag(data.againstbillflag);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"CREDIT NOTE REASON MASTER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.name}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                id="reason"
                label="Reason"
                value={reason}
                onChange={(e) => setreason(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                required
                id="rate"
                label="Gstrate"
                value={gstrate}
                onChange={(e) => setgstrate(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                required
                id="hsn"
                label="HSN CODE"
                value={hsncode}
                onChange={(e) => sethsncode(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack spacing={10} columnGap={4} direction="row" mt={5} mx={20}>
              <TextField
                id="againstbillflag"
                name="Againstbillflag"
                required
                select
                label="Against Bill Flag"
                value={againstbillflag}
                onChange={(e) => setagainstbillflag(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              >
                {againstbillflagtype.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                  setreason("");
                  setgstrate("");
                  sethsncode("");
                  setagainstbillflag("")
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                disabled={delDisabled}
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default ReasonMaster;
