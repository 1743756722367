import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  TextField,
  Switch,
} from "@mui/material";

import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const BillTransfer = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
    matchFrom: "start",
  });
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--Rejected": {
      backgroundColor: getBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
        },
      },
    },
    "& .super-app-theme--warning": {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.light,
            theme.palette.mode
          ),
        },
      },
    },
  }));
  // const columns = [
  //   {
  //     field: "serial",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Srl No.",
  //     width: 60,
  //   },
  //   {
  //     field: "partdetails",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Part Details",
  //     width: 160,
  //   },
  //   {
  //     field: "rackname",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Rack No",
  //     width: 100,
  //     type: "number",
  //   },
  //   {
  //     field: "updatemrp",
  //     type: "actions",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "UpdateMRP",
  //     width: 100,
  //     cellClassName: "actions",
  //     //   getActions: ({ id }) => {
  //     //     return [
  //     //       <GridActionsCellItem
  //     //         icon={<CurrencyRupee />}
  //     //         label="Update"
  //     //         onClick={updateMrp(id)}
  //     //         color="inherit"
  //     //       />,
  //     //     ];
  //     //   },
  //   },

  //   {
  //     field: "saleqty",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Sale Qty",
  //     width: 100,
  //     type: "number",
  //     editable: true,
  //     //   valueGetter: (params) => {
  //     //     return params.row.saleqty;
  //     //   },
  //     //   valueSetter: (params) => {
  //     //     if (parseFloat(params.value) < 0) {
  //     //       toast.error("Can't Be Negative!", {
  //     //         toastId: "error1",
  //     //       });
  //     //     } else {
  //     //       const saleqty = params.value;
  //     //       const value = parseFloat(params.value * params.row.salerate);
  //     //       const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
  //     //         2
  //     //       );
  //     //       const taxable = parseFloat(
  //     //         parseFloat(value) - parseFloat(disamt)
  //     //       ).toFixed(2);
  //     //       const gstamt = parseFloat(
  //     //         (taxable * params.row.gstpers) / 100
  //     //       ).toFixed(2);
  //     //       const netamt = parseFloat(
  //     //         parseFloat(taxable) + parseFloat(gstamt)
  //     //       ).toFixed(2);
  //     //       const upd_obj =
  //     //         parseInt(state.salecategory) === 2
  //     //           ? orderdetailrow.findIndex(
  //     //               (obj) => obj.itemid === params.row.itemid
  //     //             )
  //     //           : orderdetailrow.findIndex(
  //     //               (obj) => obj.billdetailid === params.row.billdetailid
  //     //             );
  //     //       orderdetailrow[upd_obj].saleqty = saleqty;
  //     //       orderdetailrow[upd_obj].value = value;
  //     //       orderdetailrow[upd_obj].disamt = disamt;
  //     //       orderdetailrow[upd_obj].taxable = taxable;
  //     //       orderdetailrow[upd_obj].gstamt = gstamt;
  //     //       orderdetailrow[upd_obj].netamt = netamt;
  //     //       return {
  //     //         ...params.row,
  //     //         saleqty,
  //     //         value,
  //     //         disamt,
  //     //         taxable,
  //     //         gstamt,
  //     //         netamt,
  //     //       };
  //     //     }
  //     //   },
  //   },
  //   {
  //     field: "mrp",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "MRP",
  //     type: "number",
  //     width: 100,
  //     editable: true,
  //     //   valueGetter: (params) => {
  //     //     return params.row.mrp;
  //     //   },
  //     //   valueSetter: (params) => {
  //     //     if (
  //     //       parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
  //     //       parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
  //     //       parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
  //     //       parseFloat(params.value) !== parseFloat(params.row.mrp4)
  //     //     ) {
  //     //       toast.error("Enter A Valid MRP!", {
  //     //         toastId: "error1",
  //     //       });
  //     //     } else {
  //     //       const mrp = params.value;
  //     //       const salerate = parseFloat(
  //     //         (parseFloat(params.value) * 100) /
  //     //           (100 + parseFloat(params.row.gstpers))
  //     //       ).toFixed(2);
  //     //       const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
  //     //       const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
  //     //         2
  //     //       );
  //     //       const taxable = parseFloat(
  //     //         parseFloat(value) - parseFloat(disamt)
  //     //       ).toFixed(2);
  //     //       const gstamt = parseFloat(
  //     //         (taxable * params.row.gstpers) / 100
  //     //       ).toFixed(2);
  //     //       const netamt = parseFloat(
  //     //         parseFloat(taxable) + parseFloat(gstamt)
  //     //       ).toFixed(2);
  //     //       const upd_obj =
  //     //         parseInt(state.salecategory) === 2
  //     //           ? orderdetailrow.findIndex(
  //     //               (obj) => obj.itemid === params.row.itemid
  //     //             )
  //     //           : orderdetailrow.findIndex(
  //     //               (obj) => obj.billdetailid === params.row.billdetailid
  //     //             );
  //     //       orderdetailrow[upd_obj].mrp = mrp;
  //     //       orderdetailrow[upd_obj].salerate = salerate;
  //     //       orderdetailrow[upd_obj].value = value;
  //     //       orderdetailrow[upd_obj].disamt = disamt;
  //     //       orderdetailrow[upd_obj].taxable = taxable;
  //     //       orderdetailrow[upd_obj].gstamt = gstamt;
  //     //       orderdetailrow[upd_obj].netamt = netamt;
  //     //       return {
  //     //         ...params.row,
  //     //         mrp,
  //     //         salerate,
  //     //         value,
  //     //         disamt,
  //     //         taxable,
  //     //         gstamt,
  //     //         netamt,
  //     //       };
  //     //     }
  //     //   },
  //   },
  //   {
  //     field: "mrp4",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Current MRP",
  //     type: "number",
  //     width: 100,
  //   },
  //   {
  //     field: "mrp1",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "MRP1",
  //     type: "number",
  //     width: 100,
  //   },
  //   {
  //     field: "mrp2",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "MRP2",
  //     type: "number",
  //     width: 100,
  //   },
  //   {
  //     field: "mrp3",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "MRP3",
  //     type: "number",
  //     width: 100,
  //   },
  //   {
  //     field: "salerate",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Rate",
  //     width: 100,
  //     type: "number",
  //   },
  //   {
  //     field: "value",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Value",
  //     width: 100,
  //     type: "number",
  //   },
  //   {
  //     field: "dispers",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Dis %",
  //     width: 100,
  //     type: "number",
  //   },
  //   {
  //     field: "disamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Dis Amt",
  //     width: 80,
  //     type: "number",
  //   },
  //   {
  //     field: "taxable",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Taxable Amt",
  //     width: 100,
  //     type: "number",
  //   },
  //   {
  //     field: "gstpers",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "GST %",
  //     width: 80,
  //     type: "number",
  //   },
  //   {
  //     field: "cgstamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "CGST Amt",
  //     width: 80,
  //     type: "number",
  //     //   valueGetter: (params) => {
  //     //     if (parseInt(partyInput.stateid) === 1) {
  //     //       return parseFloat(params.row.gstamt / 2).toFixed(2);
  //     //     } else {
  //     //       return 0;
  //     //     }
  //     //   },
  //   },
  //   {
  //     field: "sgstamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "SGST Amt",
  //     width: 100,
  //     type: "number",
  //     //   valueGetter: (params) => {
  //     //     if (parseInt(partyInput.stateid) === 1) {
  //     //       return parseFloat(params.row.gstamt / 2).toFixed(2);
  //     //     } else {
  //     //       return 0;
  //     //     }
  //     //   },
  //   },

  //   {
  //     field: "igstamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "IGST Amt",
  //     width: 100,
  //     type: "number",
  //     //   valueGetter: (params) => {
  //     //     if (parseInt(partyInput.stateid) !== 1) {
  //     //       return parseFloat(params.row.gstamt).toFixed(2);
  //     //     } else {
  //     //       return 0;
  //     //     }
  //     //   },
  //   },
  //   {
  //     field: "gstamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "GST Amt",
  //     width: 120,
  //     type: "number",
  //   },
  //   {
  //     field: "netamt",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Net Amt",
  //     width: 100,
  //     type: "number",
  //   },

  //   // {
  //   //   field: "action",
  //   //   type: "actions",
  //   //   headerClassName: "super-app-theme--header",
  //   //   headerName: "Delete",
  //   //   width: 100,
  //   //   cellClassName: "actions",
  //   //   getActions: ({ id }) => {
  //   //     return [
  //   //       <GridActionsCellItem
  //   //         icon={<DeleteIcon />}
  //   //         label="Delete"
  //   //         onClick={handleDeleteClick(id)}
  //   //         color="inherit"
  //   //       />,
  //   //     ];
  //   //   },
  //   // },
  //   {
  //     field: "del",
  //     headerClassName: "super-app-theme--header",
  //     headerName: "Del",
  //     width: 50,
  //     type: "number",
  //   },
  // ];
  const [billno, setbillno] = useState(null);
  const [billopts, setbillopts] = useState([]);
  const [fromparty, setfromparty] = useState(null);
  const [frompartyopts, setfrompartyopts] = useState([]);
  const [toparty, settoparty] = useState(null);
  const [topartyopts, settopartyopts] = useState([]);
  const [salebillrows, setsalebillrows] = useState([]);
  const [isMSwitchOn, setMSwitchOn] = useState(false);
  const handleMSwitchChange = () => {
    setMSwitchOn(!isMSwitchOn);
  };
  ///partydata for toParty
  async function getTopartyOpts() {
    try {
      const response = await fetch("/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      settopartyopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getTopartyOpts();
  }, []);
  ///partydata for fromParty
  async function getFrompartyOpts() {
    try {
      const response = await fetch("/billtransfer/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfrompartyopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getFrompartyOpts();
  }, []);
  ///billnos
  async function getbillnos() {
    try {
      const response = await fetch(`/billno/${fromparty.subledgerid}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setbillopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getbillnos();
  }, [fromparty]);
  //dialog
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //transfer
  const handleTransfer = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `/billtransfer/${toparty.subledgerid}/${billno.billheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const data = await res.json();
      setOpen(false);
      toast.success(data, {
        autoClose: false,
        onClose: () => window.location.reload(false),
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"BILL TRANSFER"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontSize={"14px"}>CODE WISE</Typography>
              <Switch
                onChange={handleMSwitchChange}
                checked={!isMSwitchOn}
                size="small"
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography fontSize={"14px"}>NAME WISE</Typography>
            </Stack>
            <Stack spacing={2} mt={5} ml={10} mb={"40px"} direction="row">
              <Autocomplete
                id="partyfrom"
                value={fromparty}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setfromparty(newValue);
                }}
                options={frompartyopts}
                getOptionLabel={(option) =>
                  isMSwitchOn ? option.partydetails : option.partydetails2
                }
                renderInput={(partyopts) => (
                  <TextField
                    helperText={!fromparty && "Select A party to continue"}
                    sx={{
                      width: 300,
                    }}
                    required
                    {...partyopts}
                    placeholder="From Party"
                  />
                )}
                size="small"
              />
              <Box display={fromparty === null ? "none" : "flex"}>
                <Autocomplete
                  id="billno"
                  value={billno}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setbillno(newValue);
                  }}
                  options={billopts}
                  getOptionLabel={(option) => option.billno}
                  renderInput={(partyopts) => (
                    <TextField
                      helperText={!billno && "Select Billno to continue"}
                      sx={{
                        width: 200,
                      }}
                      required
                      {...partyopts}
                      placeholder="Bill No."
                    />
                  )}
                  size="small"
                />
              </Box>
              <Box display={billno === null ? "none" : "flex"}>
                <Autocomplete
                  id="partyto"
                  value={toparty}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    settoparty(newValue);
                  }}
                  options={topartyopts}
                  getOptionLabel={(option) => option.partydetails}
                  renderInput={(partyopts) => (
                    <TextField
                      sx={{
                        width: 300,
                      }}
                      required
                      {...partyopts}
                      placeholder="To Party"
                    />
                  )}
                  size="small"
                />
              </Box>
              <Box display={toparty === null ? "none" : "flex"}>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                >
                  Transfer
                </Button>
              </Box>
            </Stack>
            {/* <Stack spacing={2} ml={10} mb={"40px"} direction="row">
              <Box
                sx={{
                  height: 350,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    // backgroundColor: "",
                    color: "#000",
                  },
                }}
              >
                <StyledDataGrid
                  sx={{ border: "1px solid #000" }}
                  rowHeight={25}
                  headerHeight={40}
                  checkboxSelection={true}
                  showColumnRightBorder={true}
                  rows={salebillrows}
                  columns={columns}
                  // getRowId={(row) => row.pmrid}
                  // onSelectionModelChange={(newSelectionModel) => {
                  //   setSelectionModel(newSelectionModel);
                  // }}
                  // selectionModel={selectionModel}
                  // getRowClassName={(params) => {
                  //   const chequeDate = new Date(params.row.chq_date); // Assuming params.row.nextduedate is a date string
                  //   const currentDate = new Date();

                  //   const timeDifferenceInMilliseconds =
                  //     chequeDate - currentDate;
                  //   const timeDifferenceInDays =
                  //     timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
                  //   if (
                  //     timeDifferenceInDays >= 0 &&
                  //     timeDifferenceInDays <= 7
                  //   ) {
                  //     // Apply the CSS class when the time difference is less than 3 days
                  //     return "super-app-theme--warning";
                  //   } else if (timeDifferenceInDays < 0) {
                  //     return "super-app-theme--Rejected";
                  //   } else {
                  //     // Return an empty string for no additional class when the condition is not met
                  //     return "";
                  //   }
                  // }}
                />
              </Box>
            </Stack> */}
            <Stack
              spacing={2}
              columnGap={6}
              direction="row"
              mt={12}
              pb={6}
              mx={"42%"}
            ></Stack>
          </Box>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to transfer the bill?"}
        </DialogTitle>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            onClick={handleTransfer}
            size="small"
            autoFocus
          >
            Agree
          </Button>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default BillTransfer;
