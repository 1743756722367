import { Box,Skeleton, MenuItem, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Edit from "./GridButtons/MainGroupButton/Edit";
import MaingrpDelete from "./GridButtons/MainGroupButton/MaingrpDelete";
import SubgrpEdit from "./GridButtons/SubGroupButton/SubgrpEdit";
import SubgrpDelete from "./GridButtons/SubGroupButton/SubgrpDelete";
import DestinationEdit from "./GridButtons/DestinationButton/DestinationEdit";
import DestinationDelete from "./GridButtons/DestinationButton/DestinationDelete";
import SubLedgerEdit from "./GridButtons/Sub-ledgerCatg-Button/SubLedgerEdit";
import SubLedgerDelete from "./GridButtons/Sub-ledgerCatg-Button/SubLedgerDelete";
import StateEdit from "./GridButtons/StateButton/StateEdit";
import StateDelete from "./GridButtons/StateButton/StateDelete";
import AddCircle from '@mui/icons-material/AddCircle';


toast.configure();
//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/////////////////////////////// ARROW COMPONENT STARTS HERE /////////////////////////////////
const LedgerMaster = () => {
  const userToken = LocalstorageHelper.GetUserToken();
  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  //MAIN GROUP HANDLE
  const [maingrploading, setMaingrploading] = useState(false);
  const [Fetch, setFetch] = useState(true);
  const [maingrpname, setmainGrpName] = useState("");
  const handleMaingrpname = async (e) => {
    e.preventDefault();
    try {
      const body = { maingrpname };
      const response = await fetch(
        "/ledger/insertmaingrp",
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      setMaingrploading(true);
      if (parseRes === 1) {
        toast.success("Main Group Created Successfully", { autoClose: 1000 });
        setmainGrpName("");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setMaingrploading(false);
        setFetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [maingrp, setMaingrp] = useState([]);
  async function getMaingrp() {
    setMaingrploading(true);
    try {
      const response = await fetch("/ledger/getmaingrp", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setMaingrp(jsonData);
      // console.log(jsonData);
      setMaingrploading(false);
      setFetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if(Fetch == true){
      getMaingrp();
    }
    
  }, [Fetch]);

  //SUB GROUP HANDLE

  //sub dropdown handle

  const [allmaingrp, setAllmaingrp] = useState([]);
  const [maingrpid, setMaingrpid] = useState("");
  const [subgrploading, setSubgrploading] = useState(false);
  const [subgrpfetch, setSubgrpfetch] = useState(true);

  async function getAllmaingrpNames() {
    try {
      const response = await fetch("/ledger/getmaingrp", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setAllmaingrp(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAllmaingrpNames();
  }, []);

  const [subgrpname, setsubGrpName] = useState("");
  const handleSubgrpname = async (e) => {
    e.preventDefault();
    try {
      const body = { subgrpname, maingrpid };
      const response = await fetch("/ledger/insertsub", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setSubgrploading(true);
      if (parseRes === 1) {
        toast.success("Sub Group Created Successfully", { autoClose: 1000 });
        setsubGrpName("");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
        setSubgrploading(false);
        setSubgrpfetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [subgrp, setSubgrp] = useState([]);
  async function getSubgrp() {
    setSubgrploading(true);
    try {
      const response = await fetch("/ledger/getsub", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setSubgrp(jsonData);
      setSubgrploading(false);
      setSubgrpfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if(subgrpfetch == true){
      getSubgrp();
    }
   
  }, [subgrpfetch]);

  //SUB-LEDGER CATEGORY HANDLE AND ALL SHORTNAMES

  const [allShorts, setAllShorts] = useState([]);
  const [shortnameid, setShortnameid] = useState("");
  const [subldgrloading, setSubldgrloading] = useState(false);
  const [subldgrfetch, setSubldgrfetch] = useState(true);

  async function getAllShortNames() {
    try {
      const response = await fetch(
        "/ledger/getshortnames",
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setAllShorts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAllShortNames();
  }, []);

  const [subledgerCatg, setsubLedgerCatg] = useState("");
  const handleSubLCatg = async (e) => {
    e.preventDefault();
    try {
      const body = { subledgerCatg, shortnameid };
      const response = await fetch(
        "/ledger/insertsubLCategory",
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      setSubldgrloading(true);
      if (parseRes === 1) {
        toast.success("sub-ledger category Created Successfully", {
          autoClose: 1000,
        });
        setsubLedgerCatg("");
        setShortnameid("");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
        setSubldgrloading(false);
        setSubldgrfetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [subLCatg, setSubLCatg] = useState([]);
  async function getsubLCatg() {
    setSubldgrloading(true);
    try {
      const response = await fetch(
        "/ledger/getsubLCategory",
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setSubLCatg(jsonData);
      // console.log(jsonData);
      setSubldgrloading(false);
      setSubldgrfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if(subldgrfetch == true){
      getsubLCatg();
    }
    
  }, [subldgrfetch]);

  //STATE HANDLE
  const [stateName, setStateName] = useState("");
  const [stateloading, setStateloading] = useState(false);
  const [statefetch, setStatefetch] = useState(true);

  const handleState = async (e) => {
    e.preventDefault();
    try {
      const body = { stateName };
      const response = await fetch("/ledger/insertState", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setStateloading(true);
      if (parseRes === 1) {
        toast.success("State Created Successfully", { autoClose: 1000 });
        setStateName("");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
        setStateloading(false);
        setStatefetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [state, setState] = useState([]);
  async function getState() {
    setStateloading(true);
    try {
      const response = await fetch("/ledger/getstates", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setState(jsonData);
      setStateloading(false);
      setStatefetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if(statefetch == true){
      getState();
    }
   
  }, [statefetch]);

  //DESTINATION HANDLE

  // const [stateidval, setStateidval] = useState("")
  const [destName, setDestName] = useState("");
  const [stateid, setStateid] = useState("");
const [destloading, setDestloading] =useState(false);
const [destfetch, setDestfetch] = useState(true);
  const handleDestName = async (e) => {
    e.preventDefault();
    try {
      const body = { destName, stateid };
      const response = await fetch("/ledger/insertDest", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setDestloading(true);
      if (parseRes === 1) {
        toast.success("Destination Created Successfully", { autoClose: 1000 });
        setDestName("");
        setStateid("");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
        setDestloading(false);
        setDestfetch(true);
      } else {
        toast.error(parseRes, { autoClose: 1000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [dest, setDest] = useState([]);
  async function getDest() {
    setDestloading(true);
    try {
      const response = await fetch("/ledger/getdest", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setDest(jsonData);
      setDestloading(false);
      setDestfetch(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if(destfetch == true){
      getDest();
    }
    
  }, [destfetch]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Sidebar
        Title={"LEDGER MASTER"}
        expandedPanel={"panel1"}
        children={
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "divider",
                background:
                  "linear-gradient(90deg, rgba(181,184,184,1) 42%, rgba(157,205,219,1) 90%)",
              }}
            >
              <Tabs
                value={value}
                variant="fullWidth"
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab label="Main Group" {...a11yProps(0)} />
                <Tab label="Sub Group" {...a11yProps(1)} />
                <Tab label="Sub-Ledger Category" {...a11yProps(2)} />
                <Tab id="state" label="State" {...a11yProps(3)} />
                <Tab label="Destination" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box component={"form"} onSubmit={handleMaingrpname}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE MAIN GROUP
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"32%"}
                >
                  <TextField
                    required
                    value={maingrpname}
                    onChange={(e) => {
                      setmainGrpName(e.target.value);
                    }}
                    id="mainGroupName"
                    label="Main Group Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                  <AddCircle fontSize="medium" /> 
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {maingrploading === false ?
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "55vh",
                  overflowY: "auto",
                  padding: "18px",
                  mt: "30px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Main Group Id
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Main Group Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {maingrp.map((main) => (
                      <TableRow key={main.maingrpid}>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {main.maingrpid}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {main.maingrpname}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <Edit allMaingrp={maingrp} maingrp={main} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <MaingrpDelete maingrp={main} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               : <Skeleton variant="rectangular"  sx={{mt:"2%"}}  width={"100%"} height={"55vh"} /> }
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box component={"form"} onSubmit={handleSubgrpname}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE SUB GROUP
                </Typography>

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"23%"}
                >
                  <TextField
                    id="mainGroupName"
                    required
                    select
                    label="Main Group Name"
                    value={maingrpid}
                    onChange={(e) => {
                      setMaingrpid(e.target.value);
                    }}
                    variant="standard"
                    sx={{
                      width: "22%",
                    }}
                  >
                    {allmaingrp.map((option) => (
                      <MenuItem key={option.maingrpid} value={option.maingrpid}>
                        {option.maingrpname}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    required
                    value={subgrpname}
                    onChange={(e) => {
                      setsubGrpName(e.target.value);
                    }}
                    id="subGroupName"
                    label="Sub Group Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                  <AddCircle fontSize="medium" /> 
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {subgrploading === false ?
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "55vh",
                  overflowY: "auto",
                  padding: "18px",
                  mt: "30px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Sub Group Id
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Main Group Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Sub Group Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {subgrp.map((sub) => (
                      <TableRow key={sub.subgrpid}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {sub.subgrpid}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {sub.maingrpname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {sub.subgrpname}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <SubgrpEdit allsubgrp={subgrp} subgrp={sub} allmaingrp={allmaingrp} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <SubgrpDelete subgrp={sub} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               : <Skeleton variant="rectangular"  sx={{mt:"2%"}}  width={"100%"} height={"55vh"} /> }
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box component="form" onSubmit={handleSubLCatg}>
                <Typography component={"span"} variant="h5" ml={"32%"}>
                  CREATE SUB-LEDGER CATEGORY
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"23%"}
                >
                  <TextField
                    required
                    value={subledgerCatg}
                    onChange={(e) => {
                      setsubLedgerCatg(e.target.value);
                    }}
                    id="subLedgercategoryName"
                    label="Sub Ledger Category Name"
                    variant="standard"
                  />

                  <TextField
                    id="shortname"
                    required
                    select
                    label="Short Name"
                    value={shortnameid}
                    onChange={(e) => {
                      setShortnameid(e.target.value);
                    }}
                    variant="standard"
                    sx={{
                      width: "22%",
                    }}
                  >
                    {allShorts.map((option) => (
                      <MenuItem key={option.nameid} value={option.nameid}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <ColorButton type="submit">
                  <AddCircle fontSize="medium" /> 
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {subldgrloading === false ?
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "55vh",
                  overflowY: "auto",
                  padding: "18px",
                  mt: "30px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Sub Ledger Category Id
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Sub Ledger Category Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Short Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {subLCatg.map((grp) => (
                      <TableRow key={grp.subledgercatgid}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {grp.subledgercatgid}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {grp.subledgercatgname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {grp.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <SubLedgerEdit allSubLCatg={subLCatg} subLCatg={grp} allShorts={allShorts} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <SubLedgerDelete subLCatg={grp} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               : <Skeleton variant="rectangular"  sx={{mt:"2%"}}  width={"100%"} height={"55vh"} /> }
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box component={"form"} onSubmit={handleState}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW STATE
                </Typography>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"30%"}
                >
                  <TextField
                    required
                    value={stateName}
                    onChange={(e) => {
                      setStateName(e.target.value);
                    }}
                    id="stateName"
                    label="State Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                  <AddCircle fontSize="medium" /> 
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {stateloading === false ?
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "55vh",
                  overflowY: "auto",
                  padding: "18px",
                  mt: "30px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        State Id
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        State Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {state.map((states) => (
                      <TableRow key={states.stateid}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {states.stateid}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {states.statename}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <StateEdit allState={state} state={states} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <StateDelete state={states} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               : <Skeleton variant="rectangular"  sx={{mt:"2%"}}  width={"100%"} height={"55vh"} /> }
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Box component={"form"} onSubmit={handleDestName}>
                <Typography component={"span"} variant="h5" ml={"35%"}>
                  CREATE NEW DESTINATION
                </Typography>

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={5}
                  ml={"23%"}
                >
                  <TextField
                    id="stateName"
                    required
                    select
                    label="State Name"
                    value={stateid}
                    onChange={(e) => {
                      setStateid(e.target.value);
                    }}
                    variant="standard"
                    sx={{
                      width: "22%",
                    }}
                  >
                    {state.map((option) => (
                      <MenuItem key={option.stateid} value={option.stateid}>
                        {option.statename}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    required
                    value={destName}
                    onChange={(e) => {
                      setDestName(e.target.value);
                    }}
                    id="destinationpName"
                    label="Destination Name"
                    variant="standard"
                  />
                  <ColorButton type="submit">
                  <AddCircle fontSize="medium" /> 
                    <Typography component={"span"} variant="button">
                      &nbsp;CREATE
                    </Typography>
                  </ColorButton>
                </Stack>
              </Box>
              {destloading === false ?
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "5px 5px 10px #404142",
                  backgroundColor: "#868c91",
                  maxHeight: "55vh",
                  overflowY: "auto",
                  padding: "18px",
                  mt: "30px",
                }}
              >
                <Table
                  sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#aad3f0",
                      border: "0.5px solid black",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Destination Id
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        State Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                      >
                        Destination Name
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{ color: "#000", border: "0.5px solid black" }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                    {dest.map((opt) => (
                      <TableRow key={opt.destid}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {opt.destid}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {opt.statename}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {opt.destname}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <DestinationEdit allStates={state} allDest={dest} dest={opt} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          <DestinationDelete dest={opt} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               : <Skeleton variant="rectangular"  sx={{mt:"2%"}}  width={"100%"} height={"55vh"} /> }
            </TabPanel>
          </Box>
        }
      />
      {redirectToLoginPage()}
    </div>
  );
};

export default LedgerMaster;
