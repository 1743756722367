import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  Fab,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import BillSearch from "@mui/icons-material/Search";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import PrintIcon from "@mui/icons-material/Print";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const MoneyReceipt = () => {
  const [disableSave, setDisableSave] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  /////////// -----------------------> DROPDOWN OPTIONS <-------------------------\\\\\\\\\\\\\\\\\\\\\\\\\

  const receivedByopts = [
    {
      value: "CS",
      label: "Cash",
    },
    {
      value: "CQ",
      label: "Cheque",
    },
    {
      value: "ON",
      label: "Online",
    },
  ];

  const [narrationopts, setnarrationOpts] = useState([]);
  async function getnarrationOpts() {
    try {
      const response = await fetch("/spare/narration", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setnarrationOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getnarrationOpts();
  }, []);

  const [bankopts, setbankOpts] = useState([]);
  const [bankInput, setBankInput] = useState(null);
  const [receivedby, setreceivedby] = useState("");
  async function getbankOpts() {
    try {
      const response = await fetch("/spare/mr/banks", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setbankOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  async function getOwnBanks() {
    try {
      const response = await fetch("/spare/mr/own/banks", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setbankOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (receivedby === "ON") {
      getOwnBanks();
    } else if (receivedby === "CQ") {
      getbankOpts();
    } else {
      return;
    }
  }, [receivedby]);

  const [partyInput, setpartyInput] = useState(null);
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/moneyreceipt/parties", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  // TEXT FIELD VALUE STATES AND CHANGE HANDLER

  const [narrationInput, setnarrationInput] = useState(null);
  const [state, setState] = useState({
    chequeno: "",
    address1: "",
    address2: "",
    address3: "",
    bank: "",
    branch: "",
    contactname: "",
    phoneno: "",
    amount: "",
    chargers: 0,
    ifsc: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const date = new Date();
  const [mrdate, setmrdate] = useState(date);
  const [cqdate, setcqdate] = useState(date);
  const [realisationdate, setrealisationdate] = useState(null);
  const [cqreturndate, setcqreturndate] = useState(null);
  const [disableaccordion, setdisableaccordion] = useState(true);
  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      try {
        if (state.amount < 0) {
          return toast.error("Amount is in neg value");
        }
        setInsertDisabled(true);
        const body = {
          mrdate,
          cqdate,
          realisationdate,
          state,
          receivedby,
          narrationInput,
          partyInput,
          bankInput,
        };
        const response = await fetch("/moneyreceipt/insert", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.type === "success") {
          toast.success(`Money Receipt ${parseRes.mrno} Created Successfully`, {
            autoClose: false,
            onClose: () => window.location.reload(false),
          });
        } else {
          toast.error(parseRes.message, { autoClose: 2000 });
          setInsertDisabled(false);
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      try {
        const body = {
          mrdate,
          cqdate,
          realisationdate,
          state,
          receivedby,
          narrationInput,
          partyInput,
          cqreturndate,
          bankInput,
        };
        const response = await fetch(
          `/moneyreceipt/update/${searchInput.mrid}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json", sptoken: userToken },
            body: JSON.stringify(body),
          }
        );
        const parseRes = await response.json();
        if (parseRes.type === "success") {
          toast.success(parseRes.message, { autoClose: false });
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes.message);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  //DELETE ITEM

  // const handleDelete = async () => {
  //   try {
  //     const response = await fetch(`/deletejobcard/${searchInput.jobcardid}`, {
  //       method: "DELETE",
  //       headers: { sptoken: userToken },
  //     });
  //     const parseRes = await response.json();
  //     toast.error(parseRes, { autoClose: 2000 });
  //     setTimeout(() => {
  //       window.location.reload(false);
  //     }, 2000);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/moneyreceipt/searchopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  // //FETCH BANK DETAILS FROM API
  // const handleBankFetch = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch(`https://ifsc.razorpay.com/${state.ifsc}`);
  //     if (response.status === 404) {
  //       toast.error("Please Enter A Valid IFSC Code");
  //       setState((prevState) => ({
  //         ...prevState,
  //         bank: "",
  //         branch: "",
  //       }));
  //     } else {
  //       const jsonData = await response.json();
  //       setState((prevState) => ({
  //         ...prevState,
  //         bank: `${jsonData.BANK}`,
  //         branch: `${jsonData.BRANCH}`,
  //       }));
  //     }
  //   } catch (err) {
  //     console.log(err.msg);
  //   }
  // };

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/moneyreceipt/searchopts/${searchInput.mrid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);
      setUpdatedisabled(false);
      setInsertDisabled(true);
      setdisableaccordion(false);
      setprintbtndisable(true);
      setState((prevState) => ({
        ...prevState,
        chequeno: `${jsonData.chq_no}`,
        address1: `${jsonData.add1}`,
        address2: `${jsonData.add2}`,
        address3: `${jsonData.add3}`,
        contactname: `${jsonData.contact_person}`,
        phoneno: `${jsonData.phone}`,
        amount: `${jsonData.mr_amount}`,
        chargers: `${jsonData.return_charge}`,
        ifsc: "",
        reason: `${jsonData.reason}`,
      }));
      setreceivedby(jsonData.recv_by);
      setcqdate(jsonData.chq_date);
      setpartyInput({
        address1: `${jsonData.add1}`,
        address2: `${jsonData.add2}`,
        address3: `${jsonData.add3}`,
        contactname: `${jsonData.contact_person}`,
        phoneno: `${jsonData.phone}`,
        subledgerid: `${jsonData.partyid}`,
        partydetails: `${jsonData.partydetails}`,
      });
      setnarrationInput({
        narration: `${jsonData.narration}`,
        narrationid: `${jsonData.narrationid}`,
      });
      setmrdate(jsonData.mr_date);
      setBankInput({
        bankid: jsonData.mrbankid,
        name: jsonData.name,
      });
      setcqreturndate(jsonData.return_date);
    } catch (err) {
      console.log(err.message);
    }
  };

  //----------- Modal Handler-------

  const style = {
    position: "absolute",
    top: "50%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  //printbtn fn & state
  const [printbtndisable, setprintbtndisable] = useState(false);

  //report fn
  const [pdfStream, setPdfStream] = useState(null);
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
  const handleprint = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`/reports/moneyreceipt/${searchInput.mrid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      })
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"MONEY RECEIPT"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              mb={"40px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.searchdetails}
                sx={{
                  width: 500,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search Items Here..."
                  />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Box
                sx={{
                  ml: "10%",
                  display: printbtndisable === false ? "none" : "flex",
                }}
              >
                <Tooltip placement="top" title="Print Money Receipt">
                  <Fab
                    size="medium"
                    color="secondary"
                    style={{ fontSize: 1 }}
                    onClick={handleprint}
                  >
                    <PrintIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={4}>
              <Autocomplete
                id="party"
                value={partyInput}
                filterOptions={filterOptions}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setpartyInput({
                      address1: "",
                      address2: "",
                      address3: "",
                      contactname: "",
                      phoneno: "",
                      subledgerid: "",
                      partydetails: "",
                    });
                    setState((prevState) => ({
                      ...prevState,
                      address1: "",
                      address2: "",
                      address3: "",
                      contactname: "",
                      phoneno: "",
                    }));
                  } else {
                    setpartyInput(newValue);
                    setState((prevState) => ({
                      ...prevState,
                      address1: `${newValue.address1}`,
                      address2: `${newValue.address2}`,
                      address3: `${newValue.address3}`,
                      contactname: `${newValue.contactperson}`,
                      phoneno: `${newValue.regdphoneno}`,
                    }));
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.subledgerid) === parseInt(value.subledgerid)
                }
                options={partyopts}
                getOptionLabel={(option) => option.partydetails}
                sx={{
                  width: "40% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Party" />
                )}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={mrdate}
                  onChange={(newValue) => {
                    setmrdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="receivedBy"
                name="receivedby"
                required
                select
                label="Received By"
                value={receivedby}
                onChange={(e) => {
                  setreceivedby(e.target.value);
                  //   if (e.target.value === "CQ") {
                  //     setreceivedby(e.target.value);
                  //     setdisablebank(false);
                  //   } else {
                  //     setreceivedby(e.target.value);
                  //     setdisablebank(true);
                  //   }
                  // }
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {receivedByopts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Cheque Date"
                  inputFormat="DD/MM/YYYY"
                  disabled={receivedby === "CS" ? true : false}
                  value={cqdate}
                  onChange={(newValue) => {
                    setcqdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Realisation Date"
                  inputFormat="DD/MM/YYYY"
                  disabled={receivedby === "CS" ? true : false}
                  value={realisationdate}
                  onChange={(newValue) => {
                    setrealisationdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider> */}
              <TextField
                id="chequeNo"
                label="Cheque No"
                name="chequeno"
                disabled={receivedby === "CQ" ? false : true}
                value={state.chequeno}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={4}>
              <Autocomplete
                id="banks"
                value={bankInput}
                disabled={receivedby === "CS" ? true : false}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setBankInput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.bankid) === parseInt(value.bankid)
                }
                options={bankopts}
                getOptionLabel={(option) => option.name}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField {...billopts} placeholder="Bank Name" />
                )}
                size="small"
              />

              <TextField
                required
                id="address1"
                label="Address 1"
                name="address1"
                value={state.address1}
                inputProps={{tabIndex:-1}}
                //onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address2"
                label="Address 2"
                name="address2"
                value={state.address2}
                inputProps={{tabIndex:-1}}
                //onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address3"
                label="Address 3"
                name="address3"
                value={state.address3}
                inputProps={{tabIndex:-1}}
                //onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mb={2}
            >
              <TextField
                id="contactName"
                label="Contact Name"
                name="contactname"
                value={state.contactname}
                inputProps={{tabIndex:-1}}
                //onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="phoneNo"
                label="Phone No"
                name="phoneno"
                value={state.phoneno}
                inputProps={{tabIndex:-1}}
                //onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <TextField
                required
                id="amountRs"
                label="Amount Rs"
                name="amount"
                value={state.amount}
                onChange={handleChange}
                type="number"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <Autocomplete
                id="narration"
                value={narrationInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setnarrationInput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.narrationid) === parseInt(value.narrationid)
                }
                options={narrationopts}
                getOptionLabel={(option) => option.narration}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Narration" />
                )}
                size="small"
              />
              <TextField
                id="reason"
                label="Reason"
                name="reason"
                value={state.reason}
                onChange={handleChange}
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <hr />
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mr={4}
              mb={2}
            >
              {receivedby === "CQ" && (
                <Accordion
                  sx={{ width: "100%", backgroundColor: "#d0deec" }}
                  disabled={disableaccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: "#a4c2e0" }}
                  >
                    <Typography>Cheque Return</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      spacing={2}
                      columnGap={6}
                      direction="row"
                      ml={33}
                      mt={1}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Return Date"
                          inputFormat="DD/MM/YYYY"
                          value={cqreturndate}
                          onChange={(newValue) => {
                            setcqreturndate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: "29%",
                                backgroundColor: "white",
                              }}
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <TextField
                        id="chargeRs"
                        label="Charge Rs"
                        name="chargers"
                        value={state.chargers}
                        onChange={handleChange}
                        type="number"
                        sx={{
                          width: "29%",
                          backgroundColor: "white",
                        }}
                        size="small"
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}
            </Stack>
            <Box sx={{ ml: "10%" }}>
              {pdfStream && (
                <iframe
                  ref={myElementRef}
                  src={pdfStream}
                  width="92%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
            <Stack spacing={2} columnGap={8} direction="row" mt={6} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => window.location.reload()}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={() => alert("Work in Progress!")}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />

      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default MoneyReceipt;
