import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Button, List, ListItemButton, ListItemIcon } from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "../../Components/landingpage.css";
import { NavLink, Redirect } from "react-router-dom";
import "./Sidebar.css";
import { io } from "socket.io-client";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
toast.configure();
const drawerWidth = 240;

//ACCORDION STYLING MUI
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  width: 240,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 240,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export default function Sidebar({ children, Title, expandedPanel }) {
  const userToken = LocalstorageHelper.GetUserToken();
  const [refreshState, setRefreshState] = useState(false);
  const pagRefresh = () => {
    if (refreshState === true) {
      return <Redirect exact to="/" />;
    }
  };

  //GET ALL MASTER MENU ITEMS
  const [invMenu, setinvMenu] = useState([]);
  async function getMasterMenu() {
    try {
      const response = await fetch("/getmastermenuopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setinvMenu(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getMasterMenu();
  }, []);

  //GET ALL TRANSACTION MENU ITEMS
  const [transMenu, setTransMenu] = useState([]);
  async function getTransMenu() {
    try {
      const response = await fetch("/gettransmenuopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setTransMenu(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getTransMenu();
  }, []);
  //GET ALL REPORT MENU ITEMS
  const [reportMenu, setReportMenu] = useState([]);
  async function getReportMenu() {
    try {
      const response = await fetch("/getreportmenuopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setReportMenu(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getReportMenu();
  }, []);
  //GET ALL MISC. MENU ITEMS
  const [miscMenu, setMiscMenu] = useState([]);
  async function getMiscMenu() {
    try {
      const response = await fetch("/getmiscmenuopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setMiscMenu(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getMiscMenu();
  }, []);

  const [finyear, setFinyear] = useState("");
  async function getFinyear() {
    try {
      const response = await fetch("/getLoginFin", {
        method: "GET",
        headers: { sptoken: userToken },
      });

      const parseRes = await response.json();
      setFinyear(parseRes.finname);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getFinyear();
    // eslint-disable-next-line
  }, []);
  //Get The username of Logged In User
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [branch, setBranch] = useState("");
  async function getName() {
    try {
      const response = await fetch("/spareDashboard", {
        method: "GET",
        headers: { sptoken: userToken },
      });

      const parseRes = await response.json();
      setName(parseRes.user_name);
      setCompany(parseRes.companyname);
      setBranch(parseRes.branchname);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getName();
    // eslint-disable-next-line
  }, []);
  //ACCORDION PANEL STATE AND EXPAND EVENT
  const [expanded, setExpanded] = useState(`${expandedPanel}`);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //LOGOUT HANDLER
  const logout = (e) => {
    e.preventDefault();
    LocalstorageHelper.RemoveUserToken();
    setRefreshState(true);
    toast.info("Logged Out!", { autoClose: 1000 });
  };
  const [socket, setSocket] = useState(null);
  const [notif, setNotif] = useState("");
  const fetchPrevNotif = async (e) => {
    try {
      const data = await fetch("/dev/messages");
      const message = await data.json();
      setNotif(message.messages);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const newSocket = io("http://68.178.167.193:4000");
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket === null) return;
    socket.on("getNotif", (res) => {
      setNotif(res);
    });
    fetchPrevNotif();
    return () => {
      socket.off("getNotif");
    };
  }, [socket, notif]);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            // backgroundImage: "linear-gradient(to right bottom, #0067c3, #0080d2, #0098dc, #12afe3, #4bc5e9)",
            // background: "#4278D1",
            // background: "-webkit-linear-gradient(top left, #4278D1, #0CACB7)",
            // background: "-moz-linear-gradient(top left, #4278D1, #0CACB7)",
            background: "linear-gradient(to bottom right, #4278D1, #0CACB7)",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                marginTop: "12px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly", // Change justifyContent to "space-between"
                alignItems: "baseline", // Add alignItems property
                columnGap: "100px",
              }}
            >
              <Typography sx={{ width: "200px" }} variant="body2">
                {Title}
              </Typography>

              <Typography
                textAlign={"center"}
                variant="body1"
                gutterBottom
                component="div"
              >
                {name}, {company}, {branch}, {finyear}
              </Typography>
            </Box>
            <Box sx={{ ml: "auto" }}>
              <Button
                variant="primary"
                className="hoveradmin"
                onClick={(e) => logout(e)}
              >
                LOGOUT
              </Button>
            </Box>
          </Toolbar>
          {notif !== "" && (
            <marquee
              scrollamount="9"
              style={{
                color: "yellow",
                fontSize: 18,
                // backgroundColor: "yellow",
                padding: 3,
              }}
            >
              <strong>{`📢${notif}📢`}</strong>
            </marquee>
          )}
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "linear-gradient(to left, #1DA8BA, #0054A8)",
              boxShadow:
                " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              color: "#BFBFBF",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          {/* <Typography variant="h6" noWrap component="div" ml={2} pb={5}>
            MASTER
          </Typography> */}
          {/* <Divider sx={{ height: "2px", boxShadow: "2px 3px 6px  #000000" }} /> */}
          <Accordion
            sx={{
              background: "linear-gradient(to left, #1DA8BA, #0054A8)",
              // boxShadow:
              //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              color: "#BFBFBF",
            }}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              sx={{
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                // boxShadow:
                //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                <b>MASTER</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: drawerWidth,
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                boxShadow:
                  " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
            >
              <Box>
                <List>
                  {invMenu.map((options, index) => (
                    <ListItem key={options.key} disablePadding>
                      <NavLink
                        exact
                        to={options.path}
                        className="link-normal"
                        activeClassName="link-active"
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            {index % 2 === 0 ? (
                              <InboxIcon sx={{ color: "white" }} />
                            ) : (
                              <MailIcon sx={{ color: "white" }} />
                            )}
                          </ListItemIcon>
                          {options.submenuname}
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Divider
            sx={{ height: "2px", boxShadow: "0px 0px 0px 2px rgba(3)" }}
          />
          <Accordion
            sx={{
              background: "linear-gradient(to left, #1DA8BA, #0054A8)",
              // boxShadow:
              //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              color: "#BFBFBF",
            }}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              sx={{
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                // boxShadow:
                //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                <b>TRANSACTION</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: drawerWidth,
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                boxShadow:
                  " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
            >
              <List>
                {transMenu.map((options, index) => (
                  <ListItem key={options.key} disablePadding>
                    <NavLink
                      exact
                      to={options.path}
                      className="link-normal"
                      activeClassName="link-active"
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {index % 2 === 0 ? (
                            <InboxIcon sx={{ color: "white" }} />
                          ) : (
                            <MailIcon sx={{ color: "white" }} />
                          )}
                        </ListItemIcon>
                        {options.submenuname}
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Divider
            sx={{ height: "2px", boxShadow: "0px 0px 0px 2px rgba(3)" }}
          />

          <Accordion
            sx={{
              background: "linear-gradient(to left, #1DA8BA, #0054A8)",
              // boxShadow:
              //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              color: "#BFBFBF",
            }}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              sx={{
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                // boxShadow:
                //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                <b>REPORTS</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: drawerWidth,
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                boxShadow:
                  " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
            >
              <List>
                {reportMenu.map((options, index) => (
                  <ListItem key={options.key} disablePadding>
                    <NavLink
                      exact
                      to={options.path}
                      className="link-normal"
                      activeClassName="link-active"
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {index % 2 === 0 ? (
                            <InboxIcon sx={{ color: "white" }} />
                          ) : (
                            <MailIcon sx={{ color: "white" }} />
                          )}
                        </ListItemIcon>
                        {options.submenuname}
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>

          <Divider
            sx={{ height: "2px", boxShadow: "0px 0px 0px 2px rgba(3)" }}
          />
          <Accordion
            sx={{
              background: "linear-gradient(to left, #1DA8BA, #0054A8)",
              // boxShadow:
              //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              color: "#BFBFBF",
            }}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              sx={{
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                // boxShadow:
                //   " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                <b>MISC</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: drawerWidth,
                background: "linear-gradient(to left, #1DA8BA, #0054A8)",
                boxShadow:
                  " rgba(0, 0, 30, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                color: "#BFBFBF",
              }}
            >
              <List>
                {miscMenu.map((options, index) => (
                  <ListItem key={options.key} disablePadding>
                    <NavLink
                      exact
                      to={options.path}
                      className="link-normal"
                      activeClassName="link-active"
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {index % 2 === 0 ? (
                            <InboxIcon sx={{ color: "white" }} />
                          ) : (
                            <MailIcon sx={{ color: "white" }} />
                          )}
                        </ListItemIcon>
                        {options.submenuname}
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Drawer>
        {/* IN SX KEEP BACKGROUNDCOLOR TO BACKGROUND.DEFAULT JUST IN CASE */}
        <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
          {children}
          <Toolbar />
        </Box>
      </Box>
      {pagRefresh()}
    </>
  );
}
