import { Edit } from "@mui/icons-material";
import { Box, Button,  Modal, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../../../LocalStorageHelper/LocalstorageHelper";
toast.configure();


const MainEdit = ({ maingrp, allMaingrp }) => {
   
  const userToken = LocalstorageHelper.GetUserToken();


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "& button": { m: 1 },
  };

  const [maingrpName, setMaingrpname] = useState(maingrp.maingrpname);
  const [helpText, setHelpText] = useState("") // change helper text
  const [disabled, setDisabled] = useState(false) //UPDATE BUTTON STATE
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMaingrpname(maingrp.maingrpname);
  };



  //Edit Data Function

  const updateDetails = async(e) => {
    e.preventDefault();
    try {
      const body = { maingrpName };
      const response = await fetch(`/ledger/updatemaingrp/${maingrp.maingrpid}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
     const parseRes =await response.json();
     toast(parseRes , {autoClose:1000});
     setOpen(false);
     setTimeout(() => {
      window.location.reload(false)
  }, 1000);
    } catch (err) {
      console.error(err.message);
    }
  };




///CHECK IF THE UOM EXISTS ALREADY 
const handleCheck = (e)=>{
let value = e.target.value
let newValue = value.toUpperCase();
setMaingrpname(e.target.value);
if(allMaingrp.map(a=>a.maingrpname).includes(newValue)){
setHelpText("Already Exists!");
setDisabled(true);
}
else{
  setHelpText("");
setDisabled(false);
}
}

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        open={open}
        aria-labelledby="Edit Main Group"
        aria-describedby="edit Maingroupname and Update"
        
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: "30ch",
            }}
            spacing={5}
            noValidate
            autoComplete="off"
            
          >
            <TextField
              id="Maingroupname"
              label="Maingroup Name"
              variant="standard"
              helperText={helpText}
              value={maingrpName}
              onChange={handleCheck}
            />
            <Button
            disabled = {disabled}
              variant="contained"
              size="medium"
              color="primary"
              onClick={e=> updateDetails(e)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default MainEdit;
