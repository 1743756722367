import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Fab,
  Tooltip,
  Typography,
  Switch,
  IconButton,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import PrintIcon from "@mui/icons-material/Print";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect, Link } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TaskIcon from "@mui/icons-material/Task";
import CancelIcon from "@mui/icons-material/Cancel";
import ImportExcel from "../StockTransfer/Stkimportexcel";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const StockTransfer = () => {
  const [disableSave, setDisableSave] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   LocalstorageHelper.RemoveUserToken();
  // }, 3600000);
  // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  const quantityRef = useRef(null);
  const newPartNoRef = useRef(null);
  const handlePartnoKey = (event) => {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === newPartNoRef.current) {
        const data = allparts.find((opt) => opt.itemcode === newModePartNo);
        if (data) {
          setpartno(data);
          setstock(data.purchaseqty);
          setDelItem(data.deleteditem);
          setaltFlag(data.altflag);
          if (quantityRef.current) {
            quantityRef.current.focus();
          }

          // Prevent the default Tab behavior
          event.preventDefault();
        } else {
          toast.error("No Part Found!");
          setNewModePartNo("");
          event.preventDefault();
        }
      }
    }
  };
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
      }
    }
    if (event.code === "F2") {
      if (event.target === quantityRef.current) {
        try {
          const response = await fetch(`/altitemstock/${partno.itemid}`, {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          });
          const jsonData = await response.json();
          setaltparts(jsonData);
          //console.log(altparts);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  }
  // const altItemRef = useRef(null);
  // async function handleAltShortcut(event) {
  //   if (event.code === "F2") {
  //     if (event.target === altItemRef.current) {
  //       try {
  //         const response = await fetch(`/altitemstock/${partno.itemid}`, {
  //           method: "GET",
  //           headers: {
  //             sptoken: userToken,
  //           },
  //         });
  //         const jsonData = await response.json();
  //         setaltparts(jsonData);
  //         //console.log(altparts);
  //       } catch (err) {
  //         console.log(err.message);
  //       }
  //     }
  //   }
  // }
  function handleSwitch(opt) {
    setpartno({
      deleteditem: opt.deleteditem,
      gstrate: opt.gstrate,
      itemid: opt.alternateitemid,
      mrp: opt.mrp,
      partdetails: opt.itemcode + "-" + opt.itemname,
      purchaseqty: opt.closingqty,
      rackname: opt.rackname,
      salerate: opt.salerate,
      spcldiscount: opt.spcldiscount,
    });
    setstock(opt.closingqty);
    if (isSwitchOn) {
      setNewModePartNo(opt.itemcode);
    }
  }
  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [ordernos, setordernos] = useState([]);
  //console.log(searchInput);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/stktransfer/doc/${searchInput.docid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setemodearray(jsonData.detail);
      setorderdate(jsonData.header.docdate);
      setbranchinput({
        branchid: jsonData.header.tobranchid,
        branchname: jsonData.header.branchname,
      });
      setprintbtndisable(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET BILL Search Parameters //
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //HOLD STATE OF PARTY SEARCH INPUT

  const [billsearch, setBillSearch] = useState(null);
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    matchFrom: "start",
  });

  //   CLICKING ON PARTY SEARCH EXECUTES THE FOLLOWING    //
  const handleBillSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/partydata/${billsearch.subledgerid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      //console.log(jsonData);
      setpartyname(jsonData[0].subledgername);
      setaddress1(jsonData[0].address1);
      setaddress2(jsonData[0].address2);
      setaddress3(jsonData[0].address3);
      setsalesmanname(jsonData[0].salesmanname);
      setpanno(jsonData[0].panno);
      setgstno(jsonData[0].gstno);
      setdiscrate(jsonData[0].partsdiscrate);
      setspecialdis(jsonData[0].specialdis);
    } catch (err) {
      console.log(err.message);
    }
  };

  //////////**************************** STATES OF TEXT FIELDS ************************************\\\\\\
  const date = new Date();
  const [orderdate, setorderdate] = useState(date);
  const [partyname, setpartyname] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [address3, setaddress3] = useState("");
  const [panno, setpanno] = useState("");
  const [gstno, setgstno] = useState("");
  const [salesmanname, setsalesmanname] = useState("");
  const [discrate, setdiscrate] = useState(0);
  const [specialdis, setspecialdis] = useState(0);
  const [searchby, setsearchby] = useState(1);
  const [newModePartNo, setNewModePartNo] = useState("");
  const [branchinput, setbranchinput] = useState(null);
  const [branchopts, setbranchopts] = useState([]);
  //branch
  async function getbranch() {
    try {
      const response = await fetch("/stktransfer/branches", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await response.json();
      console.log(data);
      setbranchopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getbranch();
  }, []);
  //aprove docno
  async function getapdocno() {
    try {
      const response = await fetch("/stktransfer/documents/toapprove", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await response.json();
      console.log(data);
      setapproveArray(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getapdocno();
  }, []);
  //approve btn
  const [approveArray, setapproveArray] = useState([]);
  const handleapprove = () => {
    try {
    } catch (error) {
      console.log(error.message);
    }
  };
  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteClick = (id) => () => {
    if (parseInt(mode) === 1) {
      setrows(rows.filter((row) => row.itemid !== id));
    } else {
      setemodearray(emodearray.filter((row) => row.itemid !== id));
    }
  };
  //------------------ ALTERNATE PART CHECK ----------------//
  const [altparts, setaltparts] = useState([]);
  const updateMrp = (id) => async () => {
    const index =
      parseInt(mode) === 1
        ? rows.findIndex((row) => row.itemid === id)
        : emodearray.findIndex((row) => row.itemid === id);
    //console.log(orderdetailrow[index].itemid);
    try {
      const response = await fetch(
        `/spare/stktrans/mrpup/${
          parseInt(mode) === 1 ? rows[index].itemid : emodearray[index].itemid
        }`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (parseInt(mode) === 1) {
        const updatedRow = {
          ...rows[index],
          mrp1: jsonData.mrp1,
          mrp2: jsonData.mrp2,
          mrp3: jsonData.mrp3,
          mrp4: jsonData.mrp4,
          stkqty: jsonData.stkqty,
        };
        const updatedArray = [
          ...rows.slice(0, index),
          updatedRow,
          ...rows.slice(index + 1),
        ];
        setrows(updatedArray);
        toast.success("Current Stock and new MRP Synced!", { autoClose: 2000 });
      } else {
        const updatedRow = {
          ...emodearray[index],
          mrp1: jsonData.mrp1,
          mrp2: jsonData.mrp2,
          mrp3: jsonData.mrp3,
          mrp4: jsonData.mrp4,
          stkqty: jsonData.stkqty,
        };
        const updatedArray = [
          ...emodearray.slice(0, index),
          updatedRow,
          ...emodearray.slice(index + 1),
        ];
        setemodearray(updatedArray);
        toast.success("Current Stock and new MRP Synced!", { autoClose: 2000 });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  //GRID TABLE COLUMN
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 80,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      type: "number",
      width: 60,
    },
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 200,
    },
    {
      field: "stkqty",
      headerClassName: "super-app-theme--header",
      headerName: "Stk Qty",
      width: 100,
      type: "number",
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 100,
      type: "number",
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Transfer Qty",
      type: "number",
      width: 100,
      editable: true,
      valueSetter: (params) => {
        if (params.value > params.row.stkqty) {
          toast.error("Cannot be greater than Order Quantity!", {
            toastId: "error1",
          });
        } else if (params.value > params.row.stkqty) {
          toast.error("Cannot be greater than Stock!", {
            toastId: "error1",
          });
        } else if (params.value < 0) {
          toast.error("Can't be negative", {
            toastId: "error1",
          });
        } else {
          const saleqty = params.value;
          const value = parseFloat(params.value * params.row.price).toFixed(2);
          const gstamt = parseFloat((value * params.row.gstpers) / 100).toFixed(
            2
          );
          const netamt = parseFloat(
            parseFloat(value) + parseFloat(gstamt)
          ).toFixed(2);
          if (parseInt(mode) === 1) {
            const upd_obj = rows.findIndex(
              (obj) => obj.itemid === params.row.itemid
            );
            rows[upd_obj].saleqty = saleqty;
            rows[upd_obj].value = value;
            rows[upd_obj].gstamt = gstamt;
            rows[upd_obj].netamt = netamt;
            return {
              ...params.row,
              saleqty: saleqty,
              value,
              gstamt,
              netamt,
            };
          } else {
            const upd_obj = emodearray.findIndex(
              (obj) => obj.itemid === params.row.itemid
            );
            emodearray[upd_obj].saleqty = saleqty;
            emodearray[upd_obj].value = value;
            emodearray[upd_obj].gstamt = gstamt;
            emodearray[upd_obj].netamt = netamt;
            return {
              ...params.row,
              saleqty: saleqty,
              value,
              gstamt,
              netamt,
            };
          }
        }
      },
    },
    {
      field: "updatemrp",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Fetch Updated Values",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<SyncIcon />}
            label="Update"
            onClick={updateMrp(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      width: 100,
      type: "number",
      editable: "true",
      valueGetter: (params) => {
        return params.row.mrp;
      },
      valueSetter: (params) => {
        if (
          parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp4)
        ) {
          toast.error("Enter A Valid MRP!", {
            toastId: "error1",
          });
        } else {
          if (parseInt(mode) === 1) {
            const mrp = params.value;
            const gstrate = parseFloat(params.row.gstpers);
            const price = parseFloat((mrp * 100) / (100 + gstrate)).toFixed(2);
            const value = parseFloat(params.row.saleqty * price).toFixed(2);
            const gstamt = parseFloat((value * gstrate) / 100).toFixed(2);
            const netamt = parseFloat(
              parseFloat(value) + parseFloat(gstamt)
            ).toFixed(2);
            const upd_obj = rows.findIndex(
              (obj) => obj.itemid === params.row.itemid
            );
            rows[upd_obj].mrp = mrp;
            rows[upd_obj].gstrate = gstrate;
            rows[upd_obj].price = price;
            rows[upd_obj].value = value;
            rows[upd_obj].gstamt = gstamt;
            rows[upd_obj].netamt = netamt;
            return {
              ...params.row,
              mrp,
              gstrate,
              price,
              value,
              gstamt,
              netamt,
            };
          } else {
            const mrp = params.value;
            const gstrate = parseFloat(params.row.gstpers);
            const price = parseFloat((mrp * 100) / (100 + gstrate)).toFixed(2);
            const value = parseFloat(params.row.saleqty * price).toFixed(2);
            const gstamt = parseFloat((value * gstrate) / 100).toFixed(2);
            const netamt = parseFloat(
              parseFloat(value) + parseFloat(gstamt)
            ).toFixed(2);
            const upd_obj = emodearray.findIndex(
              (obj) => obj.itemid === params.row.itemid
            );
            emodearray[upd_obj].mrp = mrp;
            emodearray[upd_obj].gstrate = gstrate;
            emodearray[upd_obj].price = price;
            emodearray[upd_obj].value = value;
            emodearray[upd_obj].gstamt = gstamt;
            emodearray[upd_obj].netamt = netamt;
            return {
              ...params.row,
              mrp,
              gstrate,
              price,
              value,
              gstamt,
              netamt,
            };
          }
        }
      },
    },
    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "Current MRP",
      width: 100,
      type: "number",
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      width: 100,
      type: "number",
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      width: 100,
      type: "number",
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      width: 100,
      type: "number",
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST %",
      width: 80,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 150,
      type: "number",
    },
  ];
  const [rows, setrows] = useState([]);

  // ADD ROW ON CLICK
  console.log(rows);
  const handleaddrow = () => {
    if (itemqty < 1) {
      toast.error("Value can't be 0 or negative");
    } else {
      if (
        parseInt(mode) === 1
          ? rows.map((e) => e.itemid).includes(partno.itemid)
          : emodearray.map((e) => e.itemid).includes(partno.itemid)
      ) {
        toast.error("Item Added Already!");
      } else {
        if (parseInt(mode) === 1) {
          const valueamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const gstamount = parseFloat(
            (valueamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(valueamount) + parseFloat(gstamount)
          ).toFixed(2);
          setrows((oldRows) => [
            ...oldRows,
            {
              serial:
                parseInt(mode) === 1
                  ? oldRows.length + 1
                  : emodearray.length + 1,
              partno: partno.partdetails,
              mrp: partno.mrp,
              mrp4: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              gstpers: partno.gstrate,
              price: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              value: parseFloat(valueamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: netamt,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          const valueamount =
            // parseInt(itemqty) * parseFloat(partno.salerate);
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const gstamount = parseFloat(
            (valueamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamt = parseFloat(
            parseFloat(valueamount) + parseFloat(gstamount)
          ).toFixed(2);
          setemodearray((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partno: partno.partdetails,
              mrp: partno.mrp,
              mrp4: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              gstpers: partno.gstrate,
              price: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              value: parseFloat(valueamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: netamt,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      }
    }
  };

  // STORE GRID DETAILS

  const [griddata, setgriddata] = useState([]);
  const griddataarray = Object.values(griddata);
  console.log(griddataarray);
  // Search parts
  const [partno, setpartno] = useState(null);
  const [stock, setstock] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState(0);
  const [allparts, setallparts] = useState([]);
  // console.log(allparts);
  useEffect(() => {
    if (partno) {
      quantityRef.current.focus();
    }
  }, [partno]);

  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);

  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [totallossqty, setTotallossqty] = useState(0);
  const [totlossval, setTotlossval] = useState(0);
  const [totmiscamt, setTotmiscamt] = useState(0);
  const [totgstamt, setTotgstamt] = useState(0);
  const [totnetamt, setTotnetamt] = useState(0);
  const [totvalue, setTotvalue] = useState(0);
  const [totdiscount, setTotdiscount] = useState(0);
  const [totalorderval, setTotalorderval] = useState(0);

  // CUSTOM FOOTER COMPONENT
  const [misc1, setmisc1] = useState("");
  const [misc2, setmisc2] = useState("");
  const [value1, setvalue1] = useState(0);
  const [value2, setvalue2] = useState(0);
  const gst28 = griddataarray.filter((opt) => opt.gstpers === "28.00");
  const totgst28 = parseFloat(
    gst28.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst18 = griddataarray.filter((opt) => opt.gstpers === "18.00");
  const totgst18 = parseFloat(
    gst18.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst12 = griddataarray.filter((opt) => opt.gstpers === "12.00");
  const totgst12 = parseFloat(
    gst12.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);
  const gst5 = griddataarray.filter((opt) => opt.gstpers === "5.00");
  const totgst5 = parseFloat(
    gst5.map((item) => parseFloat(item.gstamt)).reduce((a, b) => a + b, 0)
  ).toFixed(2);

  const [emodegst28, setemodegst28] = useState(0);
  const [emodegst18, setemodegst18] = useState(0);
  const [emodegst12, setemodegst12] = useState(0);
  const [emodegst5, setemodegst5] = useState(0);

  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotallossqty, setnewTotallossqty] = useState(0);
  const [newtotlossval, setnewTotlossval] = useState(0);
  const [newtotmiscamt, setnewTotmiscamt] = useState(0);
  const [newtotgstamt, setnewTotgstamt] = useState(0);
  const [newtotnetamt, setnewTotnetamt] = useState(0);
  const [newtotvalue, setnewTotvalue] = useState(0);
  const [newtotdiscount, setnewTotdiscount] = useState(0);
  const [newtotalorderval, setnewTotalorderval] = useState(0);
  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  const editedDetails = Object.values(emodedetails);
  // console.log(editedDetails);
  // SUBMIT CUSTOMER ORDER DETAILS
  const handleCustomerOrderSubmit = async (e) => {
    e.preventDefault();
    if (branchinput === null) {
      return toast.error("Please Select A Branch!!");
    }
    setDisableSave(true);
    try {
      const body = {
        tobranch: branchinput.branchid,
        documentdate: orderdate,
        itemdata: griddataarray,
        totnet: totnetamt,
        totgst: totgstamt,
        totgross: totvalue,
      };
      const response = await fetch("/stktransfer/transfer", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
      } else {
        setDisableSave(false);
        toast.error(parseRes.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  ///update
  const [disableupdate, setdisableupdate] = useState(false);
  const handleupdate = async (e) => {
    e.preventDefault();
    setdisableupdate(true);
    try {
      const body = {
        tobranch: branchinput.branchid,
        documentdate: orderdate,
        itemdata: emodearray,
        totnet: totnetamt,
        totgst: totgstamt,
        totgross: totvalue,
      };
      const response = await fetch(
        `/stktransfer/doc/update/${searchInput.docid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );

      const parseRes = await response.json();
      // console.log(parseRes)
      if (parseRes.type === "success") {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        toast.success(parseRes.message);
        setdisableupdate(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);

  // SOURCES FLAG  DROPDOWN VALUES AND HANDLER
  const [flagcode, setflagcode] = useState("OF");
  const [flags, setFlags] = useState(false);
  const [mode, setMode] = useState(1);

  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];

  //HANDLE PARTIES WHO HAVE ORDERED
  const [partyfetch, setpartyfetch] = useState(false);
  const [parties, setparties] = useState([]);
  async function getparties() {
    try {
      const response = await fetch("/stktransfer/documents", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      setpartyfetch(false);
      const jsondata = await response.json();
      console.log(jsondata);
      setparties(jsondata);
    } catch (err) {
      console.log(err.msg);
    }
  }
  useEffect(() => {
    if (partyfetch === true) {
      getparties();
    }
  }, [partyfetch]);

  //----------- Modal Handler-------\\

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 950,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const [disablereportbtn, setdisablereportbtn] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [stkopen, setstkOpen] = useState(false);
  const handlestkOpen = () => setstkOpen(true);
  const handlestkClose = () => {
    setstkOpen(false);
    setdocno(null);
    setPdfStreamR(null);
    setdisablereportbtn(false);
  };

  const [stkvopen, setstkvOpen] = useState(false);
  const handlestkvOpen = () => setstkvOpen(true);
  const handlestkvClose = () => {
    setstkvOpen(false);
    setdocno(null);
    setPdfStreamV(null);
  };

  //CUSTOMER ORDER SEARCH
  const [ordersearch, setOrderSearch] = useState(null);
  //CLICKING ON ORDER SEARCH EXECUTES FOLLOWING \\
  const [emodearray, setemodearray] = useState([]);
  const [dopen, setdOpen] = useState(false);

  const handledClickOpen = () => {
    setdOpen(true);
  };

  const handledClose = () => {
    setdOpen(false);
  };
  const [appopen, setappopen] = useState(false);

  const handledaClickOpen = () => {
    setappopen(true);
  };

  const handledaClose = () => {
    setappopen(false);
  };
  //switch
  const [isSwitchOn, setSwitchOn] = useState(true);

  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
  };
  const [missingArray, setMissingArray] = useState([]);
  const [partyValue, setPartyValue] = useState(null);

  const handlePartyHeader = async () => {
    try {
      const response = await fetch(`/partydata/${partyValue.subledgerid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setpartyname(jsonData[0].subledgername);
      setaddress1(jsonData[0].address1);
      setaddress2(jsonData[0].address2);
      setaddress3(jsonData[0].address3);
      setsalesmanname(jsonData[0].salesmanname);
      setpanno(jsonData[0].panno);
      setgstno(jsonData[0].gstno);
      setdiscrate(jsonData[0].partsdiscrate);
      setspecialdis(jsonData[0].specialdis);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handlePartyHeader();
  }, [partyValue]);
  const handlePartyDetailRecalculate = () => {
    try {
      const newArray = emodearray.map((item) => {
        const newdiscrate = item.spcldiscount
          ? parseFloat(specialdis) + parseFloat(discrate)
          : parseFloat(discrate);
        const disamt = (parseFloat(newdiscrate) * parseFloat(item.value)) / 100;
        const taxable = parseFloat(item.value) - parseFloat(disamt);
        const gstamt = (parseFloat(item.gstpers) * parseFloat(taxable)) / 100;
        const netamt = parseFloat(gstamt) + parseFloat(taxable);
        return {
          ...item,
          dispers: parseFloat(newdiscrate).toFixed(2),
          disamt: parseFloat(disamt).toFixed(2),
          taxable: parseFloat(taxable).toFixed(2),
          gstamt: parseFloat(gstamt).toFixed(2),
          netamt: parseFloat(netamt).toFixed(2),
        };
      });

      setemodearray(newArray);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handlePartyDetailRecalculate();
  }, [partyValue, discrate]);
  //print stock transfer
  const [printbtndisable, setprintbtndisable] = useState(false);
  const [pdfStream, setPdfStream] = useState(null);
  const [pdfStreamR, setPdfStreamR] = useState(null);
  const [pdfStreamV, setPdfStreamV] = useState(null);
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
  const handleprint = async (e) => {
    e.preventDefault();
    try {
      const body = { format: 1 };
      const res = await fetch(
        `/reports/stktransfer/challan/${searchInput.docid}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error.message);
    }
  };
  const [docno, setdocno] = useState(null);
  const [docopts, setdocopts] = useState([]);
  const [viewdocopts, setviewdocopts] = useState([]);
  const [sorttype, setSortType] = useState("rackname");
  const sortoptions = [
    {
      value: "rackname",
      label: "Rack",
    },
    {
      value: "itemcode",
      label: "Item Code",
    },
  ];
  async function getParam() {
    try {
      const response = await fetch("/reports/stktransferno", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setdocopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getParam();
  }, []);
  async function getParamView() {
    try {
      const response = await fetch("/reports/stktransferno/view", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setviewdocopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getParamView();
  }, []);
  //report
  const handleReport = async (e) => {
    e.preventDefault();

    // Ask for confirmation using window.confirm
    const confirmed = window.confirm(
      "Confirming This will directly effect stock,Are you sure you want to continue?"
    );

    if (!confirmed) {
      // User cancelled the action
      setdisablereportbtn(false); // Enable the report button again if needed
      return; // Exit the function
    }

    setdisablereportbtn(true); // Disable the report button to prevent multiple clicks

    try {
      const response = await fetch(
        `/reports/getstktransferlist/${docno.docid}/${sorttype}`,
        {
          headers: {
            sptoken: userToken,
          },
        }
      );

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfStreamR(pdfUrl);
    } catch (error) {
      console.error("Error fetching report:", error);
      // Handle errors appropriately (e.g., show an error message to the user)
    } finally {
      setdisablereportbtn(false); // Enable the report button again after API call completes
    }
  };
  // for view
  const handleReportV = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/reports/getstktransferlist/view/${docno.docid}/${sorttype}`,
        {
          headers: {
            sptoken: userToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStreamV(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"STOCK TRANSFER"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"15px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={parties}
                getOptionLabel={(option) => option.docno}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "500px",
                    }}
                    {...params}
                    size="small"
                    placeholder="Search Doc No."
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                //type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Box
                sx={{
                  ml: "10%",
                  display: printbtndisable === false ? "none" : "flex",
                }}
              >
                <Tooltip placement="top" title="Print Stock Transfer Challan">
                  <Fab
                    size="medium"
                    color="secondary"
                    style={{ fontSize: 1 }}
                    onClick={handleprint}
                  >
                    <PrintIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </Stack>
            <hr />
            <Stack
              spacing={1}
              columnGap={2}
              mb={2}
              direction="row"
              mt={2}
              ml={4}
            >
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartyfetch(true);
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartyfetch(false);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={orderdate || null}
                  onChange={(newValue) => {
                    setorderdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Autocomplete
                id="partysearch"
                value={branchinput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setbranchinput(newValue);
                }}
                options={branchopts}
                getOptionLabel={(option) => option.branchname}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 200,
                    }}
                    required
                    {...params}
                    placeholder="Branch"
                  />
                )}
                size="small"
              />
              <Button onClick={handlestkOpen} color="secondary" size="medium">
                Transfer List
              </Button>
              <Button onClick={handlestkvOpen} color="info" size="medium">
                Transfer List View
              </Button>
              <Link to="/stkewaybill">
                <Button>eWay Bill Print→</Button>
              </Link>
            </Stack>

            <hr />
            <Stack columnGap={3} direction="row" mt={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Old Mode</Typography>
                <Switch
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>New Mode</Typography>
              </Stack>
              <Button
                sx={{ ml: "3%", mt: "5px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD A NEW ROW
              </Button>
              <ImportExcel
                discrate={discrate}
                setrows={setrows}
                mode={mode}
                setMissingArray={setMissingArray}
              />
              {missingArray.length > 0 && (
                <Box>
                  <Tooltip placement="top" title="Some Items Might be missing!">
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="add"
                      style={{ fontSize: 1 }}
                      onClick={handledClickOpen}
                    >
                      <NotificationImportantIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              )}
              {/* <Box>
                <Tooltip placement="top" title="Approve Stock">
                  <Fab
                    size="small"
                    color="info"
                    aria-label="add"
                    style={{ fontSize: 1 }}
                    onClick={handledaClickOpen}
                  >
                    <TaskIcon />
                  </Fab>
                </Tooltip>
              </Box> */}

              <Draggable>
                <Modal
                  sx={{ mt: "5%" }}
                  open={open}
                  BackdropProps={{ invisible: true }}
                  aria-labelledby="modl-box"
                  aria-describedby="search partitem"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        padding: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        columnGap: 1,
                        rowGap: 1,
                      }}
                    >
                      <Box display={isSwitchOn ? "flex" : "none"}>
                        <TextField
                          id="partno"
                          label="Part No"
                          value={newModePartNo}
                          onChange={(e) => setNewModePartNo(e.target.value)}
                          inputRef={newPartNoRef}
                          onKeyDown={handlePartnoKey}
                          sx={{
                            width: "200px",
                          }}
                          size="small"
                        />
                      </Box>
                      <Box display={!isSwitchOn ? "flex" : "none"}>
                        <Autocomplete
                          id="partno"
                          value={partno}
                          filterOptions={filterOptions2}
                          onChange={(event, newValue) => {
                            setpartno(newValue);
                            setstock(newValue.purchaseqty);
                            setDelItem(newValue.deleteditem);
                            setaltFlag(newValue.altflag);
                            //console.log(newValue);
                          }}
                          options={allparts}
                          getOptionLabel={(option) => option.partdetails}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: "200px ",
                              }}
                              // inputRef={altItemRef}
                              // onKeyDown={handleAltShortcut}
                              placeholder="Part No"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Box>
                      <TextField
                        id="quantity"
                        label="Quantity"
                        value={itemqty}
                        onChange={(e) => setitemqty(e.target.value)}
                        inputRef={quantityRef}
                        onKeyDown={handleKeyDown}
                        type="number"
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="stock"
                        label="Stock"
                        value={stock}
                        type="number"
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="delitem"
                        label="Deleted Item"
                        value={delItem}
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="altitem"
                        label="Alternate Item"
                        value={altFlag}
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <TextField
                        id="netamount"
                        label="Net Amount"
                        value={parseInt(mode) === 1 ? totnetamt : newtotnetamt}
                        sx={{
                          width: "100px",
                        }}
                        size="small"
                        inputProps={{ tabIndex: "-1" }}
                      />
                      <IconButton
                        aria-label="add"
                        size="large"
                        color="success"
                        tabIndex={-1}
                        onClick={handleaddrow}
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <IconButton
                        aria-label="cancel"
                        size="large"
                        color="error"
                        tabIndex={-1}
                        onClick={handleClose}
                      >
                        <CancelIcon />
                      </IconButton>

                      <Stack direction="row">
                        <Table
                          sx={{
                            minWidth: 650,
                            boxShadow: "2px 6px 15px #5a5a5a",
                          }}
                          size="small"
                          aria-label="simple table"
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#3bb9ff",
                              border: "0.5px solid black",
                              height: 10,
                            }}
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Number
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Alt-Item Stock
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                                align="center"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {altparts.length > 0 ? (
                            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                              {altparts.map((opt) => (
                                <TableRow
                                  sx={{ height: 2 }}
                                  key={opt.alternateitemid}
                                >
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.itemname}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.itemcode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {opt.closingqty}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      onClick={(e) => handleSwitch(opt, e)}
                                    >
                                      Switch
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            <Typography variant="subtitle1" ml={10}>
                              NO ITEMS FOUND
                            </Typography>
                          )}
                        </Table>
                      </Stack>
                      {/* <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>
                      <Button
                        variant="contained"
                        tabIndex={-1}
                        size="medium"
                        color="success"
                        onClick={handleaddrow}
                      >
                        ADD
                      </Button>
                      <Button
                        tabIndex={-1}
                        variant="contained"
                        size="medium"
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack> */}
                    </Box>
                  </Box>
                </Modal>
              </Draggable>
            </Stack>
            <Box sx={{ m: 5, ml: "10%" }}>
              {pdfStream && (
                <iframe
                  ref={myElementRef}
                  src={pdfStream}
                  width="92%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "15px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                <DataGrid
                  sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                    outline: "2px solid red", // Change this to the desired color
                    backgroundColor: "gray",
                    color: "white",
                  }, }}
                  rowHeight={25}
                  headerHeight={40}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "serial", sort: "desc" }],
                    },
                  }}
                  showColumnRightBorder={true}
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    setgriddata(params.rows.idRowsLookup);
                    const losqtytot = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.losqty))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotallossqty(losqtytot);

                    const losqtyval = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.losval))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotlossval(losqtyval);

                    const newval = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotvalue(newval);

                    const newdisamt = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.disamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotdiscount(newdisamt);

                    const newgstamt = parseFloat(
                      griddataarray
                        .map((item) => parseFloat(item.gstamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setTotgstamt(newgstamt);

                    const newnetamt = griddataarray
                      .map((item) => parseFloat(item.netamt))
                      .reduce((a, b) => a + b, 0);
                    setTotnetamt(
                      parseFloat(
                        parseFloat(newnetamt) +
                          parseFloat(value1) +
                          parseFloat(value2)
                      ).toFixed(2)
                    );
                    const neworderval = parseFloat(
                      parseFloat(totnetamt) + parseFloat(totlossval)
                    ).toFixed(2);
                    setTotalorderval(neworderval);
                  }}
                />
                <Box sx={{ padding: "10px", height: 170 }}>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Stock Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            GST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Net Stock Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {totnetamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    //type="submit"
                    onClick={handleCustomerOrderSubmit}
                    disabled={disableSave}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    SAVE ORDER
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                <DataGrid
                  sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                    outline: "2px solid red", // Change this to the desired color
                    backgroundColor: "gray",
                    color: "white",
                  }, }}
                  rowHeight={25}
                  headerHeight={40}
                  showColumnRightBorder={true}
                  rows={emodearray}
                  columns={columns}
                  pageSize={100}
                  getRowId={(row) => row.itemid}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "serial", sort: "desc" }],
                    },
                  }}
                  onStateChange={(params) => {
                    setEmodeDetails(params.rows.idRowsLookup);
                    const losqtytot = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.losqty))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotallossqty(losqtytot);

                    const losqtyval = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.losval))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotlossval(losqtyval);

                    const newval = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotvalue(newval);

                    const newdisamt = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.disamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotdiscount(newdisamt);

                    const newgstamt = parseFloat(
                      editedDetails
                        .map((item) => parseFloat(item.gstamt))
                        .reduce((a, b) => a + b, 0)
                    ).toFixed(2);
                    setnewTotgstamt(newgstamt);

                    const newnetamt = editedDetails
                      .map((item) => parseFloat(item.netamt))
                      .reduce((a, b) => a + b, 0);
                    setnewTotnetamt(
                      parseFloat(
                        parseFloat(newnetamt) +
                          parseFloat(value1) +
                          parseFloat(value2)
                      ).toFixed(2)
                    );
                    const newordval = parseFloat(
                      parseFloat(newtotnetamt) + parseFloat(newtotlossval)
                    ).toFixed(2);
                    setnewTotalorderval(newordval);
                    const newgst28 = editedDetails.filter(
                      (opt) => opt.gstpers === "28.00"
                    );
                    const newtotgst28 = newgst28
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst28(newtotgst28);
                    const newgst18 = editedDetails.filter(
                      (opt) => opt.gstpers === "18.00"
                    );
                    const newtotgst18 = newgst18
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst18(newtotgst18);
                    const newgst12 = editedDetails.filter(
                      (opt) => opt.gstpers === "12.00"
                    );
                    const newtotgst12 = newgst12
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst12(newtotgst12);
                    const newgst5 = editedDetails.filter(
                      (opt) => opt.gstpers === "5.00"
                    );
                    const newtotgst5 = newgst5
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setemodegst5(newtotgst5);
                  }}
                />
                <Box sx={{ padding: "10px", height: 170 }}>
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    ml={4}
                    mt={2}
                  >
                    <TextField
                      id="gst5"
                      label="GST @ 5%"
                      value={parseFloat(emodegst5).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst18"
                      label="GST @ 18%"
                      value={parseFloat(emodegst18).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst12"
                      label="GST @ 12%"
                      value={parseFloat(emodegst12).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="gst28"
                      label="GST @ 28%"
                      value={parseFloat(emodegst28).toFixed(2)}
                      type="text"
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  </Stack>
                  <Stack direction="row" mt={2}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Stock Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            GST Amt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Net Stock Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotnetamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                </Box>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    //type="submit"
                    disabled={disableupdate}
                    onClick={handleupdate}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    UPDATE ORDER
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        }
      />
      <Dialog
        open={dopen}
        onClose={handledClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Part nos"}</DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Table
              stickyHeader
              sx={{
                minWidth: 400,
                boxShadow: "2px 6px 15px #5a5a5a",
                height: 200,
              }}
              size="small"
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#3bb9ff",
                  border: "0.5px solid black",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Srl No.
                  </TableCell>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Part Nos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                {missingArray.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {item}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handledClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={appopen}
        onClose={handledaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Stock Approve"}</DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Table
              stickyHeader
              sx={{
                minWidth: 500,
                boxShadow: "2px 6px 15px #5a5a5a",
                height: 10,
              }}
              size="small"
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#3bb9ff",
                  border: "0.5px solid black",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Doc No.
                  </TableCell>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Doc Date.
                  </TableCell>
                  <TableCell
                    sx={{ color: "#000", border: "0.5px solid black" }}
                    align="center"
                  >
                    Approve Btn
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                {approveArray.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {item.docno}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {item.docdate}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      <Tooltip title="Approve">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="add"
                          style={{ fontSize: 1 }}
                          onClick={handleapprove}
                        >
                          <ThumbUpIcon />
                        </Fab>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handledaClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={stkopen}>
        <DialogTitle>Select Doc No:</DialogTitle>
        <DialogContent sx={{ width: "600px", height: "auto" }}>
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              flexWrap: "wrap",
              // justifyContent: "center",
              columnGap: "5px",
              rowGap: 5,
            }}
          >
            <Autocomplete
              id="orderno"
              value={docno}
              filterOptions={filterOptions}
              onChange={(event, newValue) => {
                setdocno(newValue);
              }}
              options={docopts}
              getOptionLabel={(option) => option.docno}
              sx={{
                width: "200px ",
              }}
              renderInput={(billopts) => (
                <TextField required {...billopts} placeholder="Doc No" />
              )}
              size="small"
            />
            <TextField
              required
              select
              id="sort by"
              label="Sort By"
              value={sorttype}
              onChange={(e) => setSortType(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: "100px",
              }}
            >
              {sortoptions.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>

            <Button
              disabled={disablereportbtn}
              //type="submit"
              onClick={handleReport}
              variant="contained"
              component="label"
              size="small"
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              //endIcon={<Task />}
            >
              Show Report
            </Button>
            {/* <Button
                type="submit"
                onClick={viewReport}
                variant="contained"
                component="label"
                size="small"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                View Report
              </Button> */}
          </Box>
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStreamR && (
              <iframe src={pdfStreamR} width="100%" height="680px"></iframe>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            tabIndex={-1}
            variant="contained"
            size="medium"
            onClick={handlestkClose}
            color="error"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={stkvopen}>
        <DialogTitle>Select Doc No:</DialogTitle>
        <DialogContent sx={{ width: "600px", height: "auto" }}>
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              flexWrap: "wrap",
              // justifyContent: "center",
              columnGap: "5px",
              rowGap: 5,
            }}
          >
            <Autocomplete
              id="orderno"
              value={docno}
              filterOptions={filterOptions}
              onChange={(event, newValue) => {
                setdocno(newValue);
              }}
              options={viewdocopts}
              getOptionLabel={(option) => option.docno}
              sx={{
                width: "200px ",
              }}
              renderInput={(billopts) => (
                <TextField required {...billopts} placeholder="Doc No" />
              )}
              size="small"
            />
            <TextField
              required
              select
              id="sort by"
              label="Sort By"
              value={sorttype}
              onChange={(e) => setSortType(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: "100px",
              }}
            >
              {sortoptions.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>

            <Button
              //type="submit"
              onClick={handleReportV}
              variant="contained"
              component="label"
              size="small"
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              //endIcon={<Task />}
            >
              Show Report
            </Button>
            {/* <Button
                type="submit"
                onClick={viewReport}
                variant="contained"
                component="label"
                size="small"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                View Report
              </Button> */}
          </Box>
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStreamV && (
              <iframe src={pdfStreamV} width="100%" height="680px"></iframe>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            tabIndex={-1}
            variant="contained"
            size="medium"
            onClick={handlestkvClose}
            color="error"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default StockTransfer;
