import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Autocomplete } from "@mui/material";

import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
toast.configure();
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Deliverynoteview = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setBtnDisabled(false);
  };
  async function getDeliveryNotes() {
    const data = await fetch("/deliverynote/generated", {
      method: "GET",
      headers: {
        sptoken: userToken,
      },
    });
    const response = await data.json();
    setRows(response);
  }
  useEffect(() => {
    getDeliveryNotes();
  }, []);
  const [btndisabled, setBtnDisabled] = useState(false);
  const [pdfStream, setPdfStream] = useState(null);
  const handleView = async (params) => {
    try {
      setBtnDisabled(true);
      const billheaderid = params.row.billheaderid;
      const deliverynotedate = params.row.dnotedate;
      const headers = {
        "Content-Type": "application/json",
        sptoken: userToken,
      };
      const requestBody = {
        deliverynotedate,
      };
      await fetch(`/deliverynote/report/${billheaderid}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
          setOpen(true);
        })
        .catch((error) => {
          console.error(error);
          setBtnDisabled(false);
        });
    } catch (error) {
      console.log(error.message);
      setBtnDisabled(false);
    }
  };
  const columns = [
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 50,
    },
    {
      field: "dnoteno",
      headerClassName: "super-app-theme--header",
      headerName: "Delivery Note No",
      width: 160,
    },
    {
      field: "dnotedate",
      headerClassName: "super-app-theme--header",
      headerName: "Delivery Date",
      width: 120,
    },
    {
      field: "billno",
      headerClassName: "super-app-theme--header",
      headerName: "Bill No",
      width: 160,
    },
    {
      field: "billdate",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Date",
      width: 100,
    },
    {
      field: "partydetails",
      headerClassName: "super-app-theme--header",
      headerName: "Bill_Party",
      width: 300,
    },
    {
      field: "dnote_partydetails",
      headerClassName: "super-app-theme--header",
      headerName: "DNote_Party",
      width: 300,
    },
    {
      field: "view",
      headerClassName: "super-app-theme--header",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => handleView(params)}
          disabled={btndisabled}
        >
          View
        </Button>
      ),
    },
    {
      field: "excel",
      headerClassName: "super-app-theme--header",
      headerName: "Excel",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => printexcel(params)}
          disabled={btndisabled}
        >
          Excel
        </Button>
      ),
    },
  ];

  //formatted excel
  const printexcel = async (params) => {
    try {
      const billheaderid = params.row.billheaderid;
      const data = await fetch(`/deliverynote/excel/${billheaderid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "DeliveryNote.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"Delivery Note View"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Link to="/salebill">
              <Button>←Go Back to Sale Bill</Button>
            </Link>
            <Link to="/dnotereg">
              <Button>←Go to Delivery Note Register</Button>
            </Link>
            {pdfStream && (
              <Box>
                <Dialog
                  PaperProps={{
                    style: {
                      width: "80%", // Or any other percentage or pixel value
                      maxWidth: "none", // Ensures the dialog can grow beyond the default max width
                    },
                  }}
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>{"DELIVERY NOTE"}</DialogTitle>
                  <DialogContent sx={{ width: "100%" }}>
                    <iframe
                      src={pdfStream}
                      width="100%"
                      height="680px"
                      title="report"
                    ></iframe>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
            <DataGrid
              sx={{ height: 300 }}
              rowHeight={40}
              headerHeight={40}
              columns={columns}
              rows={rows}
              getRowId={(row) => row.dnoteno}
            />
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Deliverynoteview;
