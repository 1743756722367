import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HowToRegTwoTone from "@mui/icons-material/HowToRegTwoTone";
import Alert from "@mui/material/Alert";

toast.configure();
const AdminApprovebtn = ({ user }) => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const [disaprvBtn, setdisaprvBtn] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  //edit Data Function

  const updateDetails = async (e) => {
    e.preventDefault();
    setdisaprvBtn(true);
    try {
      const response = await fetch(`/apprvpurbill/${user.inwardid}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
      });
      const parseRes = await response.json();
      console.log(response);
      if (parseRes) {
        toast.success(parseRes, { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  //ToolTip
  const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div>
      <MyTooltip title="Approve" placement="top">
        <Button
          variant="contained"
          color="success"
          endIcon={<HowToRegTwoTone />}
          onClick={handleOpen}
        >
          Approve
        </Button>
      </MyTooltip>
      <Modal
        open={open}
        aria-labelledby="Approve"
        aria-describedby="approve purchase bill details"
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: 500,
              margin: "auto",
              p: 4,
            }}
            spacing={5}
            noValidate
            autoComplete="off"
          >
            <Alert severity="info">
              Are You Sure, You want to <strong>Approve</strong> this purchase
              bill? Chages made can not be reverted back.
            </Alert>
          </Stack>
          <Box sx={{ mx: "15%", mb: "10px" }}>
            <Button
              disabled={disaprvBtn}
              variant="contained"
              size="medium"
              color="primary"
              onClick={(e) => updateDetails(e)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {redirectToLoginPage()}
    </div>
  );
};

export default AdminApprovebtn;
