import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
toast.configure();
const PartywiseOrderBill = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  // TEXT FIELD VALUE STATES
  const [itemcatginput, setitemcatginput] = useState(null);
  const [itemcatgopts, setitemcatgopts] = useState([]);
  const [partycatginput, setpartycatginput] = useState(null);
  const [partycatgopts, setpartycatgopts] = useState([]);
  const [areainput, setareainput] = useState(null);
  const [areaopts, setareaopts] = useState([]);
  const date = new Date();
  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3

  // Use the useState hook to manage the state
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(currentDate);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  //area
  async function getArea() {
    try {
      const res = await fetch("/area", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setareaopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getArea();
  }, []);
  //itemcatg
  async function getitemcatg() {
    try {
      const res = await fetch("/itemcatg", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setitemcatgopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getitemcatg();
  }, []);
  //partycatg
  async function getpartycatg() {
    try {
      const res = await fetch("/partycatg", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setpartycatgopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getpartycatg();
  }, []);

  //report api call
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const body = {
        fromdate,
        todate,
        format: 1,
        area: areainput ? areainput.destid : 0,
        itemcatg: itemcatginput ? itemcatginput.groupid : 0,
        partycatg: partycatginput ? partycatginput.subledgercatgid : 0,
      };
      console.log(body);
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const response = await fetch("/reports/partywiseorderbill", options)
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
        setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const body = {
        fromdate,
        todate,
        area: areainput ? areainput.destid : 0,
        itemcatg: itemcatginput ? itemcatginput.groupid : 0,
        partycatg: partycatginput ? partycatginput.subledgercatgid : 0,
        format: option,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const res = await fetch("/reports/partywiseorderbill", options);
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `Partywiseorderbill_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        // Programmatically click the link to initiate download
        link.click();
        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `Partywiseorderbill_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        // Programmatically click the link to initiate download
        link.click();
        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const myElementRef = useRef(null);
  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
    //formatted excel
    const printexcel = async (e) => {
      e.preventDefault();
      if (fromdate === null) {
        return toast.error("Please Select The From Date!!");
      }
      try {
        const fromDateStr = fromdate.toISOString();
        const toDateStr = todate.toISOString();
        const body = {
          fromdate,
          todate,
          area: areainput ? areainput.destid : 0,
          itemcatg: itemcatginput ? itemcatginput.groupid : 0,
          partycatg: partycatginput ? partycatginput.subledgercatgid : 0,
        };
        const data = await fetch("/reports/partywiseorderbill/excel", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
  
        const blob = await data.blob();
  
        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "PartyWiswOrderBill.xlsx";
  
        // Append the link to the body
        document.body.appendChild(downloadLink);
  
        // Trigger the click event to start the download
        downloadLink.click();
  
        // Remove the link from the body
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.log(error.message);
      }
    };
  return (
    <>
      <Sidebar
        Title={"PARTYWISE ORDER/BILL"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} columnGap={2} direction="row" mt={5} mb={4}>
              <Autocomplete
                id="area"
                value={areainput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setareainput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.techid) === parseInt(value.techid)
                }
                options={areaopts}
                getOptionLabel={(option) => option.destname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(params) => (
                  <TextField required {...params} placeholder="Select Area" />
                )}
                size="small"
              />
              <Autocomplete
                id="category"
                value={itemcatginput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setitemcatginput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.techid) === parseInt(value.techid)
                }
                options={itemcatgopts}
                getOptionLabel={(option) => option.groupname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder="Select Item Catg"
                  />
                )}
                size="small"
              />
              <Autocomplete
                id="category"
                value={partycatginput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setpartycatginput(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.techid) === parseInt(value.techid)
                }
                options={partycatgopts}
                getOptionLabel={(option) => option.subledgercatgname}
                sx={{
                  width: "20% ",
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder="Select Party Catg"
                  />
                )}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                disabled={loading===true}
                variant="contained"
                size="small"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
               {loading ? "Loading..." : " Show"}
              </Button>
              <Button
                type="submit"
                onClick={printexcel}
                variant="contained"
                component="label"
                size="large"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                // endIcon={<Task />}
              >
                Excel
              </Button>
            </Stack>
            {pdfStream && (
              <ReportNavigation
                reportName={"Money Receipt Register"}
                onDownload={handleDownload}
              />
            )}
            <Box ref={myElementRef} sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default PartywiseOrderBill;
