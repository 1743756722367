import * as React from "react";
// import './Loginpage.css';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

toast.configure();

const theme = createTheme();

const Loginpage = () => {
  const [redirectToDashboardStatus, setRedirectToDashboardStatus] =
    useState(false);
  const [finyear, setFinyear] = useState([]);
  const [finId, setFinId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { username, password, finId };
      const response = await fetch("/auth/spareLogin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();

      if (parseRes.sptoken) {
        localStorage.setItem("sptoken", parseRes.sptoken);
        setRedirectToDashboardStatus(true);
        // props.history.push("/spareparts");
        toast.success("Logged In Successfully!!", { autoClose: 2000 });
      } else {
        //setRedirectToDashboardStatus(false);
        toast.error(parseRes, { autoClose: 2000 });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const OnRedirectToDashboard = () => {
    if (redirectToDashboardStatus === true) {
      return <Redirect exact to="/welcomepage" />;
    }
  };
  async function getFinyear() {
    try {
      const response = await fetch("/spareparts/finyear");
      const jsonData = await response.json();
      setFinyear(jsonData);
      
      // Set the default financial year to 2024-2025
      const defaultFinyear = jsonData.find(year => year.finname === '2024-2025');
      if (defaultFinyear) {
        setFinId(defaultFinyear.finyearid);
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getFinyear();
  }, []);

  const handleChange2 = (event) => {
    setFinId(event.target.value);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(https://picsum.photos/id/6/1920/1080/)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, mt: 10, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ marginTop: "5rem" }}
              >
                <FormControl margin="normal" fullWidth>
                  <InputLabel  id="finyear-label">Financial Year</InputLabel>
                  <Select
                    labelId="finyear-label"
                    id="finyear-select"
                    value={finId}
                    label="Finyear"
                    onChange={handleChange2}
                  >
                    {finyear.map((finyear) => (
                      <MenuItem
                        key={Object.values(finyear)[0]}
                        value={Object.values(finyear)[0]}
                      >
                        {Object.values(finyear)[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="userid"
                  label="User ID"
                  name="userid"
                  value={username}
                  onInput={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onInput={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Typography
        variant="p"
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          background:
            "linear-gradient(101deg, rgba(118,115,149,1) 9%, rgba(60,62,138,1) 35%, rgba(4,9,75,1) 94%)",
          color: "white",
          textAlign: " center",
          padding: "10px",
          overflow: "hidden",
        }}
      >
        Copyright © DGS INFOTECH 2022.
      </Typography>
      {OnRedirectToDashboard()}
    </>
  );
};

export default Loginpage;
