import React, { useEffect } from "react";
import {
  Box,
  Stack,
  Autocomplete,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";

import Task from "@mui/icons-material/Task";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

import IconButton from "@mui/material/IconButton";
import UploadFile from "@mui/icons-material/UploadFile";

import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import { toast } from "react-toastify";

// import { Redirect } from "react-router-dom";
toast.configure();

const ImportExcel = ({
  discrate,
  setrows,
  mode,
  setMissingArray,
  partyname,
  lubdiscrate,
  batterydiscrate,
  syntheticdiscrate,
  semisyntheticdiscrate,
  raceprodiscrate,
  enginepartspia,
  specialdis
}) => {
  const style = {
    position: "absolute",
    top: "30%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 700,
    p: 3,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const userToken = LocalstorageHelper.GetUserToken();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  //   const OPTIONS_LIMIT = 15;
  //   const filterOptions = createFilterOptions({
  //     limit: OPTIONS_LIMIT,
  //     trim: true,
  //   });
  //   const [pdfStream, setPdfStream] = useState(null);
  //   const handleReport = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const report = await fetch(
  //         `/reports/getpicklist/${searchInput.corderheaderid}/${sorttype}`
  //       )
  //         .then((response) => response.blob())
  //         .then((pdfBlob) => {
  //           const pdfUrl = URL.createObjectURL(pdfBlob);
  //           setPdfStream(pdfUrl);
  //         })
  //         .catch((error) => console.error(error));
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //   };
  //   const [searchInput,setSearchInput] = useState(null);
  //   const [param,setParam] = useState([]);
  //   async function getParam() {
  //     try {
  //       const response = await fetch("/reports/customerorderno", {
  //         method: "GET",
  //         headers: { sptoken: userToken },
  //       });
  //       const jsonData = await response.json();
  //       setParam(jsonData);
  //       // console.log(jsonData);
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   }

  //   const redirectToLoginPage = () => {
  //     if (!userToken) {
  //       return <Redirect exact to="/sparepartsLogin" />;
  //     }
  //   };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", file);
      const response = await fetch("/corderupload", {
        method: "POST",
        headers: { sptoken: userToken },
        body: data,
      });
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 2000 });
      setImportDisabled(false);
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleImport = async (e) => {
    e.preventDefault();
    try {
      const body = {
        discrate,
        lubdiscrate,
        batterydiscrate,
        syntheticdiscrate,
        semisyntheticdiscrate,
        raceprodiscrate,
        enginepartspia,
        specialdis
      };
      const response = await fetch(`/importexcel/${fileName}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      setrows(parseRes.orderdata);
      setMissingArray(parseRes.missingData ? parseRes.missingData : []);
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <Button
        sx={{ ml: "3%", mt: "5px", width: "150px" }}
        variant="outlined"
        disabled={parseInt(mode) === 2 ? true : false}
        size="small"
        onClick={() => {
          if (partyname === "") {
            return toast.error("Please Select a Party!", {
              toastId: 1,
            });
          }
          setOpen(true);
        }}
        startIcon={<AddCircleOutline />}
      >
        IMPORT EXCEL
      </Button>
      <Modal
        open={open}
        aria-labelledby="modl-box"
        aria-describedby="search partitem"
      >
        <Box sx={style}>
          <Stack spacing={5} columnGap={2} direction="row" mt={2} ml={20}>
            <IconButton
              aria-label="upload picture"
              component="label"
              sx={{ color: "#e69b00" }}
            >
              <input
                type="file"
                hidden
                onChange={(e) => {
                  setFileName(e.target.files[0].name);
                  setFile(e.target.files[0]);
                  setDisabled(false);
                }}
              />
              <FolderOpenTwoTone sx={{ width: "45px", height: "45px" }} />
            </IconButton>
            <TextField
              disabled
              id="filename"
              label="File Name"
              variant="standard"
              value={fileName}
              type="text"
              sx={{
                width: "36%",
              }}
            />
          </Stack>
          <Stack spacing={20} ml={"12%"} mt={"30px"} direction="row">
            <Button
              disabled={disabled}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              component="label"
              color="success"
              size="large"
              sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
              endIcon={<UploadFile />}
            >
              UPLOAD
            </Button>
            <Button
              type="submit"
              disabled={importDisabled}
              onClick={handleImport}
              variant="contained"
              component="label"
              size="large"
              sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
              endIcon={<Task />}
            >
              IMPORT
            </Button>
          </Stack>

          <Box sx={{ mx: "40%", mb: "10px", mt: "20px" }}>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ImportExcel;
