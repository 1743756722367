import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { MenuBook } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { Stack, Typography, TextField, MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";

import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
toast.configure();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "white",
  border: "2px solid #C0C0C0",
  borderRadius: "2%",
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  "& button": { m: 1 },
  //  overflowY: "auto"
};

export default function MenuCtrlBtn({ user }) {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };

  const [btndisable, setBtndisable] = useState(true);
  const [childopen, setChildOpen] = useState(false);
  const handleChildOpen = () => {
    setChildOpen(true);
  };
  const handleChildClose = () => {
    setChildOpen(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //MAIN MENU HANDLE
  const [menuDisabled, setMenuDisabled] = useState(true);
  const [fetchsub, setFetchSub] = useState(false);
  const [fetchdefsub, setFetchDefSub] = useState(false);

  ///CHANGE THE SUBMENU ACCORDING TO MAIN MENU

  const handleMainMenu = (e) => {
    setMainMenuid(e.target.value);
    setFetchSub(true);
    setFetchDefSub(true);
    setBtndisable(false);
    setDisableDomain(true);
  };
  //SUBMENU HANDLE
  const [allsubmenus, setAllSubMenus] = useState([]);
  const [defaultsubmenu, setDefaultSubMenu] = useState([]);

  // ALL SUBMENUS

  async function getDefaultSubMenus() {
    try {
      const response = await fetch(`/getdefaultsubmenu/${mainmenuid}`, {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setDefaultSubMenu(jsonData);
      setFetchDefSub(false);
      console.log(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    if (fetchdefsub === true) getDefaultSubMenus();
  }, [fetchdefsub]);

  // USER SPECIFIC SUBMENUS

  async function getAllSubMenus() {
    try {
      const response = await fetch(
        `/getsubmenu/${mainmenuid}/${user.user_id}`,
        {
          method: "GET",
          headers: { token: userToken },
        }
      );
      const jsonData = await response.json();
      setAllSubMenus(jsonData);
      console.log(jsonData);
      setFetchSub(false);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    if (fetchsub === true) getAllSubMenus();
  }, [fetchsub]);
  console.log(allsubmenus);
  //DOMAIN HANDLE

  const [alldomains, setAllDomains] = useState([]);
  const [domainid, setDomainid] = useState("");
  const [disabledomain, setDisableDomain] = useState(false);

  const [allmainmenu, setAllMainMenu] = useState([]);
  const [mainmenuid, setMainMenuid] = useState("");
  const [fetchmainmenu, setFetchmainmenu] = useState(false);

  const handleDomain = (e) => {
    setDomainid(e.target.value);
    setMenuDisabled(false);
    setFetchmainmenu(true);
  };

  // GET MAIN MENUS

  async function getMainMenu() {
    try {
      const response = await fetch(`/getmainmenu/${domainid}`, {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setAllMainMenu(jsonData);
      setFetchmainmenu(false);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (fetchmainmenu === true) getMainMenu();
  }, [fetchmainmenu]);

  async function getDomain() {
    try {
      const response = await fetch(`/getdomain/${user.user_id}`, {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setAllDomains(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getDomain();
  }, []);

  const [submenulist, setSubMenuList] = useState([]);
  const handleChange3 = (e) => {
    console.log(e.target.value);
    const selectedId = e.target.value;

    // Use map to update the existing object in the array
    const updatedSubmenus = allsubmenus.map((opt) => {
      if (opt.submenuid === selectedId) {
        // Update the properties of the matching object
        return { ...opt, accessflag: !opt.accessflag };
      }
      return opt;
    });

    setAllSubMenus(updatedSubmenus);
    console.log(updatedSubmenus);
  };
  // const handleChange3 = (e) => {
  //   if (e.target.checked) {
  //     setSubMenuList([...submenulist, e.target.value]);
  //   }
  //   // if(submenulist.includes(e.target.value)){
  //   //   setSubMenuList(submenulist.filter((o)=> o !== e.target.value && e.target.checked),[...submenulist,e.target.value,false])
  //   // }
  //   else {
  //     setSubMenuList(submenulist.filter((o) => o !== e.target.value));
  //   }

  //   //     submenulist.push(e.target.value, e.target.checked);
  //   //     if(submenulist.includes(e.target.value)){
  //   // let index = submenulist.indexOf(e.target.value);

  //   //     }
  // };
  console.log(submenulist);
  const children = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: "60px",
        mt: 2,
        mb: 2,
      }}
    >
      {allsubmenus.map((opt) => (
        <FormControlLabel
          key={opt.submenuid}
          label={opt.submenuname}
          control={
            <Checkbox
              checked={opt.accessflag}
              key={opt.submenuid}
              value={opt.submenuid}
              // defaultChecked={false}
              onChange={handleChange3}
            />
          }
          // control={<Checkbox value={opt.submenuid} checked={submenulist.includes(opt.submenuid)} onChange={handleChange2} />}
        />
      ))}
    </Box>
  );

  // const children2 = (
  //   <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
  //     {defaultsubmenu.map((opt) => (
  //       <FormControlLabel
  //         key={opt.submenuid}
  //         label={opt.submenuname}
  //         control={
  //           <Checkbox
  //             key={opt.submenuid}
  //             value={opt.submenuid}
  //             defaultChecked={false}
  //             onChange={handleChange3}
  //           />
  //         }
  //       />
  //     ))}
  //   </Box>
  // );

  // SEND SUBMENU ARRAY

  const sendSubmenulist = async (e) => {
    e.preventDefault();
    try {
      const body = { submenulist:allsubmenus };
      const response = await fetch(
        `/updateusermenurole/${user.user_id}/${mainmenuid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", token: userToken },
          body: JSON.stringify(body),
        }
      );
      toast.success(await response.json(), { autoClose: 1000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //ToolTip
  const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div>
      <MyTooltip title="Menu Book" placement="top">
        <Button
          variant="contained"
          onClick={handleOpen}
          size="small"
          color="secondary"
        >
          <MenuBook />
        </Button>
      </MyTooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Stack
            spacing={3}
            sx={{
              height: "80px",
              width: "100%",
              backgroundImage:
                "linear-gradient(to bottom, #6d0d8c, #82409b, #9565a9, #a989b7, #bcadc3)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              color: "white",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", pt: 3 }}
            >
              {user.user_name}'s Menu Roles
            </Typography>
          </Stack>
          <Stack m={2} sx={{ pl: 4, pr: 4 }}>
            <Box
              sx={{
                "& .MuiTextField-root": { width: "20ch", mt: 4, mb: 4 },
              }}
              noValidate
            >
              <TextField
                id="selectmenudomain"
                select
                label="Select User Domain"
                value={domainid}
                disabled={disabledomain}
                onChange={handleDomain}
                variant="standard"
                sx={{ marginRight: "10ch" }}
              >
                {alldomains.map((option) => (
                  <MenuItem key={option.domain_id} value={option.domain_id}>
                    {option.domain_name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="selectmainmenu"
                select
                label="Select Main Menu"
                disabled={menuDisabled}
                value={mainmenuid}
                onChange={handleMainMenu}
                variant="standard"
              >
                {allmainmenu.map((option) => (
                  <MenuItem key={option.mainmenuid} value={option.mainmenuid}>
                    {option.mainmenuname}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <hr />
          </Stack>

          {allsubmenus.length > 0 ? (
            <Box
              sx={{
                "& .MuiTextField-root": { width: "20ch" },
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {children}
            </Box>
          ) : (
            <Box sx={{ mt: 2, mb: 2, textAlign: "center" }}>
              <Typography variant="p">
                Select Domain and Main Menu to View or Assign
                <br /> Sub Menu Roles
              </Typography>
            </Box>
          )}
          <Stack sx={{ ml: 4, mr: 4, mb: 4 }}>
            <Button
              disabled={btndisable}
              color="secondary"
              variant={"contained"}
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              onClick={sendSubmenulist}
            >
              Assign
            </Button>
            {/* <Modal
              hideBackdrop
              open={childopen}
              onClose={handleChildClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 450, p: 4 }}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { width: "19ch" },
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  {children2}
                </Box>
                <hr />
                <Stack direction={"row"}>
                  <Button
                    type="submit"
                    onClick={sendSubmenulist}
                    variant="contained"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    color="primary"
                  >
                    Send
                  </Button>
                  <Button
                    onClick={handleChildClose}
                    variant="contained"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Box>
            </Modal> */}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
