import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Stack,
  TextField,
} from "@mui/material";
import LoginModal from "../Dashboard/LoginModal";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./DashboardOp.css";
import EmojiTransportationTwoToneIcon from "@mui/icons-material/EmojiTransportationTwoTone";
import MiscellaneousServicesTwoToneIcon from "@mui/icons-material/MiscellaneousServicesTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import ConstructionTwoToneIcon from "@mui/icons-material/ConstructionTwoTone";
import "../landingpage.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FooterElement from '../footer/FooterElement';
import { Link, Redirect } from "react-router-dom";

toast.configure();

const DashboardOptions = () => {
  const [redirectToDashboardStatus, setRedirectToDashboardStatus] =
  useState(false);

  // const [loginModal, setloginModal] = useState(false);
  const [adminModal, setadminModal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onAdminSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { username, password };
      const response = await fetch("/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();

      if (parseRes.token) {
        localStorage.setItem("token", parseRes.token);
        setRedirectToDashboardStatus(true);
       toast.success("Logged In Successfully!!",{autoClose:2000});
      } 
      else {

    toast.error(parseRes,{autoClose:2000});
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const OnRedirectToDashboard = () => {
    if (redirectToDashboardStatus === true) {
      return <Redirect exact to="/admin" />;
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          // background:
          //   "linear-gradient(101deg, rgba(118,115,149,1) 9%, rgba(60,62,138,1) 35%, rgba(4,9,75,1) 94%)",
          background:" #4278D1",
          background: "-webkit-linear-gradient(top left, #4278D1, #0CACB7)",
          background: "-moz-linear-gradient(top left, #4278D1, #0CACB7)",
          background: "linear-gradient(to bottom right, #4278D1, #0CACB7)",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           PIONEER SALES AND SERVICES
          </Typography>
          <Button
            color="inherit"
            onClick={() => setadminModal(true)}
            className="hoveradmin"
          >
            Admin Login
          </Button>
        </Toolbar>
      </AppBar>
      <div className="container-fluid">
        <div className="container">
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>01</h2>
                <h3>
                  
                  <EmojiTransportationTwoToneIcon
                    sx={{ width: "30px", height: "30px" }}
                  />
                  ShowRoom
                </h3>
                <p>
                  To Access the Showroom Cataegory please click on the (CLICK ON
                  ME) Option and Login your Details.{" "}
                </p>
                <Link className="butn" to="/showroomLogin">
                  Click on Me
                </Link>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="box">
              <div className="content">
                <h2>02</h2>
                <h3>
                 
                  <MiscellaneousServicesTwoToneIcon
                    sx={{ width: "30px", height: "30px" }}
                  />{" "}
                  Services
                </h3>
                <p>
                  To Access the Services Cataegory please click on the (CLICK ON
                  ME) Option and Login your Details.{" "}
                </p>
                <a className="butn" href="http://localhost:3002">
                  Click on Me
                </a>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="box">
              <div className="content">
                <h2>03</h2>
                <h3>
                  {" "}
                  <ManageAccountsTwoToneIcon
                    sx={{ width: "30px", height: "30px" }}
                  />{" "}
                  Accounts
                </h3>
                <p>
                  To Access the Accounts Cataegory please click on the (CLICK ON
                  ME) Option and Login your Details.{" "}
                </p>
                <Link className="butn" to="/accountsLogin">
                  Click on Me
                </Link>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="box">
              <div className="content">
                <h2>04</h2>
                <h3>
                  {" "}
                  <ConstructionTwoToneIcon
                    sx={{ width: "30px", height: "30px" }}
                  />{" "}
                  SpareParts
                </h3>
                <p>
                  To Access the Spareparts Cataegory please click on the (CLICK
                  ON ME) Option and Login your Details.{" "}
                </p>
                <Link className="butn" to="/sparepartsLogin">
                  Click on Me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* admin login */}

      <LoginModal
        show={adminModal}
        set={setadminModal}
        onClose={() => setadminModal(false)}
      >
        <Stack
          component="form"
          sx={{
            width: "30ch",
            margin: "30px",
          }}
          spacing={5}
          noValidate
          autoComplete="off"
          onSubmit={onAdminSubmit}
        >
          <Avatar
            sx={{
              m: 1,
              bgcolor: "primary.main",
              margin: "auto",
              marginTop: "30px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>

          <TextField
            required
            label="Username"
            className="form-control"
            id="username"
            type="text"
            size="small"
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          />

          <TextField
            required
            label="Password"
            className="form-control"
            id="password"
            type="password"
            size="small"
            autoComplete="current-password"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
          />

          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </Stack>
      </LoginModal>

      <FooterElement/>
      {OnRedirectToDashboard()}
    </>
  );
};

export default DashboardOptions;