import {
  Box,
  Tabs,
  Tab,
  TextField,
  Stack,
  MenuItem,
  Button,
  styled,
  Badge,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
  createFilterOptions,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { tableCellClasses } from "@mui/material";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { DataGrid } from "@mui/x-data-grid";

const UnlockProvisionalMR = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [salesman, setsalesman] = useState(null);
  const [salesmanInput, setsalesmanInput] = useState([]);
  const [salesmanrows, setsalesmanrows] = useState([]);
  const [cashTotal, setCashTotal] = useState(0);
  const [chqTotal, setChqTotal] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const isUnlocked = true;
  const [finyear, setFinyear] = useState([]);
  const [finId, setFinId] = useState("");
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  /////SALESMAN///////
  async function getSalesmanData() {
    try {
      const res = await fetch("/admin/promr/salesman", {
        method: "GET",
        headers: {
          token: userToken,
        },
      });
      const data = await res.json();
      console.log(data);
      setsalesmanInput(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getSalesmanData();
  }, []);
  ////POPULATE DATAGRID////
  async function getPMRData2Admin() {
    try {
      const res = await fetch(
        `/admin/promr/list/${salesman.salesmanid}/${finId}`,
        {
          method: "GET",
          headers: {
            token: userToken,
          },
        }
      );
      const data = await res.json();
      console.log(data);
      setsalesmanrows(data);
      let cashAmtTotal = 0;
      let checkAmtTotal = 0;

      data.forEach((item) => {
        if (item.cash_amt) {
          cashAmtTotal += parseFloat(item.cash_amt);
          setCashTotal(cashAmtTotal);
        }

        if (item.chq_amt) {
          checkAmtTotal += parseFloat(item.chq_amt);
          setChqTotal(checkAmtTotal);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getPMRData2Admin();
  }, [salesman]);

  async function getFinYears() {
    try {
      const res = await fetch("/spareparts/finyear");
      const data = await res.json();
      console.log(data);
      setFinyear(data);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getFinYears();
  }, []);
  const columns = [
    {
      field: "serial_number",
      headerClassName: "super-app-theme--header",
      headerName: "Srl No",
      width: 60,
    },
    {
      field: "subledgername",
      headerClassName: "super-app-theme--header",
      headerName: "Party Name",
      width: 650,
    },
    {
      field: "recv_date",
      headerClassName: "super-app-theme--header",
      headerName: "Receive Date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString("en-GB"),
      width: 150,
    },
    {
      field: "recv_time",
      headerClassName: "super-app-theme--header",
      headerName: "Receive Time",
      width: 150,
    },

    {
      field: "cash_amt",
      headerClassName: "super-app-theme--header",
      headerName: "Cash Amt",
      width: 250,
    },
    {
      field: "chq_date",
      headerClassName: "super-app-theme--header",
      headerName: "Cheque Date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && new Date(value).toLocaleDateString("en-GB"),
      width: 100,
    },
    {
      field: "chq_no",
      headerClassName: "super-app-theme--header",
      headerName: "cheque No. ",
      width: 150,
    },
    {
      field: "chq_amt",
      headerClassName: "super-app-theme--header",
      headerName: "Cheque Amt",
      width: 250,
    },
  ];
  return (
    <>
      <AdminSidebar />
      <Box sx={{ ml: 15 }}>
        <Stack spacing={2} ml={10} mb={2} direction="row">
          <InputLabel id="finyear-label" size="small">
            <b>Financial Year:</b>
          </InputLabel>
          <Select
            sx={{ width: "300px" }}
            labelId="finyear-label"
            id="finyear-select"
            size="small"
            value={finId}
            label="Finyear"
            onChange={(e) => setFinId(e.target.value)}
          >
            {finyear.map((finyear) => (
              <MenuItem key={finyear.finyearid} value={finyear.finyearid}>
                {finyear.finname}
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="Salesman" size="small">
            <b>Salesman:</b>
          </InputLabel>
          <Autocomplete
            id="controlled-demo"
            value={salesman}
            filterOptions={filterOptions}
            onChange={(event, newValue) => {
              setsalesman(newValue);
            }}
            options={salesmanInput}
            getOptionLabel={(option) => option.salesmandetails}
            sx={{
              width: "30% ",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Salesman" />
            )}
          />
        </Stack>
        <Stack spacing={1} mb={1} direction="row">
          <Box
            sx={{
              width: 300,
              height:80,
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              padding: 2, // Adjust the padding as needed
              border: "1px solid #ccc", // Add border with a color of your choice
              backgroundColor: "#f0f0f0", // Add background color of your choice
            }}
          >
            <Typography fontWeight={"bold"} variant="subtitle1" sx={{ pr: 2 }}>
              Total Cash: {cashTotal}
            </Typography>
            <Typography fontWeight={"bold"} variant="subtitle1">
              Total Cheque: {chqTotal}
            </Typography>
          </Box>
        </Stack>
        <Stack spacing={1} mb={1} direction="row">
          <Box
            sx={{
              height: 350,
              width: "90%",
              position: "relative",
              mt: "20px",
              boxShadow: "2px 6px 15px #5a5a5a",
              // border: "0.5px solid black",
              // borderRadius: "5px",
              "& .super-app-theme--header": {
                // backgroundColor: "#3bb9ff",
                color: "#000",
              },
            }}
          >
            <DataGrid
              sx={{ border: "1px solid #000" }}
              rowHeight={25}
              headerHeight={40}
              checkboxSelection={true}
              showColumnRightBorder={true}
              rows={salesmanrows}
              columns={columns}
              getRowId={(row) => row.pmrid}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              // getRowClassName={(params) => {
              //   const chequeDate = new Date(params.row.chq_date); // Assuming params.row.nextduedate is a date string
              //   const currentDate = new Date();

              //   const timeDifferenceInMilliseconds =
              //     chequeDate - currentDate;
              //   const timeDifferenceInDays =
              //     timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
              //   if (
              //     timeDifferenceInDays >= 0 &&
              //     timeDifferenceInDays <= 7
              //   ) {
              //     // Apply the CSS class when the time difference is less than 3 days
              //     return "super-app-theme--warning";
              //   } else if (timeDifferenceInDays < 0) {
              //     return "super-app-theme--Rejected";
              //   } else {
              //     // Return an empty string for no additional class when the condition is not met
              //     return "";
              //   }
              // }}
            />
          </Box>
        </Stack>
        <Stack
          spacing={2}
          columnGap={6}
          direction="row"
          mt={6}
          pb={6}
          mx={"42%"}
        >
          <Button
            variant="contained"
            color="primary"
            size="medium"
            // onClick={handleClick}
            startIcon={isUnlocked ? <LockOpenIcon /> : <LockIcon />}
            sx={{
              boxShadow: "2px 2px 6px #404142",
              "&:hover": {
                animation: isUnlocked ? "LOCK 0.5s" : "UNLOCK 0.5s",
              },
            }}
          >
            UNLOCK
          </Button>
        </Stack>
      </Box>
      {redirectToLoginPage()}
    </>
  );
};

export default UnlockProvisionalMR;
