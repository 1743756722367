import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Stack, MenuItem, Autocomplete, Modal } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import ArrowCircleRightTwoTone from "@mui/icons-material/ArrowCircleRightTwoTone";
import ArrowCircleUpTwoTone from "@mui/icons-material/ArrowCircleUpTwoTone";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PlacingList from "./PlacingList";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const PurchaseOrder = () => {
  const [disableSave, setDisableSave] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   LocalstorageHelper.RemoveUserToken();
  // }, 3600000);
  // purchaseOrder FLAG  DROPDOWN VALUES AND HANDLER
  const [flags, setFlags] = useState(1);
  const Flags = [
    {
      value: 1,
      label: "Direct",
    },
    {
      value: 2,
      label: "Transit",
    },
    {
      value: 3,
      label: "Edit Mode",
    },
    {
      value: 4,
      label: "View",
    },
  ];
  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/inwardsearch", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  async function getviewParam() {
    try {
      const response = await fetch("/inwardsearch/view", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (parseInt(flags) === 3) {
      getParam();
    } else if (parseInt(flags) === 4) {
      getviewParam();
    }
  }, [flags]);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [editabledetails, setEditableDetails] = useState([]);
  // console.log(editabledetails);
  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`/purchaseupdate/${searchInput.inwardno}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      // console.log(jsonData);
      jsonData.forEach((item) => {
        const rackid = item.rackid;
        setrackinput((prevRackInput) => ({
          ...prevRackInput,
          [item.itemid]: {
            rackid: item.rackid,
            rackname: item.rackname,
          },
        }));
      });
      setPartyId(jsonData[0].partyid);
      setPurType(jsonData[0].type);
      setBillDate(jsonData[0].billdate);
      setBillAmount(jsonData[0].billamount);
      setRoundOff(jsonData[0].othercharges);
      setInwardDate(jsonData[0].inwarddate);
      setDBillno(jsonData[0].billno);
      setEditableDetails(jsonData);
      setTypedisabled(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET BILL Search Parameters //
  const [billopts, setBillOpts] = useState([]);
  async function getBillOpts() {
    try {
      const response = await fetch("/unqinvno", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setBillOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getBillOpts();
  }, []);

  //HOLD STATE OF BILL SEARCH INPUT

  const [billsearch, setBillSearch] = useState(null);
  //SET BILL SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  //   CLICKING ON BILL SEARCH EXECUTES THE FOLLOWING    //

  const [griddetails, setGridDetails] = useState([]);
  const handleBillSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`/griddetails/${billsearch.invno}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      if (jsonData) {
        setIsLoading(false);
      }
      setGridDetails(jsonData);
      setBillDate(jsonData[0].invdate);
      jsonData.forEach((item) => {
        const itemId = item.itemid;
        setrackinput((prevRackInput) => ({
          ...prevRackInput,
          [itemId]: {
            rackid: item.rackid,
            rackname: `${item.rackname}`,
          },
        }));
      });
      // console.log(jsonData[0].invdate);
    } catch (err) {
      console.log(err.message);
    }
  };

  ///************************************ NEED MODULATION GET FINYEAR  COMPANY AND BRANCH ID ***********************************\\\\\\\\\\\\\\\\\\\\
  const [finyear, setFinyear] = useState("");
  async function getFinyear() {
    try {
      const response = await fetch("/getLoginFin", {
        method: "GET",
        headers: { sptoken: userToken },
      });

      const parseRes = await response.json();
      setFinyear(parseRes.finyearid);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getFinyear();
    // eslint-disable-next-line
  }, []);
  //Get The companyid and branchid of Logged In User
  const [company, setCompany] = useState("");
  const [branch, setBranch] = useState("");
  async function getName() {
    try {
      const response = await fetch("/spareDashboard", {
        method: "GET",
        headers: { sptoken: userToken },
      });

      const parseRes = await response.json();
      setCompany(parseRes.companyid);
      setBranch(parseRes.branchid);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getName();
    // eslint-disable-next-line
  }, []);

  //////////**************************** STATES OF TEXT FIELDS ************************************\\\\\\
  const currentdate = new Date();
  const [billdate, setBillDate] = useState(null);
  const [inwarddate, setInwardDate] = useState(currentdate);

  //PURCHASE ORDER TYPE OPTIONS AND HANDLER
  const [purtype, setPurType] = useState("CR");

  const types = [
    {
      typevalue: "CS",
      label: "Cash",
    },
    {
      typevalue: "CR",
      label: "Credit",
    },
  ];

  // PARTY DROP DOWN DETAILS
  const [partyopts, setPartyOpts] = useState([]);
  const [partyid, setPartyId] = useState("");
  const filteredArrayforAddress = partyopts.filter(
    (opts) => opts.subledgerid === partyid
  );
  const address1 = filteredArrayforAddress.map((item) => item.address1);
  const address2 = filteredArrayforAddress.map((item) => item.address2);
  const address3 = filteredArrayforAddress.map((item) => item.address3);
  const statecode = filteredArrayforAddress.map((item) => item.statename);
  const arraystateid = filteredArrayforAddress.map((item) => item.stateid);
  const gstno = filteredArrayforAddress.map((item) => item.gstno);
  const stateid = arraystateid[0];
  async function getPartyOpts() {
    try {
      const response = await fetch("/partyopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      // console.log(jsonData);
      setPartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getPartyOpts();
  }, []);

  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 150,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 320,
      cellClassName: "custom-font-size",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "rackname",
      headerName: "Rack",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Autocomplete
          id={params.row.itemid}
          value={rackinput[params.row.itemid] || null}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            const findIndex = griddetails.findIndex(
              (opt) => opt.itemid === params.row.itemid
            );
            // Update the rackid and rackname in the data array
            griddetails[findIndex].rackid = newValue ? newValue.rackid : null;
            griddetails[findIndex].rackname = newValue
              ? newValue.rackname
              : null;

            // Update the rackinput state for the specific itemid
            setrackinput((prevRackInput) => ({
              ...prevRackInput,
              [params.row.itemid]: newValue,
            }));
          }}
          options={rackopts}
          getOptionLabel={(option) => option.rackname}
          renderInput={(params) => (
            <TextField
              sx={{
                width: 200,
              }}
              {...params}
              size="small"
            />
          )}
        />
      ),
    },
    {
      field: "categoryname",
      headerClassName: "super-app-theme--header",
      headerName: "Catg",
      width: 80,
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "BillQty",
      width: 100,
      type: "number",
    },
    {
      field: "exprecvqty",
      headerClassName: "super-app-theme--header",
      headerName: "RcvQty",
      width: 100,
      type: "number",
      editable: true,
    },
    {
      field: "rate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "defdisc",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      type: "number",
      width: 80,
      editable: true,
    },
    {
      field: "defdiscount",
      headerClassName: "super-app-theme--header",
      headerName: "Discount",
      width: 100,
      type: "number",
      valueGetter: (params) =>
        parseFloat((params.row.defdisc * params.row.value) / 100).toFixed(2),
    },
    {
      field: "deftaxamt",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amount",
      width: 120,
      type: "number",
      valueGetter: (params) =>
        parseFloat(
          params.row.value - (params.row.defdisc * params.row.value) / 100
        ).toFixed(2),
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 100,
      type: "number",
    },
    {
      field: "defgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GSTAmt",
      width: 100,
      type: "number",
      valueGetter: (params) =>
        parseFloat(
          (params.row.gstrate *
            (params.row.value -
              (params.row.defdisc * params.row.value) / 100)) /
            100
        ).toFixed(2),
    },
    {
      field: "defnetamt",
      headerClassName: "super-app-theme--header",
      headerName: "Netamt",
      width: 100,
      type: "number",
      valueGetter: (params) =>
        parseFloat(
          params.row.value -
            (params.row.defdisc * params.row.value) / 100 +
            (params.row.gstrate *
              (params.row.value -
                (params.row.defdisc * params.row.value) / 100)) /
              100
        ).toFixed(2),
    },
  ];

  const editmodecolumns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 60,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 150,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 320,
      cellClassName: "custom-font-size",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "rackname",
      headerName: "Rack",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Autocomplete
          id={params.row.itemid}
          value={rackinput[params.row.itemid] || null}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            const findIndex = editabledetails.findIndex(
              (opt) => opt.itemid === params.row.itemid
            );
            // Update the rackid and rackname in the data array
            editabledetails[findIndex].rackid = newValue
              ? newValue.rackid
              : null;
            editabledetails[findIndex].rackname = newValue
              ? newValue.rackname
              : null;

            // Update the rackinput state for the specific itemid
            setrackinput((prevRackInput) => ({
              ...prevRackInput,
              [params.row.itemid]: newValue,
            }));
          }}
          options={rackopts}
          getOptionLabel={(option) => option.rackname}
          renderInput={(params) => (
            <TextField
              sx={{
                width: 200,
              }}
              {...params}
              size="small"
            />
          )}
        />
      ),
    },
    {
      field: "categoryname",
      headerClassName: "super-app-theme--header",
      headerName: "Catg",
      width: 80,
    },
    {
      field: "billqty",
      headerClassName: "super-app-theme--header",
      headerName: "BillQty",
      width: 80,
      type: "number",
    },
    {
      field: "recvqty",
      headerClassName: "super-app-theme--header",
      headerName: "RcvQty",
      width: 80,
      type: "number",
      //editable: true,
    },
    {
      field: "rate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "disc",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      type: "number",
      width: 80,
      editable: true,
      valueGetter: (params) => {
        return params.row.discountrate;
      },
      valueSetter: (params) => {
        const disc = params.value;
        const discountrate = params.value;
        const discountamount = (params.value * params.row.value) / 100;
        const taxableamount = params.row.value - discountamount;
        const dtlgstamt = (params.row.gstrate * taxableamount) / 100;
        const netamount = taxableamount + dtlgstamt;
        let upd_obj;
        upd_obj = editabledetails.findIndex(
          (obj) =>
            parseInt(obj.inwarddetailid) === parseInt(params.row.inwarddetailid)
        );
        editabledetails[upd_obj].disc = disc;
        editabledetails[upd_obj].discountrate = discountrate;
        editabledetails[upd_obj].discountamount = discountamount;
        editabledetails[upd_obj].taxableamount = taxableamount;
        editabledetails[upd_obj].dtlgstamt = dtlgstamt;
        editabledetails[upd_obj].netamount = netamount;
        return {
          ...params.row,
          disc,
          discountrate,
          discountamount,
          taxableamount,
          dtlgstamt,
          netamount,
        };
      },
    },
    {
      field: "discountamount",
      headerClassName: "super-app-theme--header",
      headerName: "Discount",
      width: 100,
      type: "number",
    },
    {
      field: "taxableamount",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amount",
      width: 120,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 100,
      type: "number",
    },
    {
      field: "dtlgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GSTAmt",
      width: 100,
      type: "number",
    },
    {
      field: "netamount",
      headerClassName: "super-app-theme--header",
      headerName: "Netamt",
      width: 100,
      type: "number",
    },
  ];

  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [total, setTotal] = useState(0);
  const [totrcvqty, setTotRcvqty] = useState(0);
  const [tottaxamt, setTottaxamt] = useState(0);
  const [totgstamt, setTotgstamt] = useState(0);
  const [totnetamt, setTotnetamt] = useState(0);
  const [totvalue, setTotvalue] = useState(0);
  const [totdiscount, setTotdiscount] = useState(0);
  // CUSTOM FOOTER COMPONENT

  ///////////////////////////// ************************ EDIT MODE FOOTER AND TOTAL CALCULATION PROCESS *****************\\\\\\\\\\\\\\\\\\\
  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotqty, setNewtotqty] = useState(0);
  const [newtotrcvqty, setNewTotRcvqty] = useState(0);
  const [newtottaxamt, setnewTottaxamt] = useState(0);
  const [newtotgstamt, setnewTotgstamt] = useState(0);
  const [newtotnetamt, setnewTotnetamt] = useState(0);
  const [newtotvalue, setnewTotvalue] = useState(0);
  const [newtotdiscount, setnewTotdiscount] = useState(0);

  // CUSTOM FOOTER COMPONENT

  // GET THE GRID DATA UPDATED ROW - TRANSIT CATEGORY
  const processRowUpdate = (newRow) => {
    if (newRow.defdisc !== "0.00") {
      const updatedRow = {
        ...newRow,
        discountval: (newRow.defdisc * newRow.value) / 100,
        taxableval: newRow.value - (newRow.defdisc * newRow.value) / 100,
        gstamtval:
          ((newRow.value - (newRow.defdisc * newRow.value) / 100) *
            newRow.gstrate) /
          100,
        netamtval:
          newRow.value -
          (newRow.defdisc * newRow.value) / 100 +
          ((newRow.value - (newRow.defdisc * newRow.value) / 100) *
            newRow.gstrate) /
            100,
      };
      setGridDetails(
        griddetails.map((row) =>
          row.transitid === newRow.transitid ? updatedRow : row
        )
      );
      return updatedRow;
    } else {
      const updatedRow = { ...newRow };
      setGridDetails(
        griddetails.map((row) =>
          row.transitid === newRow.transitid ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  const editedDetails = Object.values(emodedetails);

  // SUBMIT TRANSIT DETAILS
  const [distrnsitbtn, setdistrnsitbtn] = useState(false);
  const handleTransitSubmit = async (e) => {
    e.preventDefault();
    setdistrnsitbtn(true);
    try {
      const body = {
        griddetails,
        billdate,
        inwarddate,
        purtype,
        company,
        branch,
        finyear,
        partyid,
        stateid,
        tottaxamt,
        totgstamt,
        totnetamt,
        totvalue,
        totdiscount,
        billamount,
      };
      const response = await fetch(`/placetranspurorder/${billsearch.invno}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      toast.success(
        `Inward Code ${parseRes.inwardno} Generated Successfully!`,
        {
          autoClose: false,
          onClose: () => window.location.reload(false),
        }
      );
      // setdistrnsitbtn(false);
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 3000);
      // setdistrnsitbtn(false);
    } catch (err) {
      console.log(err.message);
      setdistrnsitbtn(false);
    }
  };
  /// SUBMIT EDITMODE DETAILS
  const [disupdatebtn, setdisupdatebtn] = useState(false);
  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    setdisupdatebtn(true);
    // console.log(stateid[0]);
    try {
      const body = {
        editedDetails,
        newtotqty,
        newtotrcvqty,
        newtottaxamt,
        newtotgstamt,
        newtotnetamt,
        newtotvalue,
        newtotdiscount,
        inwarddate,
        partyid,
        billamount,
        stateid,
        dbillno,
      };
      const response = await fetch(`/editedpurorder/${searchInput.inwardno}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
      }
    } catch (err) {
      console.log(err.message);
      setdisupdatebtn(false);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [billdisabled, setBilldisabled] = useState(true);
  const [billbtndisabled, setBillbtndisabled] = useState(true);
  const [typedisabled, setTypedisabled] = useState(false);

  //----------- Modal Handler-------\\

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [partno, setpartno] = useState(null);
  const [itemqty, setitemqty] = useState(0);
  const [rate, setRate] = useState(0);
  const [value, setValue] = useState(0);
  const [discpers, setDiscpers] = useState(0);
  const [discAmt, setDiscAmt] = useState(0);
  const [allparts, setallparts] = useState([]);
  const quantityRef = useRef(null);
  useEffect(() => {
    if (partno) {
      quantityRef.current.focus();
    }
  }, [partno]);

  async function getallparts() {
    try {
      const response = await fetch("/purchase/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
      // jsonData.forEach((item) => {
      //   const rackid = item.rackid;
      //   setrackinput((prevRackInput) => ({
      //     ...prevRackInput,
      //     [item.itemid]: {
      //       rackid: item.rackid,
      //       rackname: item.rackname,
      //     },
      //   }));
      // });
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);

  const rateRef = useRef(null);
  async function handleKeyDown1(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === rateRef.current) {
        setValue(parseFloat(itemqty * rate).toFixed(2));
      }
    }
  }
  const discRef = useRef(null);
  async function handleKeyDown2(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === discRef.current) {
        setDiscAmt(parseFloat((value * discpers) / 100).toFixed(2));
      }
    }
  }
  const discamtRef = useRef(null);
  async function handleKeyDown3(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === discamtRef.current) {
        handleaddrow();
      }
    }
  }
  // DIRECT PURCHASE TOTAL VALUES \\
  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [dirtotqty, setDirTotQty] = useState(0);
  const [dirtotdiscamt, setDirTotDiscamt] = useState(0);
  const [dirtottaxamt, setDirTotTaxamt] = useState(0);
  const [dirtotgstamt, setDirTotGstamt] = useState(0);
  const [dirtotnetamt, setDirTotNetamt] = useState(0);
  const [dirtotvalue, setDirTotvalue] = useState(0);
  // CUSTOM FOOTER COMPONENT
  const handleDeleteClick = (params) => () => {
    if (window.confirm("Are you sure you want to remove the item?") === true) {
      if (flags === 1) {
        console.log(params);
        setrows(rows.filter((row) => row.itemid !== params));
      } else {
        setEditableDetails(
          editabledetails.filter(
            (row) => parseInt(row.itemid) !== parseInt(params.row.itemid)
          )
        );
      }
    }
  };
  //rack data
  const [rackinput, setrackinput] = useState({});
  console.log(rackinput);
  const [rackopts, setrackopts] = useState([]);

  async function getrackdata() {
    try {
      const response = await fetch("/stktransfer/rack", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsondata = await response.json();
      setrackopts(jsondata);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    getrackdata();
  }, []);
  const directcolumns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 50,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 50,
    },
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 300,
      cellClassName: "custom-font-size",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "rackname",
      headerName: "Rack",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Autocomplete
          id={params.row.itemid}
          value={rackinput[params.row.itemid] || null}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            // console.log(rows);
            const findIndex = rows.findIndex(
              (opt) => opt.itemid === params.row.itemid
            );
            // Update the rackid and rackname in the data array
            rows[findIndex].rackid = newValue ? newValue.rackid : null;
            rows[findIndex].rackname = newValue ? newValue.rackname : null;

            // Update the rackinput state for the specific itemid
            setrackinput((prevRackInput) => ({
              ...prevRackInput,
              [params.row.itemid]: newValue,
            }));
          }}
          options={rackopts}
          getOptionLabel={(option) => option.rackname}
          renderInput={(params) => (
            <TextField
              sx={{
                width: 200,
              }}
              {...params}
              size="small"
            />
          )}
        />
      ),
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "BillQty",
      width: 80,
      type: "number",
    },
    {
      field: "rate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "discpers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      type: "number",
      width: 80,
      //editable: true,
    },
    {
      field: "discamt",
      headerClassName: "super-app-theme--header",
      headerName: "Discount",
      width: 100,
      type: "number",
    },
    {
      field: "taxamt",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amount",
      width: 120,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 80,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GSTAmt",
      width: 100,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Netamt",
      width: 100,
      type: "number",
    },
  ];

  const [rows, setrows] = useState([]);
  const [griddata, setgriddata] = useState([]);
  const griddataarray = Object.values(griddata);
  // console.log(griddataarray);
  const handleaddrow = () => {
    if (flags === 1) {
      if (itemqty < 1) {
        toast.error("Value can't be 0 or negative");
      } else if (rows.map((e) => e.itemid).includes(partno.itemid)) {
        toast.error("Item Added Already!");
      } else {
        // console.log(partno);
        setrackinput((prevRackInput) => ({
          ...prevRackInput,
          [partno.itemid]: {
            rackid: partno.rackid,
            rackname: partno.rackname,
          },
        }));
        const taxamt = parseFloat(value - discAmt).toFixed(2);
        const gstamt = parseFloat((taxamt * partno.gstrate) / 100).toFixed(2);
        const netamt = parseFloat(
          parseFloat(taxamt) + parseFloat(gstamt)
        ).toFixed(2);
        setrows((oldRows) => [
          ...oldRows,
          {
            serial: oldRows.length + 1,
            partno: partno.partdetails,
            gstrate: partno.gstrate,
            rate: parseFloat(rate).toFixed(2),
            itemid: partno.itemid,
            rackid: partno.rackid,
            rackname: partno.rackname,
            qty: parseFloat(itemqty).toFixed(2),
            discpers: parseFloat(discpers).toFixed(2),
            value: value,
            discamt: discAmt,
            taxamt: taxamt,
            gstamt: gstamt,
            netamt: netamt,
          },
        ]);
        setpartno(null);
        setitemqty(0);
        setValue(0);
        setDiscpers(0);
        setDiscAmt(0);
        setRate(0);
      }
    } else {
      if (itemqty < 1) {
        toast.error("Value can't be 0 or negative");
      } else if (editabledetails.map((e) => e.itemid).includes(partno.itemid)) {
        toast.error("Item Added Already!");
      } else {
        // console.log(partno);
        setrackinput((prevRackInput) => ({
          ...prevRackInput,
          [partno.itemid]: {
            rackid: partno.rackid,
            rackname: partno.rackname,
          },
        }));
        const taxamt = parseFloat(value - discAmt).toFixed(2);
        const gstamt = parseFloat((taxamt * partno.gstrate) / 100).toFixed(2);
        const netamt = parseFloat(
          parseFloat(taxamt) + parseFloat(gstamt)
        ).toFixed(2);
        setEditableDetails((oldRows) => [
          ...oldRows,
          {
            srlno: editabledetails.length + 1,
            inwarddetailid: editabledetails.length + 1,
            itemname: partno.itemname,
            itemcode: partno.itemcode,
            categoryname: partno.categoryname,
            gstrate: partno.gstrate,
            rate: parseFloat(rate).toFixed(2),
            itemid: partno.itemid,
            rackid: partno.rackid,
            rackname: partno.rackname,
            billqty: parseFloat(itemqty),
            recvqty: parseFloat(itemqty),
            disc: parseFloat(discpers).toFixed(2),
            discountrate: parseFloat(discpers).toFixed(2),
            value: value,
            discountamount: discAmt,
            taxableamount: taxamt,
            dtlgstamt: gstamt,
            netamount: netamt,
          },
        ]);
        setpartno(null);
        setitemqty(0);
        setValue(0);
        setDiscpers(0);
        setDiscAmt(0);
        setRate(0);
      }
    }
  };

  const [billamount, setBillAmount] = useState();
  const [calculatedbillamt, setCalculateBillAmt] = useState();
  const [roundoff, setRoundOff] = useState();
  useEffect(() => {
    if (parseInt(flags) !== 3 && parseInt(flags) !== 4) {
      const newbillamt = calculatedbillamt;
      setBillAmount(newbillamt);
    }
  }, [calculatedbillamt]);

  useEffect(() => {
    const calcRounOff = (
      parseFloat(billamount) - parseFloat(calculatedbillamt)
    ).toFixed(2);
    setRoundOff(calcRounOff);
  }, [billamount, calculatedbillamt]);

  const [dbillno, setDBillno] = useState("");
  const handleDirectSubmit = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      if (billamount === null || parseFloat(billamount) === 0) {
        toast.error("Please Enter the Bill Amount");
        setDisableSave(false);
      } else {
        const body = {
          griddataarray: rows,
          billdate,
          inwarddate,
          purtype,
          company,
          branch,
          finyear,
          partyid,
          stateid,
          dirtotvalue,
          dirtotdiscamt,
          dirtottaxamt,
          dirtotgstamt,
          dirtotnetamt,
          billamount,
        };
        const response = await fetch(`/placedirectpurorder/${dbillno}`, {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        if (response.status === 500) {
          toast.error(
            "Some Fields or RackNo. in one or many items are missing!"
          );
          return setDisableSave(false);
        }
        const parseRes = await response.json();
        if (parseRes.type === "success") {
          toast.success(parseRes.message, {
            autoClose: false,
            onClose: () => window.location.reload(false),
          });
        } else {
          toast.error(parseRes.message);
          setDisableSave(false);
        }
      }
    } catch (err) {
      console.log(err.message);
      setDisableSave(false);
    }
  };

  const resetStates = () => {
    setEditableDetails([]);
    setpartno(null);
    setitemqty(0);
    setValue(0);
    setDiscpers(0);
    setDiscAmt(0);
    setRate(0);
    setBillAmount(0);
    setRoundOff(0);
    setDBillno("");
    setPartyId(null);
    setBillDate(null);
    setSearchInput(null);
    // setCalculateBillAmt(0);
  };
  return (
    <div>
      <Sidebar
        Title={"PURCHASE BILL"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"35%"} mb={"40px"} direction="row">
              <Autocomplete
                disabled={searchdisabled}
                id="inwardno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.billno}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Inward No" />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={6} direction="row" mt={1} ml={4}>
              <TextField
                id="category"
                required
                select
                label="Category"
                value={flags}
                onChange={(e) => {
                  setFlags(e.target.value);
                  if (e.target.value === 3 || e.target.value === 4) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setTypedisabled(true);
                    resetStates();
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    resetStates();
                  }
                  if (e.target.value === 2) {
                    setBilldisabled(false);
                    setBillbtndisabled(false);
                    setTypedisabled(false);
                    resetStates();
                  } else {
                    setBilldisabled(true);
                    setBillbtndisabled(true);
                    resetStates();
                  }
                  if (e.target.value === 1) {
                    setTypedisabled(false);
                    resetStates();
                  }
                }}
                // helperText="Please select 'Direct' or 'Transit'."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Flags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="dbillno"
                disabled={
                  parseInt(flags) === 1 || parseInt(flags) === 3 ? false : true
                }
                label="Bill No"
                value={dbillno}
                onChange={(e) => setDBillno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <PlacingList />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                <Autocomplete
                  disabled={billdisabled}
                  id="billsearch"
                  value={billsearch}
                  filterOptions={filterOptions2}
                  onChange={(event, newValue) => {
                    setBillSearch(newValue);
                  }}
                  options={billopts}
                  getOptionLabel={(option) => option.invno}
                  sx={{
                    width: "85% ",
                  }}
                  renderInput={(billopts) => (
                    <TextField required {...billopts} placeholder="Bill No" />
                  )}
                  size="small"
                />
                <Button
                  aria-label="search"
                  disabled={billbtndisabled}
                  type="search"
                  variant="outlined"
                  sx={{
                    "&:hover": {
                      backgroundColor: "#673AB7",
                    },
                    backgroundColor: "purple",
                    height: "64%",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  onClick={handleBillSearch}
                >
                  <BillSearch sx={{ color: "white" }} />
                </Button>
              </Stack>

              <TextField
                id="party"
                required
                select
                label="Party"
                value={partyid}
                onChange={(e) => setPartyId(e.target.value)}
                // helperText="Please select the Party Name ."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {partyopts.map((option) => (
                  <MenuItem key={option.subledgerid} value={option.subledgerid}>
                    {option.subledgername}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="address1"
                label="Address 1"
                value={address1}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address2"
                label="Address 2"
                value={address2}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="address 3"
                label="Address 3"
                value={address3}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="type"
                required
                disabled={typedisabled}
                select
                label="Type"
                value={purtype}
                onChange={(e) => setPurType(e.target.value)}
                // helperText="Please select 'Debit' or 'Credit'."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {types.map((option) => (
                  <MenuItem key={option.typevalue} value={option.typevalue}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Bill Date"
                  disabled={parseInt(flags) !== 1 ? true : false}
                  inputFormat="DD-MM-YYYY"
                  value={billdate}
                  onChange={(newValue) => {
                    setBillDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled
                  label="Inward Date"
                  inputFormat="DD-MM-YYYY"
                  value={inwarddate}
                  onChange={(newValue) => {
                    setInwardDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="stateCode"
                required
                label="State Code"
                value={statecode}
                // helperText="Please select State Code."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              ></TextField>

              <TextField
                id="billAmount"
                label="Bill Amount"
                value={billamount}
                onChange={(e) => setBillAmount(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
                InputLabelProps={{
                  shrink: !!billamount, // Set shrink to true if the value is not empty
                }}
              />
              <TextField
                id="calcbillAmount"
                label="Calculated Amount"
                value={calculatedbillamt}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
                InputLabelProps={{
                  shrink: !!calculatedbillamt, // Set shrink to true if the value is not empty
                }}
              />
              <TextField
                id="roundoff"
                label="Round Off"
                value={roundoff}
                // onChange={(e) => setRoundOff(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
                InputLabelProps={{
                  shrink: !!roundoff, // Set shrink to true if the value is not empty
                }}
              />
              <TextField
                id="gstNo"
                label="GST No"
                value={gstno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <hr />

            {/********************TRANSIT GRID TABLE************************/}
            {flags === 1 ? (
              <>
                <Stack columnGap={3} direction="row" mt={1} mb={1}>
                  <Button
                    sx={{ ml: "3%", mt: "5px" }}
                    variant="outlined"
                    size="small"
                    onClick={handleOpen}
                    startIcon={<AddCircleOutline />}
                  >
                    ADD A NEW ROW
                  </Button>

                  <Modal
                    open={open}
                    aria-labelledby="modl-box"
                    aria-describedby="search partitem"
                  >
                    <Box sx={style}>
                      <Stack
                        columnGap={4}
                        direction="row"
                        component="form"
                        sx={{
                          width: 750,
                          margin: "auto",
                          p: 3,
                        }}
                        autoComplete="off"
                      >
                        <Autocomplete
                          id="partno"
                          value={partno}
                          filterOptions={filterOptions}
                          onChange={(event, newValue) => {
                            setpartno(newValue);
                            //console.log(newValue);
                          }}
                          options={allparts}
                          getOptionLabel={(option) => option.partdetails}
                          sx={{
                            width: "30% ",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Part No"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />

                        <TextField
                          id="quantity"
                          label="Quantity"
                          value={itemqty}
                          inputRef={quantityRef}
                          onChange={(e) => setitemqty(e.target.value)}
                          type="number"
                          sx={{
                            width: "30%",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          size="small"
                        />
                        <TextField
                          id="rate"
                          label="Rate"
                          value={rate}
                          inputRef={rateRef}
                          onKeyDown={handleKeyDown1}
                          onChange={(e) => setRate(e.target.value)}
                          type="number"
                          sx={{
                            width: "30%",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          size="small"
                        />
                      </Stack>
                      <Stack
                        columnGap={4}
                        direction="row"
                        component="form"
                        sx={{
                          width: 750,
                          margin: "auto",
                          p: 3,
                        }}
                        autoComplete="off"
                      >
                        <TextField
                          id="value"
                          label="Value"
                          value={value}
                          inputProps={{ tabIndex: "-1" }}
                          type="number"
                          sx={{
                            width: "30%",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          size="small"
                        />
                        <TextField
                          id="discpers"
                          label="Disc%"
                          value={discpers}
                          inputRef={discRef}
                          onKeyDown={handleKeyDown2}
                          onChange={(e) => setDiscpers(e.target.value)}
                          type="number"
                          sx={{
                            width: "30%",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          size="small"
                        />
                        <TextField
                          id="discamt"
                          label="Discount Amount"
                          value={discAmt}
                          inputRef={discamtRef}
                          onKeyDown={handleKeyDown3}
                          onChange={(e) => setDiscAmt(e.target.value)}
                          type="number"
                          sx={{
                            width: "30%",
                            boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                          }}
                          size="small"
                        />
                      </Stack>
                      <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>
                        <Button
                          variant="contained"
                          tabIndex={-1}
                          size="medium"
                          color="success"
                          onClick={handleaddrow}
                        >
                          ADD
                        </Button>
                        <Button
                          tabIndex={-1}
                          variant="contained"
                          size="medium"
                          onClick={handleClose}
                          color="error"
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>
                </Stack>
                <Box
                  sx={{
                    height: 550,
                    width: "100%",
                    position: "relative",
                    mt: "15px",
                    boxShadow: "2px 6px 15px #5a5a5a",
                    // border: "0.5px solid black",
                    // borderRadius: "5px",
                    "& .super-app-theme--header": {
                      backgroundColor: "#3bb9ff",
                      color: "#000",
                    },
                    "& .custom-font-size": {
                      fontSize: 13, // Adjust the font size as needed
                    },
                  }}
                >
                  <DataGrid
                    sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    }, }}
                    rowHeight={35}
                    headerHeight={30}
                    showColumnRightBorder={true}
                    rows={rows}
                    columns={directcolumns}
                    pageSize={100}
                    getRowId={(row) => row.itemid}
                    onStateChange={(params) => {
                      setgriddata(params.rows.idRowsLookup);
                      const totqty = rows
                        .map((item) => parseFloat(item.qty))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotQty(totqty);
                      const totvalue = rows
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotvalue(totvalue);
                      const totdiscamt = rows
                        .map((item) => parseFloat(item.discamt))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotDiscamt(totdiscamt);
                      const tottaxamt = rows
                        .map((item) => parseFloat(item.taxamt))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotTaxamt(tottaxamt);
                      const totgstamt = rows
                        .map((item) => parseFloat(item.gstamt))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotGstamt(totgstamt);
                      const totnetamt = rows
                        .map((item) => parseFloat(item.netamt))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setDirTotNetamt(totnetamt);
                      setCalculateBillAmt(totnetamt);
                    }}
                  />
                  <Box sx={{ padding: "10px", display: "flex" }}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Qty
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Discount
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Taxable
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total GST
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Net Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {dirtotqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {" "}
                            {dirtotvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {dirtotdiscamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {dirtottaxamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {dirtotgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {dirtotnetamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    mt={6}
                    mx={"40%"}
                  >
                    <Button
                      type="submit"
                      onClick={handleDirectSubmit}
                      disabled={disableSave}
                      variant="contained"
                      color="success"
                      size="medium"
                      sx={{
                        boxShadow: "2px 2px 6px  #404142",
                        py: 2,
                        px: 3,
                        m: 2,
                      }}
                      endIcon={<ArrowCircleRightTwoTone />}
                    >
                      SAVE Bill
                    </Button>
                  </Stack>
                </Box>
              </>
            ) : (
              <></>
            )}
            {flags === 2 ? (
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <DataGrid
                  sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                    outline: "2px solid red", // Change this to the desired color
                    backgroundColor: "gray",
                    color: "white",
                  }, }}
                  rowHeight={35}
                  headerHeight={40}
                  showColumnRightBorder={true}
                  rows={griddetails}
                  columns={columns}
                  pageSize={100}
                  getRowId={(row) => row.transitid}
                  experimentalFeatures={{ newEditingApi: true }}
                  loading={isLoading}
                  processRowUpdate={processRowUpdate}
                  onStateChange={() => {
                    const qtytotal = griddetails
                      .map((item) => parseFloat(item.qty))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotal(qtytotal);

                    const rcvtotal = griddetails
                      .map((item) => parseFloat(item.exprecvqty))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotRcvqty(rcvtotal);

                    const valtotal = griddetails
                      .map((item) => parseFloat(item.value))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotvalue(valtotal);

                    const taxbltotal = griddetails
                      .map((item) =>
                        parseFloat(
                          item.taxableval ? item.taxableval : item.deftaxamt
                        )
                      )
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTottaxamt(taxbltotal);
                    const gsttotal = griddetails
                      .map((item) =>
                        parseFloat(
                          item.gstamtval ? item.gstamtval : item.defgstamt
                        )
                      )
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotgstamt(gsttotal);
                    const nettotal = griddetails
                      .map((item) =>
                        parseFloat(
                          item.netamtval ? item.netamtval : item.defnetamt
                        )
                      )
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotnetamt(nettotal);
                    setCalculateBillAmt(nettotal);
                    const dcounttotal = griddetails
                      .map((item) =>
                        parseFloat(
                          item.discountval ? item.discountval : item.defdiscount
                        )
                      )
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setTotdiscount(dcounttotal);
                  }}
                />
                <Box sx={{ padding: "10px", display: "flex" }}>
                  <Table
                    sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#3bb9ff",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total Bill
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total RecievedQty
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total Value
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total DiscountAmt
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total TaxableAmt
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total GSTAmt
                        </TableCell>
                        <TableCell
                          sx={{ color: "#000", border: "0.5px solid black" }}
                          align="center"
                        >
                          Total NetAmt
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {total}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {" "}
                          {totrcvqty}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {totvalue}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {totdiscount}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {tottaxamt}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {totgstamt}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", border: "0.5px solid black" }}
                        >
                          {" "}
                          {totnetamt}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  mx={"40%"}
                >
                  <Button
                    type="submit"
                    onClick={handleTransitSubmit}
                    disabled={distrnsitbtn}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142", py: 2, px: 3 }}
                    endIcon={<ArrowCircleRightTwoTone />}
                  >
                    SAVE CHANGES
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {flags === 3 || parseInt(flags) === 4 ? (
              <>
                {flags === 3 && (
                  <Button
                    sx={{
                      ml: "3%",
                      mt: "5px",
                      display: editabledetails[0]?.transitflag ? "none" : "",
                    }}
                    variant="outlined"
                    size="small"
                    onClick={handleOpen}
                    startIcon={<AddCircleOutline />}
                  >
                    ADD A NEW ROW
                  </Button>
                )}
                <Modal
                  open={open}
                  aria-labelledby="modl-box"
                  aria-describedby="search partitem"
                >
                  <Box sx={style}>
                    <Stack
                      columnGap={4}
                      direction="row"
                      component="form"
                      sx={{
                        width: 750,
                        margin: "auto",
                        p: 3,
                      }}
                      autoComplete="off"
                    >
                      <Autocomplete
                        id="partno"
                        value={partno}
                        filterOptions={filterOptions}
                        onChange={(event, newValue) => {
                          setpartno(newValue);
                          //console.log(newValue);
                        }}
                        options={allparts}
                        getOptionLabel={(option) => option.partdetails}
                        sx={{
                          width: "30% ",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Part No"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />

                      <TextField
                        id="quantity"
                        label="Quantity"
                        value={itemqty}
                        inputRef={quantityRef}
                        onChange={(e) => setitemqty(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="rate"
                        label="Rate"
                        value={rate}
                        inputRef={rateRef}
                        onKeyDown={handleKeyDown1}
                        onChange={(e) => setRate(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      columnGap={4}
                      direction="row"
                      component="form"
                      sx={{
                        width: 750,
                        margin: "auto",
                        p: 3,
                      }}
                      autoComplete="off"
                    >
                      <TextField
                        id="value"
                        label="Value"
                        value={value}
                        inputProps={{ tabIndex: "-1" }}
                        type="number"
                        sx={{
                          width: "30%",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="discpers"
                        label="Disc%"
                        value={discpers}
                        inputRef={discRef}
                        onKeyDown={handleKeyDown2}
                        onChange={(e) => setDiscpers(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="discamt"
                        label="Discount Amount"
                        value={discAmt}
                        inputRef={discamtRef}
                        onKeyDown={handleKeyDown3}
                        onChange={(e) => setDiscAmt(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                          boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                        }}
                        size="small"
                      />
                    </Stack>
                    <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>
                      <Button
                        variant="contained"
                        tabIndex={-1}
                        size="medium"
                        color="success"
                        onClick={handleaddrow}
                      >
                        ADD
                      </Button>
                      <Button
                        tabIndex={-1}
                        variant="contained"
                        size="medium"
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Box>
                </Modal>
                <Box
                  sx={{
                    height: 500,
                    width: "100%",
                    mt: "20px",
                    boxShadow: "2px 6px 15px #5a5a5a",
                    // border: "0.5px solid black",
                    // borderRadius: "5px",
                    "& .super-app-theme--header": {
                      backgroundColor: "#3bb9ff",
                      color: "#000",
                    },
                    "& .custom-font-size": {
                      fontSize: 13, // Adjust the font size as needed
                    },
                  }}
                >
                  <DataGrid
                    sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    }, }}
                    rowHeight={35}
                    headerHeight={40}
                    pageSize={100}
                    showColumnRightBorder={true}
                    rows={editabledetails}
                    columns={editmodecolumns}
                    getRowId={(row) => row.inwarddetailid}
                    loading={isLoading}
                    onStateChange={(params) => {
                      setEmodeDetails(params.rows.idRowsLookup);
                      const qtytotal = editedDetails
                        .map((item) => parseFloat(item.billqty))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setNewtotqty(qtytotal);

                      const rcvtotal = editedDetails
                        .map((item) => parseFloat(item.recvqty))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setNewTotRcvqty(rcvtotal);

                      const valtotal = editedDetails
                        .map((item) => parseFloat(item.value))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setnewTotvalue(valtotal);

                      const taxbltotal = editedDetails
                        .map((item) => parseFloat(item.taxableamount))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setnewTottaxamt(taxbltotal);
                      const gsttotal = editedDetails
                        .map((item) => parseFloat(item.dtlgstamt))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setnewTotgstamt(gsttotal);
                      const nettotal = editedDetails
                        .map((item) => parseFloat(item.netamount))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setnewTotnetamt(nettotal);
                      setCalculateBillAmt(nettotal);
                      const dcounttotal = editedDetails
                        .map((item) => parseFloat(item.discountamount))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2);
                      setnewTotdiscount(dcounttotal);
                    }}
                  />
                  <Box sx={{ padding: "10px", display: "flex" }}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Bill
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total RecievedQty
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total Value
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total DiscountAmt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total TaxableAmt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total GSTAmt
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Total NetAmt
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotrcvqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotvalue}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotdiscount}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtottaxamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotgstamt}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "#000", border: "0.5px solid black" }}
                          >
                            {newtotnetamt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  {parseInt(flags) === 3 ? (
                    <>
                      <Stack
                        spacing={2}
                        columnGap={6}
                        direction="row"
                        mt={6}
                        p={2}
                        mx={"40%"}
                      >
                        <Button
                          type="submit"
                          disabled={disupdatebtn}
                          onClick={handleEditmodeSubmit}
                          variant="contained"
                          color="primary"
                          size="large"
                          sx={{
                            boxShadow: "2px 2px 6px  #404142",
                            py: 2,
                            px: 3,
                          }}
                          endIcon={<ArrowCircleUpTwoTone />}
                        >
                          UPDATE CHANGES
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default PurchaseOrder;
