import { Edit } from "@mui/icons-material";
import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tooltip,{ tooltipClasses } from '@mui/material/Tooltip';
toast.configure();
const EditButton = ({ user }) => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "2%",
    boxShadow:"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };
  const [username, setUsername] = useState(user.user_name);
  const [password, setPassword] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUsername(user.user_name);
    setPassword("");
  };
  //edit Data Function

  const updateDetails = async(e) => {
    e.preventDefault();
    try {
      const body = { username, password };
      const response = await fetch(`/user/${user.user_id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" , token: userToken},
        body: JSON.stringify(body),
      });
      // console.log(response);
     toast.success("Edited Data!!",{autoClose:1000});
     setTimeout(() => {
        window.location = "/admin";
      }, 2000);
    window.location = "/admin"
    } catch (err) {
      console.error(err.message);
    }
  };

  //ToolTip
  const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div>
         <MyTooltip  title="Edit"   placement="top">
      <Button variant="contained" size="small" onClick={handleOpen}>
        <Edit />
      </Button>
      </MyTooltip>
      <Modal
        open={open}
        aria-labelledby="Edit User"
        aria-describedby="edit username password and update"
        
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: "30ch",
              margin: "auto",
              p: 4
            }}
            spacing={5}
            noValidate
            autoComplete="off"
            
          >
            <TextField
              id="username"
              label="UserName"
              variant="standard"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              variant="standard"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={e=> updateDetails(e)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      {redirectToLoginPage()}
    </div>
  );
};

export default EditButton;
