import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Skeleton,
  TextField,
  Stack,
  MenuItem,
  IconButton,
  Typography,
  Modal,
  Switch,
  InputAdornment,
} from "@mui/material";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import PrintIcon from "@mui/icons-material/Print";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

///MODAL STYLE

const style = {
  position: "absolute",
  top: "40%",
  left: "54%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #C0C0C0",
  borderRadius: "10px",
  pb: 3,
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  "& button": { m: 1 },
};

///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const SpareLandingItemMaster = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);

  //STATES OF TEXTFIELDS
  const [itemCode, setItemCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [newModePartNo, setNewModePartNo] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [mrp, setMrp] = useState(0);
  const [mrp1, setMrp1] = useState(0);
  const [mrp2, setMrp2] = useState(0);
  const [mrp3, setMrp3] = useState(0);
  const [gst, setGst] = useState(0);
  const [max, setmax] = useState(0);
  const [min, setmin] = useState(0);
  const [ndp, setndp] = useState(0);
  const [reorder, setreorder] = useState(0);
  const [convert, setconvert] = useState(0);
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  //CONVERT VALUES
  const mrpValue = parseFloat(mrp);
  const gstValue = parseFloat(gst);
  const saleRate = (100 * mrpValue) / (100 + gstValue);
  const saleValue = saleRate.toFixed(2);

  const maxFloat = parseFloat(max);
  const maxLevel = maxFloat.toFixed(0);

  const minFloat = parseFloat(min);
  const minLevel = minFloat.toFixed(0);

  const reorderFloat = parseFloat(reorder);
  const reorderLevel = reorderFloat.toFixed(0);

  const ndpFloat = parseFloat(ndp);
  const ndpRate = ndpFloat.toFixed(2);

  const convertFloat = parseFloat(convert);
  const convertQty = convertFloat.toFixed(2);

  //  GET CATEGORY FOR DROPDOWN //

  const [category, setCategory] = useState([]);
  const [catgId, setCatgId] = useState("");
  const handleCatg = (event) => {
    setCatgId(event.target.value);
  };
  async function getCategory() {
    try {
      const response = await fetch("/getcategory", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setCategory(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getCategory();
  }, []);

  // GET UOM  FOR DROPDOWN  //

  const [uom, setUom] = useState([]);
  const [uomId, setUomId] = useState("");
  const handleUom = (event) => {
    setUomId(event.target.value);
  };
  async function getUom() {
    try {
      const response = await fetch("/getuom", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setUom(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getUom();
  }, []);

  // GET RACK DETAILS FOR DROPDOWN  //

  const [rack, setRack] = useState([]);
  const [rackId, setRackId] = useState(null);
  const handleRack = (event) => {
    setRackId(event.target.value);
  };
  async function getRack() {
    try {
      const response = await fetch("/getrack", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      // console.log(jsonData);
      setRack(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getRack();
  }, []);

  // GET ITEM GROUP FOR DROPDOWN  //

  const [group, setGroup] = useState([]);
  const [groupId, setGroupId] = useState("");
  const handleGroup = (event) => {
    setGroupId(event.target.value);
  };
  async function getGroup() {
    try {
      const response = await fetch("/getitemgroup", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setGroup(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getGroup();
  }, []);

  // special discount handlers

  const [spcldisc, setspcldisc] = useState(false);
  const spcldiscopts = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(`/deleteitem/${searchInput.itemid}`, {
        method: "DELETE",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      //RESET ALL STATES
      setItemCode("");
      setItemName("");
      setUomId("");
      setCatgId("");
      setGroupId("");
      setMrp(0);
      setMrp1(0);
      setMrp2(0);
      setMrp3(0);
      setGst(0);
      setndp(0);
      setHsnCode("");
      setRackId("");
      setmax(0);
      setmin(0);
      setreorder(0);
      setconvert(0);
      setUpdatedisabled(true);
      setInsertDisabled(false);
      setSearchInput(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      try {
        const body = {
          itemCode,
          itemName,
          uomId,
          catgId,
          groupId,
          mrpValue,
          mrp1,
          mrp2,
          mrp3,
          gstValue,
          saleValue,
          ndpRate,
          hsnCode,
          rackId: rackId.rackid,
          maxLevel,
          minLevel,
          reorderLevel,
          convertQty,
          spcldisc,
        };
        setInsertDisabled(true);
        const response = await fetch("/insertItem", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.itemid) {
          toast.success("Item Created Successfully", { autoClose: 2000 });
          //RESET ALL STATE VALUES
          setItemCode("");
          setItemName("");
          setUomId("");
          setCatgId("");
          setGroupId("");
          setMrp(0);
          setMrp1(0);
          setMrp2(0);
          setMrp3(0);
          setGst(0);
          setndp(0);
          setHsnCode("");
          setRackId("");
          setmax(0);
          setmin(0);
          setreorder(0);
          setconvert(0);
          setUpdatedisabled(true);
          setSearchInput(null);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
          setInsertDisabled(true);
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      try {
        const body = {
          itemCode,
          itemName,
          uomId,
          catgId,
          groupId,
          mrpValue,
          mrp1,
          mrp2,
          mrp3,
          gstValue,
          saleValue,
          ndpRate,
          hsnCode,
          rackId: rackId.rackid,
          maxLevel,
          minLevel,
          reorderLevel,
          convertQty,
          spcldisc,
        };
        const response = await fetch(`/updateitem/${searchInput.itemid}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        toast.success(await response.json(), { autoClose: 1000 });
        setItemCode("");
        setItemName("");
        setUomId("");
        setCatgId("");
        setGroupId("");
        setMrp(0);
        setMrp1(0);
        setMrp2(0);
        setMrp3(0);
        setGst(0);
        setndp(0);
        setHsnCode("");
        setRackId("");
        setmax(0);
        setmin(0);
        setreorder(0);
        setconvert(0);
        setUpdatedisabled(true);
        setInsertDisabled(false);
        setSearchInput(null);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/SearchDetails", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (isSwitchOn) {
      getParam();
    }
  }, [isSwitchOn]);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  // console.log(searchInput);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //MODAL STATES
  const [adjopen, setadjopen] = useState(false);
  const handleadjClose = () => setadjopen(false);
  const [pendingopen, setpendingopen] = useState(false);
  const handlependingClose = () => setpendingopen(false);
  const [recvopen, setrecvopen] = useState(false);
  const handlerecvClose = () => setrecvopen(false);
  const [stexcopen, setstexcopen] = useState(false);
  const handlestexcClose = () => setstexcopen(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [transitopen, setTransitOpen] = useState(false);
  const handleTransitClose = () => setTransitOpen(false);
  const [altitemopen, setaltitemOpen] = useState(false);
  const handlealtitemClose = () => setaltitemOpen(false);
  //TABLE DATA VALUES
  const [openingqty, setOpeningQty] = useState(0);
  const [recvqty, setRecvQty] = useState(0);
  const [issuedqty, setIssuedQty] = useState(0);
  const [blockedqty, setBlockedQty] = useState(0);
  const [currentstkqty, setCurrentstkQty] = useState(0);
  const [transitqty, setTransitQty] = useState(0);
  const [Backorderqty, setBackorderQty] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setAltFlag] = useState("");
  const [pendingqty, setpendingqty] = useState(0);
  const [stexcqty, setstexcqty] = useState(0);
  const [adjustqty, setadjustqty] = useState(0);
  const [transferin, settransferin] = useState(0);
  const [transferout, settransferout] = useState(0);
  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [blockeddtls, setblockeddtls] = useState([]);
  const [transitDetailsArray, setTransitDetailsArray] = useState([]);
  const [recvDetailsArray, setrecvDetailsArray] = useState([]);
  const [stexcDetailsArray, setstexcDetailsArray] = useState([]);
  const [pendingDetailsArray, setpendingDetailsArray] = useState([]);
  const [adjustedDetailsArray, setadjustedDetailsArray] = useState([]);
  const [altitemArray, setaltitemArray] = useState([]);
  const [correctbtntext, setcorrectbtntext] = useState("Stock Auto Correction");
  const [disablecorrectbtn, setdisablecorrectbtn] = useState(false);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      if (isSwitchOn) {
        const response = await fetch(`/itemquery/${searchInput.itemid}`, {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        });
        const jsonData = await response.json();
        // console.log(jsonData);
        if (jsonData !== "") {
          const rackValue = rack.filter(
            (opt) => opt.rackid === jsonData.rackid
          );
          setadjustqty(jsonData.adjusted.adjustqty);
          setadjustedDetailsArray(jsonData.adjustedetails);
          setaltitemArray(jsonData.alternateitem);
          setpendingDetailsArray(jsonData.pendinDetails);
          setrecvDetailsArray(jsonData.receivedDetails);
          setstexcDetailsArray(jsonData.stexcDetails);
          setTransitDetailsArray(jsonData.transitdetails);
          setAltFlag(jsonData.altflag);
          setDelItem(jsonData.deleteditem);
          setOpeningQty(jsonData.openingdetails.openingqty);
          setRecvQty(jsonData.purchasedetails.recvqty);
          setIssuedQty(jsonData.saledetails.saleqty);
          setBlockedQty(jsonData.blockedqty.ordqty);
          setCurrentstkQty(jsonData.openingdetails.closingqty);
          setTransitQty(jsonData.transitqty.transitqty);
          settransferin(jsonData.transferin.transferin);
          settransferout(jsonData.transferout.transferout);
          setBackorderQty(jsonData.backorderdetails.backordqty);
          setItemCode(jsonData.itemcode);
          setItemName(jsonData.itemname);
          setUomId(jsonData.uomid);
          setCatgId(jsonData.categoryid);
          setGroupId(jsonData.groupid);
          setMrp(jsonData.mrp);
          setMrp1(jsonData.mrp1);
          setMrp2(jsonData.mrp2);
          setMrp3(jsonData.mrp3);
          setGst(jsonData.gstrate);
          setndp(jsonData.ndprate);
          setHsnCode(jsonData.hsncode);
          setRackId(...rackValue);
          setmax(jsonData.maxlevel);
          setmin(jsonData.minlevel);
          setspcldisc(jsonData.spcldiscount);
          setreorder(jsonData.reorderlevel);
          setconvert(jsonData.convertqty);
          setUpdatedisabled(false);
          setInsertDisabled(true);
          setblockeddtls(jsonData.blockeddetails);
          setpendingqty(jsonData.pendingqty.recvqty);
          setstexcqty(jsonData.shortexcessqty.stexcqty);
          document.getElementById("currentStockCell").style.backgroundColor =
            "GOLD";
          document.getElementById("currentstkqty").style.backgroundColor =
            "#fffdaf";
          document.getElementById("currentstkqty").style.fontWeight = "bold";
        } else {
          setUpdatedisabled(true);
          setInsertDisabled(false);
        }
      } else {
        const body = { code: newModePartNo };
        const response = await fetch("/old/itemquery", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const jsonData = await response.json();
        console.log(jsonData);
        if (jsonData.type === "success") {
          const rackValue = rack.filter(
            (opt) => opt.rackid === jsonData.rackid
          );
          setSearchInput({
            itemdetails: jsonData.itemcode + "-" + jsonData.itemname,
            itemid: jsonData.itemid,
          });
          setadjustqty(jsonData.adjusted.adjustqty);
          setadjustedDetailsArray(jsonData.adjustedetails);
          setaltitemArray(jsonData.alternateitem);
          setpendingDetailsArray(jsonData.pendinDetails);
          setrecvDetailsArray(jsonData.receivedDetails);
          setstexcDetailsArray(jsonData.stexcDetails);
          setTransitDetailsArray(jsonData.transitdetails);
          setAltFlag(jsonData.altflag);
          setDelItem(jsonData.deleteditem);
          setOpeningQty(jsonData.openingdetails.openingqty);
          setRecvQty(jsonData.purchasedetails.recvqty);
          setIssuedQty(jsonData.saledetails.saleqty);
          setBlockedQty(jsonData.blockedqty.ordqty);
          setCurrentstkQty(jsonData.openingdetails.closingqty);
          setTransitQty(jsonData.transitqty.transitqty);
          settransferin(jsonData.transferin.transferin);
          settransferout(jsonData.transferout.transferout);
          setBackorderQty(jsonData.backorderdetails.backordqty);
          setItemCode(jsonData.itemcode);
          setItemName(jsonData.itemname);
          setUomId(jsonData.uomid);
          setCatgId(jsonData.categoryid);
          setGroupId(jsonData.groupid);
          setMrp(jsonData.mrp);
          setMrp1(jsonData.mrp1);
          setMrp2(jsonData.mrp2);
          setMrp3(jsonData.mrp3);
          setGst(jsonData.gstrate);
          setndp(jsonData.ndprate);
          setHsnCode(jsonData.hsncode);
          setRackId(...rackValue);
          setmax(jsonData.maxlevel);
          setmin(jsonData.minlevel);
          setspcldisc(jsonData.spcldiscount);
          setreorder(jsonData.reorderlevel);
          setconvert(jsonData.convertqty);
          setUpdatedisabled(false);
          setInsertDisabled(true);
          setblockeddtls(jsonData.blockeddetails);
          setpendingqty(jsonData.pendingqty.recvqty);
          setstexcqty(jsonData.shortexcessqty.stexcqty);
          document.getElementById("currentStockCell").style.backgroundColor =
            "GOLD";
          document.getElementById("currentstkqty").style.backgroundColor =
            "#fffdaf";
          document.getElementById("currentstkqty").style.fontWeight = "bold";
        } else {
          toast.error(jsonData.message);
          setUpdatedisabled(true);
          setInsertDisabled(false);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  //print excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const data = await fetch("/itemdetails/excel", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "item_master.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  //stock auto correct
  const handleautocorrect = async (e) => {
    e.preventDefault();
    setdisablecorrectbtn(true);
    setcorrectbtntext("Processing...");
    try {
      const res = await fetch("/stock/autocorrect", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      if (data.type === "success") {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAltSwitch = async (e, opt) => {
    try {
      setaltitemOpen(false);
      setNewModePartNo(opt.itemcode);
      setSearchInput({
        itemdetails: opt.itemcode + "-" + opt.itemname,
        itemid: opt.itemid,
      });
      if (isSwitchOn) {
        const response = await fetch(`/itemquery/${opt.itemid}`, {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        });
        const jsonData = await response.json();
        // console.log(jsonData);
        if (jsonData !== "") {
          const rackValue = rack.filter(
            (opt) => opt.rackid === jsonData.rackid
          );
          setadjustqty(jsonData.adjusted.adjustqty);
          setadjustedDetailsArray(jsonData.adjustedetails);
          setaltitemArray(jsonData.alternateitem);
          setpendingDetailsArray(jsonData.pendinDetails);
          setrecvDetailsArray(jsonData.receivedDetails);
          setstexcDetailsArray(jsonData.stexcDetails);
          setTransitDetailsArray(jsonData.transitdetails);
          setAltFlag(jsonData.altflag);
          setDelItem(jsonData.deleteditem);
          setOpeningQty(jsonData.openingdetails.openingqty);
          setRecvQty(jsonData.purchasedetails.recvqty);
          setIssuedQty(jsonData.saledetails.saleqty);
          setBlockedQty(jsonData.blockedqty.ordqty);
          setCurrentstkQty(jsonData.openingdetails.closingqty);
          setTransitQty(jsonData.transitqty.transitqty);
          settransferin(jsonData.transferin.transferin);
          settransferout(jsonData.transferout.transferout);
          setBackorderQty(jsonData.backorderdetails.backordqty);
          setItemCode(jsonData.itemcode);
          setItemName(jsonData.itemname);
          setUomId(jsonData.uomid);
          setCatgId(jsonData.categoryid);
          setGroupId(jsonData.groupid);
          setMrp(jsonData.mrp);
          setMrp1(jsonData.mrp1);
          setMrp2(jsonData.mrp2);
          setMrp3(jsonData.mrp3);
          setGst(jsonData.gstrate);
          setndp(jsonData.ndprate);
          setHsnCode(jsonData.hsncode);
          setRackId(...rackValue);
          setmax(jsonData.maxlevel);
          setmin(jsonData.minlevel);
          setspcldisc(jsonData.spcldiscount);
          setreorder(jsonData.reorderlevel);
          setconvert(jsonData.convertqty);
          setUpdatedisabled(false);
          setInsertDisabled(true);
          setblockeddtls(jsonData.blockeddetails);
          setpendingqty(jsonData.pendingqty.recvqty);
          setstexcqty(jsonData.shortexcessqty.stexcqty);
          document.getElementById("currentStockCell").style.backgroundColor =
            "GOLD";
          document.getElementById("currentstkqty").style.backgroundColor =
            "#fffdaf";
          document.getElementById("currentstkqty").style.fontWeight = "bold";
        } else {
          setUpdatedisabled(true);
          setInsertDisabled(false);
        }
      } else {
        const body = { code: opt.itemcode };
        const response = await fetch("/old/itemquery", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const jsonData = await response.json();
        console.log(jsonData);
        if (jsonData.type === "success") {
          const rackValue = rack.filter(
            (opt) => opt.rackid === jsonData.rackid
          );
          setSearchInput({
            itemdetails: jsonData.itemcode + "-" + jsonData.itemname,
            itemid: jsonData.itemid,
          });
          setadjustqty(jsonData.adjusted.adjustqty);
          setadjustedDetailsArray(jsonData.adjustedetails);
          setaltitemArray(jsonData.alternateitem);
          setpendingDetailsArray(jsonData.pendinDetails);
          setrecvDetailsArray(jsonData.receivedDetails);
          setstexcDetailsArray(jsonData.stexcDetails);
          setTransitDetailsArray(jsonData.transitdetails);
          setAltFlag(jsonData.altflag);
          setDelItem(jsonData.deleteditem);
          setOpeningQty(jsonData.openingdetails.openingqty);
          setRecvQty(jsonData.purchasedetails.recvqty);
          setIssuedQty(jsonData.saledetails.saleqty);
          setBlockedQty(jsonData.blockedqty.ordqty);
          setCurrentstkQty(jsonData.openingdetails.closingqty);
          setTransitQty(jsonData.transitqty.transitqty);
          settransferin(jsonData.transferin.transferin);
          settransferout(jsonData.transferout.transferout);
          setBackorderQty(jsonData.backorderdetails.backordqty);
          setItemCode(jsonData.itemcode);
          setItemName(jsonData.itemname);
          setUomId(jsonData.uomid);
          setCatgId(jsonData.categoryid);
          setGroupId(jsonData.groupid);
          setMrp(jsonData.mrp);
          setMrp1(jsonData.mrp1);
          setMrp2(jsonData.mrp2);
          setMrp3(jsonData.mrp3);
          setGst(jsonData.gstrate);
          setndp(jsonData.ndprate);
          setHsnCode(jsonData.hsncode);
          setRackId(...rackValue);
          setmax(jsonData.maxlevel);
          setmin(jsonData.minlevel);
          setspcldisc(jsonData.spcldiscount);
          setreorder(jsonData.reorderlevel);
          setconvert(jsonData.convertqty);
          setUpdatedisabled(false);
          setInsertDisabled(true);
          setblockeddtls(jsonData.blockeddetails);
          setpendingqty(jsonData.pendingqty.recvqty);
          setstexcqty(jsonData.shortexcessqty.stexcqty);
          document.getElementById("currentStockCell").style.backgroundColor =
            "GOLD";
          document.getElementById("currentstkqty").style.backgroundColor =
            "#fffdaf";
          document.getElementById("currentstkqty").style.fontWeight = "bold";
        } else {
          toast.error(jsonData.message);
          setUpdatedisabled(true);
          setInsertDisabled(false);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  //MAIN COMPONENT CODE - EDIT HTML CSS ETC. HERE
  return (
    <div>
      <Sidebar
        Title={"ITEM MASTER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
            }}
          >
            <Stack
              spacing={2}
              ml={"1%"}
              mb={1}
              direction="row"
              display={"flex"}
              alignItems={"center"}
            >
              <Typography>Old Mode</Typography>
              <Switch
                onChange={handleSwitchChange}
                checked={isSwitchOn}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>New Mode</Typography>
              {!isSwitchOn && (
                <TextField
                  id="partno"
                  label="Part No"
                  value={newModePartNo}
                  onChange={(e) => setNewModePartNo(e.target.value)}
                  sx={{
                    width: "200px",
                  }}
                  size="small"
                />
              )}
              {isSwitchOn && (
                <Autocomplete
                  id="controlled-demo"
                  value={searchInput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setSearchInput(newValue);
                  }}
                  options={param}
                  getOptionLabel={(option) => option.itemdetails}
                  sx={{
                    width: "70% ",
                    boxShadow: "2px 2px 6px  #404142",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      placeholder="Search Items Here..."
                    />
                  )}
                />
              )}
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Button
                onClick={handleautocorrect}
                color="info"
                disabled={disablecorrectbtn}
                variant="contained"
              >
                {correctbtntext}
              </Button>
            </Stack>

            <hr />
            {/* OLD SEARCHBAR - DELETE SEARCHBAR COMPONENT BEFORE PRODUCTION */}

            {/* <div className="searchContainer">
              <SearchBar placeholder="Enter ItemCode/Name..." data={param}/>
            </div> */}
            <Stack spacing={1} mt={4} direction="row" ml={2}>
              <TextField
                required
                id="Item Code"
                label="Item Code"
                variant="outlined"
                size="small"
                value={itemCode}
                onInput={(e) => setItemCode(e.target.value)}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                multiline
                required
                id="Item Description"
                label="Item Description"
                variant="outlined"
                size="small"
                value={itemName}
                onInput={(e) => setItemName(e.target.value)}
                sx={{
                  width: 200,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                required
                id="M.R.P"
                label="M.R.P"
                value={mrp}
                onInput={(e) => setMrp(e.target.value)}
                variant="outlined"
                size="small"
                sx={{
                  width: 100,
                  backgroundColor: "#c7eff9",
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <Autocomplete
                disablePortal
                id="rackno"
                value={rackId}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setRackId(newValue);
                }}
                size="small"
                sx={{
                  width: 140,
                }}
                options={rack}
                getOptionLabel={(option) => option.rackname}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 140,
                      "& .MuiInputBase-root": {
                        fontSize: "0.75rem", // Adjust font size as needed
                      },
                      backgroundColor:"lightsalmon"
                    }}
                    {...params}
                    size="small"
                    label="Rack No"
                  />
                )}
              />
              <TextField
                required
                id="M.R.P1"
                label="M.R.P 1"
                value={mrp1}
                onInput={(e) => setMrp1(e.target.value)}
                variant="outlined"
                size="small"
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                id="M.R.P2"
                label="M.R.P 2"
                value={mrp2}
                onInput={(e) => setMrp2(e.target.value)}
                variant="outlined"
                size="small"
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                id="M.R.P3"
                label="M.R.P 3"
                value={mrp3}
                onInput={(e) => setMrp3(e.target.value)}
                variant="outlined"
                size="small"
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Stack spacing={1} mt={4} direction="row" ml={2}>
              <TextField
                required
                //helperText="Select the Product Category"
                select
                id="Category"
                label="Category"
                variant="outlined"
                value={catgId}
                onChange={handleCatg}
                size="small"
                sx={{
                  width: 150,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              >
                {category.map((ctg) => (
                  <MenuItem
                    sx={{ fontSize: "0.75rem" }}
                    key={ctg.categoryid}
                    value={ctg.categorycode}
                  >
                    {ctg.categoryname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                //helperText="Select the Product Group"
                select
                id="Group"
                label="Group"
                value={groupId}
                onChange={handleGroup}
                variant="outlined"
                size="small"
                sx={{
                  width: 150,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              >
                {group.map((grp) => (
                  <MenuItem
                    sx={{ fontSize: "0.75rem" }}
                    key={grp.groupid}
                    value={grp.groupid}
                  >
                    {grp.groupname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                //helperText="Select Unit Of Measure"
                select
                id="UOM"
                label="UOM"
                value={uomId}
                onChange={handleUom}
                variant="outlined"
                size="small"
                sx={{
                  width: 110,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              >
                {uom.map((uom) => (
                  <MenuItem
                    sx={{ fontSize: "0.75rem" }}
                    key={uom.uomid}
                    value={uom.uomid}
                  >
                    {uom.uomname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                //helperText=" Please Select Yes or No"
                select
                id="spcDis"
                label="Special Discount"
                variant="outlined"
                value={spcldisc}
                onChange={(e) => setspcldisc(e.target.value)}
                size="small"
                sx={{
                  width: 120,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              >
                {spcldiscopts.map((ctg) => (
                  <MenuItem
                    sx={{ fontSize: "0.75rem" }}
                    key={ctg.value}
                    value={ctg.value}
                  >
                    {ctg.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="GST Rate"
                label="GST Rate"
                variant="outlined"
                value={gst}
                onInput={(e) => setGst(e.target.value)}
                size="small"
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                id="Alternate Flag"
                label="Alternate Flag"
                variant="outlined"
                size="small"
                value={altFlag}
                InputProps={{
                  endAdornment: altFlag ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setaltitemOpen(true)}
                        size="small"
                        color="secondary"
                      >
                        <EyeIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                  backgroundColor:
                    delItem && altFlag
                      ? "lightgreen"
                      : !delItem && altFlag
                      ? "lightgreen"
                      : "white",
                }}
              />
              <TextField
                id="Deleted Item"
                label="Deleted Item"
                variant="outlined"
                size="small"
                value={delItem}
                // color={delItem === true ? "error" : "primary"}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                  backgroundColor:
                    delItem && altFlag
                      ? "#ffff66"
                      : delItem && !altFlag
                      ? "#ff6666"
                      : "",
                }}
              />
            </Stack>
            <Stack spacing={1} columnGap={1} direction="row" mt={4} ml={2}>
              <TextField
                required
                disabled
                value={saleValue}
                label="SALE RATE"
                id="SALE RATE"
                variant="outlined"
                size="small"
                sx={{
                  width: 120,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />

              <TextField
                required
                id="NDP Rate"
                label="NDP Rate"
                variant="outlined"
                size="small"
                value={ndp}
                onInput={(e) => setndp(e.target.value)}
                sx={{
                  width: 120,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                required
                id="HSN Code"
                label="HSN Code"
                variant="outlined"
                size="small"
                value={hsnCode}
                onInput={(e) => setHsnCode(e.target.value)}
                sx={{
                  width: 150,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                id="Max Level"
                label="Max Level"
                variant="outlined"
                size="small"
                value={max}
                onInput={(e) => setmax(e.target.value)}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                id="Min Level"
                label="Min Level"
                variant="outlined"
                size="small"
                value={min}
                onInput={(e) => setmin(e.target.value)}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                id="ReOrder Level"
                label="ReOrder Level"
                variant="outlined"
                size="small"
                value={reorder}
                onInput={(e) => setreorder(e.target.value)}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
              <TextField
                id="Convert Qty"
                label="Convert Qty"
                variant="outlined"
                size="small"
                value={convert}
                onInput={(e) => setconvert(e.target.value)}
                sx={{
                  width: 100,
                  "& .MuiInputBase-root": {
                    fontSize: "0.75rem", // Adjust font size as needed
                  },
                }}
              />
            </Stack>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  ASSIGN COMPANY ROLES
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Order No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Order Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Party Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {blockeddtls.map((opt) => (
                        <TableRow key={opt.orderno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.orderno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.orderdate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.subledgername}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.ordqty}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={transitopen}
              onClose={handleTransitClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  TRANSIT DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Invoice No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Invoice Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {transitDetailsArray.map((opt) => (
                        <TableRow key={opt.invno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.invno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.invdate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.qty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.rate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={pendingopen}
              onClose={handlependingClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  PENDING DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward Date
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Bill No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Bill Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {pendingDetailsArray.map((opt) => (
                        <TableRow key={opt.inwardno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwardno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwarddate}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.billno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.billdate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.recvqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.rate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={recvopen}
              onClose={handlerecvClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  RECIEVED DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {recvDetailsArray.map((opt) => (
                        <TableRow key={opt.inwardno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwardno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwarddate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.recvqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.rate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={stexcopen}
              onClose={handlestexcClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  SHORT EXCESS DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Inward Date
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Short Excess No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          Short Excess Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {stexcDetailsArray.map((opt) => (
                        <TableRow key={opt.inwardno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwardno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.inwarddate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.docno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.stexcdate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.stexcqty}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={adjopen}
              onClose={handleadjClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  ADJUSTED DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    background:
                      "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "45vh",
                    overflowY: "auto",
                    padding: "18px",
                    mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          DOC No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                        >
                          DOC Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            paddingY: "16px",
                          }}
                          align="center"
                        >
                          REASON
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {adjustedDetailsArray.map((opt) => (
                        <TableRow key={opt.docno}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.docno}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.docdate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.qty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              paddingY: "16px",
                            }}
                          >
                            {opt.reason}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={altitemopen}
              onClose={handlealtitemClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ pt: 2, textAlign: "center" }}
                >
                  ALTERNATE ITEMS DETAILS
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "90%",
                    // background:
                    //   "linear-gradient(to bottom right, #4278D1, #0CACB7)",
                    maxHeight: "50vh",
                    overflowY: "auto",
                    // padding: "18px",
                    // mt: "30px",
                    ml: "5%",
                  }}
                >
                  <Table
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "2px 6px 10px #404142",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "#aad3f0",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            //paddingY: "16px",
                            fontSize: "0.875rem",
                          }}
                        >
                          ItemCode
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            fontSize: "0.875rem",
                            //paddingY: "16px",
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            fontSize: "0.875rem",
                            //paddingY: "16px",
                          }}
                        >
                          Stock Qty
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            border: "0.5px solid black",
                            fontSize: "0.875rem",
                            //paddingY: "16px",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                      {altitemArray.map((opt) => (
                        <TableRow key={opt.itemid}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              fontSize: "0.875rem",
                            }}
                          >
                            {opt.itemcode}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              fontSize: "0.875rem",
                            }}
                          >
                            {opt.itemname}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              fontSize: "0.875rem",
                            }}
                          >
                            {opt.closingqty}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#000",
                              border: "0.5px solid black",
                              fontSize: "0.875rem",
                            }}
                          >
                            <Button onClick={(e) => handleAltSwitch(e, opt)}>
                              Switch
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <TableContainer
              //component={Paper}
              sx={{
                // boxShadow: "5px 5px 10px #404142",
                // backgroundColor: "#868c91",
                maxHeight: "200px",
                overflowY: "auto",
                padding: "18px",
                mt: "2",
              }}
            >
              <Table
                sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                size="small"
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "#aad3f0",
                    border: "0.5px solid black",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="r"
                    >
                      OPENING
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      RECIEVED
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      PENDING
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      ST/EXE
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      ADJUSTED
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      ISSUED
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      BLOCKED
                    </TableCell>
                    <TableCell
                      id="currentStockCell"
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      CURRENTSTOCK
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      TRANSFER IN
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      TRANSFER OUT
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      TRANSIT
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        padding: "4px 8px", // Adjust padding to make cells slimmer
                        fontSize: 12, // Adjust font size to make text slimmer
                      }}
                      align="center"
                    >
                      BACKORDER
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {openingqty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {recvqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setrecvopen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {pendingqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setpendingopen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {stexcqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setstexcopen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {adjustqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setadjopen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {issuedqty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {blockedqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setOpen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      id="currentstkqty"
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {parseFloat(currentstkqty).toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {transferin}
                      {/* <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setTransitOpen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton> */}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {transferout}
                      {/* <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setTransitOpen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton> */}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {transitqty}
                      <IconButton
                        color="secondary"
                        aria-label="add an alarm"
                        size="small"
                        sx={{ marginLeft: "5%" }}
                        onClick={() => setTransitOpen(true)}
                      >
                        <EyeIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#000", border: "0.5px solid black" }}
                    >
                      {Backorderqty}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={2} columnGap={6} direction="row" mt={5} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setItemCode("");
                  setItemName("");
                  setUomId("");
                  setCatgId("");
                  setGroupId("");
                  setMrp(0);
                  setMrp1(0);
                  setMrp2(0);
                  setMrp3(0);
                  setGst(0);
                  setndp(0);
                  setHsnCode("");
                  setRackId("");
                  setmax(0);
                  setmin(0);
                  setreorder(0);
                  setconvert(0);
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                // onClick={handleDelete}
                disabled
              >
                DELETE
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SpareLandingItemMaster;
