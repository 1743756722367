import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Switch,
  Chip,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { darken, lighten } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Rejected": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Parts": {
    backgroundColor: "", // Change to light blue
    "&:hover": {
      backgroundColor: "", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "", // Change to light blue
      "&:hover": {
        backgroundColor: "", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Lubricant": {
    backgroundColor: "#FFFF99", // Change to light blue
    "&:hover": {
      backgroundColor: "#FFFF99", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "#FFFF99", // Change to light blue
      "&:hover": {
        backgroundColor: "#FFFF99", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Battery": {
    backgroundColor: "#FAD5A5", // Change to light blue
    "&:hover": {
      backgroundColor: "#FAD5A5", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "#FAD5A5", // Change to light blue
      "&:hover": {
        backgroundColor: "#FAD5A5", // Change to light blue
      },
    },
  },
  "& .super-app-theme--TyreTube": {
    backgroundColor: " #d9dad7",
    "&:hover": {
      backgroundColor: " #d9dad7", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: " #d9dad7", // Change to light blue
      "&:hover": {
        backgroundColor: " #d9dad7", // Change to light blue
      },
    },
  },
  "& .super-app-theme--Accessories": {
    backgroundColor: "lightblue", // Change to light blue
    "&:hover": {
      backgroundColor: "lightblue", // Change to light blue
    },
    "&.Mui-selected": {
      backgroundColor: "lightblue", // Change to light blue
      "&:hover": {
        backgroundColor: "lightblue", // Change to light blue
      },
    },
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "2px solid red", // Change this to the desired color
    backgroundColor: "gray",
    color: "white",
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const DirectSale = () => {
  const [disableSave, setDisableSave] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  const [docno, setdocno] = useState(null);
  const [pdfStreamR, setPdfStreamR] = useState(null);
  const [docopts, setdocopts] = useState([]);
  const [disablereportbtn, setdisablereportbtn] = useState(false);
  const [sorttype, setSortType] = useState("rackname");
  const [stkopen, setstkOpen] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [newModePartNo, setNewModePartNo] = useState(null);
  const newPartNoRef = useRef(null);
  // CONTROLS FOR DIFFERENT MODES

  const [mode, setMode] = useState(1);
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
    {
      value: 3,
      label: "View",
    },
  ];
  const handlePartnoKey = async (event) => {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === newPartNoRef.current) {
        const body = { code: newModePartNo };
        // const data = allparts.find((opt) => opt.itemcode === newModePartNo);
        const apicall = await fetch("/allparts/detailfetch", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await apicall.json();
        if (data.itemid) {
          setpartno(data);
          setstock(data.purchaseqty);
          setDelItem(data.deleteditem);
          setaltFlag(data.altflag);
          if (quantityRef.current) {
            quantityRef.current.focus();
          }

          // Prevent the default Tab behavior
          event.preventDefault();
        } else {
          toast.error("No Part Found!");
          setNewModePartNo("");
          newPartNoRef.current.focus();
          event.preventDefault();
        }
      }
    }
  };
  const handlestkOpen = () => setstkOpen(true);
  const handlestkClose = () => {
    setstkOpen(false);
    setdocno(null);
    setPdfStreamR(null);
    setdisablereportbtn(false);
  };
  const sortoptions = [
    {
      value: "rackname",
      label: "Rack",
    },
    {
      value: "itemcode",
      label: "Item Code",
    },
  ];
  async function getParam() {
    try {
      const response = await fetch("/spare/directsale/picklist/bills", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setdocopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getParam();
  }, []);
  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   LocalstorageHelper.RemoveUserToken();
  // }, 3600000);

  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const filterOptions3 = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
    matchFrom: "start",
  });
  // EDIT MODE BILLNOS
  const [searchInput, setSearchInput] = useState(null);
  const [emodebills, setEmodeBills] = useState([]);
  const [billtype, setBillType] = useState(1);
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  async function getemodebillnos() {
    try {
      const response = await fetch(
        `/spare/directsale/salebillopts/${billtype}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setEmodeBills(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  //for view
  async function getviewmodebillnos() {
    try {
      const response = await fetch(
        `/spare/directsale/salebillopts/view/${billtype}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setEmodeBills(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (parseInt(mode) === 2) {
      getemodebillnos();
    } else if (parseInt(mode) === 3) {
      getviewmodebillnos();
    }
  }, [billtype, mode]);

  // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  const quantityRef = useRef(null);
  const partnoRef = useRef(null);
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
        partnoRef.current.focus();
        newPartNoRef.current.focus();
        event.preventDefault();
      }
    }
    if (event.code === "F2") {
      if (event.target === quantityRef.current) {
        try {
          const response = await fetch(`/altitemstock/${partno.itemid}`, {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          });
          const jsonData = await response.json();
          setaltparts(jsonData);
          //console.log(altparts);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  }

  const [altparts, setaltparts] = useState([]);
  function handleSwitch(opt) {
    setpartno({
      deleteditem: opt.deleteditem,
      gstrate: opt.gstrate,
      itemid: opt.alternateitemid,
      mrp: opt.mrp,
      mrp4: opt.mrp,
      mrp1: opt.mrp1,
      mrp2: opt.mrp2,
      mrp3: opt.mrp3,
      partdetails: opt.itemcode + "-" + opt.itemname,
      purchaseqty: opt.closingqty,
      rackname: opt.rackname,
      salerate: opt.salerate,
      spcldiscount: opt.spcldiscount,
    });
    setstock(opt.closingqty);
  }

  //  GET Party Search Parameters  AND HOLD STATE OF SEARCH INPUT//
  const [partyInput, setPartyInput] = useState({});
  //  console.log(partyInput);
  const [partyopts, setpartyOpts] = useState([]);
  const [partyfetch, setpartyfetch] = useState(false);

  async function getpartyOpts() {
    try {
      const response = await fetch("/spare/directsale/parties", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyfetch(false);
      setpartyOpts(jsonData);
      const cashcustomer = jsonData.find((opt) => opt.cashtype === true);
      setPartyInput(cashcustomer);
      setSalesmanInput({
        salesmandetails: cashcustomer.salesmandetails,
        salesmanid: cashcustomer.salesmanid,
      });
      setState((prevState) => ({
        ...prevState,
        statename: cashcustomer.statename,
      }));
      // console.log(jsonData);
      console.log(cashcustomer);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //FETCH PARTIES AFTER COUNTER GST CUSTOMER
  useEffect(() => {
    if (partyfetch === true) {
      getpartyOpts();
    }
  }, [partyfetch]);
  //  GET MAKER-PICKER Search Parameters  AND HOLD STATE OF SEARCH INPUT//

  const [mkpkInput, setMkpkInput] = useState(null);
  const [pickerInput, setPickerInput] = useState(null);
  const [mkpkopts, setMkpkOpts] = useState([]);
  async function getMkpkOpts() {
    try {
      const response = await fetch("/spare/salebill/mkpk", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      // console.log(jsonData);
      setMkpkOpts(jsonData);
      const mkpknone = jsonData.find(
        (opt) => opt.makerpickername.trim() === "NONE"
      );
      setMkpkInput(mkpknone);
      setPickerInput(mkpknone);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getMkpkOpts();
  }, []);

  //  GET SALESMAN Search Parameters  AND HOLD STATE OF SEARCH INPUT//

  const [salesmanInput, setSalesmanInput] = useState({});
  const [salesmanopts, setSalesmanOpts] = useState([]);
  const [irn, setirn] = useState("");
  async function getSalesmanOpts() {
    try {
      const response = await fetch("/spare/salebill/salesman", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setSalesmanOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getSalesmanOpts();
  }, []);

  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });
  // FETCH EDITMODE DETAILS
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/spare/directsale/salebillopts/fetch/${searchInput.billheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setEmodeDetails(jsonData);
      setirn(jsonData[0].irn);
      setState({
        salecategory: parseInt(jsonData[0].salecatg),
        saletype: parseInt(jsonData[0].saletype),
        sorttype: jsonData[0].sorton,
        invoiceno: jsonData[0].billno,
        statename: jsonData[0].statename,
        customername: jsonData[0].customername,
        address1: jsonData[0].address1,
        address2: jsonData[0].address2,
        address3: jsonData[0].address3,
        balance: jsonData[0].balancecheck,
        partsdisc: parseFloat(jsonData[0].partsdiscrate),
        lubrdisc: parseFloat(jsonData[0].lubdiscrate),
        batterydisc: parseFloat(jsonData[0].batterydiscrate),
        labourdisc: parseFloat(jsonData[0].labourdiscrate),
        enginepartspia: parseFloat(jsonData[0].enginepartspia),
        raceprodiscrate: parseFloat(jsonData[0].raceprodiscrate),
        syntheticdiscrate: parseFloat(jsonData[0].syntheticdiscrate),
        semisyntheticdiscrate: parseFloat(jsonData[0].semisyntheticdiscrate),
      });
      setinvoicedate(jsonData[0].billdate);
      setMkpkInput({
        mkpkdetails: `${jsonData[0].mkdetails}`,
        makerpickerid: `${jsonData[0].makerid}`,
      });
      setPickerInput({
        mkpkdetails: `${jsonData[0].pkdetails}`,
        makerpickerid: `${jsonData[0].pickid}`,
      });
      setSalesmanInput({
        salesmandetails: `${jsonData[0].salesmandetails}`,
        salesmanid: `${jsonData[0].salesmanid}`,
      });
      setPartyInput({
        destinationid: `${jsonData[0].destid}`,
        stateid: `${jsonData[0].stateid}`,
        statename: `${jsonData[0].statename}`,
        subledgerid: `${jsonData[0].subledgerid}`,
        subledgername: `${jsonData[0].partydetails}`,
        specialdis: parseFloat(jsonData[0].specialdis),
      });
      setpartybtndisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  //TEXTFIELD STATES AND CHANGE HANDLER
  const [state, setState] = useState({
    saletype: 2,
    sorttype: "Rack",
    invoiceno: "",
    statename: "",
    address1: "",
    address2: "",
    address3: "",
    balance: "N",
    partsdisc: "0",
    lubrdisc: "0",
    batterydisc: "0",
    labourdisc: "0",
    customername: "",
    enginepartspia: "0",
    raceprodiscrate: "0",
    syntheticdiscrate: "0",
    semisyntheticdiscrate: "0",
  });
  const date = new Date();
  const [invoicedate, setinvoicedate] = useState(date);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteClick = (id) => () => {
    if (parseInt(mode) === 1) {
      setOrderDetailRow(orderdetailrow.filter((row) => row.itemid !== id));
    } else {
      setEmodeDetails(emodedetails.filter((row) => row.itemid !== id));
    }
  };
  const updateMrp = (id) => async () => {
    const index = orderdetailrow.findIndex((row) => row.itemid === id);
    //console.log(orderdetailrow[index].itemid);
    try {
      const response = await fetch(
        `/spare/salebill/mrpup/${orderdetailrow[index].itemid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();

      const updatedRow = {
        ...orderdetailrow[index],
        mrp1: jsonData.mrp1,
        mrp2: jsonData.mrp2,
        mrp3: jsonData.mrp3,
        mrp4: jsonData.mrp4,
      };
      const updatedArray = [
        ...orderdetailrow.slice(0, index),
        updatedRow,
        ...orderdetailrow.slice(index + 1),
      ];
      setOrderDetailRow(updatedArray);
      toast.success("Mrp Updated Succesfully", { autoClose: 2000 });
    } catch (err) {
      console.log(err.message);
    }
  };
  const updateEmodeMrp = (id) => async () => {
    const index = emodedetails.findIndex((row) => row.itemid === id);
    //console.log(orderdetailrow[index].itemid);
    try {
      const response = await fetch(
        `/spare/salebill/mrpup/${emodedetails[index].itemid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();

      const updatedRow = {
        ...emodedetails[index],
        mrp1: jsonData.mrp1,
        mrp2: jsonData.mrp2,
        mrp3: jsonData.mrp3,
        mrp4: jsonData.mrp4,
      };
      const updatedArray = [
        ...emodedetails.slice(0, index),
        updatedRow,
        ...emodedetails.slice(index + 1),
      ];
      setEmodeDetails(updatedArray);
      toast.success("Mrp Updated Succesfully", { autoClose: 2000 });
    } catch (err) {
      console.log(err.message);
    }
  };
  // DISABLED STATE OF PART DETAILS AND ITS BUTTON

  const [partydisabled, setpartydisabled] = useState(false);
  const [partybtndisabled, setpartybtndisabled] = useState(false);

  /// PENDING CUSTOMER ORDER DISABLED STATE
  const [pendingdisabled, setpendingdisabled] = useState(true);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Dele",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 80,
    },
    {
      field: "partdetails",
      headerClassName: "super-app-theme--header",
      headerName: "Part Details",
      width: 300,
      cellClassName: "custom-font-size",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "updatemrp",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "UpdateMRP",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<CurrencyRupee />}
            label="Update"
            onClick={updateMrp(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Sale Qty",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.saleqty;
      },
      valueSetter: (params) => {
        const saleqty = params.value;
        const value = parseFloat(params.value * params.row.salerate).toFixed(2);
        const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
          2
        );
        const taxable = parseFloat(
          parseFloat(value) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
          2
        );
        const cgstamt =
          parseInt(partyInput.stateid) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const sgstamt =
          parseInt(partyInput.stateid) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const igstamt =
          parseInt(partyInput.stateid) !== 1
            ? parseFloat(gstamt).toFixed(2)
            : 0;
        const netamt = parseFloat(
          parseFloat(taxable) + parseFloat(gstamt)
        ).toFixed(2);
        const upd_obj = orderdetailrow.findIndex(
          (obj) => obj.itemid === params.row.itemid
        );
        orderdetailrow[upd_obj].value = value;
        orderdetailrow[upd_obj].saleqty = saleqty;
        orderdetailrow[upd_obj].disamt = disamt;
        orderdetailrow[upd_obj].taxable = taxable;
        orderdetailrow[upd_obj].gstamt = gstamt;
        orderdetailrow[upd_obj].cgstamt = cgstamt;
        orderdetailrow[upd_obj].sgstamt = sgstamt;
        orderdetailrow[upd_obj].igstamt = igstamt;
        orderdetailrow[upd_obj].netamt = netamt;
        return {
          ...params.row,
          saleqty,
          value,
          disamt,
          taxable,
          gstamt,
          cgstamt,
          sgstamt,
          igstamt,
          netamt,
        };
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      type: "number",
      width: 100,
      editable: true,
      valueSetter: (params) => {
        if (parseInt(params.row.groupid) === 6) {
          const mrp = params.value;
          const salerate = parseFloat(
            (parseFloat(params.value) * 100) /
              (100 + parseFloat(params.row.gstpers))
          ).toFixed(2);
          const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
          const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
            2
          );
          const taxable = parseFloat(
            parseFloat(value) - parseFloat(disamt)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxable * params.row.gstpers) / 100
          ).toFixed(2);
          const cgstamt =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstamt =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstamt =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat(gstamt).toFixed(2)
              : 0;
          const netamt = parseFloat(
            parseFloat(taxable) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = orderdetailrow.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          orderdetailrow[upd_obj].mrp = mrp;
          orderdetailrow[upd_obj].salerate = salerate;
          orderdetailrow[upd_obj].value = value;
          orderdetailrow[upd_obj].disamt = disamt;
          orderdetailrow[upd_obj].taxable = taxable;
          orderdetailrow[upd_obj].gstamt = gstamt;
          orderdetailrow[upd_obj].cgstamt = cgstamt;
          orderdetailrow[upd_obj].sgstamt = sgstamt;
          orderdetailrow[upd_obj].igstamt = igstamt;
          orderdetailrow[upd_obj].netamt = netamt;
          return {
            ...params.row,
            mrp,
            salerate,
            value,
            disamt,
            taxable,
            gstamt,
            cgstamt,
            sgstamt,
            igstamt,
            netamt,
          };
        } else if (
          parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp4)
        ) {
          toast.error("Enter A Valid MRP!", {
            toastId: "error1",
          });
        } else {
          const mrp = params.value;
          const salerate = parseFloat(
            (parseFloat(params.value) * 100) /
              (100 + parseFloat(params.row.gstpers))
          ).toFixed(2);
          const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
          const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
            2
          );
          const taxable = parseFloat(
            parseFloat(value) - parseFloat(disamt)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxable * params.row.gstpers) / 100
          ).toFixed(2);
          const cgstamt =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstamt =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstamt =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat(gstamt).toFixed(2)
              : 0;
          const netamt = parseFloat(
            parseFloat(taxable) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = orderdetailrow.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          orderdetailrow[upd_obj].mrp = mrp;
          orderdetailrow[upd_obj].salerate = salerate;
          orderdetailrow[upd_obj].value = value;
          orderdetailrow[upd_obj].disamt = disamt;
          orderdetailrow[upd_obj].taxable = taxable;
          orderdetailrow[upd_obj].gstamt = gstamt;
          orderdetailrow[upd_obj].cgstamt = cgstamt;
          orderdetailrow[upd_obj].sgstamt = sgstamt;
          orderdetailrow[upd_obj].igstamt = igstamt;
          orderdetailrow[upd_obj].netamt = netamt;
          return {
            ...params.row,
            mrp,
            salerate,
            value,
            disamt,
            taxable,
            gstamt,
            cgstamt,
            sgstamt,
            igstamt,
            netamt,
          };
        }
      },
    },
    {
      field: "dispers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis %",
      width: 100,
      type: "number",
      editable: true,
      valueSetter: (params) => {
        const dispers = params.value;
        const disamt = parseFloat((params.row.value * dispers) / 100).toFixed(
          2
        );
        const taxable = parseFloat(
          parseFloat(params.row.value) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
          2
        );
        const cgstamt =
          parseInt(partyInput.stateid) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const sgstamt =
          parseInt(partyInput.stateid) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const igstamt =
          parseInt(partyInput.stateid) !== 1
            ? parseFloat(gstamt).toFixed(2)
            : 0;
        const netamt = parseFloat(
          parseFloat(taxable) + parseFloat(gstamt)
        ).toFixed(2);
        const upd_obj = orderdetailrow.findIndex(
          (obj) => obj.itemid === params.row.itemid
        );
        orderdetailrow[upd_obj].dispers = dispers;
        orderdetailrow[upd_obj].disamt = disamt;
        orderdetailrow[upd_obj].taxable = taxable;
        orderdetailrow[upd_obj].gstamt = gstamt;
        orderdetailrow[upd_obj].cgstamt = cgstamt;
        orderdetailrow[upd_obj].sgstamt = sgstamt;
        orderdetailrow[upd_obj].igstamt = igstamt;
        orderdetailrow[upd_obj].netamt = netamt;
        return {
          ...params.row,
          dispers,
          disamt,
          taxable,
          gstamt,
          cgstamt,
          sgstamt,
          igstamt,
          netamt,
        };
      },
    },
    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "Current MRP",
      type: "number",
      width: 100,
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      type: "number",
      width: 100,
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      type: "number",
      width: 100,
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      type: "number",
      width: 100,
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST %",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 80,
      type: "number",
    },

    {
      field: "disamt",
      headerClassName: "super-app-theme--header",
      headerName: "Dis Amt",
      width: 80,
      type: "number",
    },
    {
      field: "taxable",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "cgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "CGST Amt",
      width: 80,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "sgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "SGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },

    {
      field: "igstamt",
      headerClassName: "super-app-theme--header",
      headerName: "IGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput.stateid) !== 1) {
          return parseFloat(params.row.gstamt).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },

    // {
    //   field: "del",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Del",
    //   width: 50,
    //   type: "number",
    // },
  ];
  const emodecolumns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 60,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "partdetails",
      headerClassName: "super-app-theme--header",
      headerName: "Part Details",
      width: 300,
      cellClassName: "custom-font-size",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "updatemrp",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "UpdateMRP",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<CurrencyRupee />}
            label="Update"
            onClick={updateEmodeMrp(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Sale Qty",
      width: 100,
      editable: true,
      valueSetter: (params) => {
        if (parseFloat(params.value) > parseFloat(params.row.bufferstock)) {
          toast.error("Sale Quantity Should Not Exceed Buffer Stock!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else {
          const saleqty = params.value;
          const grossvalue = parseFloat(
            params.value * params.row.salerate
          ).toFixed(2);
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * params.row.gstrate) / 100).toFixed(2)
              : 0;
          const netvalue = parseFloat(
            parseFloat(taxablevalue) +
              parseFloat(cgstvalue) +
              parseFloat(sgstvalue) +
              parseFloat(igstvalue)
          ).toFixed(2);
          const upd_obj = emodedetails.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          emodedetails[upd_obj].saleqty = saleqty;
          emodedetails[upd_obj].grossvalue = grossvalue;
          emodedetails[upd_obj].discountvalue = discountvalue;
          emodedetails[upd_obj].taxablevalue = taxablevalue;
          emodedetails[upd_obj].gstamt = gstamt;
          emodedetails[upd_obj].cgstvalue = cgstvalue;
          emodedetails[upd_obj].sgstvalue = sgstvalue;
          emodedetails[upd_obj].igstvalue = igstvalue;
          emodedetails[upd_obj].netvalue = netvalue;
          return {
            ...params.row,
            saleqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      type: "number",
      width: 80,
      editable: true,
      valueSetter: (params) => {
        if (
          parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
          parseFloat(params.value) !== parseFloat(params.row.mrp4)
        ) {
          toast.error("Enter A Valid MRP!", {
            toastId: "error1",
          });
        } else {
          const mrp = params.value;
          const salerate = parseFloat(
            (parseFloat(params.value) * 100) /
              (100 + parseFloat(params.row.gstrate))
          ).toFixed(2);
          const grossvalue = parseFloat(params.row.saleqty * salerate).toFixed(
            2
          );
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat(gstamt).toFixed(2)
              : 0;
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = emodedetails.findIndex(
            (obj) => obj.itemid === params.row.itemid
          );
          emodedetails[upd_obj].mrp = mrp;
          emodedetails[upd_obj].salerate = salerate;
          emodedetails[upd_obj].grossvalue = grossvalue;
          emodedetails[upd_obj].discoutnvalue = discountvalue;
          emodedetails[upd_obj].taxablevalue = taxablevalue;
          emodedetails[upd_obj].gstamt = gstamt;
          emodedetails[upd_obj].cgstvalue = cgstvalue;
          emodedetails[upd_obj].sgstvalue = sgstvalue;
          emodedetails[upd_obj].igstvalue = igstvalue;
          emodedetails[upd_obj].netvalue = netvalue;
          return {
            ...params.row,
            mrp,
            salerate,
            grossvalue,
            discountvalue,
            taxablevalue,
            gstamt,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 80,
      type: "number",
      editable: true,
      valueSetter: (params) => {
        const discountrate = params.value;
        const discountvalue = parseFloat(
          (params.row.grossvalue * discountrate) / 100
        ).toFixed(2);
        const taxablevalue = parseFloat(
          parseFloat(params.row.grossvalue) - parseFloat(discountvalue)
        ).toFixed(2);
        const gstamt = parseFloat(
          (taxablevalue * params.row.gstrate) / 100
        ).toFixed(2);
        const cgstvalue =
          parseInt(partyInput.stateid) === 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
            : 0;
        const sgstvalue =
          parseInt(partyInput.stateid) === 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 200).toFixed(2)
            : 0;
        const igstvalue =
          parseInt(partyInput.stateid) !== 1
            ? parseFloat((taxablevalue * params.row.gstrate) / 100).toFixed(2)
            : 0;
        const netvalue = parseFloat(
          parseFloat(taxablevalue) +
            parseFloat(cgstvalue) +
            parseFloat(sgstvalue) +
            parseFloat(igstvalue)
        ).toFixed(2);
        const upd_obj = emodedetails.findIndex(
          (obj) => obj.itemid === params.row.itemid
        );

        emodedetails[upd_obj].discountrate = discountrate;
        emodedetails[upd_obj].discountvalue = discountvalue;
        emodedetails[upd_obj].taxablevalue = taxablevalue;
        emodedetails[upd_obj].gstamt = gstamt;
        emodedetails[upd_obj].cgstvalue = cgstvalue;
        emodedetails[upd_obj].sgstvalue = sgstvalue;
        emodedetails[upd_obj].igstvalue = igstvalue;
        emodedetails[upd_obj].netvalue = netvalue;
        return {
          ...params.row,
          discountrate,
          discountvalue,
          taxablevalue,
          gstamt,
          cgstvalue,
          sgstvalue,
          igstvalue,
          netvalue,
        };
      },
    },
    {
      field: "bufferstock",
      headerClassName: "super-app-theme--header",
      headerName: "Buffer Stock",
      width: 100,
      type: "number",
    },
    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "Current MRP",
      type: "number",
      width: 80,
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      type: "number",
      width: 100,
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      type: "number",
      width: 100,
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      type: "number",
      width: 80,
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "grossvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 80,
      type: "number",
    },
    {
      field: "discountvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Dis Amt",
      width: 80,
      type: "number",
    },
    {
      field: "taxablevalue",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "cgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "CGSTAmt",
      width: 80,
      type: "number",
    },
    {
      field: "sgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "SGSTAmt",
      width: 100,
      type: "number",
    },

    {
      field: "igstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "IGSTAmt",
      width: 100,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GSTAmt",
      width: 120,
      type: "number",
    },
    {
      field: "netvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
  ];
  // SETUP ARRAY FOR INSERTION OF NEW SALE BILL
  const [salebill, setsalebill] = useState([]);
  const salebillarray = Object.values(salebill);
  //console.log(salebillarray);
  //console.log(partyInput);
  // ADD ROW ON CLICK
  const [orderdetailrow, setOrderDetailRow] = useState([]);
  const [salerate, setsalerate] = useState(0);
  // console.log(orderdetailrow);
  const handleaddrow = () => {
    if (
      parseInt(mode) === 1
        ? orderdetailrow.map((e) => e.itemid).includes(partno.itemid)
        : emodedetails.map((e) => e.itemid).includes(partno.itemid)
    ) {
      toast.error("Item Added Already!");
    } else if (parseInt(partno.purchaseqty) < parseInt(itemqty)) {
      toast.error("Error: Item quantity should not exceed available stock");
    } else if (parseInt(itemqty) <= 0) {
      toast.error("Error: Item quantity should not be negative or zero!");
    } else if (parseInt(itemqty) > parseInt(partno.purchaseqty)) {
      toast.error("Error: Item quantity should not exceed stock!");
    } else {
      if (parseInt(mode) === 1) {
        if (state.saletype === 3) {
          const groupdisc =
            parseInt(partno.groupid) === 1
              ? state.partsdisc
              : parseInt(partno.groupid) === 2
              ? state.lubrdisc
              : parseInt(partno.groupid) === 3
              ? state.batterydisc
              : state.labourdisc;
          const newsalerate = salerate;
          const finaldisc =
            partno.spcldiscount === true
              ? parseFloat(partyInput.specialdis) + parseFloat(groupdisc)
              : parseFloat(groupdisc);
          const discountamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(newsalerate) *
                  parseFloat(finaldisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(newsalerate) *
                  parseFloat(finaldisc)) /
                100;
          const valueamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(newsalerate)
              : parseInt(partno.purchaseqty) * parseFloat(newsalerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const newmrp = (
            parseFloat((newsalerate * partno.gstrate) / 100) +
            parseFloat(newsalerate)
          ).toFixed(2);
          const netamount = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setOrderDetailRow((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: newmrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstpers: partno.gstrate,
              salerate: newsalerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              groupid: partno.groupid,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: parseFloat(finaldisc).toFixed(2),
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: parseFloat(netamount).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          const groupdisc = partno.discpers;
          // const finaldiscountrate =
          //   partno.spcldiscount === true
          //     ? parseFloat(discrate) + parseFloat(specialdis)
          //     : parseFloat(discrate);
          const groupDiscounts = {
            12: parseFloat(groupdisc), // ENGINE OIL
            13: parseFloat(groupdisc), // ESSENTIAL OIL
            14: parseFloat(groupdisc), // ENF SPCL 20
            15: parseFloat(groupdisc), // ENF SPCL 25
            16: parseFloat(groupdisc) + parseFloat(state.enginepartspia), // PIAJIO ENGINE PARTS
            2: parseFloat(groupdisc) + parseFloat(state.lubrdisc), // LUBRICANT
            3: parseFloat(groupdisc) + parseFloat(state.batterydisc), // BATTERY
            9: parseFloat(groupdisc) + parseFloat(state.raceprodiscrate), // RACE PRO
            10: parseFloat(groupdisc) + parseFloat(state.semisyntheticdiscrate), // SEMI SYNTHETIC
            11: parseFloat(groupdisc) + parseFloat(state.syntheticdiscrate), // SYNTHETIC
            // Add more groups here as needed
          };

          const finaldisc =(partyInput.cashtype===true || partyInput.jobcardflag===true) ? 0 : partno.spcldiscount
            ? parseFloat(partyInput.specialdis) +
              (groupDiscounts[parseInt(partno.groupid)] ||
                parseFloat(state.partsdisc))
            : groupDiscounts[parseInt(partno.groupid)] ||
              parseFloat(state.partsdisc);
          const discountamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldisc)) /
                100;
          const valueamount =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const taxableamount = parseFloat(
            valueamount - discountamount
          ).toFixed(2);
          const gstamount = parseFloat(
            (taxableamount * partno.gstrate) / 100
          ).toFixed(2);
          const netamount = parseFloat(
            parseFloat(taxableamount) + parseFloat(gstamount)
          ).toFixed(2);
          setOrderDetailRow((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstpers: partno.gstrate,
              salerate: partno.salerate,
              itemid: partno.itemid,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              groupid: partno.groupid,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              dispers: parseFloat(finaldisc).toFixed(2),
              value: parseFloat(valueamount).toFixed(2),
              disamt: parseFloat(discountamount).toFixed(2),
              taxable: parseFloat(taxableamount).toFixed(2),
              gstamt: parseFloat(gstamount).toFixed(2),
              netamt: parseFloat(netamount).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      } else {
        if (state.saletype === 3) {
          const groupdisc =
            parseInt(partno.groupid) === 1
              ? state.partsdisc
              : parseInt(partno.groupid) === 2
              ? state.lubrdisc
              : parseInt(partno.groupid) === 3
              ? state.batterydisc
              : state.labourdisc;

          const newsalerate = salerate;
          const finaldisc =
            partno.spcldiscount === true
              ? parseFloat(partyInput.specialdis) + parseFloat(groupdisc)
              : parseFloat(groupdisc);
          const discountvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(newsalerate) *
                  parseFloat(finaldisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(newsalerate) *
                  parseFloat(finaldisc)) /
                100;
          const discountrate = finaldisc;
          // console.log('Group Discount:',groupdisc);
          // console.log('Final Discount:',finaldisc);
          // console.log('Discount Rate',discountrate);
          //const bufferstock = stock;
          const grossvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(newsalerate)
              : parseInt(partno.purchaseqty) * parseFloat(newsalerate);
          const taxablevalue = parseFloat(grossvalue - discountvalue);
          const cgstrate =
            parseInt(partyInput.stateid) === 1
              ? (parseFloat(partno.gstrate) / 2).toFixed(2)
              : 0;
          const igstrate =
            parseInt(partyInput.stateid) !== 1 ? parseFloat(partno.gstrate) : 0;
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100) / 2
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100)
              : 0;
          const gstamt = parseFloat(
            parseFloat(cgstvalue * 2).toFixed(2) +
              parseFloat(igstvalue).toFixed(2)
          ).toFixed(2);
          const newmrp = (
            parseFloat((newsalerate * partno.gstrate) / 100) +
            parseFloat(newsalerate)
          ).toFixed(2);
          setEmodeDetails((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: newmrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstrate: partno.gstrate,
              salerate: newsalerate,
              itemid: partno.itemid,
              discountrate,
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              groupid: partno.groupid,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              grossvalue: parseFloat(grossvalue).toFixed(2),
              discountvalue: parseFloat(discountvalue).toFixed(2),
              taxablevalue: parseFloat(taxablevalue).toFixed(2),
              cgstrate: parseFloat(cgstrate).toFixed(2),
              sgstrate: parseFloat(cgstrate).toFixed(2),
              igstrate: parseFloat(igstrate).toFixed(2),
              cgstvalue: parseFloat(cgstvalue).toFixed(2),
              sgstvalue: parseFloat(cgstvalue).toFixed(2),
              igstvalue: parseFloat(igstvalue).toFixed(2),
              gstamt,
              netvalue: parseFloat(
                parseFloat(taxablevalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(igstvalue)
              ).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        } else {
          const groupdisc = partno.discpers;
          // const finaldiscountrate =
          //   partno.spcldiscount === true
          //     ? parseFloat(discrate) + parseFloat(specialdis)
          //     : parseFloat(discrate);
          const groupDiscounts = {
            12: parseFloat(groupdisc), // ENGINE OIL
            13: parseFloat(groupdisc), // ESSENTIAL OIL
            14: parseFloat(groupdisc), // ENF SPCL 20
            15: parseFloat(groupdisc), // ENF SPCL 25
            16: parseFloat(groupdisc) + parseFloat(state.enginepartspia), // PIAJIO ENGINE PARTS
            2: parseFloat(groupdisc) + parseFloat(state.lubrdisc), // LUBRICANT
            3: parseFloat(groupdisc) + parseFloat(state.batterydisc), // BATTERY
            9: parseFloat(groupdisc) + parseFloat(state.raceprodiscrate), // RACE PRO
            10: parseFloat(groupdisc) + parseFloat(state.semisyntheticdiscrate), // SEMI SYNTHETIC
            11: parseFloat(groupdisc) + parseFloat(state.syntheticdiscrate), // SYNTHETIC
            // Add more groups here as needed
          };

          const finaldisc =(partyInput.cashtype===true || partyInput.jobcardflag===true) ? 0 : partno.spcldiscount
            ? parseFloat(partyInput.specialdis) +
              (groupDiscounts[parseInt(partno.groupid)] ||
                parseFloat(state.partsdisc))
            : groupDiscounts[parseInt(partno.groupid)] ||
              parseFloat(state.partsdisc);
          const discountvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? (parseInt(itemqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldisc)) /
                100
              : (parseInt(partno.purchaseqty) *
                  parseFloat(partno.salerate) *
                  parseFloat(finaldisc)) /
                100;
          const discountrate = finaldisc;
          //const bufferstock = stock;
          const grossvalue =
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty) * parseFloat(partno.salerate)
              : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
          const taxablevalue = parseFloat(grossvalue - discountvalue);
          const cgstrate =
            parseInt(partyInput.stateid) === 1
              ? (parseFloat(partno.gstrate) / 2).toFixed(2)
              : 0;
          const igstrate =
            parseInt(partyInput.stateid) !== 1 ? parseFloat(partno.gstrate) : 0;
          const cgstvalue =
            parseInt(partyInput.stateid) === 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100) / 2
              : 0;
          const igstvalue =
            parseInt(partyInput.stateid) !== 1
              ? parseFloat((taxablevalue * partno.gstrate) / 100)
              : 0;
          const gstamt = parseFloat(
            parseFloat(cgstvalue * 2).toFixed(2) +
              parseFloat(igstvalue).toFixed(2)
          ).toFixed(2);
          setEmodeDetails((oldRows) => [
            ...oldRows,
            {
              serial: oldRows.length + 1,
              partdetails: partno.partdetails,
              mrp: partno.mrp,
              mrp1: partno.mrp1,
              mrp2: partno.mrp2,
              mrp3: partno.mrp3,
              mrp4: partno.mrp4,
              gstrate: partno.gstrate,
              salerate: partno.salerate,
              itemid: partno.itemid,
              discountrate: parseFloat(discountrate),
              rackname: partno.rackname,
              stkqty: partno.purchaseqty,
              ordqty: itemqty,
              groupid: partno.groupid,
              losqty:
                itemqty - partno.purchaseqty > 0
                  ? itemqty - partno.purchaseqty
                  : 0,
              losval:
                itemqty - partno.purchaseqty > 0
                  ? (itemqty - partno.purchaseqty) * partno.salerate
                  : 0,
              saleqty:
                parseInt(partno.purchaseqty) > parseInt(itemqty)
                  ? parseInt(itemqty)
                  : parseInt(partno.purchaseqty),
              grossvalue: parseFloat(grossvalue).toFixed(2),
              discountvalue: parseFloat(discountvalue).toFixed(2),
              taxablevalue: parseFloat(taxablevalue).toFixed(2),
              cgstrate: parseFloat(cgstrate).toFixed(2),
              sgstrate: parseFloat(cgstrate).toFixed(2),
              igstrate: parseFloat(igstrate).toFixed(2),
              cgstvalue: parseFloat(cgstvalue).toFixed(2),
              sgstvalue: parseFloat(cgstvalue).toFixed(2),
              igstvalue: parseFloat(igstvalue).toFixed(2),
              gstamt: gstamt,
              netvalue: parseFloat(
                parseFloat(taxablevalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(cgstvalue) +
                  parseFloat(igstvalue)
              ).toFixed(2),
              hsncode: partno.hsncode,
            },
          ]);
          setpartno(null);
          setitemqty(0);
          setstock(0);
          setDelItem("");
          setaltFlag("");
          setaltparts([]);
          setNewModePartNo("");
        }
      }
    }
  };

  // Search parts
  const [partno, setpartno] = useState(null);

  const [stock, setstock] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState();
  const [allparts, setallparts] = useState([]);
  async function getallparts() {
    try {
      if (state.saletype !== 3 && isSwitchOn) {
        const response = await fetch("/allparts", {
          method: "GET",
          headers: { sptoken: userToken },
        });
        const jsonData = await response.json();
        setallparts(jsonData);
      } else if (state.saletype === 3 && isSwitchOn) {
        const response = await fetch("/labour/alllabours", {
          method: "GET",
          headers: { sptoken: userToken },
        });
        const jsonData = await response.json();
        setallparts(jsonData);
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, [state.saletype, isSwitchOn]);

  useEffect(() => {
    if (partno && state.saletype !== 3) {
      quantityRef.current.focus();
    }
  }, [partno]);

  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [totalvalue, setTotalvalue] = useState(0);
  const [totaldisamt, setTotaldisamt] = useState(0);
  const [totaltaxamt, setTotaltaxamt] = useState(0);
  const [totalcgst, setTotalcgst] = useState(0);
  const [totalsgst, setTotalsgst] = useState(0);
  const [totaligst, setTotaligst] = useState(0);
  const [totalgstamt, setTotalgstamt] = useState(0);
  const [totalnetamt, setTotalnetamt] = useState(0);
  const [delvcharge, setDelvcharge] = useState(0);
  const [disableUpdate, setDisableUpdate] = useState(false);
  let billAmt = Math.round(parseFloat(totalnetamt) + parseFloat(delvcharge));
  let roundOff = parseFloat(
    parseFloat(billAmt) - (parseFloat(totalnetamt) + parseFloat(delvcharge))
  ).toFixed(2);

  function CustomFooterTotalComponent(props) {
    return (
      <Box sx={{ padding: "10px", height: 110 }}>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Dis Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Tot Taxable Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Tot GST Amt
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totalvalue}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.totaldisamt}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totaltaxamt}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totalcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totalsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totaligst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totalgstamt}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent.propTypes = {
    totalvalue: PropTypes.number,
    totaldisamt: PropTypes.number,
    totaltaxamt: PropTypes.number,
    totalcgst: PropTypes.number,
    totalsgst: PropTypes.number,
    totaligst: PropTypes.number,
    totalgstamt: PropTypes.number,
  };

  /// TOTAL VALUE STATES FOR  HEADER TABLE
  const [newtotaltaxable, setnewtotaltaxable] = useState(0);
  const [newtotalcgst, setnewtotalcgst] = useState(0);
  const [newtotaldiscount, setnewtotaldiscount] = useState(0);
  const [newtotalgross, setnewtotalgross] = useState(0);
  const [newtotalsgst, setnewtotalsgst] = useState(0);
  const [newtotaligst, setnewtotaligst] = useState(0);
  const [newtotalgst, setnewtotalgst] = useState(0);
  const [newtotalnetamt, setnewtotalnetamt] = useState(0);
  const [newdelvcharge, setNewDelvcharge] = useState(0);

  let newbillAmt = Math.round(
    parseFloat(newtotalnetamt) + parseFloat(newdelvcharge)
  );
  let newroundOff = parseFloat(
    parseFloat(newbillAmt) -
      (parseFloat(newtotalnetamt) + parseFloat(newdelvcharge))
  ).toFixed(2);
  function CustomFooterTotalComponent2(props) {
    return (
      <Box sx={{ padding: "10px", height: 110 }}>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Dis Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Tot Taxable Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST Amt
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  Tot GST Amt
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalgross}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.newtotaldiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaltaxable}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaligst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalgst}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent2.propTypes = {
    newtotaltaxable: PropTypes.number,
    newtotalcgst: PropTypes.number,
    newtotalsgst: PropTypes.number,
    newtotaligst: PropTypes.number,
    newtotalgross: PropTypes.number,
    newtotaldiscount: PropTypes.number,
    newtotalgst: PropTypes.number,
    newtotalnetamt: PropTypes.number,
  };

  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  console.log(emodedetails);
  // SUBMIT CUSTOMER ORDER DETAILS
  const handleSaleBillSubmit = async (e) => {
    if (mkpkInput === null || pickerInput === null) {
      return toast.error("Please enter Maker Picker!!");
    }
    if (parseFloat(billAmt) === 0) {
      return toast.error("Bill Amount Cannot Be Zero!!");
    }
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = {
        state,
        partyInput,
        mkpkInput,
        pickerInput,
        salesmanInput,
        salebillarray,
        totalcgst,
        totalsgst,
        totalvalue,
        totaldisamt,
        totaltaxamt,
        totaligst,
        totalgstamt,
        delvcharge,
        roundOff,
        billAmt,
        invoicedate,
      };
      const response = await fetch("/spare/directsale/dsinsert", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(`Bill No ${parseRes.bill} Generated Successfully!`, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  /// SUBMIT EDITMODE DETAILS

  const handleEditmodeSubmit = async (e) => {
    if (parseFloat(newbillAmt) === 0) {
      return toast.error("Bill Amount Cannot Be Zero!!");
    }
    e.preventDefault();
    try {
      setDisableUpdate(true);
      const body = {
        emodedetails,
        newtotalgross,
        newtotaltaxable,
        newtotaldiscount,
        newtotalcgst,
        newtotalsgst,
        newtotaligst,
        newtotalgst,
        newtotalnetamt,
        newbillAmt,
        newroundOff,
        newdelvcharge,
        state,
        partyInput,
        mkpkInput,
        pickerInput,
        salesmanInput,
        invoicedate,
      };
      const response = await fetch(
        `/spare/directsale/up/${searchInput.billheaderid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(`${parseRes.message}`, {
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err.message);
      setDisableUpdate(false);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);

  // DROPDOWN VALUES AND HANDLER
  const saletypeopts = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Spare",
    },
    {
      value: 3,
      label: "Labour",
    },
  ];

  const sortopts = [
    {
      value: "Rack",
      label: "Rack",
    },
    {
      value: "Item",
      label: "Item",
    },
  ];
  const balanceopts = [
    {
      value: "Y",
      label: "Yes",
    },
    {
      value: "N",
      label: "No",
    },
  ];

  //----------- Modal Handler-------

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    // "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //CLICKING ON ORDER SEARCH EXECUTES FOLLOWING \\
  const [emodearray, setemodearray] = useState([]);
  const handleRecalculate = () => {
    const discounts = [
      parseFloat(state.partsdisc),
      parseFloat(state.lubrdisc),
      parseFloat(state.batterydisc),
      parseFloat(state.labourdisc),
      parseFloat(state.syntheticdiscrate),
      parseFloat(state.semisyntheticdiscrate),
      parseFloat(state.raceprodiscrate),
      parseFloat(state.enginepartspia),
    ];

    const activeDiscounts = discounts.filter((discount) => discount > 0);

    if (activeDiscounts.length > 1) {
      return toast.error("Can't give more than 1 discount type!");
    }
    // if (
    //   (parseFloat(state.partsdisc) > 0 &&
    //     (parseFloat(state.lubrdisc) > 0 ||
    //       parseFloat(state.batterydisc) > 0 ||
    //       parseFloat(state.labourdisc) > 0)) ||
    //   (parseFloat(state.lubrdisc) > 0 &&
    //     (parseFloat(state.batterydisc) > 0 ||
    //       parseFloat(state.labourdisc) > 0)) ||
    //   (parseFloat(state.batterydisc) > 0 && parseFloat(state.labourdisc) > 0)
    // ) {
    //   return toast.error("Can't give more than 1 discount type!");
    // }
    if (parseInt(mode) === 1) {
      const updatedRows = orderdetailrow.map((row) => {
        const discpers =
          parseFloat(state.partsdisc) +
          parseFloat(state.labourdisc) +
          parseFloat(state.lubrdisc) +
          parseFloat(state.batterydisc) +
          parseFloat(state.syntheticdiscrate) +
          parseFloat(state.semisyntheticdiscrate) +
          parseFloat(state.raceprodiscrate) +
          parseFloat(state.enginepartspia);
        const disamt = ((parseFloat(row.value) * discpers) / 100).toFixed(2);
        const taxable = (parseFloat(row.value) - parseFloat(disamt)).toFixed(2);
        const gstamt = (
          (parseFloat(taxable) * parseFloat(row.gstpers)) /
          100
        ).toFixed(2);
        const cgstamt = gstamt / 2;
        const sgstamt = gstamt / 2;
        const igstamt = 0.0;
        const netamt = (parseFloat(taxable) + parseFloat(gstamt)).toFixed(2);
        return {
          ...row,
          dispers: discpers,
          disamt,
          taxable,
          gstamt,
          cgstamt,
          sgstamt,
          igstamt,
          netamt,
        };
      });
      setOrderDetailRow(updatedRows);
    } else {
      const updatedRows = emodedetails.map((row) => {
        const discpers =
          parseFloat(state.partsdisc) +
          parseFloat(state.labourdisc) +
          parseFloat(state.lubrdisc) +
          parseFloat(state.batterydisc) +
          parseFloat(state.syntheticdiscrate) +
          parseFloat(state.semisyntheticdiscrate) +
          parseFloat(state.raceprodiscrate) +
          parseFloat(state.enginepartspia);
        const disamt = ((parseFloat(row.grossvalue) * discpers) / 100).toFixed(
          2
        );
        const taxable = (
          parseFloat(row.grossvalue) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = (
          (parseFloat(taxable) * parseFloat(row.gstrate)) /
          100
        ).toFixed(2);
        const cgstamt = gstamt / 2;
        const sgstamt = gstamt / 2;
        const igstamt = 0.0;
        const netamt = (parseFloat(taxable) + parseFloat(gstamt)).toFixed(2);
        return {
          ...row,
          discountrate: discpers,
          discountvalue: disamt,
          taxablevalue: taxable,
          cgstvalue: cgstamt,
          sgstvalue: sgstamt,
          igstvalue: 0,
          netvalue: netamt,
        };
      });
      setEmodeDetails(updatedRows);
    }
  };
  //picklist
  const handleReport = async (e) => {
    e.preventDefault();
    setdisablereportbtn(true);
    try {
      const response = await fetch(
        `/spare/directsale/picklist/report/${docno.billheaderid}/${sorttype}`,
        {
          headers: {
            sptoken: userToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStreamR(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };

  const resetStates = () => {
    setEmodeDetails([]);
    setpartno(null);
    setitemqty(0);
    // setinvoicedate(null);
    setSearchInput(null);
    setMkpkInput(null);
    setPickerInput(null);
    setSalesmanInput(null);
    //setPartyInput(null);
    setState((prevState) => ({
      ...prevState,
      // statename: "",
      address1: "",
      address2: "",
      address3: "",
      partsdisc: "",
      lubrdisc: "",
      batterydisc: "",
      syntheticdiscrate: "",
      semisyntheticdiscrate: "",
      raceprodiscrate: "",
      enginepartspia: "",
      invoiceno: "",
    }));
    // setCalculateBillAmt(0);
  };

  const [gstbilltoggle, setgstbilltoggle] = useState(false);
  const [gstno, setgstno] = useState("");
  const [partyname, setpartyname] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [pincode, setpincode] = useState("");
  const [regphno, setregphno] = useState("");
  const [stateinput, setstateinput] = useState(null);
  const [stateopts, setstateopts] = useState([]);
  const [areainput, setareainput] = useState(null);
  const [areaopts, setareaopts] = useState([]);

  const [dopen, setdOpen] = useState(false);
  const handledOpen = () => setdOpen(true);
  const handledClose = () => {
    setdOpen(false);
    setgstno("");
    setpartyname("");
    setaddress1("");
    setaddress2("");
    setpincode("");
    setregphno("");
    setstateinput(null);
    setareainput(null);
  };

  //fetch state data
  async function getstatedata() {
    try {
      const response = await fetch("/spare/directsale/state", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setstateopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getstatedata();
  }, []);

  //fetch data from GSTNO
  const handleFetchData = async () => {
    try {
      if (gstno !== null || gstno !== "") {
        const body = { searchgst: gstno };
        const data = await fetch("/spare/directsale/partygstsearch", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            sptoken: userToken,
          },
          body: JSON.stringify(body),
        });
        const res = await data.json();

        setgstno((prev) => prev.toUpperCase());
        //console.log(res);
        if (res.type == "success") {
          toast.info(res.message);
          setpartyname(res.data.EWBDetails.tradeName);
          setaddress1(res.data.EWBDetails.address1);
          setaddress2(res.data.EWBDetails.address2);
          setpincode(res.data.EWBDetails.pinCode);
          // Extract stateCode from API response
          const stateCode = res.data.EWBDetails.stateCode;
          //console.log(stateCode);
          // Use array.filter() to find the state based on stateCode
          const filteredState = stateopts.filter(
            (opt) => opt.statecode === stateCode
          );
          //console.log(filteredState)
          // Set stateinput with the filtered state's id
          if (filteredState.length > 0) {
            setstateinput(filteredState[0]);
          }
        } else {
          toast.error(res.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //fetch state data
  async function getareadata() {
    try {
      const response = await fetch(
        `/spare/directsale/area/${stateinput.stateid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setareaopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (stateinput !== null) {
      getareadata();
    }
  }, [stateinput]);

  //insert subledger if gstno not find
  const [disableInsert, setdisableInsert] = useState(false);
  const handledSaveData = async (e) => {
    if (regphno === null || regphno === "") {
      setdisableInsert(false);
      return toast.error("Please enter Phone No!!");
    }
    if (areainput === null) {
      setdisableInsert(false);
      return toast.error("Please Select An Area!!");
    }
    e.preventDefault();
    setdisableInsert(true);
    try {
      const body = {
        subledgername: partyname,
        destinationid: areainput.destid,
        gstno: gstno,
        salesmanid: salesmanInput.salesmanid,
        regdphnno: regphno,
        address1: address1,
        address2: address2,
        pincode: pincode,
        deliveryat: areainput.destname,
      };
      const response = await fetch("/spare/directsale/insert/Subledger", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(`${parseRes.message}`, {
          autoClose: false,
          onClose: () => window.location.reload(false),
        });
        setpartyfetch(true);
        setgstno("");
        setpartyname("");
        setaddress1("");
        setaddress2("");
        setpincode("");
        setregphno("");
        setstateinput(null);
        setareainput(null);
        setdisableInsert(false);
      }
    } catch (err) {
      setdisableInsert(false);
      console.log(err.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"DIRECT SALE"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"10%"} mb={"10px"} direction="row">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={1}
                  name="radio-buttons-group"
                  row
                >
                  {saletypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={
                        <Radio
                          size="small"
                          disabled={parseInt(mode) === 1 ? true : false}
                        />
                      }
                      label={opt.label}
                      onChange={(e) => setBillType(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={emodebills}
                getOptionLabel={(option) => option.salebilldetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search Party"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="billMode"
                required
                select
                label="Bill Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2 || e.target.value === 3) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    resetStates();
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    resetStates();
                  }
                }}
                // helperText="Select your Bill mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Link to="/testreport">
                <Button>Sale Bill Print→</Button>
              </Link>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gstbilltoggle}
                    onChange={(e) => setgstbilltoggle(e.target.checked)}
                  />
                }
                label="GST Bill"
              />
              {gstbilltoggle && (
                <Button
                  color="success"
                  onClick={handledOpen}
                  size="small"
                  variant="outlined"
                >
                  Verify
                </Button>
              )}
              {parseInt(mode) === 2 && (
                <Button onClick={handlestkOpen} size="small" variant="outlined">
                  SALE BILL PICK LIST
                </Button>
              )}
              {parseInt(mode) === 3 &&
                (irn !== null ? (
                  <Typography display={irn === "" ? "none" : "block"}>
                    {" "}
                    {`Generated IRN ${irn}`}
                  </Typography>
                ) : (
                  <Typography>
                    IRN not available or yet to be generated!!
                  </Typography>
                ))}
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <Autocomplete
                id="partyName"
                value={partyInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    const cashcustomer = partyopts.find(
                      (opt) => opt.cashtype === true
                    );
                    console.log(cashcustomer);
                    setPartyInput({ ...cashcustomer });
                    setSalesmanInput({
                      salesmandetails: cashcustomer.salesmandetails,
                      salesmanid: cashcustomer.salesmanid,
                    });
                  } else {
                    setPartyInput(newValue);
                    setSalesmanInput({
                      salesmandetails: `${newValue.salesmandetails}`,
                      salesmanid: `${newValue.salesmanid}`,
                    });
                    setState({
                      ...state,
                      partsdisc: `${newValue.partsdiscrate}`,
                      batterydisc: `${newValue.batterydiscrate}`,
                      lubrdisc: `${newValue.lubdiscrate}`,
                      labourdisc: 0,
                      enginepartspia: `${newValue.enginepartspia}`,
                      raceprodiscrate: `${newValue.raceprodiscrate}`,
                      syntheticdiscrate: `${newValue.syntheticdiscrate}`,
                      semisyntheticdiscrate: `${newValue.semisyntheticdiscrate}`,
                    });
                  }
                }}
                options={partyopts}
                getOptionLabel={(option) => option.subledgername}
                isOptionEqualToValue={(option, value) =>
                  parseInt(option.subledgerid) === parseInt(value.subledgerid)
                }
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField
                    required
                    {...billopts}
                    placeholder="Party Details"
                  />
                )}
                size="small"
              />

              <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                <TextField
                  id="saleType"
                  required
                  select
                  label="Sale Type"
                  name="saletype"
                  value={state.saletype}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: "60%",
                  }}
                  size="small"
                >
                  {saletypeopts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="sortType"
                  required
                  name="sorttype"
                  select
                  label="Sort By"
                  value={state.sorttype}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    width: "40%",
                  }}
                  size="small"
                >
                  {sortopts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <TextField
                id="invoiceNo"
                label="Invoice No"
                name="invoiceno"
                value={state.invoiceno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  disabled
                  inputFormat="DD/MM/YYYY"
                  value={invoicedate}
                  onChange={(newValue) => {
                    setinvoicedate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="customerName"
                label="Customer Name"
                name="customername"
                value={state.customername}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="stateName"
                required
                label="State Name"
                name="statename"
                value={state.statename}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address1"
                label="Address 1"
                value={state.address1}
                name="address1"
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="address2"
                label="Address 2"
                value={state.address2}
                name="address2"
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <TextField
                id="address3"
                label="Address 3"
                value={state.address3}
                name="address3"
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <Autocomplete
                id="makersearch"
                value={mkpkInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setMkpkInput(newValue);
                }}
                options={mkpkopts}
                getOptionLabel={(option) => option.mkpkdetails}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Maker" />
                )}
                size="small"
              />
              <Autocomplete
                id="pickersearch"
                value={pickerInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setPickerInput(newValue);
                }}
                options={mkpkopts}
                getOptionLabel={(option) => option.mkpkdetails}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Picker" />
                )}
                size="small"
              />
              <Autocomplete
                id="salesmansearch"
                value={salesmanInput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setSalesmanInput(newValue);
                }}
                options={salesmanopts}
                getOptionLabel={(option) => option.salesmandetails}
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Salesman" />
                )}
                size="small"
              />
            </Stack>

            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <TextField
                id="partsDisc"
                label="Parts Disc %"
                value={state.partsdisc}
                onChange={handleChange}
                type="text"
                name="partsdisc"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="lubydisc"
                label="Lubr Disc %"
                name="lubrdisc"
                value={state.lubrdisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="batteryDisc"
                label="Battery Disc %"
                name="batterydisc"
                value={state.batterydisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="labourDisc"
                label="Labour Disc %"
                name="labourdisc"
                value={state.labourdisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="syntheticdiscrate"
                label="Synthetic Disc %"
                name="syntheticdiscrate"
                value={state.syntheticdiscrate}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="semisyntheticdiscrate"
                label="Semisynthetic Disc %"
                name="semisyntheticdiscrate"
                value={state.semisyntheticdiscrate}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="raceprodiscrate"
                label="Racepro Disc %"
                name="raceprodiscrate"
                value={state.raceprodiscrate}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="enginepartspia"
                label="EngineParts Disc %"
                name="enginepartspia"
                value={state.enginepartspia}
                onChange={handleChange}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <Button
                variant="outlined"
                size="small"
                onClick={handleRecalculate}
              >
                Recalculate
              </Button>
            </Stack>

            <hr />

            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <Button
                sx={{ ml: "3%", mt: "13px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD
              </Button>
              {/* ITEM ADD MODAL */}
              <Modal
                open={open}
                aria-labelledby="modl-box"
                aria-describedby="search partitem"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      padding: 2,
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      columnGap: 1,
                      rowGap: 1,
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Old Mode</Typography>
                      <Switch
                        onChange={handleSwitchChange}
                        checked={isSwitchOn}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>New Mode</Typography>
                    </Stack>
                    <Box display={!isSwitchOn ? "flex" : "none"}>
                      <TextField
                        id="partno"
                        label="Part No"
                        value={newModePartNo}
                        onChange={(e) => setNewModePartNo(e.target.value)}
                        inputRef={newPartNoRef}
                        onKeyDown={handlePartnoKey}
                        sx={{
                          width: "200px",
                        }}
                        size="small"
                      />
                    </Box>
                    <Box display={isSwitchOn ? "flex" : "none"}>
                      <Autocomplete
                        id="partno"
                        value={partno}
                        filterOptions={filterOptions3}
                        onChange={(event, newValue) => {
                          setpartno(newValue);
                          setstock(newValue.purchaseqty);
                          setDelItem(newValue.deleteditem);
                          setaltFlag(newValue.altflag);
                        }}
                        options={allparts}
                        getOptionLabel={(option) => option.partdetails}
                        sx={{
                          width: "200px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Part No"
                            variant="outlined"
                            size="small"
                            inputRef={partnoRef}
                          />
                        )}
                      />
                    </Box>

                    {state.saletype === 3 && (
                      <TextField
                        id="salerate"
                        label="Sale Rate"
                        value={salerate}
                        onChange={(e) => setsalerate(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                      />
                    )}
                    <TextField
                      id="quantity"
                      label="Quantity"
                      value={itemqty}
                      onChange={(e) => setitemqty(e.target.value)}
                      inputRef={quantityRef}
                      onKeyDown={handleKeyDown}
                      type="number"
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                    />

                    <TextField
                      id="stock"
                      label="Stock"
                      value={stock}
                      type="number"
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />

                    <TextField
                      id="delitem"
                      label="Deleted Item"
                      value={delItem}
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />
                    <TextField
                      id="altitem"
                      label="Alternate Item"
                      value={altFlag}
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />

                    <Button
                      variant="contained"
                      tabIndex={-1}
                      size="medium"
                      color="success"
                      onClick={handleaddrow}
                    >
                      ADD
                    </Button>
                    <Button
                      variant="contained"
                      tabIndex={-1}
                      size="medium"
                      onClick={handleClose}
                      color="error"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Stack direction="row" mt={1} p={1}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Name
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Number
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Stock
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {altparts.length > 0 ? (
                        <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                          {altparts.map((opt) => (
                            <TableRow key={opt.alternateitemid}>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.itemname}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.itemcode}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.closingqty}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                <Button onClick={(e) => handleSwitch(opt, e)}>
                                  Switch
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <Typography variant="subtitle1" ml={10}>
                          NO ITEMS FOUND
                        </Typography>
                      )}
                    </Table>
                  </Stack>
                </Box>
              </Modal>
              <TextField
                id="delvcharg"
                label=" + Delv Charge"
                value={parseInt(mode) === 1 ? delvcharge : newdelvcharge}
                onChange={(e) =>
                  parseInt(mode) === 1
                    ? setDelvcharge(e.target.value)
                    : setNewDelvcharge(e.target.value)
                }
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              <TextField
                id="roundoff"
                label="Round Off"
                value={parseInt(mode) === 1 ? roundOff : newroundOff}
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              <TextField
                id="billamt"
                label="Bill Amount"
                value={parseInt(mode) === 1 ? billAmt : newbillAmt}
                type="number"
                sx={{
                  width: 200,
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              {orderdetailrow.length > 0 && (
                <>
                  <Chip
                    label="Parts"
                    size="small"
                    sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
                  />
                  <Chip
                    label="Lubricant"
                    size="small"
                    sx={{
                      backgroundColor: "#FFFF99",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Battery"
                    size="small"
                    sx={{
                      backgroundColor: "#FAD5A5",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Tyre & Tube"
                    size="small"
                    sx={{
                      backgroundColor: " #d9dad7",
                      //color: "white",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Accessries"
                    size="small"
                    sx={{
                      backgroundColor: "lightblue",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                </>
              )}
              {emodedetails.length > 0 && (
                <>
                  <Chip
                    label="Parts"
                    size="small"
                    sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
                  />
                  <Chip
                    label="Lubricant"
                    size="small"
                    sx={{
                      backgroundColor: "#FFFF99",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Battery"
                    size="small"
                    sx={{
                      backgroundColor: "#FAD5A5",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Tyre & Tube"
                    size="small"
                    sx={{
                      backgroundColor: " #d9dad7",
                      //color: "white",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Chip
                    label="Accessries"
                    size="small"
                    sx={{
                      backgroundColor: "lightblue",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                </>
              )}
            </Stack>
            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <StyledDataGrid
                  sx={{ border: "1px solid #000", height: 400 }}
                  rowHeight={35}
                  headerHeight={40}
                  showColumnRightBorder={true}
                  rows={orderdetailrow}
                  columns={columns}
                  getRowClassName={(params) => {
                    if (
                      parseFloat(params.row.closingqty) <
                      parseFloat(params.row.saleqty)
                    ) {
                      return "super-app-theme--Rejected";
                    } else if (params.row.groupid === "1") {
                      return "super-app-theme--Parts";
                    } else if (params.row.groupid === "2") {
                      return "super-app-theme--Lubricant";
                    } else if (params.row.groupid === "3") {
                      return "super-app-theme--Battery";
                    } else if (params.row.groupid === "4") {
                      return "super-app-theme--TyreTube";
                    } else if (params.row.groupid === "5") {
                      return "super-app-theme--Accessories";
                    } else {
                      return "";
                    }
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "serial", sort: "desc" }],
                    },
                  }}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    setsalebill(params.rows.idRowsLookup);
                    const valuetotal = salebillarray
                      .map((item) => parseFloat(item.value))
                      .reduce((a, b) => a + b, 0);
                    setTotalvalue(parseFloat(valuetotal).toFixed(2));
                    const distotal = salebillarray
                      .map((item) => parseFloat(item.disamt))
                      .reduce((a, b) => a + b, 0);
                    setTotaldisamt(parseFloat(distotal).toFixed(2));
                    const taxtotal = salebillarray
                      .map((item) => parseFloat(item.taxable))
                      .reduce((a, b) => a + b, 0);
                    setTotaltaxamt(parseFloat(taxtotal).toFixed(2));

                    const cgsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt / 2))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) === 1
                      ? setTotalcgst(parseFloat(cgsttotal).toFixed(2))
                      : setTotalcgst(0);
                    const sgsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt / 2))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) === 1
                      ? setTotalsgst(parseFloat(sgsttotal).toFixed(2))
                      : setTotalsgst(0);
                    const igsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    parseInt(partyInput.stateid) !== 1
                      ? setTotaligst(parseFloat(igsttotal).toFixed(2))
                      : setTotaligst(0);
                    const gsttotal = salebillarray
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0);
                    setTotalgstamt(parseFloat(gsttotal).toFixed(2));

                    const nettotal = salebillarray
                      .map((item) => parseFloat(item.netamt))
                      .reduce((a, b) => a + b, 0);
                    setTotalnetamt(parseFloat(nettotal).toFixed(2));
                  }}
                  components={{
                    Footer: CustomFooterTotalComponent,
                  }}
                  componentsProps={{
                    footer: {
                      totalvalue,
                      totaldisamt,
                      totaltaxamt,
                      totalcgst,
                      totalsgst,
                      totaligst,
                      totalgstamt,
                    },
                  }}
                />

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  justifyContent={"center"}
                >
                  <Button
                    //type="submit"
                    onClick={handleSaleBillSubmit}
                    disabled={disableSave}
                    variant="contained"
                    color="success"
                  >
                    SAVE BILL
                  </Button>
                  <Button
                    ////type="submit"
                    //onClick={handleEditmodeSubmit}
                    variant="contained"
                    color="warning"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    RESET BILL
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 || parseInt(mode) === 3 ? (
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <StyledDataGrid
                  sx={{ border: "1px solid #000", height: 400 }}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  rows={emodedetails}
                  columns={emodecolumns}
                  getRowClassName={(params) => {
                    if (
                      parseFloat(params.row.closingqty) <
                      parseFloat(params.row.saleqty)
                    ) {
                      return "super-app-theme--Rejected";
                    } else if (params.row.groupid === "1") {
                      return "super-app-theme--Parts";
                    } else if (params.row.groupid === "2") {
                      return "super-app-theme--Lubricant";
                    } else if (params.row.groupid === "3") {
                      return "super-app-theme--Battery";
                    } else if (params.row.groupid === "4") {
                      return "super-app-theme--TyreTube";
                    } else if (params.row.groupid === "5") {
                      return "super-app-theme--Accessories";
                    } else {
                      return "";
                    }
                  }}
                  getRowId={(row) => row.itemid}
                  onStateChange={(params) => {
                    const taxabletotal = emodedetails
                      .map((item) => parseFloat(item.taxablevalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaltaxable(taxabletotal);
                    const nettotal = emodedetails
                      .map((item) => parseFloat(item.netvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalnetamt(nettotal);
                    const cgsttotal = emodedetails
                      .map((item) => parseFloat(item.cgstvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalcgst(cgsttotal);
                    const sgsttotal = emodedetails
                      .map((item) => parseFloat(item.sgstvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalsgst(sgsttotal);
                    const igsttotal = emodedetails
                      .map((item) => parseFloat(item.igstvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaligst(igsttotal);
                    const grosstotal = emodedetails
                      .map((item) => parseFloat(item.grossvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalgross(grosstotal);
                    const discounttotal = emodedetails
                      .map((item) => parseFloat(item.discountvalue))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotaldiscount(discounttotal);
                    const gsttotal = emodedetails
                      .map((item) => parseFloat(item.gstamt))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2);
                    setnewtotalgst(gsttotal);
                  }}
                  components={{
                    Footer: CustomFooterTotalComponent2,
                  }}
                  componentsProps={{
                    footer: {
                      newtotaltaxable,
                      newtotalcgst,
                      newtotalsgst,
                      newtotaligst,
                      newtotalgross,
                      newtotaldiscount,
                      newtotalgst,
                    },
                  }}
                />
                {parseInt(mode) === 2 && (
                  <Stack
                    spacing={2}
                    columnGap={6}
                    direction="row"
                    mt={6}
                    pb={6}
                    justifyContent={"center"}
                  >
                    <Button
                      //type="submit"
                      disabled={disableUpdate}
                      onClick={handleEditmodeSubmit}
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    >
                      UPDATE BILL
                    </Button>
                    <Button
                      //onClick={}
                      variant="contained"
                      color="warning"
                      size="medium"
                      sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    >
                      RESET BILL
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <></>
            )}
            <Dialog open={stkopen}>
              <DialogTitle>Select Doc No:</DialogTitle>
              <DialogContent sx={{ width: "600px", height: "auto" }}>
                <Box
                  sx={{
                    padding: "5px",
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "center",
                    columnGap: "5px",
                    rowGap: 5,
                  }}
                >
                  <Autocomplete
                    id="orderno"
                    value={docno}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setdocno(newValue);
                    }}
                    options={docopts}
                    getOptionLabel={(option) => option.billno}
                    sx={{
                      width: "200px ",
                    }}
                    renderInput={(billopts) => (
                      <TextField required {...billopts} placeholder="Doc No" />
                    )}
                    size="small"
                  />
                  <TextField
                    id="roundoff"
                    label="Round Off"
                    value={parseInt(mode) === 1 ? roundOff : newroundOff}
                    type="number"
                    sx={{
                      width: 200,
                      backgroundColor: "#c7eff9",
                    }}
                    size="small"
                  />

                  <Button
                    disabled={disablereportbtn}
                    //type="submit"
                    onClick={handleReport}
                    variant="contained"
                    component="label"
                    size="small"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    //endIcon={<Task />}
                  >
                    Show Report
                  </Button>
                </Box>
                <Box sx={{ backgroundColor: "white" }}>
                  {pdfStreamR && (
                    <iframe
                      src={pdfStreamR}
                      width="100%"
                      height="680px"
                    ></iframe>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  tabIndex={-1}
                  variant="contained"
                  size="medium"
                  onClick={handlestkClose}
                  color="error"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog maxWidth={"md"} fullWidth open={dopen}>
              <DialogTitle>Fill The Details Below</DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    width: "100%",
                    gap: 1,
                    p: 1,
                  }}
                >
                  <TextField
                    id="gstno"
                    label="GSTNo"
                    value={gstno}
                    onBlur={handleFetchData}
                    onChange={(e) => setgstno(e.target.value)}
                    sx={{
                      width: 200,
                      backgroundColor: "#c7eff9",
                    }}
                    size="small"
                  />
                  <TextField
                    id="party"
                    label="Customer Name"
                    value={partyname}
                    onChange={(e) => setpartyname(e.target.value)}
                    sx={{
                      width: 200,
                    }}
                    size="small"
                  />
                  <TextField
                    id="address1"
                    label="Address1"
                    value={address1}
                    onChange={(e) => setaddress1(e.target.value)}
                    sx={{
                      width: 200,
                    }}
                    size="small"
                  />
                  <TextField
                    id="address2"
                    label="Address2"
                    value={address2}
                    onChange={(e) => setaddress2(e.target.value)}
                    sx={{
                      width: 200,
                    }}
                    size="small"
                  />
                  <TextField
                    id="pinno"
                    label="Pincode"
                    value={pincode}
                    onChange={(e) => setpincode(e.target.value)}
                    sx={{
                      width: 200,
                    }}
                    size="small"
                  />
                  <TextField
                    id="regphoneno"
                    label="RegPhoneNo"
                    value={regphno}
                    onChange={(e) => setregphno(e.target.value)}
                    sx={{
                      width: 200,
                    }}
                    size="small"
                  />
                  <Autocomplete
                    id="state"
                    disabled
                    value={stateinput}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setstateinput(newValue);
                    }}
                    options={stateopts}
                    getOptionLabel={(option) => option.statename}
                    sx={{
                      width: "200px ",
                    }}
                    renderInput={(billopts) => (
                      <TextField required {...billopts} placeholder="State" />
                    )}
                    size="small"
                  />
                  <Autocomplete
                    id="area"
                    value={areainput}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setareainput(newValue);
                    }}
                    options={areaopts}
                    getOptionLabel={(option) => option.destname}
                    sx={{
                      width: "200px ",
                    }}
                    renderInput={(billopts) => (
                      <TextField required {...billopts} placeholder="Area" />
                    )}
                    size="small"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={disableInsert}
                  tabIndex={-1}
                  variant="contained"
                  size="medium"
                  onClick={handledSaveData}
                  color="success"
                >
                  Save
                </Button>
                <Button
                  tabIndex={-1}
                  variant="contained"
                  size="medium"
                  onClick={handledClose}
                  color="error"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default DirectSale;
