import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, MenuItem, Autocomplete, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import "./ItemMaster.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
// import { purple } from "@mui/material/colors";
// import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
// import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const AlternetItem = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      try {
        const body = {
          itemid,
          altitemid,
        };

        const response = await fetch("/insertaltitem", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();
        if (parseRes) {
          toast.success(parseRes, {
            autoClose: 2000,
          });
          setitemid(null);
          setaltitemid(null);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  //DELETE ITEM

  async function handleDelete(opt) {
    try {
      const response = await fetch(
        `/delitem/${searchInput.itemid}/${opt.alternateitemid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  }

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/getaltitem", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      // console.log(jsonData)
      setParam(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  const [itemid, setitemid] = useState(null);
  const [altitemid, setaltitemid] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //
  const [griddetails, setgriddetails] = useState([]);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/getaltitem/${searchInput.itemid}`, {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const jsonData = await response.json();
      setgriddetails(jsonData);
      setInsertDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"ALTERNATE ITEM"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack spacing={2} ml={"34%"} mb={"40px"} direction="row">
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.itemdetails}
                sx={{
                  width: "30% ",
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Code Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />

            <Stack
              spacing={20}
              columnGap={4}
              direction="row"
              mt={5}
              mb={5}
              ml={5}
              mr={5}
            >
              <Autocomplete
                id="controlled-demo"
                value={itemid}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setitemid(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.itemdetails}
                sx={{
                  width: "50% ",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Item Code" size="small" />
                )}
              />
              <Autocomplete
                id="controlled-demo"
                value={altitemid}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setaltitemid(newValue);
                }}
                options={param}
                getOptionLabel={(option) => option.itemdetails}
                sx={{
                  width: "50% ",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Alternate Item Code"
                    size="small"
                  />
                )}
              />
            </Stack>
            <hr />

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "5px 5px 10px #404142",
                backgroundColor: "#868c91",
                maxHeight: "55vh",
                overflowY: "auto",
                padding: "18px",
                mt: "30px",
              }}
            >
              <Table
                sx={{ minWidth: 650, boxShadow: "2px 6px 10px #404142" }}
                size="small"
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "#aad3f0",
                    border: "0.5px solid black",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        paddingY: "16px",
                      }}
                      align="center"
                    >
                      Alt-Item Number
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        paddingY: "16px",
                      }}
                      align="center"
                    >
                      Alt-Item Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        border: "0.5px solid black",
                        paddingY: "16px",
                      }}
                      align="center"
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                  {griddetails.map((opt) => (
                    <TableRow key={opt.alternateitemid}>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                      >
                        {opt.itemcode}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                      >
                        {opt.itemname}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#000",
                          border: "0.5px solid black",
                          paddingY: "16px",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="error"
                          onClick={() => handleDelete(opt)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack spacing={2} columnGap={6} direction="row" mt={12} mx={"30%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setInsertDisabled(false);
                  setSearchInput(null);
                  setitemid(null);
                  setaltitemid(null);
                  setgriddetails([]);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default AlternetItem;
