import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Box, Stack, Autocomplete, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Task from "@mui/icons-material/Task";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
toast.configure();
const SaleBillWordPrint = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [param, setParam] = useState([]);
  const saletypeopts = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Spare",
    },
    {
      value: 3,
      label: "Labour",
    },
  ];
  const [billType, setBillType] = useState(1);
  //billnos according billtype

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [pdfStream, setPdfStream] = useState(null);
  const [finyearname, setfinyearname] = useState();
  const [finopts, setfinopts] = useState([]);
  console.log(finopts);
  async function getfindetails() {
    try {
      const response = await fetch("/admin/findetails", {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setfinopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfindetails();
  }, []);
  async function getParam() {
    try {
      const response = await fetch(
        `/admin/billdetails/${finyearname}/${billType}`,
        {
          method: "GET",
          headers: { token: userToken },
        }
      );
      const jsonData = await response.json();
      setParam(jsonData);
      //   console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, [billType, finyearname]);
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/admin/salebill/doc/${searchInput.billheaderid}`,
        {
          method: "GET",
          headers: { token: userToken },
        }
      );
      const blob = await response.blob(); // Convert response to a Blob
      const filename = `${searchInput.billno}.rtf`; // Change file extension to RTF

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to initiate download
      link.click();

      // Clean up resources
      URL.revokeObjectURL(link.href);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <AdminSidebar />
      <Stack
        spacing={10}
        columnGap={2}
        direction="row"
        mt={2}
        justifyContent={"center"}
        mb={2}
      >
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Bill Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={1}
            name="radio-buttons-group"
            row
          >
            {saletypeopts.map((opt) => (
              <FormControlLabel
                key={opt.value}
                value={opt.value}
                control={<Radio />}
                label={opt.label}
                onChange={(e) => setBillType(e.target.value)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack
        spacing={2}
        columnGap={2}
        direction="row"
        mt={5}
        justifyContent={"center"}
        mb={4}
      >
        <TextField
          id="Finyear"
          required
          name="Finyear"
          select
          label="Select Finyear"
          value={finyearname}
          onChange={(e) => setfinyearname(e.target.value)}
          variant="outlined"
          sx={{
            width: "10%",
          }}
          size="small"
        >
          {finopts.map((option) => (
            <MenuItem key={option.finyearid} value={option.finyearid}>
              {option.finname}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          id="party"
          value={searchInput}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            setSearchInput(newValue);
          }}
          options={param}
          getOptionLabel={(option) => option.billno}
          sx={{
            width: "30% ",
          }}
          renderInput={(billopts) => (
            <TextField required {...billopts} placeholder="Bill No" />
          )}
          size="small"
        />
        <Button
          type="submit"
          // disabled={importDisabled}
          onClick={handleReport}
          variant="contained"
          component="label"
          size="large"
          sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
          endIcon={<Task />}
        >
          Download Report
        </Button>
      </Stack>
      {/* <Box sx={{ backgroundColor: "white" }}>
        {pdfStream && (
          <iframe
            srcDoc={pdfStream}
            width="100%"
            height="600px"
            title="report"
          ></iframe>
        )}
      </Box> */}
      {redirectToLoginPage()}
    </>
  );
};

export default SaleBillWordPrint;
