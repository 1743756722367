import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
toast.configure();

const StkTransferRegister = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const date = new Date();
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [reportTypeValue, setReportTypeValue] = useState(1);
  const reportType = [
    {
      name: "Transfered Stock",
      value: 1,
    },
    {
      name: "Received Stock",
      value: 2,
    },
  ];
  //report
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      if (fromdate === null) {
        toast.error("Please Select A valid Date!");
      } else {
        const body = { fromdate, todate, reportTypeValue };
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch("/reports/stktransferreg", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      //partyarray = parray.map((opt) => opt.subledgerid);
      const body = { fromdate, todate, reportTypeValue };
      const data = await fetch("/stktransferreg/download/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      if (reportTypeValue === 1) {
        downloadLink.download = "transferdstock.xlsx";
      } else {
        downloadLink.download = "receivedstock.xlsx";
      }

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"STOCK TRANSFER REGISTER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <TextField
                id="Type"
                name="Type"
                required
                select
                label="Type"
                value={reportTypeValue}
                onChange={(e) => setReportTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {reportType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                Show Report
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Box>
            {/* {pdfStream && (
            <ReportNavigation
              onDownload={handleDownload}
              reportName={"GOVT GST"}
            />
          )} */}
            <Box sx={{ backgroundColor: "white", mt: 5 }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default StkTransferRegister;
