import React from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import { Box, Stack, Autocomplete, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InputLabel from "@mui/material/InputLabel";
toast.configure();

///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Estimate = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  const [state, setState] = useState({
    address1: "",
    address2: "",
    address3: "",
    partsdisc: "0",
    lubrdisc: "0",
    batterydisc: "0",
    labourdisc: "0",
    customername: "",
    regno: "",
  });
  const today = new Date();
  const [estimatedate, setestimatedate] = useState(today);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [isWorkshop, setIsWorkshop] = useState(false);
  // console.log(isWorkshop);
  const [orderdetailrow, setOrderDetailRow] = useState([]);
  const [labourCharge, setLabourCharge] = useState(0);
  console.log(orderdetailrow);
  const billAmt = calculateBillamt();
  const roundOff = calculateRoundOff();
  const handleDeleteClick = (id) => () => {
    setOrderDetailRow(orderdetailrow.filter((row) => row.itemid !== id));
  };
  const handleRecalculate = () => {
    if (
      (parseFloat(state.partsdisc) > 0 &&
        (parseFloat(state.lubrdisc) > 0 ||
          parseFloat(state.batterydisc) > 0 ||
          parseFloat(state.labourdisc) > 0)) ||
      (parseFloat(state.lubrdisc) > 0 &&
        (parseFloat(state.batterydisc) > 0 ||
          parseFloat(state.labourdisc) > 0)) ||
      (parseFloat(state.batterydisc) > 0 && parseFloat(state.labourdisc) > 0)
    ) {
      return toast.error("Can't give more than 1 discount type!");
    }
    const updatedRows = orderdetailrow.map((row) => {
      const discpers =
        parseFloat(state.partsdisc) +
        parseFloat(state.labourdisc) +
        parseFloat(state.lubrdisc) +
        parseFloat(state.batterydisc);
      const disamt = ((parseFloat(row.value) * discpers) / 100).toFixed(2);
      const taxable = (parseFloat(row.value) - parseFloat(disamt)).toFixed(2);
      const gstamt = (
        (parseFloat(taxable) * parseFloat(row.gstpers)) /
        100
      ).toFixed(2);
      const cgstamt = gstamt / 2;
      const sgstamt = gstamt / 2;
      const igstamt = 0.0;
      const netamt = (parseFloat(taxable) + parseFloat(gstamt)).toFixed(2);
      return { ...row, dispers: discpers, disamt, taxable, gstamt, netamt };
    });
    setOrderDetailRow(updatedRows);
  };
  function calculateBillamt() {
    return Math.round(
      orderdetailrow
        .map((item) => parseFloat(item.netamt))
        .reduce((sum, i) => sum + i, 0) + parseFloat(labourCharge)
    );
  }
  function calculateRoundOff() {
    return (
      parseFloat(billAmt) -
      parseFloat(
        orderdetailrow
          .map((item) => parseFloat(item.netamt))
          .reduce((sum, i) => sum + i, 0) + parseFloat(labourCharge)
      )
    ).toFixed(2);
  }
  const columns = [
    {
      field: "action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Del",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 80,
    },
    {
      field: "partdetails",
      headerClassName: "super-app-theme--header",
      headerName: "Part Details",
      width: 160,
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 100,
      type: "number",
    },

    // {
    //   field: "updatemrp",
    //   type: "actions",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "UpdateMRP",
    //   width: 100,
    //   cellClassName: "actions",
    //   // getActions: ({ id }) => {
    //   //   return [
    //   //     <GridActionsCellItem
    //   //       icon={<CurrencyRupee />}
    //   //       label="Update"
    //   //       onClick={updateMrp(id)}
    //   //       color="inherit"
    //   //     />,
    //   //   ];
    //   // },
    // },

    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Est. Qty",
      width: 100,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.saleqty;
      },
      valueSetter: (params) => {
        const saleqty = params.value;
        const value = parseFloat(params.value * params.row.salerate).toFixed(2);
        const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
          2
        );
        const taxable = parseFloat(
          parseFloat(value) - parseFloat(disamt)
        ).toFixed(2);
        const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
          2
        );
        const cgstamt =
          parseInt(partyInput ? partyInput.stateid : 1) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const sgstamt =
          parseInt(partyInput ? partyInput.stateid : 1) === 1
            ? parseFloat(gstamt / 2).toFixed(2)
            : 0;
        const igstamt =
          parseInt(partyInput ? partyInput.stateid : 1) !== 1
            ? parseFloat(gstamt).toFixed(2)
            : 0;
        const netamt = parseFloat(
          parseFloat(taxable) + parseFloat(gstamt)
        ).toFixed(2);
        const upd_obj =
          parseInt(state.salecategory) === 2
            ? orderdetailrow.findIndex(
                (obj) => obj.itemid === params.row.itemid
              )
            : orderdetailrow.findIndex(
                (obj) => obj.billdetailid === params.row.billdetailid
              );
        orderdetailrow[upd_obj].value = value;
        orderdetailrow[upd_obj].disamt = disamt;
        orderdetailrow[upd_obj].taxable = taxable;
        orderdetailrow[upd_obj].gstamt = gstamt;
        orderdetailrow[upd_obj].cgstamt = cgstamt;
        orderdetailrow[upd_obj].sgstamt = sgstamt;
        orderdetailrow[upd_obj].igstamt = igstamt;
        orderdetailrow[upd_obj].netamt = netamt;
        return {
          ...params.row,
          saleqty,
          value,
          disamt,
          taxable,
          gstamt,
          cgstamt,
          sgstamt,
          igstamt,
          netamt,
        };
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      type: "number",
      width: 100,
      editable: true,
      // valueSetter: (params) => {
      //   if (parseInt(params.row.groupid) === 6) {
      //     const mrp = params.value;
      //     const salerate = parseFloat(
      //       (parseFloat(params.value) * 100) /
      //         (100 + parseFloat(params.row.gstpers))
      //     ).toFixed(2);
      //     const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
      //     const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
      //       2
      //     );
      //     const taxable = parseFloat(
      //       parseFloat(value) - parseFloat(disamt)
      //     ).toFixed(2);
      //     const gstamt = parseFloat(
      //       (taxable * params.row.gstpers) / 100
      //     ).toFixed(2);
      //     const cgstamt =
      //       parseInt(partyInput.stateid) === 1
      //         ? parseFloat(gstamt / 2).toFixed(2)
      //         : 0;
      //     const sgstamt =
      //       parseInt(partyInput.stateid) === 1
      //         ? parseFloat(gstamt / 2).toFixed(2)
      //         : 0;
      //     const igstamt =
      //       parseInt(partyInput.stateid) !== 1
      //         ? parseFloat(gstamt).toFixed(2)
      //         : 0;
      //     const netamt = parseFloat(
      //       parseFloat(taxable) + parseFloat(gstamt)
      //     ).toFixed(2);
      //     const upd_obj = orderdetailrow.findIndex(
      //       (obj) => obj.itemid === params.row.itemid
      //     );
      //     orderdetailrow[upd_obj].mrp = mrp;
      //     orderdetailrow[upd_obj].salerate = salerate;
      //     orderdetailrow[upd_obj].value = value;
      //     orderdetailrow[upd_obj].disamt = disamt;
      //     orderdetailrow[upd_obj].taxable = taxable;
      //     orderdetailrow[upd_obj].gstamt = gstamt;
      //     orderdetailrow[upd_obj].cgstamt = cgstamt;
      //     orderdetailrow[upd_obj].sgstamt = sgstamt;
      //     orderdetailrow[upd_obj].igstamt = igstamt;
      //     orderdetailrow[upd_obj].netamt = netamt;
      //     return {
      //       ...params.row,
      //       mrp,
      //       salerate,
      //       value,
      //       disamt,
      //       taxable,
      //       gstamt,
      //       cgstamt,
      //       sgstamt,
      //       igstamt,
      //       netamt,
      //     };
      //   } else if (
      //     parseFloat(params.value) !== parseFloat(params.row.mrp1) &&
      //     parseFloat(params.value) !== parseFloat(params.row.mrp2) &&
      //     parseFloat(params.value) !== parseFloat(params.row.mrp3) &&
      //     parseFloat(params.value) !== parseFloat(params.row.mrp4)
      //   ) {
      //     toast.error("Enter A Valid MRP!", {
      //       toastId: "error1",
      //     });
      //   } else {
      //     const mrp = params.value;
      //     const salerate = parseFloat(
      //       (parseFloat(params.value) * 100) /
      //         (100 + parseFloat(params.row.gstpers))
      //     ).toFixed(2);
      //     const value = parseFloat(params.row.saleqty * salerate).toFixed(2);
      //     const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
      //       2
      //     );
      //     const taxable = parseFloat(
      //       parseFloat(value) - parseFloat(disamt)
      //     ).toFixed(2);
      //     const gstamt = parseFloat(
      //       (taxable * params.row.gstpers) / 100
      //     ).toFixed(2);
      //     const cgstamt =
      //       parseInt(partyInput.stateid) === 1
      //         ? parseFloat(gstamt / 2).toFixed(2)
      //         : 0;
      //     const sgstamt =
      //       parseInt(partyInput.stateid) === 1
      //         ? parseFloat(gstamt / 2).toFixed(2)
      //         : 0;
      //     const igstamt =
      //       parseInt(partyInput.stateid) !== 1
      //         ? parseFloat(gstamt).toFixed(2)
      //         : 0;
      //     const netamt = parseFloat(
      //       parseFloat(taxable) + parseFloat(gstamt)
      //     ).toFixed(2);
      //     const upd_obj = orderdetailrow.findIndex(
      //       (obj) => obj.itemid === params.row.itemid
      //     );
      //     orderdetailrow[upd_obj].mrp = mrp;
      //     orderdetailrow[upd_obj].salerate = salerate;
      //     orderdetailrow[upd_obj].value = value;
      //     orderdetailrow[upd_obj].disamt = disamt;
      //     orderdetailrow[upd_obj].taxable = taxable;
      //     orderdetailrow[upd_obj].gstamt = gstamt;
      //     orderdetailrow[upd_obj].cgstamt = cgstamt;
      //     orderdetailrow[upd_obj].sgstamt = sgstamt;
      //     orderdetailrow[upd_obj].igstamt = igstamt;
      //     orderdetailrow[upd_obj].netamt = netamt;
      //     return {
      //       ...params.row,
      //       mrp,
      //       salerate,
      //       value,
      //       disamt,
      //       taxable,
      //       gstamt,
      //       cgstamt,
      //       sgstamt,
      //       igstamt,
      //       netamt,
      //     };
      //   }
      // },
    },
    {
      field: "mrp4",
      headerClassName: "super-app-theme--header",
      headerName: "Current MRP",
      type: "number",
      width: 100,
    },
    {
      field: "mrp1",
      headerClassName: "super-app-theme--header",
      headerName: "MRP1",
      type: "number",
      width: 100,
    },
    {
      field: "mrp2",
      headerClassName: "super-app-theme--header",
      headerName: "MRP2",
      type: "number",
      width: 100,
    },
    {
      field: "mrp3",
      headerClassName: "super-app-theme--header",
      headerName: "MRP3",
      type: "number",
      width: 100,
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 100,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "dispers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis %",
      width: 100,
      type: "number",
      editable: true,
      // valueSetter: (params) => {
      //   const dispers = params.value;
      //   const disamt = parseFloat((params.row.value * dispers) / 100).toFixed(
      //     2
      //   );
      //   const taxable = parseFloat(
      //     parseFloat(params.row.value) - parseFloat(disamt)
      //   ).toFixed(2);
      //   const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
      //     2
      //   );
      //   const cgstamt =
      //     parseInt(partyInput.stateid) === 1
      //       ? parseFloat(gstamt / 2).toFixed(2)
      //       : 0;
      //   const sgstamt =
      //     parseInt(partyInput.stateid) === 1
      //       ? parseFloat(gstamt / 2).toFixed(2)
      //       : 0;
      //   const igstamt =
      //     parseInt(partyInput.stateid) !== 1
      //       ? parseFloat(gstamt).toFixed(2)
      //       : 0;
      //   const netamt = parseFloat(
      //     parseFloat(taxable) + parseFloat(gstamt)
      //   ).toFixed(2);
      //   const upd_obj =
      //     parseInt(state.salecategory) === 2
      //       ? orderdetailrow.findIndex(
      //           (obj) => obj.itemid === params.row.itemid
      //         )
      //       : orderdetailrow.findIndex(
      //           (obj) => obj.billdetailid === params.row.billdetailid
      //         );
      //   orderdetailrow[upd_obj].dispers = dispers;
      //   orderdetailrow[upd_obj].disamt = disamt;
      //   orderdetailrow[upd_obj].taxable = taxable;
      //   orderdetailrow[upd_obj].gstamt = gstamt;
      //   orderdetailrow[upd_obj].cgstamt = cgstamt;
      //   orderdetailrow[upd_obj].sgstamt = sgstamt;
      //   orderdetailrow[upd_obj].igstamt = igstamt;
      //   orderdetailrow[upd_obj].netamt = netamt;
      //   return {
      //     ...params.row,
      //     dispers,
      //     disamt,
      //     taxable,
      //     gstamt,
      //     cgstamt,
      //     sgstamt,
      //     igstamt,
      //     netamt,
      //   };
      // },
    },
    {
      field: "disamt",
      headerClassName: "super-app-theme--header",
      headerName: "Dis Amt",
      width: 80,
      type: "number",
    },
    {
      field: "taxable",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable Amt",
      width: 100,
      type: "number",
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST %",
      width: 80,
      type: "number",
    },
    {
      field: "cgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "CGST Amt",
      width: 80,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput ? partyInput.stateid : 1) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "sgstamt",
      headerClassName: "super-app-theme--header",
      headerName: "SGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput ? partyInput.stateid : 1) === 1) {
          return parseFloat(params.row.gstamt / 2).toFixed(2);
        } else {
          return 0;
        }
      },
    },

    {
      field: "igstamt",
      headerClassName: "super-app-theme--header",
      headerName: "IGST Amt",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        if (parseInt(partyInput ? partyInput.stateid : 1) !== 1) {
          return parseFloat(params.row.gstamt).toFixed(2);
        } else {
          return 0;
        }
      },
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
  ];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [partno, setpartno] = useState(null);
  const [stock, setstock] = useState(0);
  const [delItem, setDelItem] = useState("");
  const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState();
  const [salerate, setsalerate] = useState(0);
  const [allparts, setallparts] = useState([]);
  const [altparts, setaltparts] = useState([]);
  const [partyInput, setPartyInput] = useState(null);
  // console.log(partyInput);
  function handleSwitch(opt) {
    setpartno({
      deleteditem: opt.deleteditem,
      gstrate: opt.gstrate,
      itemid: opt.alternateitemid,
      mrp: opt.mrp,
      mrp4: opt.mrp,
      mrp1: opt.mrp1,
      mrp2: opt.mrp2,
      mrp3: opt.mrp3,
      partdetails: opt.itemcode + "-" + opt.itemname,
      purchaseqty: opt.closingqty,
      rackname: opt.rackname,
      salerate: opt.salerate,
      spcldiscount: opt.spcldiscount,
    });
    setstock(opt.closingqty);
  }
  const quantityRef = useRef(null);
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
      }
    }
    if (event.code === "F2") {
      if (event.target === quantityRef.current) {
        try {
          const response = await fetch(`/altitemstock/${partno.itemid}`, {
            method: "GET",
            headers: {
              sptoken: userToken,
            },
          });
          const jsonData = await response.json();
          setaltparts(jsonData);
          //console.log(altparts);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  }
  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    // "& button": { m: 1 },
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions3 = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
    matchFrom: "start",
  });

  const handleaddrow = () => {
    if (orderdetailrow.map((e) => e.itemid).includes(partno.itemid)) {
      toast.error("Item Added Already!");
    } else if (parseInt(partno.purchaseqty) < parseInt(itemqty)) {
      toast.error("Error: Item quantity should not exceed available stock");
    } else {
      const groupdisc =
        parseInt(partno.groupid) === 1
          ? state.partsdisc
          : parseInt(partno.groupid) === 2
          ? state.lubrdisc
          : parseInt(partno.groupid) === 3
          ? state.batterydisc
          : state.labourdisc;
      const finaldisc =
        partno.spcldiscount === true
          ? parseFloat(partyInput ? partyInput.specialdis : 0) +
            parseFloat(groupdisc)
          : parseFloat(groupdisc);
      const discountamount =
        parseInt(partno.purchaseqty) > parseInt(itemqty)
          ? (parseInt(itemqty) *
              parseFloat(partno.salerate) *
              parseFloat(finaldisc)) /
            100
          : (parseInt(partno.purchaseqty) *
              parseFloat(partno.salerate) *
              parseFloat(finaldisc)) /
            100;
      const valueamount =
        parseInt(partno.purchaseqty) > parseInt(itemqty)
          ? parseInt(itemqty) * parseFloat(partno.salerate)
          : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
      const taxableamount = parseFloat(valueamount - discountamount).toFixed(2);
      const gstamount = parseFloat(
        (taxableamount * partno.gstrate) / 100
      ).toFixed(2);
      const netamount = parseFloat(
        parseFloat(taxableamount) + parseFloat(gstamount)
      ).toFixed(2);
      setOrderDetailRow((oldRows) => [
        ...oldRows,
        {
          serial: oldRows.length + 1,
          partdetails: partno.partdetails,
          mrp: partno.mrp,
          mrp1: partno.mrp1,
          mrp2: partno.mrp2,
          mrp3: partno.mrp3,
          mrp4: partno.mrp4,
          gstpers: partno.gstrate,
          salerate: partno.salerate,
          itemid: partno.itemid,
          rackname: partno.rackname,
          stkqty: partno.purchaseqty,
          ordqty: itemqty,
          groupid: partno.groupid,
          losqty:
            itemqty - partno.purchaseqty > 0 ? itemqty - partno.purchaseqty : 0,
          losval:
            itemqty - partno.purchaseqty > 0
              ? (itemqty - partno.purchaseqty) * partno.salerate
              : 0,
          saleqty:
            parseInt(partno.purchaseqty) > parseInt(itemqty)
              ? parseInt(itemqty)
              : parseInt(partno.purchaseqty),
          dispers: parseFloat(finaldisc).toFixed(2),
          value: parseFloat(valueamount).toFixed(2),
          disamt: parseFloat(discountamount).toFixed(2),
          taxable: parseFloat(taxableamount).toFixed(2),
          gstamt: parseFloat(gstamount).toFixed(2),
          netamt: parseFloat(netamount).toFixed(2),
        },
      ]);
      setpartno(null);
      setitemqty(0);
      setstock(0);
      setDelItem("");
      setaltFlag("");
      setaltparts([]);
    }
  };
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/spare/directsale/parties", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  const [disableSave, setDisableSave] = useState(false);
  const [entryMode, setEntryMode] = useState(1);
  const [estimateInput, setEstimateInput] = useState(null);
  const [estimateopts, setEstimateOpts] = useState([]);
  const getEstimates = async () => {
    try {
      const data = await fetch("/estimate/estimatenos", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
      });
      const response = await data.json();
      setEstimateOpts(response);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getEstimates();
  }, []);

  const handleEstimateSubmit = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const requestbody = {
        orderdetailrow,
        partyInput,
        state,
        estimatedate,
        billAmt,
        roundOff,
        labourCharge,
      };
      const endpoint = await fetch("/estimate/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        body: JSON.stringify(requestbody),
      });
      const data = await endpoint.json();
      if (data.type === "error") {
        toast.error(data.message);
        setDisableSave(false);
      } else {
        toast.success(data.message, {
          autoClose: false,
          onClose: () => window.location.reload(),
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchEstimateData = async () => {
    try {
      const data = await fetch(
        `/estimate/estimatedata/${estimateInput.estheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const response = await data.json();
      setestimatedate(response.headerdata.estimatedate);
      setIsWorkshop(response.headerdata.jobcustomername ? true : false);
      setState({
        customername: response.headerdata.jobcustomername,
        address1: response.headerdata.jobaddress1,
        address2: response.headerdata.jobaddress2,
        address3: response.headerdata.jobaddress3,
        regno: response.headerdata.jobcarregno,
        partsdisc: response.headerdata.partsdiscrate,
        lubrdisc: response.headerdata.lubdiscrate,
        batterydisc: response.headerdata.batterydiscrate,
        labourdisc: response.headerdata.labourdiscrate,
      });
      // setPartyInput({
      //   batterydiscrate: "0.00",
      //   cashtype: false,
      //   destid: 1,
      //   destinationid: "1",
      //   lubdiscrate: "0.00",
      //   partsdiscrate: "20.00",
      //   salesmandetails: "N001-NABIN GUPTA",
      //   salesmanid: "3",
      //   specialdis: "0",
      //   stateid: 1,
      //   statename: "WEST BENGAL",
      //   subledgerid: "60",
      //   subledgername: "A0025-AUTO AID CENTRE",
      // });
      setLabourCharge(response.headerdata.totlabourcharge);
      setOrderDetailRow(response.detaildata);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (parseInt(entryMode) === 2 && estimateInput !== null) {
      fetchEstimateData();
    }
  }, [entryMode, estimateInput]);

  return (
    <div>
      <Sidebar
        Title={"ESTIMATE"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Mode</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={entryMode}
                  label="Mode"
                  onChange={(e) => setEntryMode(e.target.value)}
                >
                  <MenuItem value={1}>New</MenuItem>
                  <MenuItem value={2}>Edit</MenuItem>
                </Select>
              </FormControl>
              {parseInt(entryMode) === 2 && (
                <Autocomplete
                  id="estimateno"
                  value={estimateInput}
                  filterOptions={filterOptions3}
                  onChange={(event, newValue) => {
                    setEstimateInput(newValue);
                  }}
                  options={estimateopts}
                  getOptionLabel={(option) => option.estdetails}
                  isOptionEqualToValue={(option, value) =>
                    parseInt(option.estheaderid) === parseInt(value.estheaderid)
                  }
                  sx={{
                    width: "38% ",
                  }}
                  renderInput={(billopts) => (
                    <TextField
                      required
                      {...billopts}
                      placeholder="Estimate Details"
                    />
                  )}
                  size="small"
                />
              )}
              {parseInt(entryMode) === 1 && (
                <Autocomplete
                  id="partyName"
                  value={partyInput}
                  filterOptions={filterOptions3}
                  onChange={(event, newValue) => {
                    setPartyInput(newValue);
                    setState({
                      ...state,
                      partsdisc: `${newValue.partsdiscrate}`,
                      batterydisc: `${newValue.batterydiscrate}`,
                      lubrdisc: `${newValue.lubdiscrate}`,
                      labourdisc: 0,
                    });
                  }}
                  options={partyopts}
                  getOptionLabel={(option) => option.subledgername}
                  isOptionEqualToValue={(option, value) =>
                    parseInt(option.subledgerid) === parseInt(value.subledgerid)
                  }
                  sx={{
                    width: "20% ",
                  }}
                  renderInput={(billopts) => (
                    <TextField
                      required
                      {...billopts}
                      placeholder="Party Details"
                    />
                  )}
                  size="small"
                />
              )}
              {/* <TextField
                id="stateName"
                required
                label="State Name"
                name="statename"
                value={state.statename}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={estimatedate}
                  onChange={(newValue) => {
                    setestimatedate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isWorkshop}
                    value={isWorkshop}
                    onChange={() => {
                      setIsWorkshop(!isWorkshop);
                      isWorkshop && setLabourCharge(0);
                    }}
                  />
                }
                label="is Workshop"
              />
            </Stack>
            <Stack spacing={2} columnGap={2} direction="row" mt={1} ml={4}>
              {isWorkshop && (
                <>
                  <TextField
                    id="customerName"
                    label="Customer Name"
                    name="customername"
                    value={state.customername}
                    autoFocus
                    onChange={handleChange}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="address1"
                    label="Address 1"
                    value={state.address1}
                    name="address1"
                    onChange={handleChange}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="address2"
                    label="Address 2"
                    value={state.address2}
                    name="address2"
                    onChange={handleChange}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="address3"
                    label="Address 3"
                    value={state.address3}
                    name="address3"
                    onChange={handleChange}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="regno"
                    label="RegNo."
                    value={state.regno}
                    name="regno"
                    onChange={handleChange}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                </>
              )}
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <TextField
                id="partsDisc"
                label="Parts Disc %"
                value={state.partsdisc}
                onChange={handleChange}
                type="text"
                name="partsdisc"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="lubydisc"
                label="Lubr Disc %"
                name="lubrdisc"
                value={state.lubrdisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="batteryDisc"
                label="Battery Disc %"
                name="batterydisc"
                value={state.batterydisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="labourDisc"
                label="Labour Disc %"
                name="labourdisc"
                value={state.labourdisc}
                onChange={handleChange}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <Button
                variant="outlined"
                size="small"
                onClick={handleRecalculate}
              >
                Recalculate
              </Button>
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={1}
              ml={4}
              mb={1}
            >
              <Button
                sx={{ ml: "3%", mt: "13px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD
              </Button>

              <Modal
                open={open}
                aria-labelledby="modl-box"
                aria-describedby="search partitem"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      padding: 2,
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      columnGap: 1,
                      rowGap: 1,
                    }}
                  >
                    <Autocomplete
                      id="partno"
                      value={partno}
                      filterOptions={filterOptions3}
                      onChange={(event, newValue) => {
                        setpartno(newValue);
                        setstock(newValue.purchaseqty);
                        setDelItem(newValue.deleteditem);
                        setaltFlag(newValue.altflag);
                      }}
                      options={allparts}
                      getOptionLabel={(option) => option.partdetails}
                      sx={{
                        width: "30% ",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Part No"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    {state.saletype === 3 && (
                      <TextField
                        id="salerate"
                        label="Sale Rate"
                        value={salerate}
                        onChange={(e) => setsalerate(e.target.value)}
                        type="number"
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                      />
                    )}
                    <TextField
                      id="quantity"
                      label="Quantity"
                      value={itemqty}
                      onChange={(e) => setitemqty(e.target.value)}
                      inputRef={quantityRef}
                      onKeyDown={handleKeyDown}
                      type="number"
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                    />

                    <TextField
                      id="stock"
                      label="Stock"
                      value={stock}
                      type="number"
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />

                    <TextField
                      id="delitem"
                      label="Deleted Item"
                      value={delItem}
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />
                    <TextField
                      id="altitem"
                      label="Alternate Item"
                      value={altFlag}
                      sx={{
                        width: "30%",
                        // boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                      }}
                      size="small"
                      inputProps={{ tabIndex: "-1" }}
                    />

                    <Button
                      variant="contained"
                      tabIndex={-1}
                      size="medium"
                      color="success"
                      onClick={handleaddrow}
                    >
                      ADD
                    </Button>
                    <Button
                      variant="contained"
                      tabIndex={-1}
                      size="medium"
                      onClick={handleClose}
                      color="error"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Stack direction="row" mt={1} p={1}>
                    <Table
                      sx={{
                        minWidth: "100%",
                        boxShadow: "2px 6px 15px #5a5a5a",
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          backgroundColor: "#3bb9ff",
                          border: "0.5px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Name
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Number
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Alt-Item Stock
                          </TableCell>
                          <TableCell
                            sx={{ color: "#000", border: "0.5px solid black" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {altparts.length > 0 ? (
                        <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                          {altparts.map((opt) => (
                            <TableRow key={opt.alternateitemid}>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.itemname}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.itemcode}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                {opt.closingqty}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  color: "#000",
                                  border: "0.5px solid black",
                                }}
                              >
                                <Button onClick={(e) => handleSwitch(opt, e)}>
                                  Switch
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <Typography variant="subtitle1" ml={10}>
                          NO ITEMS FOUND
                        </Typography>
                      )}
                    </Table>
                  </Stack>
                </Box>
              </Modal>
              {isWorkshop && (
                <TextField
                  id="labour charges"
                  label="Labour Charges"
                  value={labourCharge}
                  onChange={(e) => setLabourCharge(e.target.value)}
                  type="number"
                  sx={{
                    width: "25%",
                    backgroundColor: "#c7eff9",
                  }}
                  size="small"
                />
              )}

              <TextField
                id="roundoff"
                label="Round Off"
                value={roundOff}
                type="number"
                sx={{
                  width: "25%",
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
              <TextField
                id="billamt"
                label="Bill Amount"
                value={billAmt}
                type="number"
                sx={{
                  width: "25%",
                  backgroundColor: "#c7eff9",
                }}
                size="small"
              />
            </Stack>
            <Box
              sx={{
                height: 400,
                width: "100%",
                position: "relative",
                mt: "20px",
                boxShadow: "2px 6px 15px #5a5a5a",
                // border: "0.5px solid black",
                // borderRadius: "5px",
                "& .super-app-theme--header": {
                  backgroundColor: "#3bb9ff",
                  color: "#000",
                },
              }}
            >
              <DataGrid
                sx={{ border: "1px solid #000", height: 400 }}
                rowHeight={25}
                headerHeight={40}
                showColumnRightBorder={true}
                rows={orderdetailrow}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "serial", sort: "desc" }],
                  },
                }}
                getRowId={(row) => row.itemid}
              />

              <Stack
                spacing={2}
                columnGap={6}
                direction="row"
                mt={6}
                pb={6}
                justifyContent={"center"}
              >
                <Button
                  type="submit"
                  onClick={handleEstimateSubmit}
                  disabled={disableSave}
                  variant="contained"
                  color="success"
                >
                  SAVE BILL
                </Button>
                <Button
                  type="submit"
                  //onClick={handleEditmodeSubmit}
                  variant="contained"
                  color="warning"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                >
                  RESET BILL
                </Button>
              </Stack>
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Estimate;
