import {
  Box,
  Tabs,
  Tab,
  TextField,
  Stack,
  MenuItem,
  Button,
  styled,
  Badge,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast, ToastContainer } from "react-toastify";
import { tableCellClasses } from "@mui/material";
import { Redirect } from "react-router-dom";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import BillSearch from "@mui/icons-material/Search";
import HowToReg from "@mui/icons-material/HowToReg";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";
import { keyframes } from "@emotion/react";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
toast.configure();

const ItemOpCorrection = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [partno, setpartno] = useState("");
  const [itemid, setitemid] = useState("");
  //console.log(itemid)
  const [newOpBalance, setNewOpBalance] = useState(0);
  const [itemRow, setitemRow] = useState([]);
  const [finyearname, setfinyearname] = useState(null);
  const [disableUpdate, setdisableUpdate] = useState(false);
  //console.log(finyearname)
  const [correctbtntext, setcorrectbtntext] = useState("Stock Auto Correction");
  const [disablecorrectbtn, setdisablecorrectbtn] = useState(false);
  const [finopts, setfinopts] = useState([]);
  //console.log(finopts);
  //fetch finyear
  async function getfindetails() {
    try {
      const response = await fetch("/admin/findetails", {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setfinopts(jsonData);
      // Set the default financial year to 2024-2025
      const defaultFinyear = jsonData.find(
        (year) => year.finname === "2024-2025"
      );
      if (defaultFinyear) {
        setfinyearname(defaultFinyear.finyearid);
      }
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfindetails();
  }, []);

  const handleItemFetch = async () => {
    try {
      if (partno !== null || partno !== "") {
        const body = { code: partno, finId: finyearname };
        const data = await fetch("/admin/opening/correction/items", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            token: userToken,
          },
          body: JSON.stringify(body),
        });
        const res = await data.json();

        setpartno((prev) => prev.toUpperCase());
        //console.log(res);
        if (res.type === "success") {
          toast.info(res.message);
          setitemid(res.data[0].itemid);
          setitemRow(res.data);
        } else {
          toast.error(res.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateStock = async (e) => {
    e.preventDefault();
    setdisableUpdate(true);
    try {
      if (newOpBalance < 0) {
        toast.error("Opening Value Cannot Be Less Than 0 !!");
        setdisableUpdate(false);
        return;
      }
      const body = { id: itemid, finId: finyearname, newOpBalance };
      const data = await fetch("/admin/opening/update", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          token: userToken,
        },
        body: JSON.stringify(body),
      });
      const res = await data.json();
      if (res.type === "success") {
        setTimeout(() => {
          toast.info(res.message);
          window.location.reload();
        }, 2500);
      }
    } catch (error) {
      setdisableUpdate(false);
      console.log(error.message);
    }
  };

  //stock auto correct
  const handleautocorrect = async (e) => {
    e.preventDefault();
    setdisablecorrectbtn(true);
    setcorrectbtntext("Processing...");
    try {
      const res = await fetch(`/admin/autocorrect/${finyearname}`, {
        method: "GET",
        headers: {
          token: userToken,
        },
      });
      const data = await res.json();
      if (data.type === "success") {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error) {
      setdisablecorrectbtn(false);
      console.log(error.message);
    }
  };
  return (
    <>
      <ToastContainer />
      <AdminSidebar />
      <Stack direction={"row"} spacing={2} justifyContent={"center"} mt={1}>
        <Typography variant="h6">Item Opening Stock Correction:</Typography>
      </Stack>
      <Stack direction={"row"} spacing={2} ml={10} mt={6}>
        <TextField
          id="Finyear"
          required
          name="Finyear"
          select
          label="Select Finyear"
          value={finyearname}
          onChange={(e) => setfinyearname(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          sx={{
            width: "10%",
          }}
          size="small"
        >
          {finopts.map((finyear) => (
            <MenuItem
              key={Object.values(finyear)[0]}
              value={Object.values(finyear)[0]}
            >
              {Object.values(finyear)[1]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          onBlur={handleItemFetch}
          id="item"
          label="Itemcode"
          value={partno}
          onChange={(e) => setpartno(e.target.value)}
          type="text"
          sx={{
            width: 350,
          }}
          size="small"
        />
      </Stack>
      {itemRow.length > 0 && (
        <Stack direction={"row"} spacing={2} ml={10} mt={4}>
          <Box sx={{ minWidth: 650 }}>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  "& .MuiTableCell-head": {
                    backgroundColor: "#1976d2",
                    color: "white",
                    fontWeight: "bold",
                  },
                  "& .MuiTableCell-body": {
                    fontSize: "0.875rem",
                    padding: "8px",
                  },
                  "& .MuiTableRow-root:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Item Code</TableCell>
                    <TableCell align="right">Item Name</TableCell>
                    <TableCell align="right">Opening Balance</TableCell>
                    <TableCell align="right">Closing Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemRow?.map((row) => (
                    <TableRow
                      key={row.itemid}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.itemcode}
                      </TableCell>
                      <TableCell align="right">{row.itemname}</TableCell>
                      <TableCell align="right">{row.openingqty}</TableCell>
                      <TableCell align="right">{row.closingqty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      )}
      <Stack direction={"row"} spacing={2} ml={10} mt={4}>
        <TextField
          id="newOpening"
          label="New Opening Balance"
          value={newOpBalance}
          onChange={(e) => setNewOpBalance(e.target.value)}
          type="number"
          sx={{
            width: 250,
          }}
          size="small"
        />
      </Stack>
      <Stack direction={"row"} spacing={2} ml={10} mt={6}>
        <Button
          onClick={handleUpdateStock}
          disabled={disableUpdate}
          size="smaill"
          variant="contained"
          color="primary"
        >
          Update
        </Button>
        <Button
          onClick={handleautocorrect}
          color="warning"
          disabled={disablecorrectbtn}
          variant="contained"
        >
          {correctbtntext}
        </Button>
      </Stack>
      {redirectToLoginPage()}
    </>
  );
};

export default ItemOpCorrection;
