import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const StockAdjust = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const [mode, setMode] = useState(1);
  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [billsearch, setBillSearch] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    matchFrom: "start",
  });
  const [searchInput, setSearchInput] = useState(null);
  //HANDLE PARTIES WHO HAVE ORDERED
  const [partyfetch, setpartyfetch] = useState(false);
  const [parties, setparties] = useState([]);
  async function getparties() {
    try {
      const response = await fetch("/stockadjust/docs", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      setpartyfetch(false);
      const jsondata = await response.json();
      setparties(jsondata);
    } catch (err) {
      console.log(err.msg);
    }
  }
  useEffect(() => {
    if (partyfetch === true) {
      getparties();
    }
  }, [partyfetch]);
  //  GET BILL Search Parameters //
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/partydata", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);
  const date = new Date();
  const [docdate, setdocdate] = useState(date);
  const [docno, setdocno] = useState("");
  //----------- Modal Handler-------\\

  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [itemarray, setitemarray] = useState([]);
  const columns = [
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "SrlNo.",
      type: "number",
      width: 60,
    },
    // {
    //   field: "itemid",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "PartNo.",
    //   type: "number",
    //   width: 100,
    // },
    {
      field: "partdesc",
      headerClassName: "super-app-theme--header",
      headerName: "Part Des",
      width: 400,
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "Qty",
      headerAlign: "left",
      width: 100,
      type: "number",
      // editable: true,
      // valueSetter: (params) => {
      //   const qty = params.value;
      //   const filterIndex = itemarray.findIndex(
      //     (opt) => parseInt(opt.itemid) === parseInt(params.row.itemid)
      //   );
      //   console.log(filterIndex);
      //   console.log(itemarray[filterIndex]);
      //   itemarray[filterIndex].qty = qty;
      //   return {
      //     ...params.row,
      //     qty,
      //   };
      // },
    },
    {
      field: "reason",
      headerClassName: "super-app-theme--header",
      headerName: "Reason",
      width: 300,
      // editable: true,
      // valueSetter: (params) => {
      //   const newqty = params.value;
      //   const filterIndex = itemarray.findIndex(
      //     (opt) => opt.itemid === params.row.itemid
      //   );
      //   itemarray[filterIndex].reason = newqty;
      //   return {
      //     ...params.row,
      //     reason: newqty,
      //   };
      // },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",

      headerClassName: "super-app-theme--header",
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const handleDeleteClick = (id) => {
    const filter = itemarray.filter((opt) => opt.itemid !== id);
    setitemarray(filter);
  };
  const handleaddrow = (e) => {
    e.preventDefault();
    if (!partno) {
      toast.error("Please select a part");
      return;
    }

    const newRow = {
      serial: itemarray.length + 1, // You may need to adjust how you generate serial numbers
      partdesc: partno.partdetails,
      itemid: partno.itemid,
      rackid: partno.rackid,
      salerate: partno.salerate,
      qty: parseInt(itemqty),
      reason: remarks,
      // Add other fields as needed
    };

    // Check if the part is already in the rows
    if (itemarray.some((row) => row.partdesc === newRow.partdesc)) {
      toast.error("Part already added to the list");
      return;
    }

    // Update the rows state with the new row
    setitemarray((prevRows) => [...prevRows, newRow]);

    // Reset the input fields
    setpartno(null);
    setitemqty(0);
    setremarks("");
  };
  // // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  // const quantityRef = useRef(null);
  // async function handleKeyDown(event) {
  //   if (event.keyCode === 9 || event.key === "Tab") {
  //     if (event.target === quantityRef.current) {
  //       handleaddrow();
  //     }
  //   }
  // }
  ///modal partnos
  const [partno, setpartno] = useState(null);
  const [allparts, setallparts] = useState([]);
  const [itemqty, setitemqty] = useState(0);
  const [remarks, setremarks] = useState("");
  // useEffect(() => {
  //   if (partno) {
  //     quantityRef.current.focus();
  //   }
  // }, [partno]);

  async function getallparts() {
    try {
      const response = await fetch("/stockadjust/items", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  //insert data
  const handlesave = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      const body = { docdate, itemarray };
      const res = await fetch(`/stockadjust/new`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      toast.success("Stock adjustment and update successful!", {
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };
  ///set data edit mode
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`/stockadjust/data/${searchInput.stkheaderid}`, {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      //console.log(data)
      setdocno(data.documentData.docno);
      setdocdate(data.documentData.docdate);
      setitemarray(data.detailsData);
    } catch (error) {
      console.log(error.message);
    }
  };
  ///update data
  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    setDisableUpdate(true);
    try {
      const body = { itemarray };
      console.log(body)
      const res = await fetch(
        `/stockadjust/update/${searchInput.stkheaderid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const data = res.json();
      toast.success("Document Updated!", { autoClose: 15000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"Stock Adjust"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"15px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={parties}
                getOptionLabel={(option) => option.docno}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    sx={{
                      width: "500px",
                    }}
                    {...params}
                    placeholder="Search"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                // type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
            </Stack>
            <hr />
            <Stack
              spacing={1}
              columnGap={2}
              mb={2}
              direction="row"
              mt={2}
              ml={4}
            >
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (e.target.value === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartyfetch(true);
                  } else {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartyfetch(false);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* {parseInt(mode) === 1 ? (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Autocomplete
                    id="partysearch"
                    value={billsearch}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setBillSearch(newValue);
                    }}
                    options={partyopts}
                    getOptionLabel={(option) => option.partydetails}
                    renderInput={(partyopts) => (
                      <TextField
                        sx={{
                          width: 200,
                        }}
                        required
                        {...partyopts}
                        placeholder="Search Party"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      marginLeft: 50,
                      backgroundColor: "green",
                      borderRadius: "10px",
                    }}
                    // onClick={handleBillSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              ) : (
                <Stack spacing={1} sx={{ width: "20%" }} direction="row">
                  <Autocomplete
                    id="Order Search"
                    value={ordersearch}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setOrderSearch(newValue);
                    }}
                    options={ordernos}
                    getOptionLabel={(option) => option.orderno}
                    renderInput={(ordernos) => (
                      <TextField
                        sx={{
                          width: 200,
                        }}
                        required
                        {...ordernos}
                        placeholder="Search Order"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    size="small"
                    type="search"
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#673AB7",
                      },
                      marginLeft: 50,
                      backgroundColor: "purple",
                      borderRadius: "10px",
                    }}
                    // onClick={handleOrderSearch}
                  >
                    <BillSearch sx={{ color: "white" }} />
                  </Button>
                </Stack>
              )} */}
              <TextField
                id="Docno"
                label="Docno"
                value={docno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={docdate || null}
                  onChange={(newValue) => {
                    setdocdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <hr />
            <Stack columnGap={3} direction="row" mt={1}>
              <Button
                sx={{ ml: "3%", mt: "5px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD A NEW ROW
              </Button>
              <Draggable>
                <Modal
                  sx={{ mt: "5%" }}
                  open={open}
                  BackdropProps={{ invisible: true }}
                  aria-labelledby="modl-box"
                  aria-describedby="search partitem"
                >
                  <Box sx={style}>
                    <Stack
                      columnGap={2}
                      direction="row"
                      component="form"
                      sx={{
                        width: 800,
                        mt: 2,
                        p: 2,
                      }}
                      autoComplete="off"
                    >
                      <Autocomplete
                        id="partno"
                        value={partno}
                        filterOptions={filterOptions}
                        onChange={(event, newValue) => {
                          setpartno(newValue);
                          //console.log(newValue);
                        }}
                        options={allparts}
                        getOptionLabel={(option) => option.partdetails}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              width: "200px ",
                            }}
                            // inputRef={altItemRef}
                            // onKeyDown={handleAltShortcut}
                            placeholder="Part No"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                      <TextField
                        id="quantity"
                        label="Quantity"
                        value={itemqty}
                        onChange={(e) => setitemqty(e.target.value)}
                        // inputRef={quantityRef}
                        // onKeyDown={handleKeyDown}
                        type="number"
                        sx={{
                          width: "200px",
                        }}
                        size="small"
                      />
                      <TextField
                        id="reason"
                        label="Reason"
                        value={remarks}
                        onChange={(e) => setremarks(e.target.value)}
                        type="text"
                        sx={{
                          width: "30%",
                        }}
                        size="small"
                      />
                    </Stack>
                    <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>
                      {/* <SearchAltItem allparts={allparts} /> */}
                      <Button
                        variant="contained"
                        size="medium"
                        color="success"
                        onClick={handleaddrow}
                      >
                        ADD
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Box>
                </Modal>
              </Draggable>
            </Stack>
            <Box
              sx={{
                height: 300,
                width: "100%",
                position: "relative",
                mt: "15px",
                boxShadow: "2px 6px 15px #5a5a5a",
                // border: "0.5px solid black",
                // borderRadius: "5px",
                "& .super-app-theme--header": {
                  backgroundColor: "#3bb9ff",
                  color: "#000",
                },
              }}
            >
              <DataGrid
                sx={{ height: 300, border: "1px solid #000" }}
                rowHeight={25}
                headerHeight={40}
                showColumnRightBorder={true}
                rows={itemarray}
                columns={columns}
                pageSize={100}
                getRowId={(row) => row.itemid}
              />
            </Box>
            <Stack
              display={mode === 1 ? "flex" : "none"}
              spacing={2}
              columnGap={6}
              direction="row"
              mt={2}
              pb={6}
              mx={"42%"}
            >
              <Button
                //type="submit"
                disabled={disableSave}
                onClick={handlesave}
                variant="contained"
                color="primary"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                SAVE
              </Button>
            </Stack>
            <Stack
              display={mode === 2 ? "flex" : "none"}
              spacing={2}
              columnGap={6}
              direction="row"
              mt={2}
              pb={6}
              mx={"42%"}
            >
              <Button
                //type="submit"
                disabled={disableUpdate}
                onClick={handleEditmodeSubmit}
                variant="contained"
                color="success"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default StockAdjust;
