import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import UploadFile from "@mui/icons-material/UploadFile";
import Task from "@mui/icons-material/Task";
import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
toast.configure();

const MrpUpdate = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const OPTIONS_LIMIT2 = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
    matchFrom: "start",
  });
  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const [itemcatginput, setitemcatginput] = useState(null);
  const [itemcatgopt, setitemcatgopt] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  //itemcatg api
  async function getitemcatgdata() {
    try {
      const res = await fetch("/importmrp/itemcatg", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const data = await res.json();
      setitemcatgopt(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getitemcatgdata();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", file);
      const response = await fetch("/uploadmrplist", {
        method: "POST",
        headers: { sptoken: userToken },
        body: data,
      });
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 2000 });
      setImportDisabled(false);
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleImport = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { category: itemcatginput.categoryid };
      console.log(body);
      const response = await fetch(`/importmrplist/${fileName}`, {
        method: "POST",
        headers: { sptoken: userToken, "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      console.log(parseRes);
      if (parseRes.type === "success") {
        toast.success(parseRes.message, { autoClose: 1000 });
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div>
        <Sidebar
          Title={"MRP Update"}
          expandedPanel={"panel4"}
          children={
            <Box
              component="form"
              sx={{
                width: "100%",
              }}
              Validate
              // onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Stack
                spacing={2}
                ml={"34%"}
                mt={"100px"}
                mb={5}
                direction="row"
                align="center"
              >
                {" "}
                <Typography variant="h5">
                  SELECT THE Item Catagory TO IMPORT
                </Typography>
                <Autocomplete
                  id="partysearch"
                  value={itemcatginput}
                  filterOptions={filterOptions}
                  onChange={(event, newValue) => {
                    setitemcatginput(newValue);
                  }}
                  options={itemcatgopt}
                  getOptionLabel={(option) => option.categoryname}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: 200,
                      }}
                      required
                      {...params}
                      placeholder="Item Catagory"
                    />
                  )}
                  size="small"
                />
              </Stack>

              <hr />
              {isloading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 10,
                  }}
                >
                  <CircularProgress />

                  <Typography mt={3} fontWeight={"bold"} textAlign="center">
                    Please Wait... It Might Take Up to 5 minutes to Update!!
                  </Typography>
                </Box>
              ) : (
                <>
                  <Stack
                    spacing={2}
                    ml={"34%"}
                    mt={"100px"}
                    direction="row"
                    align="center"
                  >
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      sx={{ color: "#e69b00" }}
                    >
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          setFileName(e.target.files[0].name);
                          setFile(e.target.files[0]);
                          setDisabled(false);
                        }}
                      />
                      <FolderOpenTwoTone
                        sx={{ width: "45px", height: "45px" }}
                      />
                    </IconButton>
                    <TextField
                      disabled
                      id="filename"
                      label="File Name"
                      variant="standard"
                      value={fileName}
                      type="text"
                      sx={{
                        width: "36%",
                      }}
                    />
                  </Stack>
                  <Stack spacing={20} ml={"29%"} mt={"80px"} direction="row">
                    <Button
                      disabled={disabled}
                      type="submit"
                      onClick={handleSubmit}
                      variant="contained"
                      component="label"
                      color="success"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                      endIcon={<UploadFile />}
                    >
                      UPLOAD
                    </Button>
                    <Button
                      type="submit"
                      disabled={importDisabled}
                      onClick={handleImport}
                      variant="contained"
                      component="label"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                      endIcon={<Task />}
                    >
                      IMPORT
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          }
        />
        {/* <FooterElement /> */}
        {redirectToLoginPage()}
      </div>
    </>
  );
};

export default MrpUpdate;
