import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Modal, Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
const PartyLedgerReport = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const columns = [
    {
      field: "subledgercode",
      headerClassName: "super-app-theme--header",
      headerName: "Code",
      width: 90,
    },
    {
      field: "subledgername",
      headerClassName: "super-app-theme--header",
      headerName: "Party Name",
      width: 350,
      editable: true,
    },
  ];

  const [rows, setRows] = useState([]);

  const [reportType, setReportType] = useState([]);
  const [reportTypeValue, setReportTypeValue] = useState("4");
  const [ledgerType, setledgerType] = useState([
    {
      id: 1,
      name: "Bill Wise",
    },
    {
      id: 2,
      name: "Delivery Note Wise",
    },
  ]);
  const [ledgerTypeValue, setledgerTypeValue] = useState(1);
  const [parties, setParties] = useState([]);
  const [todate, settodate] = useState(date);
  const currentYear = new Date().getFullYear();
  const initialDate = new Date(currentYear, 3, 1); // Months are 0-based

  const [fromdate, setfromdate] = useState(initialDate);

  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  //for billwise
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!fromdate) {
      setLoading(false);
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const body = { partyids: parties };

      const res = await fetch(
        `/reports/spare/partyledger/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const pdfBlob = await res.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfStream(pdfUrl);
      // Optionally, you could open the PDF in a new tab
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setLoading(false);
    }
  };
  //for deliverynote wise
  const handleReportDeliverynoteWise = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!fromdate) {
      setLoading(false);
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const body = { partyids: parties };

      const res = await fetch(
        `/reports/spare/partyledger/deliverynote/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const pdfBlob = await res.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfStream(pdfUrl);
      // Optionally, you could open the PDF in a new tab
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setLoading(false);
    }
  };
  //GET MAIN LEDGERES
  async function getMainLedgers() {
    try {
      const res = await fetch("/reports/partyledger/mainledgers", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      setReportType(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getMainLedgers();
  }, []);

  //GET PARTIES BASED ON MAINLEDGER FOR NORMAL PARTY LEDGER
  const getParties = async () => {
    try {
      const res = await fetch(
        `/reports/partyledger/parties/${reportTypeValue}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const data = await res.json();
      setRows(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (reportTypeValue !== null && parseInt(ledgerTypeValue) === 1) {
      getParties();
    }
  }, [reportTypeValue, ledgerTypeValue]);

  //GET PARTIES BASED ON MAINLEDGER FOR NORMAL PARTY LEDGER
  const getPartiesForDeliveryNote = async () => {
    try {
      const res = await fetch("/reports/partyledger/deliverynote/parties", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      setRows(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (parseInt(ledgerTypeValue) === 2) {
      getPartiesForDeliveryNote();
    }
  }, [ledgerTypeValue]);

  const handleDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const body = { partyids: parties };
      const res = await fetch(
        `/reports/spare/partyledger/download/${option}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PartyLedger_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PartyLedger_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };

  //data only excel billwise
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const body = {
        fromdate,
        todate,
        partyids: parties,
      };
      const data = await fetch("/reports/dataonly/partyledger", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "Partyledger.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  //data only excel billwise
  const printexcelDeliveryNoteWise = async (e) => {
    e.preventDefault();
    try {
      const body = {
        fromdate,
        todate,
        partyids: parties,
      };
      const data = await fetch("/reports/dataonly/partyledger/deliverynote", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "PartyledgerDeliveryNoteWise.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"PARTY LEDGER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              justifyContent={"center"}
              mb={4}
            >
              <TextField
                id="LedgerType"
                name="LedgerType"
                required
                select
                label="Ledger Type"
                value={ledgerTypeValue}
                onChange={(e) => setledgerTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: 220,
                }}
                size="small"
              >
                {ledgerType?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {parseInt(ledgerTypeValue) === 1 && (
                <TextField
                  id="accountname"
                  name="accountname"
                  required
                  select
                  label="Account Name"
                  value={reportTypeValue}
                  onChange={(e) => setReportTypeValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: 200,
                  }}
                  size="small"
                >
                  {reportType.map((option) => (
                    <MenuItem
                      key={option.mainledgerid}
                      value={option.mainledgerid}
                    >
                      {option.mainledgername}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: 150,
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: 150,
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              {parseInt(ledgerTypeValue) === 1 && (
                <>
                  <Button
                    type="submit"
                    onClick={handleReport}
                    disabled={loading === true}
                    variant="contained"
                    component="label"
                    size="small"
                    // sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                    // endIcon={<Task />}
                  >
                    {loading ? "Fetching Data..." : " Show Report"}
                  </Button>{" "}
                  <Button
                    onClick={printexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    // endIcon={<PrintIcon />}
                  >
                    Excel
                  </Button>
                </>
              )}
              {parseInt(ledgerTypeValue) === 2 && (
                <>
                  <Button
                    type="submit"
                    onClick={handleReportDeliverynoteWise}
                    disabled={loading === true}
                    variant="contained"
                    component="label"
                    size="small"
                    // sx={{ boxShadow: "2px 2px 6px  #404142", px: 5 }}
                    // endIcon={<Task />}
                  >
                    {loading
                      ? "Fetching Data..."
                      : "Delivery Note Ledger Report"}
                  </Button>
                  <Button
                    onClick={printexcelDeliveryNoteWise}
                    size="small"
                    variant="contained"
                    color="success"
                    // endIcon={<PrintIcon />}
                  >
                    Delivery Note Ledger Excel
                  </Button>
                </>
              )}
            </Stack>
            <Box sx={{ height: 400, width: "100%", marginLeft: "20%" }}>
              <DataGrid
                sx={{
                  width: "60%",
                  "& .super-app-theme--header": {
                    // backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
                rows={rows}
                columns={columns}
                rowHeight={30}
                headerHeight={40}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                getRowId={(item) => item.subledgerid}
                checkboxSelection
                onSelectionModelChange={(item) => setParties(item)}
              />
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default PartyLedgerReport;
