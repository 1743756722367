import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack, Button } from "@mui/material";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import BackupTable from "@mui/icons-material/BackupTable";
import GTranslate from "@mui/icons-material/GTranslate";
import Filter from "@mui/icons-material/Filter";
import Download from "@mui/icons-material/Download";
import { useState } from "react";

const ReportNavigation = ({ onDownload, reportName }) => {
  const [option, setoption] = useState(1);

  const handleChange = (event) => {
    setoption(event.target.value);
  };
  const handleDownload = (e) => {
    e.preventDefault();
    if (reportName) {
      onDownload(option, reportName);
    } else {
      alert("Please enter a report name.");
    }
  };

  return (
    <div>
      <Box sx={{ marginTop: "2rem", flexGrow: 1, width: "100%" }}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Stack spacing={0} direction="row" width={"100%"}>
              <Stack spacing={0} direction="row" width={400}>
                <FormControl fullWidth>
                  <Select
                    value={option}
                    onChange={handleChange}
                    sx={{
                      width: "70%",
                      margin: "5px",
                      height: "40px",
                      color: "white",
                      border: "1px solid white",
                    }}
                  >
                    <MenuItem value={1}>
                      <PictureAsPdf sx={{ height: 15 }} /> PDF
                    </MenuItem>
                    <MenuItem value={2}>
                      {" "}
                      <BackupTable sx={{ height: 15 }} /> EXCEL
                    </MenuItem>
                  </Select>
                </FormControl>

                <Button
                  onClick={(e) => handleDownload(e)}
                  sx={{
                    height: 40,
                    backgroundColor: "#757de8",
                    pl: 5,
                    pr: 5,
                    mt: 0.5,
                  }}
                  variant="contained"
                  endIcon={<Download />}
                >
                  Download
                </Button>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default ReportNavigation;
