import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Fab,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import { useState, useRef } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LoadingAnimation from "../../Components/LoadingAnimation";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CrediteNoteInvoice from "./CrediteNoteInvoice";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const SaleReturn = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // --------- SHORTCUT KEY FOR ALT ITEM AND AUTO FIRE ON TAB -------- //
  const quantityRef = useRef(null);
  async function handleKeyDown(event) {
    if (event.keyCode === 9 || event.key === "Tab") {
      if (event.target === quantityRef.current) {
        handleaddrow();
      }
    }
  }
  const [disableSave, setDisableSave] = useState(false);
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    LocalstorageHelper.RemoveUserToken();
  }, 3600000);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //  GET BILL Search Parameters //
  const [partyopts, setpartyOpts] = useState([]);
  const [partydetails, setPartyDetails] = useState(null);
  console.log(partydetails);
  // Search parts
  const [partno, setpartno] = useState(null);
  const [stock, setstock] = useState(0);
  // const [delItem, setDelItem] = useState("");
  // const [altFlag, setaltFlag] = useState("");
  const [itemqty, setitemqty] = useState();
  // const [allparts, setallparts] = useState([]);
  useEffect(() => {
    if (partno) {
      quantityRef.current.focus();
    }
  }, [partno]);
  // async function getallparts() {
  //   try {
  //     const response = await fetch("/allparts", {
  //       method: "GET",
  //       headers: { sptoken: userToken },
  //     });
  //     const jsonData = await response.json();
  //     console.log(jsonData);
  //     setallparts(jsonData);
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // }

  // useEffect(() => {
  //   getallparts();
  // }, []);
  async function getpartyOpts() {
    try {
      const response = await fetch("/salereturn/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //DOCUMENT NOS FOR EDIT MODE
  const [docopts, setDocOpts] = useState([]);
  const [docdetails, setDocDetails] = useState(null);
  const [couponcode, setcouponcode] = useState("");
  async function getDocOpts() {
    try {
      const response = await fetch("/salereturn/documentnos", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setDocOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getDocOpts();
  }, []);

  const [billnos, setBillNos] = useState([]);
  const [billnoval, setBillNoVal] = useState(null);
  //HOLD STATE OF PARTY SEARCH INPUT

  const [billsearch, setBillSearch] = useState(null);
  //SET PARTY SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });

  //   CLICKING ON PARTY SEARCH EXECUTES THE FOLLOWING    //
  const handleFetchBills = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/salereturn/fetchsalebills/${partydetails.subledgerid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setBillNos(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  //////////**************************** STATES OF TEXT FIELDS ************************************\\\\\\
  const date = new Date();
  const [billdate, setbilldate] = useState(null);
  const [documentdate, setdocumentdate] = useState(date);
  const [docno, setDocNo] = useState("");

  //Grid Loader status
  const [isLoading, setIsLoading] = useState(false);

  //GRID TABLE COLUMN
  const columns = [
    {
      field: "srlno",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 30,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 140,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 300,
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack No",
      width: 80,
      type: "number",
    },

    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Sale Qty",
      width: 80,
      type: "number",
    },
    {
      field: "retnqty",
      headerClassName: "super-app-theme--header",
      headerName: "Retn-Qty",
      width: 80,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.retnqty;
      },
      valueSetter: (params) => {
        if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) > parseFloat(params.row.saleqty)) {
          toast.error("Can't be Greater Than Sale Qty!", {
            toastId: "error2",
          });
        } else {
          const retnqty = params.value;
          const grossvalue = parseFloat(retnqty * params.row.salerate).toFixed(
            2
          );
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(partydetails.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(partydetails.stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(partydetails.stateid) === 1
              ? 0
              : parseFloat(gstamt).toFixed(2);
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = rows.findIndex(
            (obj) => obj.billdetailid === params.row.billdetailid
          );
          rows[upd_obj].retnqty = retnqty;
          rows[upd_obj].grossvalue = grossvalue;
          rows[upd_obj].discountvalue = discountvalue;
          rows[upd_obj].taxablevalue = taxablevalue;
          rows[upd_obj].cgstvalue = cgstvalue;
          rows[upd_obj].sgstvalue = sgstvalue;
          rows[upd_obj].igstvalue = igstvalue;
          rows[upd_obj].netvalue = netvalue;
          return {
            ...params.row,
            retnqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 80,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 60,
      type: "number",
    },
    {
      field: "taxablevalue",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable",
      width: 80,
      type: "number",
    },

    {
      field: "cgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "CGST",
      width: 70,
      type: "number",
    },

    {
      field: "sgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "SGST",
      width: 70,
      type: "number",
    },
    {
      field: "igstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "IGST",
      width: 70,
      type: "number",
    },
    {
      field: "netvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
    {
      field: "grossvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 100,
      type: "number",
    },
    {
      field: "discountvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Amt",
      width: 100,
      type: "number",
    },
  ];
  const emodecolumns = [
    {
      field: "srlno",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      width: 20,
    },
    {
      field: "itemcode",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      width: 140,
    },
    {
      field: "itemname",
      headerClassName: "super-app-theme--header",
      headerName: "Part Desc",
      width: 300,
    },

    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 80,
      type: "number",
    },

    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "Sale Qty",
      width: 80,
      type: "number",
    },
    {
      field: "retnqty",
      headerClassName: "super-app-theme--header",
      headerName: "Retn-Qty",
      width: 80,
      type: "number",
      editable: true,
      valueGetter: (params) => {
        return params.row.retnqty;
      },
      valueSetter: (params) => {
        if (parseFloat(params.value) < 0) {
          toast.error("Can't Be Negative!", {
            toastId: "error1",
          });
        } else if (parseFloat(params.value) > parseFloat(params.row.saleqty)) {
          toast.error("Can't be Greater Than Sale Qty!", {
            toastId: "error2",
          });
        } else {
          const retnqty = params.value;
          const grossvalue = parseFloat(retnqty * params.row.salerate).toFixed(
            2
          );
          const discountvalue = parseFloat(
            (grossvalue * params.row.discountrate) / 100
          ).toFixed(2);
          const taxablevalue = parseFloat(
            parseFloat(grossvalue) - parseFloat(discountvalue)
          ).toFixed(2);
          const gstamt = parseFloat(
            (taxablevalue * params.row.gstrate) / 100
          ).toFixed(2);
          const cgstvalue =
            parseInt(emodedetails[0].stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const sgstvalue =
            parseInt(emodedetails[0].stateid) === 1
              ? parseFloat(gstamt / 2).toFixed(2)
              : 0;
          const igstvalue =
            parseInt(emodedetails[0].stateid) === 1
              ? 0
              : parseFloat(gstamt).toFixed(2);
          const netvalue = parseFloat(
            parseFloat(taxablevalue) + parseFloat(gstamt)
          ).toFixed(2);
          const upd_obj = emodedetails.findIndex(
            (obj) => obj.salereturndetailid === params.row.salereturndetailid
          );
          emodedetails[upd_obj].retnqty = retnqty;
          emodedetails[upd_obj].grossvalue = grossvalue;
          emodedetails[upd_obj].discountvalue = discountvalue;
          emodedetails[upd_obj].taxablevalue = taxablevalue;
          emodedetails[upd_obj].cgstvalue = cgstvalue;
          emodedetails[upd_obj].sgstvalue = sgstvalue;
          emodedetails[upd_obj].igstvalue = igstvalue;
          emodedetails[upd_obj].netvalue = netvalue;
          return {
            ...params.row,
            retnqty,
            grossvalue,
            discountvalue,
            taxablevalue,
            cgstvalue,
            sgstvalue,
            igstvalue,
            netvalue,
          };
        }
      },
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      width: 80,
      type: "number",
    },
    {
      field: "salerate",
      headerClassName: "super-app-theme--header",
      headerName: "Rate",
      width: 80,
      type: "number",
    },
    {
      field: "gstrate",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 60,
      type: "number",
    },
    {
      field: "taxablevalue",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable",
      width: 80,
      type: "number",
    },

    {
      field: "cgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "CGST",
      width: 70,
      type: "number",
    },
    {
      field: "sgstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "SGST",
      width: 70,
      type: "number",
    },
    {
      field: "igstvalue",
      headerClassName: "super-app-theme--header",
      headerName: "IGST",
      width: 70,
      type: "number",
    },

    {
      field: "netvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 100,
      type: "number",
    },
    {
      field: "grossvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "discountrate",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 100,
      type: "number",
    },
    {
      field: "discountvalue",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Amt",
      width: 100,
      type: "number",
    },
  ];
  const [rows, setrows] = useState([]);

  // STORE GRID DETAILS

  const [griddata, setgriddata] = useState([]);
  const griddataarray = Object.values(griddata);
  console.log(griddataarray);
  //console.log(griddataarray);
  /// TOTAL VALUE STATES FOR HEADER TABLE

  const [tottaxable, setTottaxable] = useState(0);
  const [totcgst, setTotcgst] = useState(0);
  const [totsgst, setTotsgst] = useState(0);
  const [totigst, setTotigst] = useState(0);
  const [totgross, setTotgross] = useState(0);
  const [totnet, setTotnet] = useState(0);
  const [totdiscount, setTotdiscount] = useState(0);
  const [value, setvalue] = useState();
  const [remarks, setremarks] = useState("");
  const [hsn, sethsn] = useState("");
  const [reasoninput, setreasoninput] = useState(null);
  //console.log(reasoninput)
  const [reasonopts, setreasonopts] = useState([]);
  const [gstrate, setgstrate] = useState(0);
  const [gstamt, setgstamt] = useState(0);
  const [cgstamt, setcgstamt] = useState(0);
  const [sgstamt, setsgstamt] = useState(0);
  const [igstamt, setigstamt] = useState(0);
  const [netamt, setnettamt] = useState(0);

  function CustomFooterTotalComponent(props) {
    return (
      <Box sx={{ padding: "10px", height: 140 }}>
        <CustomPagination/>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  TAXABLE
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  GROSS
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  DISCOUNT
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  NET
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.tottaxable}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.totcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totigst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totgross}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.totdiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.totnet}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent.propTypes = {
    tottaxable: PropTypes.number,
    totcgst: PropTypes.number,
    totsgst: PropTypes.number,
    totigst: PropTypes.number,
    totgross: PropTypes.number,
    totnet: PropTypes.number,
    totdiscount: PropTypes.number,
  };
  function CustomFooterTotalComponent2(props) {
    return (
      <Box sx={{ padding: "10px", height: 140 }}>
        <CustomPagination/>
        <Stack direction="row" mt={2}>
          <Table
            sx={{ minWidth: "100%", boxShadow: "2px 6px 15px #5a5a5a" }}
            size="small"
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#3bb9ff",
                border: "0.5px solid black",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  TAXABLE
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  CGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  SGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  IGST
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  GROSS
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  DISCOUNT
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "0.5px solid black" }}
                  align="center"
                >
                  NET
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaltaxable}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.newtotalcgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalsgst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaligst}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotalgross}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {props.newtotaldiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#000", border: "0.5px solid black" }}
                >
                  {" "}
                  {props.newtotalnet}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
    );
  }

  CustomFooterTotalComponent2.propTypes = {
    newtotaltaxable: PropTypes.number,
    newtotalcgst: PropTypes.number,
    newtotalsgst: PropTypes.number,
    newtotaligst: PropTypes.number,
    newtotalgross: PropTypes.number,
    newtotaldiscount: PropTypes.number,
    newtotalnet: PropTypes.number,
  };
  //custom pagination
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handlePageChange = (event, value) => {
      apiRef.current.setPage(value - 1);
    };

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={handlePageChange}
        color="primary"
      />
    );
  }
  /// TOTAL VALUE STATES FOR  HEADER TABLE

  const [newtotaltaxable, setnewtotaltaxable] = useState(0);
  const [newtotalcgst, setnewtotalcgst] = useState(0);
  const [newtotaldiscount, setnewtotaldiscount] = useState(0);
  const [newtotalgross, setnewtotalgross] = useState(0);
  const [newtotalnet, setnewtotalnet] = useState(0);
  const [newtotalsgst, setnewtotalsgst] = useState(0);
  const [newtotaligst, setnewtotaligst] = useState(0);
  ///EDIT MODE GRID STORE ARRAY

  const [emodedetails, setEmodeDetails] = useState([]);
  const editedDetails = Object.values(emodedetails);
  // SUBMIT CUSTOMER ORDER DETAILS
  const handleSaleReturnSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    setDisableSave(true);
    try {
      let preparedDetail;
      let body;
      if (category === "NO" && gstflag === "YES") {
        preparedDetail = [
          {
            billdate: billdate,
            billdetailid: 1,
            billheaderid: null,
            cgstvalue: cgstamt,
            discountrate: 0,
            discountvalue: 0,
            grossvalue: value,
            gstrate: gstrate,
            igstvalue: igstamt,
            itemcode: null,
            itemid: null,
            itemname: reasoninput?.name,
            mrp: value,
            netvalue: netamt,
            rackname: null,
            retnqty: 1,
            saleqty: 1,
            salerate: null,
            sgstvalue: sgstamt,
            srlno: 1,
            taxablevalue: value,
            reasonid: reasoninput?.reasonid,
            hsn,
          },
        ];
        body = {
          documentdate,
          billdate,
          griddataarray: preparedDetail,
          tottaxable: value,
          totgross: value,
          totcgst: cgstamt,
          totsgst: sgstamt,
          totigst: igstamt,
          totdiscount: 0,
          totnet: netamt,
          billamt: netamt,
          roundoff: 0,
          billnoval,
          category,
          gstflag,
          partyid: partydetails?.subledgerid,
          remarks,
          reason: reasoninput?.name,
          couponcode,
        };
      } else if (category === "NO" && gstflag === "NO") {
        preparedDetail = [
          {
            billdate: billdate,
            billdetailid: 1,
            billheaderid: null,
            cgstvalue: 0,
            discountrate: 0,
            discountvalue: 0,
            grossvalue: value,
            gstrate: 0,
            igstvalue: 0,
            itemcode: null,
            itemid: null,
            itemname: reasoninput?.name,
            mrp: value,
            netvalue: value,
            rackname: null,
            retnqty: 1,
            saleqty: 1,
            salerate: null,
            sgstvalue: 0,
            srlno: 1,
            taxablevalue: 0,
            reasonid: reasoninput.reasonid,
            hsn,
          },
        ];
        body = {
          documentdate,
          billdate,
          griddataarray: preparedDetail,
          tottaxable: 0,
          totgross: value,
          totcgst: 0,
          totsgst: 0,
          totigst: 0,
          totdiscount: 0,
          totnet: value,
          billamt: value,
          roundoff: 0,
          billnoval,
          category,
          gstflag,
          partyid: partydetails?.subledgerid,
          remarks,
          reason: reasoninput?.name,
          couponcode,
        };
      } else {
        body = {
          documentdate,
          billdate,
          griddataarray,
          tottaxable,
          totgross,
          totcgst,
          totsgst,
          totigst,
          totdiscount,
          totnet,
          billamt,
          roundoff,
          billnoval,
          category,
          gstflag,
          partyid: partydetails?.subledgerid,
          remarks,
          reason: reasoninput?.name,
          couponcode,
        };
      }
      const response = await fetch("/salereturn/submitsalertn", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(`Document No ${parseRes.docno} Generated Successfully!`, {
          autoClose: 3000,
        });
      } else {
        toast.error(parseRes.message);
        toast.error("Something Went Wrong!");
        console.log(parseRes.error);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //DISABLED STATES

  const [searchdisabled, setSearchDisabled] = useState(true);
  const [searchbtndisabled, setSearchbtndisabled] = useState(true);
  const [partydisabled, setpartydisabled] = useState(false);
  const [outwarddisabled, setoutwarddisabled] = useState(false);
  // SOURCES FLAG  DROPDOWN VALUES AND HANDLER
  const [mode, setMode] = useState(1);

  const allmodes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Edit",
    },
  ];
  // CATEGORY  DROPDOWN VALUES AND HANDLER
  const [category, setCategory] = useState("YES");
  const Category = [
    {
      value: "YES",
      label: "YES",
    },
    {
      value: "NO",
      label: "NO",
    },
  ];
  //credit note reasons
  async function getreasons() {
    try {
      const response = await fetch("/salereturn/reasons", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await response.json();
      // console.log(data);
      setreasonopts(data);
      // setgstrate(data[0].gstrate);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (category === "NO") {
      getreasons();
    }
  }, [category]);
  //calculation for not against jobcard
  async function calculateNewValue() {
    if (partydetails === null && value > 0) {
      toast.error("Please Select A Party!!", {
        toastId: 1,
      });
      setvalue("");
    }
    const gstamount = parseFloat((value * gstrate) / 100).toFixed(2);
    const cgstamount =
      parseInt(partydetails.stateid) === 1
        ? (parseFloat(gstamount) / 2).toFixed(2)
        : 0;
    const sgstamount =
      parseInt(partydetails.stateid) === 1
        ? (parseFloat(gstamount) / 2).toFixed(2)
        : 0;
    const igstamount =
      parseInt(partydetails.stateid) === 1
        ? 0
        : parseFloat(gstamount).toFixed(2);
    const netamount = parseFloat(
      parseFloat(value) + parseFloat(gstamount)
    ).toFixed(2);
    setgstamt(gstamount);
    setcgstamt(cgstamount);
    setsgstamt(sgstamount);
    setigstamt(igstamount);
    setnettamt(netamount);
  }
  useEffect(() => {
    if (category === "NO") {
      calculateNewValue();
    }
  }, [value, category, gstrate]);

  // GST  DROPDOWN VALUES AND HANDLER
  const [gstflag, setgstflag] = useState("YES");
  const GstFlag = [
    {
      value: "YES",
      label: "With GST",
    },
    {
      value: "NO",
      label: "Without GST",
    },
  ];
  //Fetch Bill Details
  const handlebilldetails = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/salereturn/salebilldetails/${billnoval.billheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      setprevreturnedarray(jsonData.previousData);
      setrows(jsonData.saleReturnData);
      setbilldate(jsonData.saleReturnData[0].billdate);
    } catch (err) {
      console.log(err.message);
    }
  };
  // FETCH DATA FOR EDITMODE
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/salereturn/documentdata/${docdetails.salereturnheaderid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);
      if (jsonData[0].withgst === "NO" && jsonData[0].againstbill === "NO") {
        setdocumentdate(jsonData[0].documentdate);
        setbilldate(jsonData[0].billdate);
        setDocNo(jsonData[0].documentno);
        setCategory(jsonData[0].againstbill);
        setgstflag(jsonData[0].withgst);
        setvalue(jsonData[0].netvalue);
        setreasoninput({
          reasonid: jsonData[0].reasonid,
          name: jsonData[0].name,
        });
        setremarks(jsonData[0].remarks);
        setcouponcode(jsonData[0].couponcode);
        setPartyDetails({
          partyid: jsonData[0].partyid,
          partydetails: jsonData[0].partydetails,
          stateid: jsonData[0].stateid,
          destid: jsonData[0].destid,
        });
        sethsn(jsonData[0].hsn);
      } else if (
        jsonData[0].withgst === "YES" &&
        jsonData[0].againstbill === "NO"
      ) {
        setdocumentdate(jsonData[0].documentdate);
        setbilldate(jsonData[0].billdate);
        setDocNo(jsonData[0].documentno);
        setCategory(jsonData[0].againstbill);
        setgstflag(jsonData[0].withgst);
        setvalue(jsonData[0].grossvalue);
        // setnettamt(jsonData[0].netvalue);
        setgstrate(jsonData[0].gstrate);
        setreasoninput({
          reasonid: jsonData[0].reasonid,
          name: jsonData[0].name,
        });
        setcouponcode(jsonData[0].couponcode);
        setremarks(jsonData[0].remarks);
        setPartyDetails({
          partyid: jsonData[0].partyid,
          partydetails: jsonData[0].partydetails,
          stateid: jsonData[0].stateid,
          destid: jsonData[0].destid,
        });
        sethsn(jsonData[0].hsn);
      } else {
        setEmodeDetails(jsonData);
        setdocumentdate(jsonData[0].documentdate);
        setCategory(jsonData[0].againstbill);
        setgstflag(jsonData[0].withgst);
        setbilldate(jsonData[0].billdate);
        setDocNo(jsonData[0].documentno);
        setPartyDetails({
          partyid: jsonData[0].partyid,
          partydetails: jsonData[0].partydetails,
          stateid: jsonData[0].stateid,
          destid: jsonData[0].destid,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  var billamt = Math.round(parseFloat(totnet));
  var roundoff = parseFloat(billamt - parseFloat(totnet).toFixed(2)).toFixed(2);
  var emodebillamt = Math.round(parseFloat(newtotalnet));
  var emoderoundoff = parseFloat(
    emodebillamt - parseFloat(newtotalnet).toFixed(2)
  ).toFixed(2);

  //prev returned data
  const [prevreturnedarray, setprevreturnedarray] = useState([]);
  //add row
  const handleaddrow = (e) => {
    e.preventDefault();
    if (parseInt(mode) === 1) {
      const discountamount = 0;
      const valueamount =
        parseInt(partno.purchaseqty) > parseInt(itemqty)
          ? parseInt(itemqty) * parseFloat(partno.salerate)
          : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
      const taxableamount = parseFloat(valueamount - discountamount).toFixed(2);
      const gstamount = parseFloat(
        (taxableamount * partno.gstrate) / 100
      ).toFixed(2);
      const cgstamt =
        parseInt(partydetails.stateid) === 1
          ? parseFloat(gstamount).toFixed(2) / 2
          : 0;
      const sgstamt =
        parseInt(partydetails.stateid) === 1
          ? parseFloat(gstamount).toFixed(2) / 2
          : 0;
      const igstamt =
        parseInt(partydetails.stateid) === 1
          ? 0
          : parseFloat(gstamount).toFixed(2) / 2;
      const netamount = parseFloat(
        parseFloat(taxableamount) + parseFloat(gstamount)
      ).toFixed(2);
      setrows((oldRows) => [
        ...oldRows,
        {
          srlno: oldRows.length + 1,
          itemid: partno.itemid,
          itemcode: partno.itemcode,
          itemname: partno.partdetails,
          rackname: partno.rackname,
          saleqty: 0,
          retnqty: itemqty,
          mrp: partno.mrp,
          salerate: partno.salerate,
          gstrate: partno.gstrate,
          taxablevalue: parseFloat(taxableamount).toFixed(2),
          cgstvalue: parseFloat(cgstamt).toFixed(2),
          sgstvalue: parseFloat(sgstamt).toFixed(2),
          igstvalue: parseFloat(igstamt).toFixed(2),
          netvalue: parseFloat(netamount).toFixed(2),
          grossvalue: parseFloat(valueamount).toFixed(2),
          discountrate: 0,
          discountvalue: 0,
        },
      ]);
      setpartno(null);
      setitemqty(0);
      setstock(0);
    } else {
      const discountamount = 0;
      const valueamount =
        parseInt(partno.purchaseqty) > parseInt(itemqty)
          ? parseInt(itemqty) * parseFloat(partno.salerate)
          : parseInt(partno.purchaseqty) * parseFloat(partno.salerate);
      const taxableamount = parseFloat(valueamount - discountamount).toFixed(2);
      const gstamount = parseFloat(
        (taxableamount * partno.gstrate) / 100
      ).toFixed(2);
      const cgstamt =
        parseInt(searchInput.stateid) === 1
          ? parseFloat(gstamount).toFixed(2) / 2
          : 0;
      const sgstamt =
        parseInt(searchInput.stateid) === 1
          ? parseFloat(gstamount).toFixed(2) / 2
          : 0;
      const igstamt =
        parseInt(searchInput.stateid) === 1
          ? 0
          : parseFloat(gstamount).toFixed(2) / 2;
      const netamount = parseFloat(
        parseFloat(taxableamount) + parseFloat(gstamount)
      ).toFixed(2);
      setEmodeDetails((oldRows) => [
        ...oldRows,
        {
          srlno: oldRows.length + 1,
          itemid: partno.itemid,
          itemcode: partno.itemcode,
          itemname: partno.partdetails,
          rackname: partno.rackname,
          saleqty: 0,
          retnqty: itemqty,
          mrp: partno.mrp,
          salerate: partno.salerate,
          gstrate: partno.gstrate,
          taxablevalue: parseFloat(taxableamount).toFixed(2),
          cgstvalue: parseFloat(cgstamt).toFixed(2),
          sgstvalue: parseFloat(sgstamt).toFixed(2),
          igstvalue: parseFloat(igstamt).toFixed(2),
          netvalue: parseFloat(netamount).toFixed(2),
          grossvalue: parseFloat(valueamount).toFixed(2),
          discountrate: 0,
          discountvalue: 0,
        },
      ]);
      setpartno(null);
      setitemqty(0);
      setstock(0);
    }
  };
  /// SUBMIT EDITMODE DETAILS

  const handleEditmodeSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      let preparedDetail;
      let body;
      if (category === "NO" && gstflag === "YES") {
        preparedDetail = [
          {
            billdate: billdate,
            salereturnheaderid: docdetails.salereturnheaderid,
            billdetailid: 1,
            billheaderid: null,
            cgstvalue: cgstamt,
            discountrate: 0,
            discountvalue: 0,
            grossvalue: value,
            gstrate: gstrate,
            igstvalue: igstamt,
            itemcode: null,
            itemid: null,
            itemname: reasoninput?.name,
            mrp: value,
            netvalue: netamt,
            rackname: null,
            retnqty: 1,
            saleqty: 1,
            salerate: null,
            sgstvalue: sgstamt,
            srlno: 1,
            taxablevalue: value,
            reasonid: reasoninput?.reasonid,
            couponcode,
            hsn,
          },
        ];
        body = {
          emodedetails: preparedDetail,
          newtotaltaxable: value,
          newtotalcgst: cgstamt,
          newtotalsgst: sgstamt,
          newtotaligst: igstamt,
          newtotalgross: value,
          newtotaldiscount: 0,
          newtotalnet: netamt,
          emodebillamt: netamt,
          emoderoundoff: 0,
          documentdate,
          remarks,
          reason: reasoninput?.name,
          couponcode,
          gstflag,
        };
      } else if (category === "NO" && gstflag === "NO") {
        preparedDetail = [
          {
            billdate: billdate,
            salereturnheaderid: docdetails.salereturnheaderid,
            billdetailid: 1,
            billheaderid: null,
            cgstvalue: 0,
            discountrate: 0,
            discountvalue: 0,
            grossvalue: value,
            gstrate: 0,
            igstvalue: 0,
            itemcode: null,
            itemid: null,
            itemname: reasoninput?.name,
            mrp: value,
            netvalue: value,
            rackname: null,
            retnqty: 1,
            saleqty: 1,
            salerate: null,
            sgstvalue: 0,
            srlno: 1,
            taxablevalue: 0,
            reasonid: reasoninput.reasonid,
            couponcode,
            hsn,
          },
        ];
        body = {
          emodedetails: preparedDetail,
          newtotaltaxable: 0,
          newtotalcgst: 0,
          newtotalsgst: 0,
          newtotaligst: 0,
          newtotalgross: value,
          newtotaldiscount: 0,
          newtotalnet: value,
          emodebillamt: value,
          emoderoundoff: 0,
          documentdate,
          remarks,
          reason: reasoninput?.name,
          couponcode,
          gstflag,
        };
      } else {
        body = {
          emodedetails,
          newtotaltaxable,
          newtotalcgst,
          newtotalsgst,
          newtotaligst,
          newtotalgross,
          newtotaldiscount,
          newtotalnet,
          emodebillamt,
          emoderoundoff,
          documentdate,
          remarks,
          reason: reasoninput?.name,
          couponcode,
          gstflag,
        };
      }

      const response = await fetch("/salereturn/emodesubmit", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <Sidebar
        Title={"CREDIT NOTE"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} mb={2} direction="row" justifyContent={"center"}>
              <Autocomplete
                disabled={searchdisabled}
                id="partyno"
                value={docdetails}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setDocDetails(newValue);
                }}
                options={docopts}
                getOptionLabel={(option) => option.documentdetails}
                sx={{
                  width: 500,
                  boxShadow: "2px 2px 6px  #404142",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder="Search Document"
                  />
                )}
              />
              <ColorButton
                disabled={searchbtndisabled}
                // type="search"
                variant="contained"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Link to="/cninvoice">
                <Button variant="outlined" color="secondary" size="small">
                  Print Credit Note
                </Button>
              </Link>
            </Stack>
            <Stack
              spacing={2}
              mb={1}
              direction="row"
              justifyContent={"flex-start"}
            >
              {prevreturnedarray.length > 0 ? (
                <>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={handleClickOpen}
                  >
                    <PriorityHighIcon />
                  </Fab>
                  <Typography color={"darkmagenta"}>
                    Previously entered data found! Click on notification to
                    view!
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Stack>
            <hr />
            <Stack spacing={2} columnGap={1} direction="row" mt={2} ml={4}>
              <TextField
                id="editmode"
                required
                select
                label="Edit Mode"
                value={mode}
                onChange={(e) => {
                  setMode(e.target.value);
                  if (parseInt(e.target.value) === 1) {
                    setSearchDisabled(true);
                    setSearchbtndisabled(true);
                    setpartydisabled(false);
                    setoutwarddisabled(false);
                  } else if (parseInt(e.target.value) === 2) {
                    setSearchDisabled(false);
                    setSearchbtndisabled(false);
                    setpartydisabled(true);
                    setoutwarddisabled(true);
                  }
                }}
                // helperText="Select customer Edit mode"
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {allmodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="documentNo"
                label="Document No"
                value={docno}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Document Date"
                  disabled
                  inputFormat="DD/MM/YYYY"
                  value={documentdate}
                  onChange={(newValue) => {
                    setdocumentdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Autocomplete
                id="party"
                value={partydetails}
                disabled={partydisabled}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setPartyDetails(newValue);
                  // console.log(newValue);
                }}
                options={partyopts}
                getOptionLabel={(option) => option.partydetails}
                sx={{
                  width: "25% ",
                }}
                renderInput={(ordernos) => (
                  <TextField required {...ordernos} placeholder="Party" />
                )}
                size="small"
              />
            </Stack>

            <Stack
              display={"flex"}
              spacing={1}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              justifyItems={"center"}
            >
              <TextField
                id="againstBill"
                required
                select
                disabled={mode === 2}
                label="Against Bill"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                variant="outlined"
                sx={{
                  width: "10%",
                }}
                size="small"
              >
                {Category.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="gstflag"
                required
                select
                disabled={category === "YES"}
                label="GSTFLAG"
                value={gstflag}
                onChange={(e) => setgstflag(e.target.value)}
                variant="outlined"
                sx={{
                  width: "15%",
                }}
                size="small"
              >
                {GstFlag.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {category === "YES" ? (
                <>
                  {" "}
                  <Button
                    onClick={handleFetchBills}
                    variant="outlined"
                    size="small"
                  >
                    Fetch Outward
                  </Button>
                  <Autocomplete
                    id="outwardNo"
                    value={billnoval}
                    disabled={outwarddisabled}
                    filterOptions={filterOptions2}
                    onChange={(event, newValue) => {
                      setBillNoVal(newValue);
                    }}
                    options={billnos}
                    getOptionLabel={(option) => option.billno}
                    sx={{
                      width: "20% ",
                    }}
                    renderInput={(billnos) => (
                      <TextField
                        required
                        {...billnos}
                        placeholder="Outward No"
                      />
                    )}
                    size="small"
                  />
                  <Button
                    onClick={handlebilldetails}
                    variant="outlined"
                    size="small"
                  >
                    Fetch Details
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    id="value"
                    label="Value"
                    value={value}
                    onChange={(e) => setvalue(e.target.value)}
                    type="number"
                    sx={{
                      width: "15%",
                    }}
                    size="small"
                  />
                  <Autocomplete
                    id="reasons"
                    value={reasoninput}
                    filterOptions={filterOptions2}
                    onChange={(event, newValue) => {
                      setreasoninput(newValue);
                      setgstrate(newValue.gstrate);
                      sethsn(newValue.hsn);
                    }}
                    options={reasonopts}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      width: "20% ",
                    }}
                    renderInput={(billnos) => (
                      <TextField required {...billnos} placeholder="Reasons" />
                    )}
                    size="small"
                  />
                  {reasoninput?.couponflag === true && (
                    <TextField
                      id="couponcode"
                      label="Coupon Code"
                      value={couponcode}
                      onChange={(e) => setcouponcode(e.target.value)}
                      type="number"
                      sx={{
                        width: 150,
                      }}
                      size="small"
                    />
                  )}
                  <TextField
                    id="hsn"
                    label="HSN"
                    value={hsn}
                    type="text"
                    sx={{
                      width: 150,
                    }}
                    size="small"
                  />

                  <TextField
                    id="remarks"
                    label="Remarks"
                    value={remarks}
                    multiline
                    onChange={(e) => setremarks(e.target.value)}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                </>
              )}
            </Stack>
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={2}
              ml={4}
              mb={1}
            >
              {category === "YES" ? (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Outward Date"
                      disabled
                      inputFormat="DD/MM/YYYY"
                      value={billdate}
                      onChange={(newValue) => {
                        setbilldate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="roundoff"
                    label="Round Off"
                    value={parseInt(mode) === 2 ? emoderoundoff : roundoff}
                    type="text"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="billamt"
                    label="Bill Amount"
                    value={parseInt(mode) === 2 ? emodebillamt : billamt}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                </>
              ) : (
                <></>
              )}
              {category === "NO" && gstflag === "YES" ? (
                <>
                  <TextField
                    id="gstrate"
                    label="Gst %"
                    value={gstrate}
                    // type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="gstamt"
                    label="Gst Amt"
                    value={gstamt}
                    onChange={(e) => setgstamt(e.target.value)}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="cgst"
                    label="CGST"
                    value={cgstamt}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="sgst"
                    label="SGST"
                    value={sgstamt}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="isgst"
                    label="IGST"
                    value={igstamt}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />{" "}
                  <TextField
                    id="netamt"
                    label="Net Amt"
                    value={netamt}
                    type="number"
                    sx={{
                      width: "20%",
                    }}
                    size="small"
                  />
                </>
              ) : (
                <></>
              )}
            </Stack>
            {/* {category === "NO" ? (
              <>
                <Stack
                  spacing={2}
                  columnGap={2}
                  direction="row"
                  mt={1}
                  ml={4}
                  mb={1}
                >
                  <Button
                    sx={{ mt: 1 }}
                    variant="outlined"
                    size="small"
                    onClick={handlemOpen}
                    startIcon={<AddCircleOutline />}
                  >
                    ADD A NEW ROW
                  </Button>
                </Stack>
              </>
            ) : (
              <></>
            )} */}

            {parseInt(mode) === 1 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  // boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                {category === "YES" ? (
                  <>
                    <DataGrid
                      sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                        outline: "2px solid red", // Change this to the desired color
                        backgroundColor: "gray",
                        color: "white",
                      }, }}
                      rowHeight={25}
                      headerHeight={40}
                      showColumnRightBorder={true}
                      rows={rows}
                      columns={columns}
                      getRowId={(row) => row.itemid}
                      onStateChange={(params) => {
                        setgriddata(params.rows.idRowsLookup);
                        const taxabletotal = griddataarray
                          .map((item) => parseFloat(item.taxablevalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTottaxable(taxabletotal);
                        const cgsttotal = griddataarray
                          .map((item) => parseFloat(item.cgstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotcgst(cgsttotal);
                        const sgsttotal = griddataarray
                          .map((item) => parseFloat(item.sgstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotsgst(sgsttotal);
                        const igsttotal = griddataarray
                          .map((item) => parseFloat(item.igstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotigst(igsttotal);
                        const grosstotal = griddataarray
                          .map((item) => parseFloat(item.grossvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotgross(grosstotal);
                        const discounttotal = griddataarray
                          .map((item) => parseFloat(item.discountvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotdiscount(discounttotal);
                        const nettotal = griddataarray
                          .map((item) => parseFloat(item.netvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setTotnet(nettotal);
                      }}
                      components={{
                        Footer: CustomFooterTotalComponent,
                      }}
                      componentsProps={{
                        footer: {
                          tottaxable,
                          totcgst,
                          totsgst,
                          totigst,
                          totgross,
                          totdiscount,
                          totnet,
                        },
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    // type="submit"
                    onClick={handleSaleReturnSubmit}
                    disabled={disableSave}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    SAVE DOCUMENT
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {parseInt(mode) === 2 ? (
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  // boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
              >
                {category === "YES" ? (
                  <>
                    <DataGrid
                      sx={{ border: "1px solid #000",  "& .MuiDataGrid-cell:focus": {
                        outline: "2px solid red", // Change this to the desired color
                        backgroundColor: "gray",
                        color: "white",
                      }, }}
                      rowHeight={25}
                      headerHeight={40}
                      showColumnRightBorder={true}
                      rows={emodedetails}
                      columns={emodecolumns}
                      getRowId={(row) => row.itemid}
                      onStateChange={(params) => {
                        const taxabletotal = emodedetails
                          .map((item) => parseFloat(item.taxablevalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotaltaxable(taxabletotal);
                        const cgsttotal = emodedetails
                          .map((item) => parseFloat(item.cgstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotalcgst(cgsttotal);
                        const sgsttotal = emodedetails
                          .map((item) => parseFloat(item.sgstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotalsgst(sgsttotal);
                        const igsttotal = emodedetails
                          .map((item) => parseFloat(item.igstvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotaligst(igsttotal);
                        const grosstotal = emodedetails
                          .map((item) => parseFloat(item.grossvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotalgross(grosstotal);
                        const discounttotal = emodedetails
                          .map((item) => parseFloat(item.discountvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotaldiscount(discounttotal);
                        const nettotal = emodedetails
                          .map((item) => parseFloat(item.netvalue))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2);
                        setnewtotalnet(nettotal);
                      }}
                      pagination={true}
                      components={{
                        Footer: CustomFooterTotalComponent2,
                        // Pagination: CustomPagination,
                      }}
                      componentsProps={{
                        footer: {
                          newtotaltaxable,
                          newtotalcgst,
                          newtotalsgst,
                          newtotaligst,
                          newtotalgross,
                          newtotaldiscount,
                          newtotalnet,
                        },
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <Stack
                  spacing={2}
                  columnGap={6}
                  direction="row"
                  mt={6}
                  pb={6}
                  mx={"42%"}
                >
                  <Button
                    // type="submit"
                    onClick={handleEditmodeSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  >
                    UPDATE DOCUMENT
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            {prevreturnedarray.length > 0 ? (
              <>
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  fullWidth // Set fullWidth to true to make the dialog wide
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Previously Returned Item Details
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <Box sx={{ padding: "10px", height: 200 }}>
                      <Table
                        sx={{ minWidth: 720 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Doc No</TableCell>
                            <TableCell align="center">Code</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Sale Qty</TableCell>
                            <TableCell align="center">Return Qty</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {prevreturnedarray.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.documentno}
                              </TableCell>
                              <TableCell align="right">
                                {row.itemcode}
                              </TableCell>
                              <TableCell align="right">
                                {row.itemname}
                              </TableCell>
                              <TableCell align="right">{row.saleqty}</TableCell>
                              <TableCell align="right">
                                {row.returnqty}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </DialogContent>
                </BootstrapDialog>
              </>
            ) : (
              <></>
            )}
            {/* <Modal
              open={mopen}
              aria-labelledby="modl-box"
              aria-describedby="search partitem"
            >
              <Box sx={style}>
                <Stack
                  columnGap={2}
                  direction="row"
                  component="form"
                  sx={{
                    width: 750,
                    p: 2,
                  }}
                  autoComplete="off"
                >
                  <Autocomplete
                    id="partno"
                    value={partno}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setpartno(newValue);
                      setstock(newValue.purchaseqty);
                      setDelItem(newValue.deleteditem);
                      setaltFlag(newValue.altflag);
                    }}
                    options={allparts}
                    getOptionLabel={(option) => option.partdetails}
                    sx={{
                      width: "30% ",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Part No"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />

                  <TextField
                    id="quantity"
                    label="Quantity"
                    value={itemqty}
                    onChange={(e) => setitemqty(e.target.value)}
                    inputRef={quantityRef}
                    onKeyDown={handleKeyDown}
                    type="number"
                    sx={{
                      width: "30%",
                    }}
                    size="small"
                  />
                  <TextField
                    id="stock"
                    label="Stock"
                    value={stock}
                    type="number"
                    sx={{
                      width: "30%",
                    }}
                    size="small"
                    inputProps={{ tabIndex: "-1" }}
                  />
                </Stack>
                <Stack
                  columnGap={2}
                  direction="row"
                  component="form"
                  sx={{
                    width: 750,
                    p: 2,
                  }}
                  autoComplete="off"
                >
        

                  <Button
                    variant="contained"
                    tabIndex={-1}
                    size="medium"
                    color="success"
                    onClick={handleaddrow}
                  >
                    ADD
                  </Button>
                  <Button
                    variant="contained"
                    tabIndex={-1}
                    size="medium"
                    onClick={handlemClose}
                    color="error"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Modal> */}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SaleReturn;
