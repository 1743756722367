import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";

const WelcomePage = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  const [updates, setUpdates] = useState([]);
  const [resolvedIssues, setResolvedIssues] = useState([]);

  useEffect(() => {
    // Mock data for updates
    const mockUpdates = [
      {
        id: 1,
        title: "Customer Order Party Search",
        description:
          "Implement a subledger-like search functionality for customer orders to easily find parties.",
      },
      {
        id: 2,
        title: "Default Financial Year",
        description:
          "Set the current year as the default financial year for all transactions.",
      },
      {
        id: 3,
        title: "Active Row Color in Sale Bill",
        description:
          "Highlight the active row in the sale bill for better visibility.",
      },
      {
        id: 4,
        title: "Customer Order New Entry Auto Complete",
        description:
          "Enable auto-complete functionality for up to 20 items in new customer order entries.",
      },
      {
        id: 5,
        title: "Stock Transfer Edit Mode Serial Number Descending Order",
        description: "Ensure serial numbers are displayed in descending order in the edit mode for stock transfers."
      },
      {
        id: 6,
        title: "Delivery Note Register PDF",
        description: "Generate PDF reports for the delivery note register.",
      },
      {
        id: 7,
        title: "Party Wise Order Bill Excel",
        description: "Export party-wise order bills to Excel format.",
      },
      {
        id: 8,
        title: "Picklist Serial Number Issue",
        description:
          "Ensure items from the same location are grouped under the same serial number in the sale bill picklist.",
      },
      {
        id: 9,
        title: "Subledger Code Added to Reports",
        description: "The subledger code has been added where needed in Excel and PDF files wherever possible."
      },
      {
        id: 10,
        title: "Report PDF Viewer",
        description: "Some reports were coming off as simple html, we have added PDF viewer inside them for better user experience."
      }
    ];

    // Mock data for resolved issues
    const mockResolvedIssues = [
      {
        id: 1,
        title: "Discount System Rework",
        resolution: "Full Rework of discount system will be implemented.",
      },
      {
        id: 2,
        title: "CREDIT NOTE PRINT",
        resolution:
          "The HSN code will now be displayed from the credit and debit note records instead of the Reason or Account Master",
      },
    ];

    setUpdates(mockUpdates);
    setResolvedIssues(mockResolvedIssues);
  }, []);

  return (
    <div>
      <Sidebar
        Title="WELCOME"
        expandedPanel="panel2"
        children={
          <>
            <Box p={3}>
              <Typography variant="h5" gutterBottom>
                Development Updates - 27/06/2024
              </Typography>
              <List>
                {updates.map((update) => (
                  <ListItem key={update.id}>
                    <ListItemText
                      primary={update.title}
                      secondary={update.description}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box p={3}>
              <Typography variant="h5" gutterBottom>
                Upcoming Updates
              </Typography>
              <List>
                {resolvedIssues.map((issue) => (
                  <ListItem key={issue.id}>
                    <ListItemText
                      primary={issue.title}
                      secondary={issue.resolution}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <FooterElement />
            {redirectToLoginPage()}
          </>
        }
      />
    </div>
  );
};

export default WelcomePage;
