import { Edit } from "@mui/icons-material";
import { Box, Button, MenuItem, Modal, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../../../LocalStorageHelper/LocalstorageHelper";
toast.configure();

const SubLedgerEdit = ({ allSubLCatg, subLCatg, allShorts }) => {
  const userToken = LocalstorageHelper.GetUserToken();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "& button": { m: 1 },
  };

  const [subLCatgname, setSubLCatgname] = useState(subLCatg.subledgercatgname);
  const [shortname, setShortname] = useState(subLCatg.nameid);
  const [helpText, setHelpText] = useState(""); // change helper text
  const [disabled, setDisabled] = useState(false); //UPDATE BUTTON STATE
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSubLCatgname(subLCatg.subledgercatgname);
  };

  //Edit Data Function

  const updateDetails = async (e) => {
    e.preventDefault();
    try {
      const body = { subLCatgname, shortname };
      const response = await fetch(
        `/ledger/updatesubLCategory/${subLCatg.subledgercatgid}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      toast(parseRes, { autoClose: 1000 });
      setOpen(false);
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (err) {
      console.error(err.message);
    }
  };

  ///CHECK IF THE SUB LEDGER CATEGORY EXISTS ALREADY
  const handleCheck = (e) => {
    let value = e.target.value;
    let newValue = value.toUpperCase();
    setSubLCatgname(e.target.value);
    if (allSubLCatg.map((a) => a.subledgercatgname).includes(newValue)) {
      setHelpText("Already Exists!");
      setDisabled(true);
    } else {
      setHelpText("");
      setDisabled(false);
    }
  };

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        open={open}
        aria-labelledby="Edit Main Group"
        aria-describedby="edit Maingroupname and Update"
      >
        <Box sx={style}>
          <Stack
            component="form"
            sx={{
              width: "30ch",
            }}
            spacing={5}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="Sub Ledger Category Name"
              label="Sub Ledger Category Name"
              variant="standard"
              helperText={helpText}
              value={subLCatgname}
              onChange={handleCheck}
            />
            <TextField
              id="ShortName"
              required
              select
              label="Short Name"
              value={shortname}
              onChange={(e) => {
                setShortname(e.target.value);
              }}
              variant="standard"
              sx={{
                width: "100%",
              }}
            >
              {allShorts.map((option) => (
                <MenuItem key={option.nameid} value={option.nameid}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <Button
              disabled={disabled}
              variant="contained"
              size="medium"
              color="primary"
              onClick={(e) => updateDetails(e)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default SubLedgerEdit;
