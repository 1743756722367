import {
  Box,
  Tabs,
  Tab,
  TextField,
  Stack,
  MenuItem,
  Button,
  styled,
  Badge,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { tableCellClasses } from "@mui/material";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import RoleButton from "../../Components/AdminRoleButton/RoleButton";
import MenuCtrlBtn from "../../Components/AdminMenuctrlbtn/AdminMenucontrol";
import DeactivateButton from "../../Components/AdminDeactivateButton/DeactivateButton";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import EditButton from "../../Components/AdminEditButton/EditButton";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7E0D0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.secondary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.black,
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));
const CreatedUsers = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
    //GET ALL THE USERS FROM DATABASE

    const [users, setUsers] = useState([]);

    async function getUsers() {
      try {
        const response = await fetch("/user", {
          method: "GET",
          headers: { token: userToken },
        });
        const jsonData = await response.json();
        setUsers(jsonData.filter((user) => user.deactivation_date === null));
      } catch (err) {
        console.error(err.message);
      }
    }
  
    useEffect(() => {
      getUsers();
    }, []);
  return (
    <>
      <AdminSidebar />
      <Box p={1}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ overflowY: "auto" }}
          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">UserName</StyledTableCell>
                  <StyledTableCell align="left">Created on</StyledTableCell>
                  <StyledTableCell align="left">Company</StyledTableCell>
                  <StyledTableCell align="left">Branch</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <StyledTableRow
                    key={user.user_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="left">
                      {user.user_name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.created_date}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.companyname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.branchname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <EditButton user={user} />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <RoleButton user={user} />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <MenuCtrlBtn user={user} />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <DeactivateButton user={user} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {redirectToLoginPage()}
    </>
  );
};

export default CreatedUsers;
