import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
//import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  Autocomplete,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";

import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
toast.configure();

const Invoice = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);
  const [loading, setLoading] = useState(false);

  const [selectedbills, setselectedbills] = useState([]);
  const [checked, setChecked] = useState(false);
  const date = new Date();
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(null);
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const columns = [
    {
      field: "billdate",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Date",
      width: 94,
      editable: false,
      type: "date",
      // cellClassName: "custom-cell",
      // headerClassName: "custom-header",
    },
    {
      field: "billno",
      headerClassName: "super-app-theme--header",
      headerName: "Bill No.",
      width: 150,
      editable: false,
    },
    {
      field: "subledgername",
      headerClassName: "super-app-theme--header",
      headerName: "Party's Name",
      width: 300,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "irn",
      headerClassName: "super-app-theme--header",
      headerName: "IRN",
      width: 400,
      editable: false,
      cellClassName: "custom-cell4Irn",
      headerClassName: "custom-header",
    },
    {
      field: "ackno",
      headerClassName: "super-app-theme--header",
      headerName: "Ack No.",
      width: 120,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "ackdate",
      headerClassName: "super-app-theme--header",
      headerName: "Ack Date",
      width: 100,
      editable: false,
      type: "date",
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Generate e-Invoice",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Button
            onClick={(e) => handleEinvoice(e, id)}
            variant="contained"
            size="small"
            disabled={loading}
          >
            Generate
          </Button>,
        ];
      },
    },
  ];

  async function getbills() {
    try {
      const response = await fetch("/einvoice/bills/details", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      // setallparts(jsonData);
      setselectedbills(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getbills();
  }, []);

  const [pdfStream, setPdfStream] = useState(null);
  //invoice
  const handleEinvoice = async (e, id) => {
    e.preventDefault();
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        sptoken: userToken,
      };
      const report = await fetch(`/einvoice/irn/${id}`, {
        headers,
      });
      if (report.ok) {
        // Success case: Generate PDF or handle as needed
        const pdfBlob = await report.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfStream(pdfUrl);
        setLoading(false);
      } else {
        // Error case: Handle the error information from the server
        const errorData = await report.json();
        toast.error(errorData.errorMessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //excel
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      //partyarray = parray.map((opt) => opt.subledgerid);
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const data = await fetch("/einvoice/download/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "eInvoice.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [einvoicedata, seteinvoicedata] = useState([]);
  const einvoicecolumns = [
    {
      field: "gstno",
      headerClassName: "super-app-theme--header",
      headerName: "Gst No",
      width: 130,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "partyname",
      headerClassName: "super-app-theme--header",
      headerName: "Party's Name",
      width: 300,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "billno",
      headerClassName: "super-app-theme--header",
      headerName: "Bill No.",
      width: 150,
      editable: false,
    },
    {
      field: "billdate",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Date",
      width: 100,
      editable: false,
      type: "date",
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "billamt",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Amt",
      width: 100,
      editable: false,
      type: "date",
      // cellClassName: "custom-cell",
      // headerClassName: "custom-header",
    },
    {
      field: "ackno",
      headerClassName: "super-app-theme--header",
      headerName: "Ack No.",
      width: 120,
      editable: false,
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "ackdate",
      headerClassName: "super-app-theme--header",
      headerName: "Ack Date",
      width: 100,
      editable: false,
      type: "date",
      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      field: "irn",
      headerClassName: "super-app-theme--header",
      headerName: "IRN",
      width: 400,
      editable: false,
      cellClassName: "custom-cell4Irn",
      headerClassName: "custom-header",
    },
  ];

  const viewdata = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const body = { fromdate: fromDateStr, todate: toDateStr };
      const res = await fetch("/einvoice/data/view", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      seteinvoicedata(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"e-Invoice"}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack spacing={2} mt={1} justifyContent={"center"} direction="row">
              {" "}
              <FormControlLabel
                label="Toggle Report"
                control={<Checkbox checked={checked} onChange={handleToggle} />}
              />
            </Stack>
            {!checked && (
              <Stack spacing={2} mt={1} ml={10} mb={"40px"} direction="row">
                {pdfStream && (
                  <iframe
                    src={pdfStream}
                    width="100%"
                    height="480px"
                    title="report"
                  ></iframe>
                )}
                <Box
                  sx={{
                    height: 600,
                    width: "100%",
                    marginLeft: 10,
                    display: "flex",
                  }}
                >
                  <DataGrid
                    sx={{
                      width: "100%",
                      "& .super-app-theme--header": {
                        // backgroundColor: "#3bb9ff",
                        color: "#000",
                      },
                      // "& .custom-header": {
                      //   fontSize: 10, // Decrease font size for the custom header
                      // },
                      "& .custom-cell": {
                        fontSize: 11, // Decrease font size for the custom cell
                      },
                      "& .custom-cell4Irn": {
                        fontSize: 9, // Decrease font size for the custom cell
                      },
                    }}
                    rows={selectedbills}
                    columns={columns}
                    loading={loading}
                    rowHeight={40}
                    headerHeight={40}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                      // sorting: {
                      //   sortModel: [{ field: "billno", sort: "desc" }],
                      // },
                    }}
                    pageSizeOptions={[5]}
                    getRowId={(item) => item.billheaderid}
                    // checkboxSelection
                    // onSelectionModelChange={(item) => setpartno(item)}
                  />
                </Box>
              </Stack>
            )}
            {checked && (
              <>
                <Stack
                  spacing={2}
                  mt={1}
                  justifyContent={"center"}
                  direction="row"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD/MM/YYYY"
                      value={fromdate}
                      onChange={(newValue) => {
                        setfromdate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      value={todate}
                      onChange={(newValue) => {
                        settodate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    onClick={printexcel}
                    size="small"
                    variant="contained"
                    color="success"
                    //endIcon={<PrintIcon />}
                  >
                    Excel
                  </Button>
                  <Button
                    onClick={viewdata}
                    size="small"
                    variant="contained"
                    color="primary"
                    //endIcon={<PrintIcon />}
                  >
                    View
                  </Button>
                </Stack>
                <Stack spacing={2} mt={3} justifyContent={"center"} direction="row">
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      marginLeft: 10,
                      display: "flex",
                    }}
                  >
                    <DataGrid
                      sx={{
                        width: "100%",
                        "& .super-app-theme--header": {
                          // backgroundColor: "#3bb9ff",
                          color: "#000",
                        },
                        // "& .custom-header": {
                        //   fontSize: 10, // Decrease font size for the custom header
                        // },
                        "& .custom-cell": {
                          fontSize: 11, // Decrease font size for the custom cell
                        },
                        "& .custom-cell4Irn": {
                          fontSize: 9, // Decrease font size for the custom cell
                        },
                      }}
                      rows={einvoicedata}
                      columns={einvoicecolumns}
                      //loading={loading}
                      rowHeight={40}
                      headerHeight={40}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        }
                      }}
                      pageSizeOptions={[5]}
                      getRowId={(item) => item.billheaderid}
                      // checkboxSelection
                      // onSelectionModelChange={(item) => setpartno(item)}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default Invoice;
