import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FooterElement from "../../Components/footer/FooterElement";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Input,
  Switch,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";

import { useState } from "react";
import { useEffect } from "react";

import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
toast.configure();

//STYLED BUTTON
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const Subledger = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 1800000);
  const OPTIONS_LIMIT2 = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT2,
    trim: true,
  });
  // TEXT FIELD VALUE STATES
  const [subledgercode, setSubledgerCode] = useState("");
  const [subledgername, setSubledgerName] = useState("");
  const [creditlimit, setCreditLimit] = useState("");
  const [deactivatedate, setDeactivateDate] = useState(null);
  const [deactivateflag, setDeactivateFlag] = useState(false);
  const [onlineorder, setOnlineOrder] = useState(false);
  const [partsdisc, setPartsDisc] = useState("");
  const [lubdisc, setLubdisc] = useState("");
  const [batterydisc, setBatteryDisc] = useState("");
  const [spcldisc, setSpclDisc] = useState("");
  const [contactperson, setContactPerson] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [regdphnno, setRegdphnno] = useState("");
  const [emailid, setEmailid] = useState("");
  const [panno, setPanno] = useState("");
  const [cstno, setCstno] = useState("");
  const [gstno, setGstno] = useState("");
  const [mainledgerid, setMainLedgerid] = useState("");
  const [destinationid, setDestinationid] = useState("");
  const [salesmanid, setSalesmanid] = useState("");
  const [transporterid, setTransporterid] = useState(null);
  const [subledgercatgid, setSubledgercatgid] = useState("");
  const [gstdate, setGstDate] = useState(null);
  const [pincode, setPinCode] = useState("");
  const [deliveryat, setDeliveryAt] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [creditlimitdays, setCreditlimitdays] = useState("");
  const [availscheme, setAvailscheme] = useState("");
  const [cashtype, setcashtype] = useState(false);
  const [enginepartspia, setenginepartspia] = useState(0);
  const [vehiclepartspia, setvehiclepartspia] = useState(0);
  const [syntheticOilDisc, setSyntheticOilDisc] = useState(0);
  const [semiSyntheticOilDisc, setSemiSyntheticOilDisc] = useState(0);
  const [rateProDisc, setRateProDisc] = useState(0);
  const cashtypeopts = [
    {
      name: "True",
      value: true,
    },
    {
      name: "False",
      value: false,
    },
  ];
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let createddate = `${day}-${month}-${year}`;
  // SET THE UPDATE BUTTON'S DISABLED/ACTIVE STATE

  const [updateDisabled, setUpdatedisabled] = useState(true);
  const [insertDisabled, setInsertDisabled] = useState(false);

  //INSERT AND UPDATE BUTTON ACCORDING TO THE BUTTON PRESSED

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!insertDisabled) {
      try {
        setInsertDisabled(true);
        const body = {
          subledgercode,
          subledgername,
          mainledgerid,
          subledgercatgid,
          availscheme,
          creditlimit,
          creditlimitdays,
          deactivateflag,
          deactivatedate,
          partsdisc,
          lubdisc,
          batterydisc,
          destinationid,
          emailid,
          contactperson,
          phoneno,
          gstno,
          panno,
          gstdate,
          salesmanid,
          transporterid: transporterid.transporterid,
          regdphnno,
          onlineorder,
          address1,
          address2,
          address3,
          cstno,
          pincode,
          deliveryat,
          createddate,
          spcldisc,
          cashtype,
          syntheticOilDisc,
          semiSyntheticOilDisc,
          rateProDisc,
          enginepartspia,
        };
        const response = await fetch("/insertSubledger", {
          method: "POST",
          headers: { "Content-Type": "application/json", sptoken: userToken },
          body: JSON.stringify(body),
        });
        const parseRes = await response.json();

        if (parseRes.salesmanid) {
          toast.success("Subledger Created Successfully", {
            autoClose: 2000,
          });
          //RESET ALL STATE VALUES
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          toast.error(parseRes, { autoClose: 2000 });
          setInsertDisabled(false);
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    if (insertDisabled) {
      try {
        setUpdatedisabled(true);
        const body = {
          subledgercode,
          subledgername,
          mainledgerid,
          subledgercatgid,
          availscheme,
          creditlimit,
          creditlimitdays,
          deactivateflag,
          deactivatedate,
          partsdisc,
          lubdisc,
          batterydisc,
          destinationid,
          emailid,
          contactperson,
          phoneno,
          gstno,
          panno,
          gstdate,
          salesmanid,
          transporterid: transporterid.transporterid,
          regdphnno,
          onlineorder,
          address1,
          address2,
          address3,
          cstno,
          pincode,
          deliveryat,
          spcldisc,
          cashtype,
          syntheticOilDisc,
          semiSyntheticOilDisc,
          rateProDisc,
          enginepartspia,
        };
        const response = await fetch(
          `/updateSubledger/${searchInput.subledgerid}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json", sptoken: userToken },
            body: JSON.stringify(body),
          }
        );
        toast.success(await response.json(), { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } catch (err) {
        console.log(err.message);
        setUpdatedisabled(false);
      }
    }
  };

  //DELETE ITEM

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `/deleteSubledger/${searchInput.subledgerid}`,
        {
          method: "DELETE",
          headers: { sptoken: userToken },
        }
      );
      const parseRes = await response.json();
      toast.error(parseRes, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //  GET Search Parameters //
  const [param, setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/subledgersearch", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);

  //HOLD STATE OF SEARCH INPUT

  const [searchInput, setSearchInput] = useState(null);
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
    matchFrom: "start",
  });

  //   CLICKING ON SEARCH EXECUTES THE FOLLOWING    //

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/subledgerfetch/${searchInput.subledgerid}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData !== "" && jsonData !== null) {
        console.log(jsonData);
        setSubledgerCode(jsonData.subledgercode);
        setSubledgerName(jsonData.subledgername);
        setCreditlimitdays(jsonData.creditlimitdays);
        setDeactivateDate(jsonData.deactivatedate);
        setOnlineOrder(jsonData.onlineorder);
        setDeactivateFlag(jsonData.deactivateflag);
        setSubledgercatgid(jsonData.subledgercategoryid);
        setCreditLimit(jsonData.creditlimitamount);
        setAvailscheme(jsonData.availscheme);
        setPartsDisc(jsonData.partsdiscrate);
        setLubdisc(jsonData.lubdiscrate);
        setBatteryDisc(jsonData.batterydiscrate);
        setSpclDisc(jsonData.specialdis);
        setMainLedgerid(jsonData.mainledgerid);
        setAddress1(jsonData.address1);
        setAddress2(jsonData.address2);
        setAddress3(jsonData.address3);
        setPinCode(jsonData.pincode);
        setDestinationid(jsonData.destinationid);
        setRegdphnno(jsonData.regdphoneno);
        setPhoneno(jsonData.phoneno);
        setEmailid(jsonData.emailid);
        setPanno(jsonData.panno);
        setCstno(jsonData.cstno);
        setGstno(jsonData.gstno);
        setContactPerson(jsonData.contactperson);
        setGstDate(jsonData.gstdate);
        setDeliveryAt(jsonData.deliveryat);
        setSalesmanid(jsonData.salesmanid);
        setTransporterid({
          transporterid: jsonData.transporterid,
          transportername: jsonData.transportername,
        });
        setSyntheticOilDisc(jsonData.syntheticdiscrate);
        setSemiSyntheticOilDisc(jsonData.semisyntheticdiscrate);
        setRateProDisc(jsonData.raceprodiscrate);
        setenginepartspia(jsonData.enginepartspia);
        setUpdatedisabled(false);
        setInsertDisabled(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  //GENERATE AUTO CODE

  const [Fetch, setFetch] = useState(false);

  const handleSubledgerName = (e) => {
    setSubledgerName(e.target.value);
    setFetch(true);
    if (maxCode !== null) {
      const maxCodeNoStr = maxCode.slice(1); // sliced first String e.g- From 'D002' => '002'
      const maxCodeNo = parseInt(maxCodeNoStr); // '002' => 2
      const newMaxCode = (maxCodeNo + 1).toString(); // 2 => '3'
      const padString = newMaxCode.padStart(4, "0"); // '003'
      const finalValue = subledgername
        .charAt(0)
        .toUpperCase()
        .concat(padString);
      setSubledgerCode(finalValue);
    } else {
      setMaxCode(subledgername.charAt(0).toUpperCase().concat("0000"));
    }
  };
  const [maxCode, setMaxCode] = useState(""); // max Code e.g - 'D002'

  async function getMaxSubledgerCode() {
    try {
      const response = await fetch(
        `/maxsubledgercode/${subledgername.charAt(0).toUpperCase()}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      setFetch(false);
      const jsonData = await response.json();
      setMaxCode(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    if (Fetch === true) {
      getMaxSubledgerCode();
    }
  }, [Fetch]);

  // SUBLEDGER FLAG  DROPDOWN VALUES

  const Flags = [
    {
      value: false,
      label: "False",
    },
    {
      value: true,
      label: "True",
    },
  ];

  //MAINLEDGER DROPDOWN OPTIONS

  const [mainldgropts, SetMainLdgrOpts] = useState([]);

  async function getMainledgerOpts() {
    try {
      const response = await fetch("/getmainldgropts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      SetMainLdgrOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getMainledgerOpts();
  }, []);

  // TRANSPORTER DROPDOWN OPTIONS

  const [transporteropts, SetTransporterOpts] = useState([]);

  async function getTransporterOpts() {
    try {
      const response = await fetch("/transporteropts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      SetTransporterOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getTransporterOpts();
  }, []);

  //SALESMAN DROPDOWN OPTIONS

  const [salesmanopts, SetSalesmanOpts] = useState([]);

  async function getSalesmanOpts() {
    try {
      const response = await fetch("/salesmanopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      SetSalesmanOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getSalesmanOpts();
  }, []);

  // AREA DROPDOWN OPTIONS
  const [areaopts, SetAreaOpts] = useState([]);

  async function getAreaOpts() {
    try {
      const response = await fetch("/areaopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      SetAreaOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getAreaOpts();
  }, []);

  // SUB LEDGER CATEGORY DROPDOWN OPTIONS
  const [subldgrcatgopts, SetSubldgrcatgOpts] = useState([]);

  async function getSubldgrcatgOpts() {
    try {
      const response = await fetch("/getsubldgrcatgopts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      SetSubldgrcatgOpts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getSubldgrcatgOpts();
  }, []);

  //deactivate date disable state

  const [isdisabled, setIsdisabled] = useState(true);

  const deactivateflagHandler = (e) => {
    setDeactivateFlag(e.target.value);
    if (e.target.value === false) {
      setDeactivateDate(null);
    }
  };

  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const data = await fetch("/subledger/download/excel", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "subledger.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  const [isSwitchOn, setSwitchOn] = useState(false);
  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
  };
  return (
    <div>
      <Sidebar
        Title={"SUBLEDGER"}
        expandedPanel={"panel1"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              justifyContent={"center"}
              mb={"20px"}
              direction="row"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontSize={"14px"}>CODE WISE</Typography>
                <Switch
                  onChange={handleSwitchChange}
                  checked={!isSwitchOn}
                  size="small"
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography fontSize={"14px"}>NAME WISE</Typography>
              </Stack>
              <Autocomplete
                id="controlled-demo"
                value={searchInput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setSearchInput(newValue);
                }}
                options={param}
                getOptionLabel={(option) =>
                  isSwitchOn
                    ? option.subledgerdetails
                    : option.subledgerdetails2
                }
                size="small"
                sx={{
                  width: "65%",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Items Here..." />
                )}
              />
              <ColorButton
                type="search"
                variant="contained"
                size="small"
                key="one"
                onClick={handleSearch}
                sx={{
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
                }}
              >
                <SearchRoundedIcon />
                SEARCH
              </ColorButton>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Stack>
            <hr />
            <Stack spacing={1} columnGap={2} direction="row" mt={3} ml={3}>
              <TextField
                required
                disabled
                id="subledgerCode"
                label="Code"
                value={subledgercode}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <TextField
                required
                multiline
                id="subledgerName"
                label="Name"
                value={subledgername}
                onChange={handleSubledgerName}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="creditlimitDays"
                label="Creditlimit Days"
                value={creditlimitdays}
                onChange={(e) => setCreditlimitdays(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deactivate Date"
                  inputFormat="DD/MM/YYYY"
                  value={deactivatedate}
                  disabled={
                    isdisabled
                      ? deactivateflag === false
                      : deactivateflag === true
                  }
                  onChange={(newValue) => {
                    setDeactivateDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                id="onlineOrder"
                required
                select
                label="Online Order"
                value={onlineorder}
                onChange={(e) => setOnlineOrder(e.target.value)}
                //helperText="Please select 'True' or 'False'."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Flags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <TextField
                id="deactivate"
                select
                label="Deactivate"
                value={deactivateflag}
                onChange={deactivateflagHandler}
                //helperText="Please select 'True' or 'False'."
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Flags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="parrtyCategory"
                required
                select
                label="Party Category"
                value={subledgercatgid}
                onChange={(e) => {
                  setSubledgercatgid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {subldgrcatgopts.map((option) => (
                  <MenuItem
                    key={option.subledgercatgid}
                    value={option.subledgercatgid}
                  >
                    {option.subledgercatgname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="creditLimit"
                label="Credit Limit"
                value={creditlimit}
                onChange={(e) => setCreditLimit(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="availableScheme"
                required
                select
                label="Available Scheme"
                value={availscheme}
                onChange={(e) => {
                  setAvailscheme(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {Flags.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack
              spacing={1}
              columnGap={2}
              direction="row"
              mt={2}
              ml={3}
              mb={2}
            >
              <TextField
                id="partsDisc"
                label="Parts%"
                value={partsdisc}
                onChange={(e) => setPartsDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="lubricantDisc"
                label="Lubricant%"
                value={lubdisc}
                onChange={(e) => setLubdisc(e.target.value)}
                type="text"
                sx={{
                  width: 110,
                }}
                size="small"
              />
              <TextField
                id="batteryDisc"
                label="Battery%"
                value={batterydisc}
                onChange={(e) => setBatteryDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="spcDisc"
                label="Special%"
                value={spcldisc}
                onChange={(e) => setSpclDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="syntheticOilDisc"
                label="Synthetic%"
                value={syntheticOilDisc}
                InputLabelProps={{shrink:true}}
                onChange={(e) => setSyntheticOilDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="semiSyntheticOilDisc"
                label="SemiSynthetic%"
                value={semiSyntheticOilDisc}
                InputLabelProps={{shrink:true}}
                onChange={(e) => setSemiSyntheticOilDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="rateProDisc"
                label="RateProDisc%"
                InputLabelProps={{shrink:true}}
                value={rateProDisc}
                onChange={(e) => setRateProDisc(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
              <TextField
                id="rateProDisc"
                label="EnginePartsDisc%"
                InputLabelProps={{shrink:true}}
                value={enginepartspia}
                onChange={(e) => setenginepartspia(e.target.value)}
                type="text"
                sx={{
                  width: 100,
                }}
                size="small"
              />
            </Stack>
            <hr />
            <Stack spacing={1} columnGap={2} direction="row" mt={3} ml={3}>
              <TextField
                id="mainLedger"
                required
                select
                label="Main Ledger"
                value={mainledgerid}
                onChange={(e) => {
                  setMainLedgerid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {mainldgropts.map((option) => (
                  <MenuItem
                    key={option.mainledgerid}
                    value={option.mainledgerid}
                  >
                    {option.mainledgername}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="cashType"
                required
                select
                label="Cash Type"
                value={cashtype}
                onChange={(e) => {
                  setcashtype(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {cashtypeopts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <TextField
                required
                multiline
                id="address1"
                label="Address 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                multiline
                id="address2"
                label="Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                multiline
                id="address3"
                label="Address 3"
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                required
                id="pincodeNo"
                label="Pin Code"
                value={pincode}
                onChange={(e) => setPinCode(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <TextField
                id="area"
                required
                select
                label="Area"
                value={destinationid}
                onChange={(e) => {
                  setDestinationid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {areaopts.map((option) => (
                  <MenuItem key={option.destid} value={option.destid}>
                    {option.destname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="contactPerson"
                label="Contact Person"
                value={contactperson}
                onChange={(e) => setContactPerson(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                required
                id="phone1"
                label="Registered Phone No"
                value={regdphnno}
                onChange={(e) => setRegdphnno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="phone2"
                label="Alternate Phone No "
                value={phoneno === null ? "" : phoneno}
                onChange={(e) => setPhoneno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <TextField
                id="email"
                label="Email"
                value={emailid === null ? "" : emailid}
                onChange={(e) => setEmailid(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="panNo"
                label="PAN No"
                value={panno === null ? "" : panno}
                onChange={(e) => setPanno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="cstNo"
                label="Aadhaar No"
                value={cstno === null ? "" : cstno}
                onChange={(e) => setCstno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="gstNo"
                label="GST No"
                value={gstno === null ? "" : gstno}
                onChange={(e) => setGstno(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
            </Stack>
            <Stack spacing={1} columnGap={2} direction="row" mt={2} ml={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="GST Date"
                  inputFormat="DD/MM/YYYY"
                  value={gstdate}
                  onChange={(newValue) => {
                    setGstDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                required
                id="deliveryAt"
                label="Delivery At"
                value={deliveryat}
                onChange={(e) => setDeliveryAt(e.target.value)}
                type="text"
                sx={{
                  width: "20%",
                }}
                size="small"
              />
              <TextField
                id="salesman"
                required
                select
                label="Salesman"
                value={salesmanid}
                onChange={(e) => {
                  setSalesmanid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {salesmanopts.map((option) => (
                  <MenuItem key={option.salesmanid} value={option.salesmanid}>
                    {option.salesmanname}
                  </MenuItem>
                ))}
              </TextField>
              {/* <TextField
                id="transporter"
                required
                select
                label="Transporter"
                value={transporterid}
                onChange={(e) => {
                  setTransporterid(e.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {transporteropts.map((option) => (
                  <MenuItem
                    key={option.transporterid}
                    value={option.transporterid}
                  >
                    {option.transportername}
                  </MenuItem>
                ))}
              </TextField> */}
              <Autocomplete
                id="makersearch"
                disablePortal={true}
                value={transporterid}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setTransporterid(newValue);
                }}
                options={transporteropts}
                getOptionLabel={(option) => option.transportername}
                // isOptionEqualToValue={(option, value) =>
                //   option.makerpickerid === value.makerpickerid
                // }
                sx={{
                  width: "20% ",
                }}
                renderInput={(billopts) => (
                  <TextField
                    required
                    {...billopts}
                    placeholder="Transporters"
                  />
                )}
                size="small"
              />
            </Stack>

            <Stack spacing={2} columnGap={2} direction="row" mt={5} mx={"23%"}>
              <Button
                disabled={insertDisabled}
                type="submit"
                variant="contained"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                INSERT
              </Button>
              <Button
                disabled={updateDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="warning"
                onClick={() => {
                  setSubledgerCode("");
                  setSubledgerName("");
                  setCreditlimitdays("");
                  setDeactivateDate(null);
                  setOnlineOrder(false);
                  setDeactivateFlag(false);
                  setSubledgercatgid("");
                  setCreditLimit("");
                  setAvailscheme("");
                  setPartsDisc("");
                  setLubdisc("");
                  setBatteryDisc("");
                  setSpclDisc("");
                  setMainLedgerid("");
                  setAddress1("");
                  setAddress2("");
                  setAddress3("");
                  setPinCode("");
                  setDestinationid("");
                  setRegdphnno("");
                  setPhoneno("");
                  setEmailid("");
                  setPanno("");
                  setCstno("");
                  setGstno("");
                  setGstDate(null);
                  setDeliveryAt("");
                  setSalesmanid("");
                  setTransporterid("");
                  setContactPerson("");
                  setUpdatedisabled(true);
                  setInsertDisabled(false);
                  setSearchInput(null);
                }}
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 3 }}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                color="error"
                key="four"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                onClick={handleDelete}
              >
                DELETE
              </Button>
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default Subledger;
