import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import UploadFile from "@mui/icons-material/UploadFile";
import Task from "@mui/icons-material/Task";
import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
toast.configure();

const AltItemExcelUpload = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [missingflag, setmissingflag] = useState();
  const [missingitems, setmissingitems] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", file);
      const response = await fetch("/uploadaltitemexcel", {
        method: "POST",
        headers: { sptoken: userToken },
        body: data,
      });
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 2000 });
      setImportDisabled(false);
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleImport = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`/importaltitemexcel/${fileName}`, {
        method: "POST",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      console.log(parseRes);
      if (
        parseRes.updatestatus === "success" &&
        parseRes.missingflag === false
      ) {
        toast.success(parseRes.message, { autoClose: 1000 });
        setIsLoading(false);
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
      } else {
        setIsLoading(false);
        setmissingflag(true);
        setmissingitems(parseRes.missingitems);
        toast.info("UPdated!! Although some items are missing.");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div>
        <Sidebar
          Title={"ALT ITEM UPDATE"}
          expandedPanel={"panel4"}
          children={
            <Box
              component="form"
              sx={{
                width: "100%",
              }}
              Validate
              // onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Stack
                spacing={2}
                ml={"38%"}
                mt={"100px"}
                direction="row"
                align="center"
              >
                <Typography variant="h5" align="center">
                  SELECT THE FILE TO IMPORT
                </Typography>
              </Stack>

              <hr />
              {isloading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 10,
                  }}
                >
                  <CircularProgress />

                  <Typography mt={3} fontWeight={"bold"} textAlign="center">
                    Please Wait... It Might Take Up to 5 minutes to Update!!
                  </Typography>
                </Box>
              ) : (
                <>
                  <Stack
                    spacing={2}
                    ml={"34%"}
                    mt={"100px"}
                    direction="row"
                    align="center"
                  >
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      sx={{ color: "#e69b00" }}
                    >
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          setFileName(e.target.files[0].name);
                          setFile(e.target.files[0]);
                          setDisabled(false);
                        }}
                      />
                      <FolderOpenTwoTone
                        sx={{ width: "45px", height: "45px" }}
                      />
                    </IconButton>
                    <TextField
                      disabled
                      id="filename"
                      label="File Name"
                      variant="standard"
                      value={fileName}
                      type="text"
                      sx={{
                        width: "36%",
                      }}
                    />
                  </Stack>
                  <Stack spacing={20} ml={"29%"} mt={"80px"} direction="row">
                    <Button
                      disabled={disabled}
                      type="submit"
                      onClick={handleSubmit}
                      variant="contained"
                      component="label"
                      color="success"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                      endIcon={<UploadFile />}
                    >
                      UPLOAD
                    </Button>
                    <Button
                      type="submit"
                      disabled={importDisabled}
                      onClick={handleImport}
                      variant="contained"
                      component="label"
                      size="large"
                      sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                      endIcon={<Task />}
                    >
                      IMPORT
                    </Button>
                  </Stack>
                  {missingflag && (
                    <>
                      <Stack spacing={2} mt={"80px"} direction="column" alignItems={'center'}>
                        <Typography variant="h6" textAlign={"center"}>
                          Missing Items!!
                        </Typography>
                        <Box
                          sx={{
                            width: 550,
                          }}
                        >
                          <Table
                            stickyHeader
                            sx={{
                              minWidth: 150,
                              boxShadow: "2px 6px 15px #5a5a5a",
                              height: 100,
                            }}
                            size="small"
                            aria-label="simple table"
                          >
                            <TableHead
                              sx={{
                                backgroundColor: "#3bb9ff",
                                border: "0.5px solid black",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                  align="center"
                                >
                                  ItemCode
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    border: "0.5px solid black",
                                  }}
                                  align="center"
                                >
                                  ItemName
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
                              {missingitems.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {item.itemcode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#000",
                                      border: "0.5px solid black",
                                    }}
                                  >
                                    {item.itemname}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </Box>
          }
        />
        {/* <FooterElement /> */}
        {redirectToLoginPage()}
      </div>
    </>
  );
};

export default AltItemExcelUpload;
