import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Modal, Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { createFilterOptions } from "@mui/material/Autocomplete";
import PrintIcon from "@mui/icons-material/Print";

const SalesmanPartySummary = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const columns = [
    {
      field: "salesmanid",
      headerClassName: "super-app-theme--header",
      headerName: "Code",
      width: 90,
    },
    {
      field: "salesmandetails",
      headerClassName: "super-app-theme--header",
      headerName: "Salesman Name",
      width: 350,
      editable: true,
    },
  ];

  const [selectedparties, setselectedparties] = useState([]);
  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const partyType = [
    {
      name: "Selective",
      value: true,
    },
    {
      name: "All",
      value: false,
    },
  ];
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  const [reportTypeValue, setReportTypeValue] = useState("SUM");
  const [partyTypeValue, setpartyTypeValue] = useState(false);
  const [salesmanid, setsalesmanid] = useState([]);
  // Get the current date
  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3

  // Use the useState hook to manage the state
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(currentDate);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  async function getallparts() {
    try {
      const response = await fetch("/reports/salesmanpartysummary/salesman", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      // setallparts(jsonData);
      setselectedparties(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (salesmanid.length === 0 && partyType === true) {
        setLoading(false)
        toast.error("Please Select A valid Party!");
      } else {
        const body = {
          fromdate,
          todate,
          salesmanarray: salesmanid,
        };
        console.log(body);
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch("/reports/salesmanpartysummary", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
          setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };

  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      if (salesmanid.length === 0 && partyType === true) {
        toast.error("Please Select A valid Party!");
      } else {
        const body = {
          fromdate,
          todate,
          salesmanarray: salesmanid,
        };
        const data = await fetch("/reports/salesmanpartysummary/excel", {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        const blob = await data.blob();

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "Salesmanpartysum.xlsx";

        // Append the link to the body
        document.body.appendChild(downloadLink);

        // Trigger the click event to start the download
        downloadLink.click();

        // Remove the link from the body
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"SALES MAN PARTY SUMMARY"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              mx={2}
              mb={4}
            >
              <TextField
                id="receipt"
                name="receipt"
                required
                select
                label="Party Type"
                value={partyTypeValue}
                onChange={(e) => setpartyTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {partyType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                type="submit"
                onClick={handleReport}
                disabled={loading===true}
                variant="contained"
                component="label"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Loading..." : "Report"}
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Stack>
            {/* <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              mx={2}
              mb={4}
            >
              <Box display={partyType===true?"none":"flex"}>
                <Autocomplete
                  id="party"
                  value={partno}
                  onChange={(event, newValue) => {
                    setpartno(newValue);
                  }}
                  filterOptions={filterOptions}
                  options={allparts}
                  getOptionLabel={(option) => option.partydetails}
                  sx={{
                    width: 300,
                    
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Party Names"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Button
                sx={{ml:"10%"}}
                  variant="contained"
                  tabIndex={-1}
                  size="small"
                  color="success"
                  onClick={handleaddrow}
                >
                  ADD
                </Button>
              </Box>
            </Stack> */}
            {/* <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              mx={20}
              mb={4}
            >

            </Stack> */}
            {/* <Button
              sx={{ ml: "3%", mt: "5px" }}
              variant="outlined"
              size="small"
              onClick={handleOpen}
              startIcon={<AddCircleOutline />}
            >
              ADD PARTY
            </Button> */}
            {/* <Modal
              open={open}
              aria-labelledby="modl-box"
              aria-describedby="search partitem"
            >
              <Box sx={style}>
                <Stack
                  columnGap={4}
                  direction="row"
                  component="form"
                  sx={{
                    width: 750,
                    margin: "auto",
                    p: 3,
                  }}
                  autoComplete="off"
                ></Stack>
                <Stack columnGap={1} direction="row" mb={1} ml={"38%"}>

                  <Button
                    tabIndex={-1}
                    variant="contained"
                    size="medium"
                    onClick={handleClose}
                    color="error"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Modal> */}
            <Box
              sx={{
                height: 400,
                marginLeft: "10%",
                display: partyTypeValue === true ? "flex" : "none",
              }}
            >
              <DataGrid
                sx={{
                  width: "50%",
                  "& .super-app-theme--header": {
                    // backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                }}
                rows={selectedparties}
                columns={columns}
                rowHeight={30}
                headerHeight={40}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                getRowId={(item) => item.salesmanid}
                checkboxSelection
                onSelectionModelChange={(item) => setsalesmanid(item)}
              />
            </Box>
            {/* {pdfStream && (
              // <ReportNavigation
              //   reportName={"BillOutStandong"}
              //   // onDownload={handleDownload}
              // />
            )} */}
            <Box ref={myElementRef}></Box>
            <Box ref={myElementRef} sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default SalesmanPartySummary;
