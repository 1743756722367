import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LocalstorageHelper from "../../../../LocalStorageHelper/LocalstorageHelper";
import Delete from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
toast.configure();
const DestinationDelete = ({dest}) => {

    const userToken = LocalstorageHelper.GetUserToken();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


//Delete Destination Function
const remove = async (id) => {
    try {
      const deleteUser = await fetch(
        `/ledger/deleteDest/${id}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "Application/json" , sptoken: userToken},
        }
      );
      toast.error("Destination Deleted!!", { autoClose: 2000 });
      window.location.reload(false);
    } catch (err) {
      console.error(err.message);
    }
  };



  return (
    <div>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        <Delete/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want To Delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       WARNING! deleting this category can't be rolled back 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={()=> remove(dest.destid)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DestinationDelete;
