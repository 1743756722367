import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
toast.configure();
const NonMovingPartsList = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3

  // Use the useState hook to manage the state
  const date = new Date();
  const [fromdate, setfromdate] = useState(currentDate);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (todate === null) {
        setLoading(false)
        toast.error("Please Select A valid Date!");
      } else {
        const body = { fromdate, todate, format: 1 };
        console.log(body);
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch("/reports/spare/nonmovingpartslist", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
          setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const body = {
        fromdate,
        todate,
        format: option,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const res = await fetch("/reports/spare/nonmovingpartslist", options);
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `NonMove${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `StkAdjstRegister_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);
  return (
    <>
      <Sidebar
        Title={"Non Moving Parts List"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              mx={20}
              mb={4}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "30%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "30%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                disabled={loading===true}
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
            </Stack>
            <Box ref={myElementRef}></Box>
            {pdfStream && (
              <ReportNavigation
                reportName={"NON MOVING PARTS LIST"}
                onDownload={handleDownload}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default NonMovingPartsList;
