import React from "react";
import { Typography } from "@mui/material";

const FooterElement = () => {
  return (
    <div>
      <Typography
        variant="p"
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          // background:
          //   "linear-gradient(101deg, rgba(118,115,149,1) 9%, rgba(60,62,138,1) 35%, rgba(4,9,75,1) 94%)",
          background: "#4278D1",
          background: "-webkit-linear-gradient(top left, #4278D1, #0CACB7)",
          background: "-moz-linear-gradient(top left, #4278D1, #0CACB7)",
          background: " linear-gradient(to bottom right, #4278D1, #0CACB7)",
          
          color: "white",
          textAlign: " center",
          padding: "10px",
          overflow: "hidden",
          zIndex: 99,
        }}
      >
        Copyright © DGS INFOTECH 2022.
      </Typography>
    </div>
  );
}


export default FooterElement;