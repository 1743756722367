import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Stack,
  Modal,
  Autocomplete,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { createFilterOptions } from "@mui/material/Autocomplete";
import PrintIcon from "@mui/icons-material/Print";

const CustomerOrderReport = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const columns = [
    {
      field: "srlno",
      headerName: "Srl",
      width: 60,
      sortable: false,
    },
    {
      field: "partyname",
      headerName: "Party Name",
      width: 800,
    },
  ];

  const [parray, setitemarray] = useState([]);
  let partyarray = [];
  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const reportType = [
    {
      name: "Due List",
      value: 1,
    },
    {
      name: "Summary",
      value: 2,
    },
  ];
  const [reportTypeValue, setReportTypeValue] = useState(1);
  const [radioInput, setRadioInput] = useState(0);
  const handleRadio = (e) => {
    setRadioInput(e.target.value);
  };
  const [partno, setpartno] = useState(null);
  const [allparts, setallparts] = useState([]);
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(null);
  const [pdfStream, setPdfStream] = useState(null);
  const [disprntbtn, setdisprntbtn] = useState(true);
  const [loading, setLoading] = useState(false);
  async function getallparts() {
    try {
      const response = await fetch("/reports/customerorder/parties", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  const handleaddrow = (e) => {
    e.preventDefault();
    const newrow = [
      ...parray,
      {
        srlno: parray.length + 1,
        partyname: partno.subledgerdetails,
        subledgerid: partno.subledgerid,
      },
    ];
    setitemarray(newrow);
    setpartno(null);
  };
  const OPTIONS_LIMIT = 100;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (fromdate === null) {
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      partyarray = parray.map((opt) => opt.subledgerid);
      const body = { partyarray };
      const res = await fetch(
        `/reports/customerorder/${radioInput}/${reportTypeValue}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await res.blob();
      const pdfUrl = URL.createObjectURL(data)
      setPdfStream(pdfUrl);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      partyarray = parray.map((opt) => opt.subledgerid);
      const body = { partyarray };
      const res = await fetch(
        `/reports/customerorder/download/${option}/${radioInput}/${reportTypeValue}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `${
          parseInt(reportTypeValue) === 1
            ? "CusotomerOrderDueList"
            : "CustomerOrderSummary"
        }_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `${
          parseInt(reportType) === 1
            ? "CusotomerOrderDueList"
            : "CustomerOrderSummary"
        }_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };

  //print excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      partyarray = parray.map((opt) => opt.subledgerid);
      const body = { partyarray };
      const data = await fetch(
        `/reports/customerorder/excel/download/${radioInput}/${reportTypeValue}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "CustomerOrder.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"Customer Order Report"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <TextField
                id="reporttype"
                name="reporttype"
                required
                select
                label="Report Type"
                value={reportTypeValue}
                onChange={(e) => setReportTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {reportType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Report For
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioInput}
                  onChange={handleRadio}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="All Parties"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Selective Partie(s)"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                type="submit"
                onClick={handleReport}
                disabled={loading===true}
                variant="contained"
                component="label"
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
            </Box>
            <Box sx={{ display: parseInt(radioInput) === 0 ? "none" : "flex" }}>
              <Button
                sx={{ width: "150px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD PARTY
              </Button>
            </Box>
            <Box
              sx={{
                display: parseInt(radioInput) === 0 ? "none" : "flex",
                marginTop: "2rem",
                width: "100%",
              }}
            >
              <DataGrid
                sx={{ width: "100%", height: 200 }}
                rows={parray}
                columns={columns}
                getRowId={(opt) => opt.srlno}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                density="compact"
                disableColumnMenu
              />
            </Box>
            {pdfStream && (
              <ReportNavigation
                onDownload={handleDownload}
                reportName={"CustomerOrderReport"}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
      <Box>
        <Dialog open={open}>
          <DialogTitle>Select Party:</DialogTitle>
          <DialogContent sx={{ width: "500px", height: "auto" }}>
            <Box>
              <Autocomplete
                id="party"
                value={partno}
                onChange={(event, newValue) => {
                  setpartno(newValue);
                }}
                options={allparts}
                getOptionLabel={(option) => option.subledgerdetails}
                filterOptions={filterOptions}
                sx={{
                  width: "400px",
                  boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Party Names"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "10px",
              }}
            >
              <Button
                variant="contained"
                tabIndex={-1}
                size="medium"
                color="success"
                onClick={handleaddrow}
              >
                ADD
              </Button>
              <Button
                tabIndex={-1}
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CustomerOrderReport;
