import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import UploadFile from "@mui/icons-material/UploadFile";
import Task from "@mui/icons-material/Task";
import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
toast.configure();

///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const BackOrder = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const [btnloading, setBtnLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("file", file);
      const response = await fetch("/uploadbackorder", {
        method: "POST",
        headers: { sptoken: userToken },
        body: data,
      });
      const parseRes = await response.json();
      toast.success(parseRes, { autoClose: 2000 });
      setImportDisabled(false);
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleImport = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await fetch(`/importbackorder/${fileName}`, {
        method: "POST",
        headers: { sptoken: userToken },
      });
      const parseRes = await response.json();
      setBtnLoading(false);
      toast.success(parseRes, { autoClose: 1000 });
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <Sidebar
        Title={"TRANSPORTER"}
        expandedPanel={"panel4"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            Validate
            // onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Typography variant="h5" align="center" mt={"24px"} mb={4}>
              SELECT THE FILE TO IMPORT
            </Typography>
            <hr />
            <Stack
              spacing={2}
              ml={"34%"}
              mt={"100px"}
              direction="row"
              align="center"
            >
              <IconButton
                aria-label="upload picture"
                component="label"
                sx={{ color: "#e69b00" }}
              >
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFileName(e.target.files[0].name);
                    setFile(e.target.files[0]);
                    setDisabled(false);
                  }}
                />
                <FolderOpenTwoTone sx={{ width: "45px", height: "45px" }} />
              </IconButton>
              <TextField
                disabled
                id="filename"
                label="File Name"
                variant="standard"
                value={fileName}
                type="text"
                sx={{
                  width: "36%",
                }}
              />
            </Stack>
            <Stack spacing={20} ml={"29%"} mt={"80px"} direction="row">
              <Button
                disabled={disabled}
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                component="label"
                color="success"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                endIcon={<UploadFile />}
              >
                UPLOAD
              </Button>
              {btnloading === true ? (
                <CircularProgress
                  size={40}
                />
              ) : (
                <Button
                  type="submit"
                  disabled={importDisabled}
                  onClick={handleImport}
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ boxShadow: "2px 2px 6px  #404142", px: 5, py: 2 }}
                  endIcon={<Task />}
                >
                  IMPORT
                </Button>
              )}
            </Stack>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default BackOrder;
