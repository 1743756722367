import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { createFilterOptions } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PurchaseRegister = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const columns = [
    {
      field: "srlno",
      headerName: "Srl",
      width: 60,
      sortable: false,
    },
    {
      field: "itemcode",
      headerName: "Item Code",
      width: 700,
    },
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Actions",
    //   headerClassName: "super-app-theme--header",
    //   width: 100,
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={() => handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  const [itemarray, setitemarray] = useState([]);
  let itemids = [];
  // const handleDeleteClick = (id) => {
  //   const filter = itemarray.filter((opt) => opt.srlno == id);
  //   setitemarray(filter);
  // };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = useState(0);
  const [radioInput, setRadioInput] = useState(0);
  const handleRadio = (e) => {
    setRadioInput(e.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [partno, setpartno] = useState(null);
  const [allparts, setallparts] = useState([]);
  const [todate, settodate] = useState(date);
  const [fromdate, setfromdate] = useState(null);
  const [todates, settodates] = useState(date);
  const [fromdates, setfromdates] = useState(null);
  const [pdfStream, setPdfStream] = useState(null);
  const [pdfStreamd, setPdfStreamd] = useState(null);
  const [loading, setLoading] = useState(false);

  /////////////MODAL FETCH IN DETAILS/////////
  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  const handleaddrow = (e) => {
    e.preventDefault();
    const newrow = [
      ...itemarray,
      {
        srlno: itemarray.length + 1,
        itemcode: partno.partdetails,
        itemid: partno.itemid,
      },
    ];
    setitemarray(newrow);
    setpartno(null);
  };
  ////////////////////////////////////////////////////
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  ////////////////////////////////////////////////SUMMARY API CALL////////////////////////////////////////////////////////////
  const handlesumReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (fromdates === null) {
      setLoading(false)
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdates.toISOString();
      const toDateStr = todates.toISOString();

      const res = await fetch(
        `/reports/spare/purregister/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const data = await res.blob();
      const pdfUrl = URL.createObjectURL(data);
      setPdfStream(pdfUrl);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdates.toISOString();
      const toDateStr = todates.toISOString();
      const res = await fetch(
        `/reports/spare/purregister/download/${option}/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PurchaseRegisterSummary_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PurchaseRegisterSummary_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  //print excel
  const printexcelpsum = async (e) => {
    e.preventDefault();
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      const data = await fetch(
        `/reports/purchasesum/excel/download/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      downloadLink.download = "PURCHASE_SUMMARY_REPORT";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////DETAILS API CALL//////////////////////////////////////////////////////////
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (fromdate === null) {
      setLoading(false)
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const res = await fetch(
        `/reports/spare/purregisterdtls/${radioInput}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await res.blob();
      const pdfUrl = URL.createObjectURL(data);
      setPdfStreamd(pdfUrl);
      setLoading(false)
      console.log(data);
    } catch (error) {
      setLoading(false)
      console.log(error.message);
    }
  };
  const handleDDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const res = await fetch(
        `/reports/spare/purregisterdtls/download/${option}/${radioInput}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PurchaseRegisterDetails_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PurchaseRegisterDetails_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const printexcelpdetl = async (e) => {
    e.preventDefault();
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const data = await fetch(
        `/reports/purchasedetl/excel/download/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      downloadLink.download = "PURCHASE_DETAIL_REPORT";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Sidebar
        Title={"Purchase Register(Detail/Summary)"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Summary" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    marginTop: "4rem",
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "10px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD/MM/YYYY"
                      value={fromdate}
                      onChange={(newValue) => {
                        setfromdate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      value={todate}
                      onChange={(newValue) => {
                        settodate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <FormLabel>
                      <b>Purchase Register For</b>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioInput}
                      onChange={handleRadio}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="All Parts"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Selective Part(s)"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Button
                    type="submit"
                    onClick={handleReport}
                    variant="contained"
                    disabled={loading===true}
                    component="label"
                    size="small"
                    sx={{
                      boxShadow: "2px 2px 6px  #404142",
                    }}
                    endIcon={<Task />}
                  >
                    {loading ? "Fetching Data..." : " Show Report"}
                  </Button>
                  <Button
                    onClick={printexcelpdetl}
                    size="small"
                    variant="contained"
                    color="success"
                    endIcon={<PrintIcon />}
                  >
                    Excel
                  </Button>
                </Box>
                <Box
                  sx={{ display: parseInt(radioInput) === 0 ? "none" : "flex" }}
                >
                  <Button
                    sx={{ width: "150px" }}
                    variant="outlined"
                    size="medium"
                    onClick={handleOpen}
                    startIcon={<AddCircleOutline />}
                  >
                    ADD PARTS
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: parseInt(radioInput) === 0 ? "none" : "flex",
                    marginTop: "2rem",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      sx={{
                        width: "100%",
                        height: 200,
                      }}
                      rows={itemarray}
                      columns={columns}
                      getRowId={(opt) => opt.srlno}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick
                      density="compact"
                      disableColumnMenu
                    />
                  </Box>
                </Box>
                {pdfStreamd && (
                  <ReportNavigation
                    onDownload={handleDDownload}
                    reportName={"PurchaseRegisterDetails"}
                  />
                )}
                <Box sx={{ backgroundColor: "white" }}>
                  {pdfStreamd && (
                    <iframe
                      src={pdfStreamd}
                      width="100%"
                      height="680px"
                      title="report"
                    ></iframe>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    marginTop: "4rem",
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD/MM/YYYY"
                      value={fromdates}
                      onChange={(newValue) => {
                        setfromdates(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD/MM/YYYY"
                      value={todates}
                      onChange={(newValue) => {
                        settodates(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          sx={{
                            width: "20%",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    type="submit"
                    onClick={handlesumReport}
                    disabled={loading===true}
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ boxShadow: "2px 2px 6px  #404142" }}
                    endIcon={<Task />}
                  >
                    {loading ? "Fetching Data..." : " Show Report"}
                  </Button>
                  <Button
                    onClick={printexcelpsum}
                    size="small"
                    variant="contained"
                    color="success"
                    endIcon={<PrintIcon />}
                  >
                    Excel
                  </Button>
                </Box>
                {pdfStream && (
                  <ReportNavigation
                    onDownload={handleDownload}
                    reportName={"PurchaseRegisterSummary"}
                  />
                )}
                <Box sx={{ backgroundColor: "white" }}>
                  {pdfStream && (
                    <iframe
                      src={pdfStream}
                      width="100%"
                      height="680px"
                      title="report"
                    ></iframe>
                  )}
                </Box>
              </TabPanel>
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
      <Box>
        <Dialog open={open}>
          <DialogTitle id="alert-dialog-title">Select Parts:</DialogTitle>
          <DialogContent sx={{ width: "500px", height: "auto" }}>
            <Box>
              <Autocomplete
                id="party"
                value={partno}
                onChange={(event, newValue) => {
                  setpartno(newValue);
                }}
                options={allparts}
                getOptionLabel={(option) => option.partdetails}
                sx={{
                  width: "400px",
                  boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
                }}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Part Names"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "10px",
              }}
            >
              <Button
                variant="contained"
                tabIndex={-1}
                size="medium"
                color="success"
                onClick={handleaddrow}
              >
                ADD
              </Button>
              <Button
                tabIndex={-1}
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default PurchaseRegister;
