import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  Stack,
  Modal,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { createFilterOptions } from "@mui/material/Autocomplete";
const StockLedgerBinCard = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const columns = [
    {
      field: "srlno",
      headerName: "Srl",
      width: 60,
      sortable: false,
    },
    {
      field: "itemcode",
      headerName: "Item Code",
      width: 500,
    },
  ];

  const [itemarray, setitemarray] = useState([]);
  let itemids = [];
  const style = {
    position: "absolute",
    top: "35%",
    left: "58%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #C0C0C0",
    borderRadius: "5px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    "& button": { m: 1 },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [partno, setpartno] = useState(null);
  const [allparts, setallparts] = useState([]);
  const currentYear = new Date().getFullYear();
  const initialDate = new Date(currentYear, 3, 1); // Months are 0-based

  const [fromdate, setfromdate] = useState(initialDate);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);
  async function getallparts() {
    try {
      const response = await fetch("/allparts", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setallparts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getallparts();
  }, []);
  const handleaddrow = (e) => {
    e.preventDefault();
    const newrow = [
      ...itemarray,
      {
        srlno: itemarray.length + 1,
        itemcode: partno.partdetails,
        itemid: partno.itemid,
      },
    ];
    setitemarray(newrow);
    setpartno(null);
  };
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (fromdate === null) {
      setLoading(false);
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const res = await fetch(
        `/reports/spare/stkledgerbincard/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await res.blob();
      const pdfUrl = URL.createObjectURL(data);
      setPdfStream(pdfUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const res = await fetch(
        `/reports/spare/stkledgerbincard/download/${option}/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `StockLedgerBincard_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `StockLedgerBincard_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });

  //data only excel
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate.toISOString();
      const toDateStr = todate.toISOString();
      itemids = itemarray.map((opt) => opt.itemid);
      const body = { itemids };
      const data = await fetch(
        `/reports/spare/stkledgerbincard/excel/${fromDateStr}/${toDateStr}`,
        {
          method: "POST",
          headers: {
            sptoken: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "Stkledgerbincard.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"STOCK LEDGER BIN CARD"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                disabled={loading === true}
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Box>
            <Box>
              <Button
                sx={{ width: "150px" }}
                variant="outlined"
                size="small"
                onClick={handleOpen}
                startIcon={<AddCircleOutline />}
              >
                ADD A NEW ROW
              </Button>
            </Box>
            <Box sx={{ width: "100%", marginTop: "2rem" }}>
              <DataGrid
                sx={{
                  width: "100%",
                  height: 300,
                }}
                rows={itemarray}
                columns={columns}
                getRowId={(opt) => opt.srlno}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                density="compact"
                disableColumnMenu
              />
            </Box>
            {pdfStream && (
              <ReportNavigation
                onDownload={handleDownload}
                reportName={"StockLedgerBincard"}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
      <Dialog open={open}>
        <DialogTitle>Select Parts No</DialogTitle>
        <DialogContent sx={{ width: "500px", height: "auto" }}>
          <Autocomplete
            id="partno"
            value={partno}
            onChange={(event, newValue) => {
              setpartno(newValue);
            }}
            options={allparts}
            getOptionLabel={(option) => option.partdetails}
            filterOptions={filterOptions}
            sx={{
              width: "400px",
              boxShadow: " rgba(0, 0, 0, 0.55) 0px 3px 10px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Part No"
                variant="outlined"
                size="small"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "10px",
            }}
          >
            <Button
              variant="contained"
              tabIndex={-1}
              size="medium"
              color="success"
              onClick={handleaddrow}
            >
              ADD
            </Button>
            <Button
              tabIndex={-1}
              variant="contained"
              size="medium"
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StockLedgerBinCard;
