import React, { useEffect } from "react";
import { Box, Stack, Autocomplete, Modal, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import Task from "@mui/icons-material/Task";
const PlacingList = () => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const userToken = LocalstorageHelper.GetUserToken();
  const [open, setOpen] = useState(false);
  const [param,setParam] = useState([]);
  async function getParam() {
    try {
      const response = await fetch("/reports/purbilldetails", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setParam(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getParam();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [searchInput, setSearchInput] = useState(null);
  const [pdfStream, setPdfStream] = useState(null);
  const handleReport = async (e) => {
    e.preventDefault();
      try {
        const report = await fetch(
          `/reports/getplacinglist/${searchInput.inwardid}`
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      } catch (err) {
        console.log(err.message);
      }
  };
  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
        size="large"
        sx={{ boxShadow: "2px 2px 6px  #404142" }}
      >
        Placing List
      </Button>

      <Modal 
        open={open}
        aria-labelledby="modl-box"
        aria-describedby="search partitem"
      >
        <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 6,
              mt:2
        
        }}>
          <Box sx={{display:"flex",flexWrap:"wrap",columnGap:"20px"}}>
            <Autocomplete
              id="party"
               value={searchInput}
              filterOptions={filterOptions}
               onChange={(event, newValue) => {
                   setSearchInput(newValue);
              }}
               options={param}
               getOptionLabel={(option) => option.inwardno}
              sx={{
                width: "30% ",
              }}
              renderInput={(billopts) => (
                <TextField required {...billopts} placeholder="Inward No" />
              )}
              size="small"
            />

            <Button
              type="submit"
               onClick={handleReport}
              variant="contained"
              component="label"
              size="large"
              sx={{ boxShadow: "2px 2px 6px  #404142" }}
              endIcon={<Task />}
            >
              Show Report
            </Button>
            <Button
                variant="contained"
                size="medium"
                onClick={handleClose}
                color="error"
              >
                Close
              </Button>
            </Box>
          {pdfStream && <ReportNavigation />}
          <Box sx={{ backgroundColor: "white" }}>
            {pdfStream && (
              <iframe
                src={pdfStream}
                width="100%"
                height="350px"
                title="report"
              ></iframe>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PlacingList;
